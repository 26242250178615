import defaultTheme from './default';
import darkTheme from './dark';

export const getTheme = theme => {
  switch (theme) {
    case 'dark':
      return darkTheme;
    default:
      return defaultTheme;
  }
};

export default defaultTheme;
