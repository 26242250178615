// Default redux namespace
export const namespace = 'captudata';

// Use this states for API requests
export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';

// Use this for changing local state
export const UPDATE = 'UPDATE';
export const REQUEST = 'REQUEST';
export const ADD = 'ADD';
export const ADD_NEXT_PAGE = 'ADD_NEXT_PAGE';
export const REMOVE_PAGINATION = 'REMOVE_PAGINATION';
export const RESET = 'RESET';
export const TOGGLE = 'TOGGLE';
export const REMOVE = 'REMOVE';

// Login states
export const CHECK_AUTHORIZATION = 'CHECK_AUTHORIZATION';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGOUT = 'LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_ADD = 'LOGIN_ADD';
export const LOGIN_USER_PERMISSIONS = 'LOGIN_USER_PERMISSIONS';
export const USER_LOCALE_CHANGE_REQUEST = 'USER_LOCALE_CHANGE_REQUEST';
export const USER_LOCALE_CHANGE_ERROR = 'USER_LOCALE_CHANGE_ERROR';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

// Organization group types
export const REQUEST_ORG_TYPES = 'REQUEST_ORG_TYPES';
export const ADD_ORG_TYPES = 'ADD_ORG_TYPES';
export const REQUEST_ORG_GROUP = 'REQUEST_ORG_GROUP';
export const ADD_ORG_GROUP = 'ADD_ORG_GROUP';

// Program
export const REQUEST_PROGRAM = 'REQUEST_PROGRAM';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const SHOW_PROJECT = 'SHOW_PROJECT';
export const SELECT_PROJECT_ID = 'SELECT_PROJECT_ID';
export const REMOVE_PROGRAM = 'REMOVE_PROGRAM';

// Inspection
export const REQUEST_PROJECT = 'REQUEST_PROJECT';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_INSPECTIONS = 'ADD_INSPECTIONS';
export const ADD_INSPECTION = 'ADD_INSPECTION';
export const RELOAD_INSPECTION = 'RELOAD_INSPECTION';
export const REQUEST_INSPECTION = 'REQUEST_INSPECTION';
export const HOVER_OBSERVATION = 'HOVER_OBSERVATION';
export const SELECT_OBSERVATION = 'SELECT_OBSERVATION';
export const SET_INSPECTION_DEFAULT = 'SET_INSPECTION_DEFAULT';
export const SET_INSPECTION_PUBLISHED = 'SET_INSPECTION_PUBLISHED';
export const INSPECTION_PUBLISH_UPDATED = 'INSPECTION_PUBLISH_UPDATED';
export const INSPECTION_UPDATED = 'INSPECTION_UPDATED';
export const PROJECT_UPDATED = 'PROJECT_UPDATED';
export const GET_INSPECTION_KML = 'GET_INSPECTION_KML';
export const DOWNLOAD_INSPECTION_KML = 'DOWNLOAD_INSPECTION_KML';
export const DOWNLOADED_KML = 'DOWNLOADED_KML';
export const DOWNLOAD_INSPECTION_PDF = 'DOWNLOAD_INSPECTION_PDF';
export const SET_INSPECTION_STATUS = 'SET_INSPECTION_STATUS';
export const SYNC_WITH_THIRD_PARTY = 'SYNC_WITH_THIRD_PARTY';
export const SYNC_WITH_THIRD_PARTY_SUCCESS = 'SYNC_WITH_THIRD_PARTY_SUCCESS';
export const GET_INSPECTIONS_BY_PROJECT = 'GET_INSPECTIONS_BY_PROJECT';
export const GET_INSPECTIONS_BY_PROJECT_SUCCESS = 'GET_INSPECTIONS_BY_PROJECT_SUCCESS';
export const GET_INSPECTIONS_BY_PROJECT_FAIL = 'GET_INSPECTIONS_BY_PROJECT_FAIL';
export const GET_INSPECTION_ASSETS = 'GET_INSPECTION_ASSETS';
export const GET_INSPECTION_ASSETS_SUCCESS = 'GET_INSPECTION_ASSETS_SUCCESS';
export const GET_INSPECTION_ASSETS_FAIL = 'GET_INSPECTION_ASSETS_FAIL';

// Inspection files
export const REQUEST_INSPECTION_FILES = 'REQUEST_INSPECTION_FILES';
export const UPLOAD_INSPECTION_FILE = 'UPLOAD_INSPECTION_FILE';
export const DOWNLOAD_INSPECTION_FILE = 'DOWNLOAD_INSPECTION_FILE';
export const DELETE_INSPECTION_FILE = 'ADD_INSPECTION_FILE';
export const ADD_INSPECTION_FILES = 'ADD_INSPECTION_FILES';

// Project
export const SELECT_PROJECT = 'SELECT_PROJECT';
export const GET_FILTERED_PROJECTS = 'GET_FILTERED_PROJECTS';
export const GET_FILTERED_PROJECTS_SUCCESS = 'GET_FILTERED_PROJECTS_SUCCESS';
export const GET_FILTERED_PROJECTS_FAIL = 'GET_FILTERED_PROJECTS_FAIL';
export const GET_FILTERED_PROJECTS_HEADERS = 'GET_FILTERED_PROJECTS_HEADERS';
export const GET_FILTERED_PROJECTS_HEADERS_SUCCESS = 'GET_FILTERED_PROJECTS_HEADERS_SUCCESS';
export const GET_FILTERED_PROJECTS_HEADERS_FAIL = 'GET_FILTERED_PROJECTS_HEADERS_FAIL';
export const SET_FILTERED_PROJECTS_HISTORY_KEY = 'SET_FILTERED_PROJECTS_HISTORY_KEY';
export const DOWNLOAD_FORMULARY_PROJECTS_EXCEL = 'DOWNLOAD_FORMULARY_PROJECTS_EXCEL';
export const UPDATING_BUDGET = 'UPDATING_BUDGET';

// Analytics
export const REQUEST_ANALYTICS_DATA = 'REQUEST_ANALYTICS_DATA';
export const REQUEST_ANALYTICS_HEADERS = 'REQUEST_ANALYTICS_HEADERS';
export const ADD_ANALYTICS_HEADERS = 'ADD_ANALYTICS_HEADERS';
export const ADD_ANALYTICS_DATA = 'ADD_ANALYTICS_DATA';
export const GET_ANALYTICS_DATA = 'GET_ANALYTICS_DATA';
export const FILTER_ANALYTICS_DATA = 'FILTER_ANALYTICS_DATA';
export const GET_EXCEL = 'GET_EXCEL';
export const DOWNLOAD_EXCEL_COMPLETED = 'DOWNLOAD_EXCEL_COMPLETED';
export const DOWNLOADED_EXCEL = 'DOWNLOADED_EXCEL';

// Observations
export const ADD_OBSERVATION = 'ADD_OBSERVATION';
export const DELETE_OBSERVATION = 'DELETE_OBSERVATION';
export const UPDATE_OBSERVATION = 'UPDATE_OBSERVATION';
export const UPDATE_OBSERVATION_SUCCESS = 'UPDATE_OBSERVATION_SUCCESS';
export const UPDATE_OBSERVATION_FAIL = 'UPDATE_OBSERVATION_FAIL';
export const DELETE_ASSET = 'DELETE_ASSET';
export const ADD_ASSET = 'ADD_ASSET';

// CfTypes
export const GET_CF_TYPES = 'GET_CF_TYPES';
export const ADD_CF_TYPES = 'ADD_CF_TYPES';

// Admin
export const ADD_ADMIN_USERS = 'ADD_ADMIN_USERS';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const CREATE_ADMIN_USERS = 'CREATE_ADMIN_USERS';
export const UPDATE_ADMIN_USERS = 'UPDATE_ADMIN_USERS';
export const DELETE_ADMIN_USERS = 'DELETE_ADMIN_USERS';
export const ADD_ADMIN_MODEL_USERS = 'ADD_ADMIN_MODEL_USERS';
export const GET_ADMIN_MODEL_USERS = 'GET_ADMIN_MODEL_USERS';
export const REMOVE_ADMIN_MODEL_USERS = 'REMOVE_ADMIN_MODEL_USERS';
export const INCLUDE_ADMIN_MODEL_USERS = 'INCLUDE_ADMIN_MODEL_USERS';
export const GET_ADMIN_CF_DEFINITIONS = 'GET_ADMIN_CF_DEFINITIONS';
export const ADD_ADMIN_CF_DEFINITIONS = 'ADD_ADMIN_CF_DEFINITIONS';
export const CREATE_ADMIN_PROJECT = 'CREATE_ADMIN_PROJECT';
export const ADMIN_PROJECT_CREATED = 'ADMIN_PROJECT_CREATED';
export const ADD_ADMIN_USER_MODELS = 'ADD_ADMIN_USER_MODELS';
export const GET_ADMIN_USER_MODELS = 'GET_ADMIN_USER_MODELS';
export const INCLUDE_ADMIN_USER_MODELS = 'INCLUDE_ADMIN_USER_MODELS';
export const REMOVE_ADMIN_USER_MODELS = 'REMOVE_ADMIN_USER_MODELS';
export const ADMIN_USER_RESET_PASSWORD = 'ADMIN_USER_RESET_PASSWORD';
export const ADMIN_USER_RESET_PASSWORD_SUCCESS = 'ADMIN_USER_RESET_PASSWORD_SUCCESS';
export const ADMIN_USER_RESET_PASSWORD_CLEAR = 'ADMIN_USER_RESET_PASSWORD_CLEAR';
export const CREATE_ADMIN_ORG_GROUP = 'CREATE_ADMIN_ORG_GROUP';
export const UPDATE_ADMIN_ORG_GROUP = 'UPDATE_ADMIN_ORG_GROUP';
export const CREATE_ADMIN_ORG_GROUP_SUCCESS = 'CREATE_ADMIN_ORG_GROUP_SUCCESS';
export const UPDATE_ADMIN_ORG_GROUP_SUCCESS = 'UPDATE_ADMIN_ORG_GROUP_SUCCESS';
export const CREATE_ADMIN_ORG_GROUP_FAIL = 'CREATE_ADMIN_ORG_GROUP_FAIL';
export const UPDATE_ADMIN_ORG_GROUP_FAIL = 'UPDATE_ADMIN_ORG_GROUP_FAIL';
export const GET_ADMIN_FORMULARIES = 'GET_ADMIN_FORMULARIES';
export const GET_ADMIN_FORMULARIES_SUCCESS = 'GET_ADMIN_FORMULARIES_SUCCESS';
export const GET_ADMIN_FORMULARIES_FAIL = 'GET_ADMIN_FORMULARIES_FAIL';
export const ADD_ADMIN_MODEL_MODELS = 'ADD_ADMIN_MODEL_MODELS';
export const GET_ADMIN_MODEL_MODELS = 'GET_ADMIN_MODEL_MODELS';
export const REMOVE_ADMIN_MODEL_MODELS = 'REMOVE_ADMIN_MODEL_MODELS';
export const INCLUDE_ADMIN_MODEL_MODELS = 'INCLUDE_ADMIN_MODEL_MODELS';
export const GET_ADMIN_SELECTED_PROJECT = 'GET_ADMIN_SELECTED_PROJECT';
export const GET_ADMIN_SELECTED_PROJECT_SUCCESS = 'GET_ADMIN_SELECTED_PROJECT_SUCCESS';
export const GET_ADMIN_SELECTED_PROJECT_FAIL = 'GET_ADMIN_SELECTED_PROJECT_FAIL';
export const GET_ADMIN_API_CREDENTIALS = 'GET_ADMIN_API_CREDENTIALS';
export const GET_ADMIN_API_CREDENTIALS_SUCCESS = 'GET_ADMIN_API_CREDENTIALS_SUCCESS';
export const GET_ADMIN_API_CREDENTIALS_FAIL = 'GET_ADMIN_API_CREDENTIALS_FAIL';

// Work Orders
export const GET_WORK_ORDERS = 'GET_WORK_ORDERS';
export const GET_WORK_ORDERS_SUCCESS = 'GET_WORK_ORDERS_SUCCESS';
export const GET_WORK_ORDERS_FAIL = 'GET_WORK_ORDERS_FAIL';
export const CREATE_WORK_ORDER = 'CREATE_WORK_ORDER';
export const CREATE_WORK_ORDER_SUCCESS = 'CREATE_WORK_ORDER_SUCCESS';
export const CREATE_WORK_ORDER_FAIL = 'CREATE_WORK_ORDER_FAIL';
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER';
export const UPDATE_WORK_ORDER_SUCCESS = 'UPDATE_WORK_ORDER_SUCCESS';
export const UPDATE_WORK_ORDER_FAIL = 'UPDATE_WORK_ORDER_FAIL';
export const DELETE_WORK_ORDER = 'DELETE_WORK_ORDER';
export const DELETE_WORK_ORDER_SUCCESS = 'DELETE_WORK_ORDER_SUCCESS';
export const DELETE_WORK_ORDER_FAIL = 'DELETE_WORK_ORDER_FAIL';
export const GET_WORK_ORDERS_USERS = 'GET_WORK_ORDERS_USERS';
export const GET_WORK_ORDERS_USERS_SUCCESS = 'GET_WORK_ORDERS_USERS_SUCCESS';
export const GET_WORK_ORDERS_PROJECTS = 'GET_WORK_ORDERS_PROJECTS';
export const GET_WORK_ORDERS_PROJECTS_SUCCESS = 'GET_WORK_ORDERS_PROJECTS_SUCCESS';
export const GET_WORK_ORDERS_FORMULARIES = 'GET_WORK_ORDERS_FORMULARIES';
export const GET_WORK_ORDERS_FORMULARIES_SUCCESS = 'GET_WORK_ORDERS_FORMULARIES_SUCCESS';

// Reports
export const GET_FORMULARY_REPORT = 'GET_FORMULARY_REPORT';
export const GET_FORMULARY_REPORT_SUCCESS = 'GET_FORMULARY_REPORT_SUCCESS';
export const GET_FORMULARY_REPORT_FAIL = 'GET_FORMULARY_REPORT_FAIL';
export const CLEAN_FORMULARY_REPORT = 'CLEAN_FORMULARY_REPORT';
export const GET_FORMULARY_SECTIONS = 'GET_FORMULARY_SECTIONS';
export const GET_FORMULARY_SECTIONS_SUCCESS = 'GET_FORMULARY_SECTIONS_SUCCESS';
export const GET_FORMULARY_SECTIONS_FAIL = 'GET_FORMULARY_SECTIONS_FAIL';
export const GET_FORMULARY_REPORTS_BY_QUESTION = 'GET_FORMULARY_REPORTS_BY_QUESTION';
export const GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS = 'GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS';
export const GET_FORMULARY_REPORTS_BY_QUESTION_FAIL = 'GET_FORMULARY_REPORTS_BY_QUESTION_FAIL';
export const GET_PROJECT_FORMULARIES = 'GET_PROJECT_FORMULARIES';
export const GET_PROJECT_FORMULARIES_SUCCESS = 'GET_PROJECT_FORMULARIES_SUCCESS';
export const GET_PROJECT_FORMULARIES_FAIL = 'GET_PROJECT_FORMULARIES_FAIL';
export const GET_PROJECT_FORMULARY = 'GET_PROJECT_FORMULARY';
export const GET_PROJECT_FORMULARY_SUCCESS = 'GET_PROJECT_FORMULARY_SUCCESS';
export const GET_PROJECT_FORMULARY_FAIL = 'GET_PROJECT_FORMULARY_FAIL';
export const GET_PROJECT_FORMULARY_INSPECTIONS = 'GET_PROJECT_FORMULARY_INSPECTIONS';
export const GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS = 'GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS';
export const GET_PROJECT_FORMULARY_INSPECTIONS_FAIL = 'GET_PROJECT_FORMULARY_INSPECTIONS_FAIL';

// Common-Filters
export const GET_FILTERED_ADMINISTRATIVE_DIVISIONS = 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS';
export const GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS = 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS';
export const GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL = 'GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAIL = 'GET_COUNTRIES_FAIL';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const CLEAR_DIVISIONS_FILTERS = 'CLEAR_DIVISIONS_FILTERS';
export const GET_CUSTOM_FIELD_FILTERS = 'GET_CUSTOM_FIELD_FILTERS';
export const GET_CUSTOM_FIELD_FILTERS_SUCCESS = 'GET_CUSTOM_FIELD_FILTERS_SUCCESS';
export const GET_CUSTOM_FIELD_FILTERS_FAIL = 'GET_CUSTOM_FIELD_FILTERS_FAIL';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL';

//  Projects-info
export const GET_SHARED_INFO_BY_TOKEN = 'GET_SHARED_INFO_BY_TOKEN';
export const GET_SHARED_INFO_BY_TOKEN_SUCCESS = 'GET_SHARED_INFO_BY_TOKEN_SUCCESS';
export const GET_SHARED_INFO_BY_TOKEN_FAIL = 'GET_SHARED_INFO_BY_TOKEN_FAIL';
export const GET_SHARED_PROJECTS = 'GET_SHARED_PROJECTS';
export const GET_SHARED_PROJECTS_SUCCESS = 'GET_SHARED_PROJECTS_SUCCESS';
export const GET_SHARED_PROJECTS_FAIL = 'GET_SHARED_PROJECTS_FAIL';
export const CLEAN_SHARED_INFO_BY_TOKEN = 'CLEAN_SHARED_INFO_BY_TOKEN';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_PROJECT_SHARED_PROJECTS = 'GET_PROJECT_SHARED_PROJECTS';
export const GET_PROJECT_SHARED_PROJECTS_SUCCESS = 'GET_PROJECT_SHARED_PROJECTS_SUCCESS';
export const GET_PROJECT_SHARED_PROJECTS_FAIL = 'GET_PROJECT_SHARED_PROJECTS_FAIL';
export const CREATE_SHARED_PROJECT = 'CREATE_SHARED_PROJECT';
export const CREATE_SHARED_PROJECT_SUCCESS = 'CREATE_SHARED_PROJECT_SUCCESS';
export const CREATE_SHARED_PROJECT_FAIL = 'CREATE_SHARED_PROJECT_SUCCESS_FAIL';
export const CREATE_PROJECT_DOCUMENT = 'CREATE_PROJECT_DOCUMENT';
export const CREATE_PROJECT_DOCUMENT_SUCCESS = 'CREATE_PROJECT_DOCUMENT_SUCCESS';
export const CREATE_PROJECT_DOCUMENT_FAIL = 'CREATE_PROJECT_DOCUMENT_FAIL';
export const EDIT_PROJECT_DOCUMENT = 'EDIT_PROJECT_DOCUMENT';
export const EDIT_PROJECT_DOCUMENT_SUCCESS = 'EDIT_PROJECT_DOCUMENT_SUCCESS';
export const EDIT_PROJECT_DOCUMENT_FAIL = 'EDIT_PROJECT_DOCUMENT_FAIL';
export const DELETE_PROJECT_DOCUMENT = 'DELETE_PROJECT_DOCUMENT';
export const DELETE_PROJECT_DOCUMENT_SUCCESS = 'DELETE_PROJECT_DOCUMENT_SUCCESS';
export const DELETE_PROJECT_DOCUMENT_FAIL = 'DELETE_PROJECT_DOCUMENT_FAIL';
export const DOWNLOAD_PROJECT_DOCUMENT = 'DOWNLOAD_PROJECT_DOCUMENT';
export const DOWNLOAD_PROJECT_DOCUMENT_SUCCESS = 'DOWNLOAD_PROJECT_DOCUMENT_SUCCESS';
export const DOWNLOAD_PROJECT_DOCUMENT_FAIL = 'DOWNLOAD_PROJECT_DOCUMENT_FAIL';
export const GET_PROJECT_DOCUMENTS = 'GET_PROJECT_DOCUMENTS';
export const GET_PROJECT_DOCUMENTS_SUCCESS = 'GET_PROJECT_DOCUMENTS_SUCCESS';
export const GET_PROJECT_DOCUMENTS_FAIL = 'GET_PROJECT_DOCUMENTS_FAIL';
export const GET_PHYSICAL_PROGRESSES = 'GET_PHYSICAL_PROGRESSES';
export const GET_PHYSICAL_PROGRESSES_SUCCESS = 'GET_PHYSICAL_PROGRESSES_SUCCESS';
export const GET_PHYSICAL_PROGRESSES_FAIL = 'GET_PHYSICAL_PROGRESSES_FAIL';
export const ADD_PHYSICAL_PROGRESS = 'ADD_PHYSICAL_PROGRESS';
export const ADD_PHYSICAL_PROGRESS_SUCCESS = 'ADD_PHYSICAL_PROGRESS_SUCCESS';
export const ADD_PHYSICAL_PROGRESS_FAIL = 'ADD_PHYSICAL_PROGRESS_FAIL';
export const GET_FINANCIAL_PROGRESSES = 'GET_FINANCIAL_PROGRESSES';
export const GET_FINANCIAL_PROGRESSES_SUCCESS = 'GET_FINANCIAL_PROGRESSES_SUCCESS';
export const GET_FINANCIAL_PROGRESSES_FAIL = 'GET_FINANCIAL_PROGRESSES_FAIL';
export const ADD_FINANCIAL_PROGRESS = 'ADD_FINANCIAL_PROGRESS';
export const ADD_FINANCIAL_PROGRESS_SUCCESS = 'ADD_FINANCIAL_PROGRESS_SUCCESS';
export const ADD_FINANCIAL_PROGRESS_FAIL = 'ADD_FINANCIAL_PROGRESS_FAIL';

// bulkADLvlX
export const GET_BULK_ADMINISTRATIVE_DIVISIONS = 'GET_BULK_ADMINISTRATIVE_DIVISIONS';
export const GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS = 'GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS';
export const GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL = 'GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL';

// Project-Details
export const SET_PROJECT_COUNTRIES = 'SET_PROJECT_COUNTRY';
export const SET_PROJECT_COUNTRIES_SUCCESS = 'GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS';
export const SET_PROJECT_COUNTRIES_FAIL = 'GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL';

const actionTypes = {
  namespace,
  LOADING,
  ERROR,
  SUCCESS,
  UPDATE,
  REQUEST,
  ADD,
  ADD_NEXT_PAGE,
  REMOVE_PAGINATION,
  RESET,
  TOGGLE,
  REMOVE,
  CHECK_AUTHORIZATION,
  LOGIN_REQUEST,
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_ADD,
  REQUEST_ORG_TYPES,
  ADD_ORG_TYPES,
  REQUEST_ORG_GROUP,
  ADD_ORG_GROUP,
  REQUEST_PROGRAM,
  ADD_PROGRAM,
  SHOW_PROJECT,
  REQUEST_PROJECT,
  UPDATE_PROJECT,
  ADD_INSPECTIONS,
  ADD_INSPECTION,
  RELOAD_INSPECTION,
  REQUEST_INSPECTION,
  HOVER_OBSERVATION,
  SELECT_OBSERVATION,
  SELECT_PROJECT,
  SELECT_PROJECT_ID,
  REMOVE_PROGRAM,
  SET_INSPECTION_DEFAULT,
  SET_INSPECTION_STATUS,
  INSPECTION_UPDATED,
  PROJECT_UPDATED,
  GET_INSPECTION_KML,
  DOWNLOAD_INSPECTION_KML,
  DOWNLOADED_KML,
  DOWNLOAD_INSPECTION_PDF,
  REQUEST_ANALYTICS_DATA,
  REQUEST_ANALYTICS_HEADERS,
  ADD_ANALYTICS_HEADERS,
  ADD_ANALYTICS_DATA,
  GET_ANALYTICS_DATA,
  FILTER_ANALYTICS_DATA,
  GET_EXCEL,
  DOWNLOAD_EXCEL_COMPLETED,
  ADD_OBSERVATION,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAIL,
  DELETE_OBSERVATION,
  DELETE_ASSET,
  ADD_ASSET,
  ADD_ADMIN_USERS,
  GET_ADMIN_USERS,
  CREATE_ADMIN_USERS,
  UPDATE_ADMIN_USERS,
  DELETE_ADMIN_USERS,
  ADD_ADMIN_MODEL_USERS,
  GET_ADMIN_MODEL_USERS,
  REMOVE_ADMIN_MODEL_USERS,
  INCLUDE_ADMIN_MODEL_USERS,
  GET_ADMIN_CF_DEFINITIONS,
  ADD_ADMIN_CF_DEFINITIONS,
  CREATE_ADMIN_PROJECT,
  ADMIN_PROJECT_CREATED,
  ADD_ADMIN_USER_MODELS,
  GET_ADMIN_USER_MODELS,
  GET_ADMIN_SELECTED_PROJECT,
  INCLUDE_ADMIN_USER_MODELS,
  REMOVE_ADMIN_USER_MODELS,
  GET_WORK_ORDERS,
  GET_WORK_ORDERS_SUCCESS,
  GET_WORK_ORDERS_FAIL,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAIL,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAIL,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER_FAIL,
  GET_WORK_ORDERS_USERS,
  GET_WORK_ORDERS_USERS_SUCCESS,
  GET_WORK_ORDERS_PROJECTS,
  GET_WORK_ORDERS_PROJECTS_SUCCESS,
  GET_WORK_ORDERS_FORMULARIES,
  GET_WORK_ORDERS_FORMULARIES_SUCCESS,
  GET_FORMULARY_REPORT,
  GET_FORMULARY_REPORT_SUCCESS,
  GET_FORMULARY_REPORT_FAIL,
  CLEAN_FORMULARY_REPORT,
  GET_FORMULARY_SECTIONS,
  GET_FORMULARY_SECTIONS_SUCCESS,
  GET_FORMULARY_SECTIONS_FAIL,
  GET_FORMULARY_REPORTS_BY_QUESTION,
  GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS,
  GET_FORMULARY_REPORTS_BY_QUESTION_FAIL,
  DOWNLOAD_FORMULARY_PROJECTS_EXCEL,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL,
  GET_CUSTOM_FIELD_FILTERS,
  GET_CUSTOM_FIELD_FILTERS_SUCCESS,
  GET_CUSTOM_FIELD_FILTERS_FAIL,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  SELECT_COUNTRY,
  CLEAR_DIVISIONS_FILTERS,
  GET_SHARED_INFO_BY_TOKEN,
  GET_SHARED_INFO_BY_TOKEN_SUCCESS,
  GET_SHARED_INFO_BY_TOKEN_FAIL,
  GET_SHARED_PROJECTS,
  GET_SHARED_PROJECTS_SUCCESS,
  GET_SHARED_PROJECTS_FAIL,
  GET_PROJECT_SHARED_PROJECTS,
  GET_PROJECT_SHARED_PROJECTS_SUCCESS,
  GET_PROJECT_SHARED_PROJECTS_FAIL,
  CLEAN_SHARED_INFO_BY_TOKEN,
  SET_TOKEN,
  GET_BULK_ADMINISTRATIVE_DIVISIONS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL,
  GET_PROJECT_FORMULARIES,
  GET_PROJECT_FORMULARIES_SUCCESS,
  GET_PROJECT_FORMULARIES_FAIL,
  GET_PROJECT_FORMULARY,
  GET_PROJECT_FORMULARY_SUCCESS,
  GET_PROJECT_FORMULARY_FAIL,
  GET_PROJECT_FORMULARY_INSPECTIONS,
  GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS,
  GET_PROJECT_FORMULARY_INSPECTIONS_FAIL,
  GET_INSPECTIONS_BY_PROJECT,
  GET_INSPECTIONS_BY_PROJECT_SUCCESS,
  GET_INSPECTIONS_BY_PROJECT_FAIL,
  GET_INSPECTION_ASSETS,
  GET_INSPECTION_ASSETS_SUCCESS,
  GET_INSPECTION_ASSETS_FAIL,
  CREATE_SHARED_PROJECT,
  CREATE_SHARED_PROJECT_SUCCESS,
  CREATE_SHARED_PROJECT_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  CREATE_PROJECT_DOCUMENT,
  CREATE_PROJECT_DOCUMENT_SUCCESS,
  CREATE_PROJECT_DOCUMENT_FAIL,
  EDIT_PROJECT_DOCUMENT,
  EDIT_PROJECT_DOCUMENT_SUCCESS,
  EDIT_PROJECT_DOCUMENT_FAIL,
  DELETE_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT_SUCCESS,
  DELETE_PROJECT_DOCUMENT_FAIL,
  DOWNLOAD_PROJECT_DOCUMENT,
  DOWNLOAD_PROJECT_DOCUMENT_SUCCESS,
  DOWNLOAD_PROJECT_DOCUMENT_FAIL,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_FAIL,
  SET_PROJECT_COUNTRIES,
  SET_PROJECT_COUNTRIES_SUCCESS,
  SET_PROJECT_COUNTRIES_FAIL
};

export default actionTypes;
