import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { get, assign } from 'lodash';
import {
  REQUEST_ORG_TYPES,
  ADD_ORG_TYPES,
  REQUEST_ORG_GROUP,
  ADD_ORG_GROUP,
  GET_FILTERED_PROJECTS,
  GET_FILTERED_PROJECTS_SUCCESS,
  GET_FILTERED_PROJECTS_FAIL
} from '../../../redux/action-types';
import { getOrgGroupTypes, getOrgGroup, getPaginatedProjects } from './helpers';

export function* typesRequest() {
  yield takeEvery(REQUEST_ORG_TYPES, function*({ payload }) {
    const resp = yield call(getOrgGroupTypes, payload);

    const types = [];
    let parent = resp;

    if (!parent) {
      return;
    }
    do {
      types.push({
        id: get(parent, 'id'),
        name: get(parent, 'name'),
        leaf: get(parent, 'leaf'),
        isACountry: get(parent, 'is_a_country'),
        parentId: get(parent, 'parent_id'),
        controlledByUser: get(parent, 'controlled_by_user')
      });

      yield put({
        type: REQUEST_ORG_GROUP,
        payload: assign({}, payload, { typeId: get(parent, 'id') })
      });

      parent = parent.child;
    } while (parent);
    yield put({
      type: ADD_ORG_TYPES,
      payload: types
    });
  });
}

export function* groupRequest() {
  yield takeEvery(REQUEST_ORG_GROUP, function*({ payload }) {
    const resp = yield call(getOrgGroup, payload);

    yield put({
      type: ADD_ORG_GROUP,
      id: payload.typeId,
      payload: resp
    });
  });
}

export function* paginatedProjectsRequest() {
  yield takeEvery(GET_FILTERED_PROJECTS, function*({ payload }) {
    const resp = yield call(getPaginatedProjects, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_FILTERED_PROJECTS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_FILTERED_PROJECTS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(typesRequest), fork(groupRequest), fork(paginatedProjectsRequest)]);
}
