import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';

export const AddBeneficiaryButtonComponent = ({ history, variant = 'contained' }) => {
  const { messages: intlMessages } = useIntl();
  const handleButtonClick = () => {
    history.push('/beneficiaries/create');
  };

  return (
    <Button variant={variant} onClick={handleButtonClick}>
      <Typography variant="button">{intlMessages['beneficiaries.addBeneficiary']}</Typography>
    </Button>
  );
};

AddBeneficiaryButtonComponent.propTypes = {
  variant: PropTypes.string,
  history: PropTypes.object
};

export const AddBeneficiaryButton = withRouter(AddBeneficiaryButtonComponent);
