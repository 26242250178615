import axios from 'axios';
import { get } from 'lodash';

import { apiConfig } from '../settings';
import { Storage } from '../utils/storage';

import { store } from '../redux/store';
import { actionCreators } from '../containers/Auth/ducks/reducer';
import { notify } from '../components/toastMessage';

/**
 * Returns object with authentication header data.
 *
 * @param {boolean} noContentType Flag to add Content-Type=undefined header
 */
export const getApiHeaders = noContentType => {
  const headers = Storage.getData('authHeaders');

  if (noContentType) {
    headers['Content-Type'] = undefined;
  }
  return headers;
};

/**
 * Set axios configuration.
 * @param {Boolean} useStringify
 */
export const setAxiosConfig = useStringify => {
  axios.defaults.baseURL = apiConfig.apiUrl;

  if (getApiHeaders()) {
    axios.defaults.headers.common = getApiHeaders();
  }

  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

  if (useStringify) {
    // Transform data payload with stringify.
    axios.defaults.transformRequest = obj => JSON.stringify(obj);
  }
};

export const getApi = (useStringify, customFn) => {
  setAxiosConfig(useStringify, customFn);

  const axiosInstance = axios.create();

  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const status = get(error, 'response.status');
      const errorResponse = get(error, 'response.data');

      if (status === 401) {
        store.dispatch(actionCreators.logout());
      } else if (status === 404) {
        return {
          data: errorResponse.message,
          status: get(error, 'response.status')
        };
      }

      notify(errorResponse.message, 'error');
      return Promise.reject(error);
    }
    // Do something with response error
  );

  return axiosInstance;
};
