import { get, assign } from 'lodash';

import { getApi } from '../../api';

export const getFormularyReport = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/formularies?include=none`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getFormularySections = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`formularies/${data.formularyId}/sections`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getFormularyReportsByQuestion = async (data = {}) => {
  let resp = {};
  const params = {
    formulary_report: {
      formulary_id: data.formularyId,
      section_id: data.sectionId,
      administrative_divisions_ids: data.adminDivisionsId,
      cf_value_filters: data.cfFilters
    }
  };

  try {
    resp = await getApi().post(`clients/${data.clientId}/formulary_reports`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getFormularyProjectsExcel = async (data = {}) => {
  let resp = {};
  let params = {
    by_project_ids: data.projectIds,
    client_id: data.clientId
  };

  if (!!data && !!data.formularyId) {
    assign(params, { formulary_id: data.formularyId });
  }

  try {
    resp = await getApi().post('inspections/xlsx', params, {
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
      responseType: 'arraybuffer'
    });
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getProjectFormularies = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`projects/${data.projectId}/formularies?include=none`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getProjectFormulary = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`projects/${data.projectId}/formularies/${data.formularyId}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getProjectFormularyInspections = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`projects/${data.projectId}/formularies/${data.formularyId}/inspections`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
