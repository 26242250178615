import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { LinkButton } from '../atoms/LinkButton';
import { Modal } from '../molecules/Modal';
import { useAssignMemberToGroup, useUnassignMemberToGroup } from '../../queries/queryGroups';
import { CONTACT_GROUPS_MEMBERS_HEAD } from '../../containers/Beneficiaries/constants';
import { GroupsTable } from './GroupsTable';
import { useGetContactGroupMembers } from '../../queries/queryBeneficiaries';
import { ContactMemberForm } from './ContactMemberForm';
import { EmptySection } from '../molecules/EmptySection';

export const AssignGroupToBeneficiaryContent = ({ beneficiary }) => {
  const [openAssignGroupModal, setOpenAssignGroupModal] = useState(false);
  const { data = [], refetch: refetchGroupMembers } = useGetContactGroupMembers({
    contactId: beneficiary.id
  });
  const onSuccess = () => {
    refetchGroupMembers();
  };
  const handleClose = () => {
    setOpenAssignGroupModal(false);
  };
  const onAssignSuccess = () => {
    refetchGroupMembers();
    handleClose();
  };
  const { mutate: unassignMemberToGroup } = useUnassignMemberToGroup({ onSuccess });
  const { mutate: assignMemberToGroup } = useAssignMemberToGroup({ onSuccess: onAssignSuccess });
  const { messages: intlMessages } = useIntl();

  const handleAssignToProject = () => {
    setOpenAssignGroupModal(!openAssignGroupModal);
  };

  const onSubmit = ({ groupId, ...params }) => {
    assignMemberToGroup({
      groupId,
      params: { ...params }
    });
  };

  const groupMembers = data.map(
    ({
      id: memberId,
      contact_group: {
        name: groupName,
        id: groupId,
        contact_group_type: { name: type }
      },
      contact_group_type_role: { name: role }
    }) => {
      const handleUnassignMember = () => {
        unassignMemberToGroup({
          groupId,
          memberId
        });
      };

      return {
        name: groupName,
        link: `/groups/${groupId}`,
        role,
        type,
        delete: { contactName: beneficiary.name, groupName, action: handleUnassignMember }
      };
    }
  );

  const modalUI = (
    <Modal
      open={openAssignGroupModal}
      onClose={handleClose}
      title={<FormattedMessage id="group.associateGroup" />}
      subtitle={
        <Typography>
          <FormattedMessage id="beneficiaries.form.mandatoryMessage" />
          <Typography component="span">
            <i>*</i> .
          </Typography>
        </Typography>
      }
    >
      <Grid container>
        <ContactMemberForm
          onSubmit={onSubmit}
          handleClose={handleClose}
          addTo="beneficiary"
          defaultValues={{ beneficiary: { id: beneficiary.id } }}
        />
      </Grid>
    </Modal>
  );

  return (
    <CardContent>
      <CardSectionTitle
        title={intlMessages['beneficiaries.section.groupSubtitle']}
        actions={<LinkButton onClick={handleAssignToProject}>{intlMessages['group.associateGroup']}</LinkButton>}
      />
      {groupMembers.length > 0 && <GroupsTable data={groupMembers} dataColumns={CONTACT_GROUPS_MEMBERS_HEAD} variant="small" />}
      {groupMembers.length === 0 && <EmptySection>{intlMessages['beneficiaries.empty.groups']}</EmptySection>}
      {openAssignGroupModal && modalUI}
    </CardContent>
  );
};

AssignGroupToBeneficiaryContent.propTypes = {
  beneficiary: PropTypes.object.isRequired
};
