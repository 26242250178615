import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import React from 'react';
import { useCollapsibleContainerStyles } from './CollapsibleContainer.styles';
import PropTypes from 'prop-types';

export const CollapsibleContainer = ({ children, title }) => {
  const { accordionClasses, accordionDetailsClasses, accordionSummaryClasses, typographyClasses } = useCollapsibleContainerStyles();
  return (
    <Accordion classes={accordionClasses}>
      <AccordionSummary classes={accordionSummaryClasses} expandIcon={<ExpandMoreIcon />}>
        <Typography classes={typographyClasses}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails classes={accordionDetailsClasses}>{children}</AccordionDetails>
    </Accordion>
  );
};

CollapsibleContainer.propTypes = {
  children: PropTypes.object.isRequired,
  title: PropTypes.any.isRequired
};
