import { all } from 'redux-saga/effects';
import authSagas from '../containers/Auth/ducks/saga';
import programsSagas from '../containers/Programs/ducks/saga';
import projectsSagas from '../containers/_Projects/ducks/saga';
import inspectionsSagas from '../containers/Inspections/ducks/saga';
import analyticsSagas from '../containers/Analytic/ducks/saga';
import adminSagas from './admin/saga';
import ordersSagas from './work-orders/saga';
import reportsSagas from './reports/saga';
import commonFiltersSagas from './common-filters/saga';
import projectsInfoSagas from './projects-info/saga';
import bulkADLvlXSagas from './bulkADLvlX/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    programsSagas(),
    projectsSagas(),
    inspectionsSagas(),
    analyticsSagas(),
    adminSagas(),
    ordersSagas(),
    reportsSagas(),
    commonFiltersSagas(),
    projectsInfoSagas(),
    bulkADLvlXSagas()
  ]);
}
