import { map, get, set, isEmpty } from 'lodash';

import { getApi } from '../../../api';
import { getCheckedArrayIds } from '../../../utils/customFields';

export const getProjectDetails = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/projects/${data.projectId}/`);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

const getCustomFields = fields => {
  return fields.map(f => ({ cf_definition_id: f.cf_definition_id, value: f.value }));
};

const aDLevels = (params, bulkADLevels) => {
  bulkADLevels.map((level, index) => {
    set(params, `administrative_divisions_lvl_${index + 1}_ids`, getCheckedArrayIds(level));
  });
};

// Setting updating
export const setProjectUpdate = async (data = {}) => {
  let resp = {};

  const { clientId, projectId, name, description, projectType, acf, externalId, bulkADLevels, manage_contract } = data;

  let params = {
    cf_values: getCustomFields(acf),
    client_id: clientId,
    id: projectId,
    name,
    description,
    external_id: externalId,
    project_type: projectType,
    manage_contract
  };

  if (!!bulkADLevels) {
    aDLevels(params, bulkADLevels);
  }

  try {
    resp = await getApi().put(`clients/${clientId}/projects/${projectId}/`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const setInspectionDefault = async (data = {}) => {
  let resp = {};

  const { clientId, isDefault } = data;

  const params = {
    client_id: clientId,
    is_default: isDefault
  };

  try {
    resp = await getApi().put(`/inspections/${data.id}`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const setInspectionPublished = async (data = {}) => {
  let resp = {};

  const { clientId, published } = data;

  const params = {
    client_id: clientId,
    published: published
  };

  try {
    resp = await getApi().put(`/inspections/${data.id}`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const setInspectionStatus = async (data = {}) => {
  let resp = {};

  const { id, status } = data;
  const params = { status };

  try {
    resp = await getApi().put(`/inspections/${id}/update_transition`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getInspection = async (data = {}) => {
  const params = {
    client_id: data.clientId
  };

  let resp = {};

  try {
    resp = await getApi().get(`inspections/${data.inspectionId}`, { params });
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getInspectionKML = async (data = {}) => {
  let resp = {};
  const { inspectionId, clientId } = data;
  const params = {
    client_id: clientId
  };

  try {
    resp = await getApi().post(
      `/inspections/${inspectionId}/kml`,
      { params },
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const setThirdPartySynchronize = async inspectionId => {
  let resp = {};

  try {
    resp = await getApi().post(`/inspections/${inspectionId}/third_party_synchronize`, {
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' }
    });
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const getInspectionPdf = async (data = {}) => {
  let resp = {};
  const { inspectionId, clientId } = data;
  const params = {
    client_id: clientId
  };

  try {
    resp = await getApi().post(
      `/inspections/${inspectionId}/pdf`,
      { params },
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' }, responseType: 'arraybuffer' }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const getInspectionFiles = async (data = {}) => {
  const params = {};

  let resp = {};

  try {
    resp = await getApi().get(`inspections/${data.inspectionId}/documents`, { params });
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const downloadInspectionFile = async (data = {}) => {
  let resp = {};
  const { inspectionId, documentId } = data;

  try {
    resp = await getApi().get(
      `/inspections/${inspectionId}/documents/${documentId}`,
      {},
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: get(resp, 'headers')['content-type'],
    name: '',
    url: resp.request.responseURL
  };
};

export const deleteInspectionFile = async (data = {}) => {
  let resp = {};
  const { inspectionId, documentId } = data;

  try {
    resp = await getApi().delete(
      `/inspections/${inspectionId}/documents/${documentId}`,
      {},
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const uploadInspectionFile = async (data = {}) => {
  let resp = {};
  let formData = new FormData();
  const { file, inspectionId } = data;
  formData.append('document', file);

  try {
    resp = await getApi().post(`/inspections/${inspectionId}/documents`, formData, {
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition', 'Content-Type': 'multipart/form-data' }
    });
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const createObservation = async (data = {}) => {
  let resp = {};
  const params = data.observation;

  try {
    resp = await getApi().post(`/observations`, params, { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } });
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const setObservationUpdate = async (data = {}) => {
  let resp = {};

  const { id, text } = data;
  const params = { text };

  try {
    resp = await getApi().put(`observations/${id}`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const deleteObservation = async (data = {}) => {
  let resp = {};
  const { id } = data;

  try {
    resp = await getApi().delete(`/observations/${id}`, {}, { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } });
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const deleteObservationAsset = async (data = {}) => {
  let resp = {};
  const { id, observationId } = data;

  try {
    resp = await getApi().delete(
      `/observations/${observationId}/media_assets/${id}`,
      {},
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const createObservationAsset = async (data = {}) => {
  let resp = {};
  const { id } = data;
  const params = data.asset;
  try {
    resp = await getApi().post(`/observations/${id}/media_assets`, params, {
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' }
    });
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

export const getCFTypes = async () => {
  let resp = {};

  try {
    resp = resp = await getApi().get(`custom_fields/cf_types`, {});
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: '',
    name: ''
  };
};

// Altimetry data functions
const findObservationOrder = (trackPoints, observation) => {
  const observationOrder = trackPoints.find(point => point.latitude === observation.latitude && point.longitude === observation.longitude);

  return observationOrder && observationOrder.order ? observationOrder.order : 0;
};

// // Altimetry data functions
// const findObservationOrder = (trackPoints, observation) =>
//   trackPoints.find(
//     point =>
//       point.latitude === observation.latitude &&
//       point.longitude === observation.longitude
//   ).order;

export const getAltimetryData = data => ({
  chartData: map(data.track_points, trackPoint => ({
    name: trackPoint.order,
    altitude: Math.round(trackPoint.altitude)
  })),
  referenceDots: map(data.observations, obs => ({
    id: obs.id,
    name: findObservationOrder(data.track_points, obs),
    altitude: obs.altitude,
    observation: obs
  }))
});

export const getProjectInspections = async (data = {}) => {
  let resp = {};
  const { projectId } = data.payload;
  let path = `/projects/${projectId}/inspections`;

  try {
    resp = await getApi().get(path);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getInspectionAssets = async (data = {}) => {
  let resp = {};
  const { inspectionId } = data.payload;
  let path = `/inspections/${inspectionId}/media_assets`;

  try {
    resp = await getApi().get(path);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
