export const palette = {
  captudataBlue: '#2A6D9E',
  captudataBlue50: '#95B6CF',
  cerulean: '#4A90E2',
  azure: '#2D9DFF',
  white: '#FFFFFF',
  white20: '#ffffff66',
  black: '#000000',
  artic: '#E5F2FB',
  candy: '#F13434',
  LightRed: '#FF8383',
  dimGray: '#6D6D6D',
  darkGray: '#323232',
  darkGray20: '#32323233',
  darkGrayTable: '#D6D6D6',
  darkGrayTableSecond: '#bdbdbd',
  platinum: '#E0E0E0',
  peakcock: '#004D71',
  silver: '#CECECE',
  captuDataYellow: '#E29624',
  captuDataYellowBorderTable: '#EEC07C',
  emerald: '#43BC64',
  yellow: '#E29624',
  lavender: '#ACB9FF',
  violet: '#48487B',
  statusProject: '#9B51E0',
  progressLevel: {
    HIGHEST: '#5E96BF',
    HIGH: '#58AA84',
    MEDIUM_HIGH: '#A0A749',
    MEDIUM_LOW: '#E09F3F',
    LOW: '#A23D48',
    LOWEST: '#74A0C4'
  }
};
