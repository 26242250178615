import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useHeaderContentStyles } from './HeaderContent.styles';

export const HeaderContent = ({ title, subtitle, children, breadcrumb }) => {
  const classes = useHeaderContentStyles();

  return (
    <Box className={classes.root}>
      <Grid container justifyContent={'space-between'}>
        <Grid item>
          {breadcrumb && breadcrumb}
          <Typography variant={'h3'} className={classes.heading}>
            {title}
          </Typography>
        </Grid>
        <Grid className={classes.controls}>{children}</Grid>
      </Grid>
      {subtitle && <Typography className={classes.subtitle}>{subtitle}</Typography>}
    </Box>
  );
};

HeaderContent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  breadcrumb: PropTypes.node
};
