import React from 'react';
import { MenuItem } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectField } from './SelectField';
import { useGetAlias } from '../../queries/queryAlias';
import { getCurrentUser } from '../../utils/auth';

const AliasSelectorComponent = ({ clientId, onChange = () => {}, placeholder, dispatch: _, ...props }) => {
  const { data: alias = [] } = useGetAlias({ clientId });

  const behaviorChange = event => {
    onChange(alias.find(x => x.id === event.target.value));
  };

  return (
    <SelectField displayEmpty onChange={behaviorChange} {...props}>
      {placeholder && (
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
      )}
      {alias.map((value, index) => (
        <MenuItem key={index} value={value.id}>
          {value.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

AliasSelectorComponent.propTypes = {
  onChange: PropTypes.func,
  clientId: PropTypes.number,
  placeholder: PropTypes.string,
  dispatch: PropTypes.func
};

const mapToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const AliasSelector = connect(mapToProps)(AliasSelectorComponent);
