import { all, takeEvery, put, fork, call } from 'redux-saga/effects';

import {
  REQUEST_ANALYTICS_DATA,
  REQUEST_ANALYTICS_HEADERS,
  ADD_ANALYTICS_HEADERS,
  ADD_ANALYTICS_DATA,
  GET_EXCEL,
  DOWNLOAD_EXCEL_COMPLETED
} from '../../../redux/action-types';
import { getAnalyticData, getAnalyticHeaders, getExcel } from './helpers';

export function* typesRequest() {
  //   yield takeEvery(REQUEST_ANALYTICS_DATA, function*({ payload }) {
  //     const resp = yield call(getAnalyticData, payload);
  //     const types = [];
  //     const parent = resp;
  //     if (!parent) {
  //     }
  //   });
}

export function* analyticsRequest() {
  yield takeEvery(REQUEST_ANALYTICS_DATA, function*({ payload }) {
    const resp = yield call(getAnalyticData, payload);
    yield put({
      type: ADD_ANALYTICS_DATA,
      payload: resp
    });
  });
}

export function* analyticsHeadersRequest() {
  yield takeEvery(REQUEST_ANALYTICS_HEADERS, function*({ payload }) {
    const resp = yield call(getAnalyticHeaders, payload);
    yield put({
      type: ADD_ANALYTICS_HEADERS,
      payload: resp
    });
  });
}

export function* downloadExcel() {
  yield takeEvery(GET_EXCEL, function*({ payload }) {
    const resp = yield call(getExcel, payload);

    if (resp.data !== undefined) {
      yield put({
        type: DOWNLOAD_EXCEL_COMPLETED,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export default function* rootSaga() {
  yield all([fork(typesRequest), fork(analyticsRequest), fork(downloadExcel), fork(analyticsHeadersRequest)]);
}
