import { get } from 'lodash';
import { Map } from 'immutable';

import {
  REQUEST_ANALYTICS_DATA,
  ADD_ANALYTICS_DATA,
  REQUEST_ANALYTICS_HEADERS,
  ADD_ANALYTICS_HEADERS,
  FILTER_ANALYTICS_DATA,
  GET_EXCEL,
  DOWNLOAD_EXCEL_COMPLETED,
  DOWNLOADED_EXCEL
} from '../../../redux/action-types';

const initState = new Map({
  analyticsData: [],
  analyticsHeaders: [],
  isLoading: false,
  isLoadingExcel: false,
  excel: '',
  analyticsDataFilter: []
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const analyticsReducer = (state = initState, action) => {
  switch (action.type) {
    case REQUEST_ANALYTICS_DATA:
      return state.set('isLoading', true);
    case ADD_ANALYTICS_DATA:
      state = state
        .set('isLoading', false)
        .set('analyticsData', get(action, 'payload', {}))
        .set('analyticsDataFilter', get(action, 'payload', {}));
      return state;
    case ADD_ANALYTICS_HEADERS:
      state = state.set('isLoading', false).set('analyticsHeaders', get(action, 'payload', {}));
      return state;
    case FILTER_ANALYTICS_DATA:
      return state.set('analyticsDataFilter', get(action, 'payload', {}));
    case GET_EXCEL:
      return state.set('isLoadingExcel', true);
    case DOWNLOAD_EXCEL_COMPLETED:
      return (state = state.set('isLoadingExcel', false).set('excel', get(action, 'payload', {})));
    case DOWNLOADED_EXCEL:
      return (state = state.set('excel', undefined));

    default:
      return state;
  }
};

// Action Creators
/**
 * Request all organization group types
 */
const requestAnalytics = payload => ({
  type: REQUEST_ANALYTICS_DATA,
  payload
});

const requestAnalyticsHeaders = payload => ({
  type: REQUEST_ANALYTICS_HEADERS,
  payload
});

const filterAnalytics = payload => ({
  type: FILTER_ANALYTICS_DATA,
  payload
});

const downloadExcel = payload => ({
  type: GET_EXCEL,
  payload
});

const downloadedExcel = payload => ({
  type: DOWNLOADED_EXCEL,
  payload
});

export const actionCreators = {
  requestAnalytics,
  requestAnalyticsHeaders,
  filterAnalytics,
  downloadExcel,
  downloadedExcel
};

export default analyticsReducer;
