import { makeStyles } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

export const makeBeneficiaryFormStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(3)
  }
});

export const useBeneficiaryFormStyles = () => {
  return makeBeneficiaryFormStyles();
};
