import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Card } from '../atoms/Card';
import { EmptySection } from '../molecules/EmptySection';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { UploadDocumentButton } from '../molecules/UploadDocumentButton';
import { AssignMemberToGroupContent } from './AssignMemberToGroupContent';
import { GroupAttachments } from './GroupAttachments';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { getCurrentUser } from '../../utils/auth';
import { useGetContactGroupDocuments, useUploadGroupDocument } from '../../queries/queryContactGroups';
import { AssignProjectToGroupContent } from './AssingProjectToGroupContent';

const GroupAssetsSectionComponent = ({ group, isLoading }) => {
  const { documents: documentsFromGroup = [], id: groupId } = group;
  const { data: documentsFromIndex = [], refetch } = useGetContactGroupDocuments({
    groupId
  });
  const onSuccess = () => {
    refetch();
  };

  const documents = isEmpty(documentsFromIndex) ? documentsFromGroup : documentsFromIndex;
  const { mutate: uploadGroupDocument } = useUploadGroupDocument({ onSuccess });
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryStyles();

  const handleSelectDocument = e => {
    uploadGroupDocument({ groupId, file: e.target.files[0] });
  };

  const projectsUI = (
    <Card variant={'stretch'}>
      <CardContent>{!isEmpty(group) && <AssignProjectToGroupContent group={group} />}</CardContent>
    </Card>
  );

  const documentsSectionUI = () => {
    if (documents.length === 0) {
      return <EmptySection>{intlMessages['group.empty.documents']}</EmptySection>;
    }

    if (isLoading) {
      return <CircularProgress />;
    }

    return <GroupAttachments groupId={groupId} documents={documents} onAssetsChange={onSuccess} />;
  };

  return (
    <Grid container direction={'column'}>
      <Typography variant={'h6'} className={classes.heading}>
        {intlMessages['group.section.groupAssets']}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          {projectsUI}
        </Grid>
        <Grid item xs={4}>
          <Card variant={'stretch'}>{!isLoading && <AssignMemberToGroupContent group={group} />}</Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant={'stretch'}>
            <CardContent>
              <CardSectionTitle
                title={intlMessages['beneficiaries.section.documentsSubtitle']}
                actions={
                  <UploadDocumentButton label={intlMessages['beneficiaries.attachDocument']} handleSelectDocument={handleSelectDocument} />
                }
              />
              {documentsSectionUI()}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

GroupAssetsSectionComponent.propTypes = {
  group: PropTypes.object.isRequired,
  onAssetsChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({ currentUser: getCurrentUser(state) });

export const GroupAssetsSection = connect(mapStateToProps)(GroupAssetsSectionComponent);
