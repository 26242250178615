import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import moment from 'moment';
import { isObject } from 'lodash';
import { SIMPLE_DASH_DATE_FORMAT } from '../../utils/utils';

export const NativeDatePicker = ({ onChange = () => {}, value, ...props }) => {
  const onChangeHandler = event => {
    onChange(moment(event.target.value));
  };

  const normalizedValue = isObject(value) ? value.format(SIMPLE_DASH_DATE_FORMAT) : value;

  return <TextField type="date" InputLabelProps={{ shrink: true }} value={normalizedValue} onChange={onChangeHandler} {...props} />;
};

NativeDatePicker.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};
