import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SelectField } from './SelectField';

const EDUCATIONAL_LEVEL_TYPES = [
  'no_education',
  'elementary_school_incomplete',
  'elementary_school_complete',
  'high_school_incomplete',
  'high_school_complete',
  'vocational_technical_school_incomplete',
  'vocational_technical_school_complete',
  'associates_degree_incomplete',
  'associates_degree_complete',
  'bachelors_degree_incomplete',
  'bachelors_degree_complete',
  'masters_degree_incomplete',
  'masters_degree_complete',
  'doctorate_phd_incomplete',
  'doctorate_phd_complete'
];

export const EducationalLevelSelector = props => (
  <SelectField {...props}>
    {EDUCATIONAL_LEVEL_TYPES.map((value, index) => (
      <MenuItem key={index} value={value}>
        <FormattedMessage id={`selector.educationLevel.${value}`} />
      </MenuItem>
    ))}
  </SelectField>
);
