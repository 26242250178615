import React from 'react';
import { LinkButton } from '../atoms/LinkButton';
import { makeStyles } from '@material-ui/core';

const makeUploadDocumentButton = makeStyles(() => ({
  input: {
    display: 'none'
  },
  label: {
    cursor: 'pointer'
  }
}));

export const useUploadDocumentButton = () => makeUploadDocumentButton();

export const UploadDocumentButton = ({ label, handleSelectDocument }) => {
  const classes = useUploadDocumentButton();

  return (
    <LinkButton>
      <label htmlFor="contained-button-file" className={classes.label}>
        {label}
      </label>
      <input type="file" onChange={handleSelectDocument} id="contained-button-file" multiple className={classes.input} />
    </LinkButton>
  );
};
