import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { WideContainer } from '../../components/atoms/WideContainer';
import { GeneralInformationSection } from '../../components/organims/GeneralInformationSection';
import { BeneficiaryAssetsSection } from '../../components/organims/BeneficiaryAssetsSection';
import { BeneficiaryActionRecordSection } from '../../components/organims/BeneficiaryActionRecordSection';
import { useGetBeneficiaryById } from '../../queries/queryBeneficiaries';
import { getCurrentUser } from '../../utils/auth';

const BeneficiaryDetailComponent = ({ clientId, match, location }) => {
  const { beneficiaryId } = match.params;
  const { data: beneficiary = {}, refetch, isLoading } = useGetBeneficiaryById({ clientId, beneficiaryId });

  useEffect(() => {
    const param = new URLSearchParams(location.search);
    const updated = param.get('updated');

    if (updated === 'true') {
      refetch();
    }
  }, []);

  const onAssetsChange = asset => {
    if (asset) {
      refetch();
    }
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      {!isLoading && (
        <>
          <HeaderContent title={beneficiary.name} />
          <BodyContent component={WideContainer}>
            <GeneralInformationSection beneficiary={beneficiary} type={beneficiary.contactType} />
            <BeneficiaryAssetsSection beneficiary={beneficiary} onAssetsChange={onAssetsChange} />
            <BeneficiaryActionRecordSection beneficiaryId={beneficiaryId} clientId={clientId} />
          </BodyContent>
        </>
      )}
    </MuiThemeProvider>
  );
};

BeneficiaryDetailComponent.propTypes = {
  match: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export const BeneficiaryDetail = connect(state => ({ clientId: getCurrentUser(state).client_id }))(BeneficiaryDetailComponent);
