import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useInspectionWithContractStyles } from './InspectionWithContract.styles';
import { FormattedMessage } from 'react-intl';
import { ProgressBar } from '../ProgressBar';
import Typography from '@material-ui/core/Typography';
import { getProjectColorKey } from '../utils';
import { parseStringDateToFormat } from '../../../../utils/utils';

export const SummaryInspectionContract = ({ project }) => {
  const { textUpdateAtClasses, textStatusClasses } = useInspectionWithContractStyles();
  const { main_contract: mainContract } = project;
  const {
    status_humanized: statusHumanized,
    financial_progress_formatted: financialProgressFormatted,
    physical_progress_formatted: physicalProgressFormatted,
    execution_progress_formatted: executionProgressFormatted,
    start_date: startDate,
    current_termination_date: currentTerminationDate,
    contract_modifications_and_progress_updated_at: contractModificationsAndProgressUpdatedAt
  } = mainContract;

  const colorProject = getProjectColorKey(project);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Typography>
          <FormattedMessage id="programs.inspection.statusWork" />
          :&nbsp;
        </Typography>
        <Typography classes={textStatusClasses}>{statusHumanized}</Typography>
      </Grid>
      <Grid item classes={textUpdateAtClasses}>
        <Typography>
          <FormattedMessage id="programs.inspection.dataUpdatedAt" />
          :&nbsp;{parseStringDateToFormat(contractModificationsAndProgressUpdatedAt, '-')}
        </Typography>
      </Grid>

      <ProgressBar value={financialProgressFormatted} colorKey={colorProject}>
        <FormattedMessage id="programs.inspection.financialProgress" />
      </ProgressBar>
      <ProgressBar value={physicalProgressFormatted} colorKey={colorProject}>
        <FormattedMessage id="programs.inspection.physicalProgress" />
      </ProgressBar>
      <ProgressBar value={executionProgressFormatted} colorKey={colorProject}>
        <FormattedMessage id="programs.inspection.executionPeriod" />
      </ProgressBar>
      <br />

      <Grid container justifyContent="space-between">
        <Typography>
          <FormattedMessage id="programs.inspection.start" />
          :&nbsp;{parseStringDateToFormat(startDate, '-')}
        </Typography>
        <Typography>
          <FormattedMessage id="programs.inspection.end" />
          :&nbsp;{parseStringDateToFormat(currentTerminationDate, '-')}
        </Typography>
      </Grid>
    </>
  );
};

SummaryInspectionContract.propTypes = {
  project: PropTypes.object.isRequired
};
