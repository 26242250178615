import { alpha, Chip as MuiChip, styled } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

export const Chip = styled(MuiChip)(({ type = 'default' }) => {
    let variantAttr = {};

    switch (type) {
      case 'legally_incorporated':
        variantAttr = {
          backgroundColor: palette.emerald,
          color: palette.white
        };
        break;
      case 'unincorporated':
        variantAttr = {
          backgroundColor: palette.white,
          color: `${palette.dimGray} !important`,
          border: `1px solid ${palette.dimGray}`
        };
        break;
      case 'in_the_process_of_incorporation':
        variantAttr = {
          backgroundColor: palette.white,
          color: `${palette.emerald} !important`,
          border: `1px solid ${palette.emerald}`
        };
        break;
      case 'active':
        variantAttr = { backgroundColor: palette.captuDataYellow };
        break;
      case 'inactive':
        variantAttr = { backgroundColor: palette.silver };
        break;
    }

    return (
      {
        borderRadius: 8,
        border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
        color: `${palette.white} !important`,
        fontWeight: 'bold',
        width: 'fit-content',
        ...variantAttr
      }
    );
  }
);
