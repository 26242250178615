import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MenuList from '@material-ui/core/MenuList';
import { ProjectMenuItem } from './ProjectMenuItem';
import projectsMenuStyles from './ProjectsMenu.styles';
import { actionCreators } from '../../ducks/reducer';

const useProjectMenuStyles = makeStyles(projectsMenuStyles);

const ProjectsMenuComponent = ({ title, items = [], showProject, saveIdProjectSelected, idProjectSelected }) => {
  const excSideMenu = useRef(null);
  const classes = useProjectMenuStyles();

  const jumpTo = index => {
    if (excSideMenu.current) {
      const component = excSideMenu.current.children[index];

      component.scrollIntoView(true);
    }
  };

  useEffect(() => {
    const position = items.findIndex(({ id }) => id === idProjectSelected);

    if (position < 0) return;

    jumpTo(position);
  }, [idProjectSelected]);

  if (items.length === 0) {
    return (
      <div className={classes.root}>
        {title && <Typography variant="h6">{title}</Typography>}
        <Typography variant="body2" className={`${classes.root}__menu__text`}>
          <FormattedMessage id="programs.inspection.noElementsFound" defaultMessage="No se encontraron elementos" />
        </Typography>
      </div>
    );
  }

  const clickOnItem = projectId => {
    if (idProjectSelected && idProjectSelected === projectId) {
      saveIdProjectSelected(undefined);
      return;
    }

    saveIdProjectSelected(projectId);
  };

  return (
    <div className={classes.root}>
      {title && <Typography variant="h6">{title}</Typography>}
      <MenuList ref={excSideMenu} className={`${classes.root}__menu`}>
        {items.map((project, index) => (
          <ProjectMenuItem
            key={index}
            label={project.name}
            onItemMouseOver={() => showProject(project)}
            onItemMouseOut={() => showProject(null)}
            onClick={() => clickOnItem(project.id)}
            project={project}
            active={idProjectSelected && idProjectSelected === project.id}
          />
        ))}
      </MenuList>
    </div>
  );
};

ProjectsMenuComponent.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
  square: PropTypes.bool,
  showProject: PropTypes.func,
  saveIdProjectSelected: PropTypes.func,
  idProjectSelected: PropTypes.number
};

ProjectsMenuComponent.deafultProps = {
  square: false
};

export const ProjectsMenu = connect(
  state => {
    const { idProjectSelected } = state.Programs.get('idProjectSelected') || {};

    return {
      idProjectSelected
    };
  },
  {
    showProject: actionCreators.showProject,
    saveIdProjectSelected: actionCreators.saveIdProjectSelected
  }
)(ProjectsMenuComponent);
