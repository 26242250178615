import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '../atoms/Card';
import { useDocumentsClasses } from './BeneficiaryAttachments.style';
import { LinkButton } from '../atoms/LinkButton';
import { SEPARATED_DATE } from '../../utils/utils';
import { useDeleteBeneficiaryDocument, useDownloadBeneficiaryDocument } from '../../queries/queryBeneficiaries';

export const BeneficiaryAttachments = ({ documents, clientId, beneficiaryId, onAssetsChange }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useDocumentsClasses();
  const { data: deletedAsset, mutate: deleteBeneficiaryDocument } = useDeleteBeneficiaryDocument();
  const { mutate: downloadBeneficiaryDocument } = useDownloadBeneficiaryDocument();

  useEffect(() => {
    if (deletedAsset) {
      onAssetsChange(deletedAsset);
    }
  }, [deletedAsset]);

  const handleDelete = documentId => {
    deleteBeneficiaryDocument({ clientId, beneficiaryId, documentId });
  };
  const handleDownloadDocument = attachedDocument => {
    downloadBeneficiaryDocument({ clientId, beneficiaryId, attachedDocument });
  };

  const headingUI = (
    <Grid container className={classes.heading} alignItems="center">
      <Grid item xs={10}>
        <Typography className={classes.title}>{intlMessages['beneficiaries.lastDocumentsAttached']}</Typography>
      </Grid>
      <Grid item xs={2}>
        <LinkButton>{intlMessages['beneficiaries.showAll']}</LinkButton>
      </Grid>
    </Grid>
  );

  return (
    <>
      {headingUI}
      {documents.map(({ id, created_at, name }) => (
        <Card key={id} variant={'stretch'} className={classes.card}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={6}>
              {name}
            </Grid>
            <Grid item xs={2}>
              {moment(created_at).format(SEPARATED_DATE)}
            </Grid>
            <Grid item xs={2}>
              <LinkButton onClick={() => handleDownloadDocument({ id, name })}>{intlMessages['common.download']}</LinkButton>
            </Grid>
            <Grid item xs={2}>
              <LinkButton variant="danger" onClick={() => handleDelete(id)} key={id}>
                {intlMessages['common.delete']}
              </LinkButton>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );
};

BeneficiaryAttachments.propTypes = {
  documents: PropTypes.array.isRequired,
  clientId: PropTypes.number.isRequired,
  beneficiaryId: PropTypes.number.isRequired,
  onAssetsChange: PropTypes.func.isRequired
};
