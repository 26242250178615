import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';

import {
  LOGIN_SUCCESS,
  LOGOUT,
  RESET,
  CHECK_AUTHORIZATION,
  LOGIN_ADD,
  LOGIN_USER_PERMISSIONS,
  USER_LOCALE_CHANGE_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD,
  SET_NEW_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE
} from '../../../redux/action-types';

import { updateUser } from '../../../redux/admin/helper';
import { setAuth, clearAuth, getPermissions, resetPassword, setNewPassword } from './helpers';
import { siteConfig } from '../../../settings';
import { history } from '../../../redux/store';
import { Storage } from '../../../utils/storage';
import { redirectToPermissionUrls } from '../../../utils/utils';
import { actionCreators as authActions } from '../../../redux/auth/actions';
import { notify } from '../../../components/toastMessage';

export function* loginSuccess() {
  yield takeEvery(LOGIN_SUCCESS, function*({ payload, hist }) {
    yield setAuth(payload);

    yield put({
      type: LOGIN_ADD,
      payload
    });

    yield put({ type: LOGIN_USER_PERMISSIONS });

    if (hist) {
      hist.push(siteConfig.homePage);
      push(siteConfig.homePage);
    }
  });
}

export function* logout() {
  yield takeEvery(LOGOUT, function*() {
    yield clearAuth();

    yield put({
      type: RESET
    });

    history.push('/login');
    push('/login');
  });
}

export function* checkAuthorization() {
  yield takeEvery(CHECK_AUTHORIZATION, function*() {
    const user = Storage.getData('currentUser');
    const isLoggedIn = Storage.getData('currentUser');

    if ((!user || isEmpty(user)) && !isLoggedIn) {
      logout();
    } else {
      yield put({
        type: LOGIN_ADD,
        payload: {
          data: user
        }
      });
      yield put({ type: LOGIN_USER_PERMISSIONS });
    }
  });
}

function* getUserPermissions() {
  yield takeEvery(LOGIN_USER_PERMISSIONS, function*() {
    const user = Storage.getData('currentUser');
    const isLoggedIn = Storage.getData('currentUser');

    if (!(!user || isEmpty(user)) && !!isLoggedIn) {
      const response = yield getPermissions();

      if (!response) {
        history.push('/login');
        push('/login');
      } else if (response.status === 200) {
        yield put({
          type: LOGIN_ADD,
          payload: {
            data: response.data
          }
        });
        redirectToPermissionUrls(response.data.permissions);
      }
    }
  });
}

function* changeUserLocale() {
  yield takeEvery(USER_LOCALE_CHANGE_REQUEST, function*({ payload }) {
    const resp = yield call(updateUser, payload);

    if (!!resp && resp.status === 200) {
      Storage.setData('local_lang', payload.user.settings_locale);
      window.location.reload();
    } else {
      yield put(authActions.changeLocaleFail());
    }
  });
}

export function* resetPasswordAux(action) {
  const result = yield call(resetPassword, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: RESET_PASSWORD_SUCCESS,
      payload: result
    });
  } else {
    yield put({
      type: RESET_PASSWORD_FAILURE,
      payload: result
    });
  }
}

export function* setNewPasswordAux(action) {
  const result = yield call(setNewPassword, action.payload);
  if (!!result && result.status === 200) {
    yield put({
      type: SET_NEW_PASSWORD_SUCCESS,
      payload: result
    });
  } else {
    yield put({
      type: SET_NEW_PASSWORD_FAILURE,
      payload: result
    });
  }
  yield clearAuth();
}

/**
 * Watchers
 */
export function* setPasswordRequest() {
  yield takeEvery(SET_NEW_PASSWORD, setNewPasswordAux);
}

export function* resetPasswordRequest() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordAux);
}

export default function* rootSaga() {
  yield all([
    fork(loginSuccess),
    fork(logout),
    fork(checkAuthorization),
    fork(getUserPermissions),
    fork(changeUserLocale),
    resetPasswordRequest(),
    setPasswordRequest()
  ]);
}
