import { makeStyles } from '@material-ui/core';

const useAgeSelector = makeStyles(theme => ({
  custom: {
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2)
  },
  typography: {
    lineHeight: theme.spacing(0.8),
    marginLeft: theme.spacing(2)
  },
  menuItem: {
    paddingTop: theme.spacing(0.1),
    paddingBottom: theme.spacing(0.1)
  }
}));

export const useAgeSelectorClasses = () => useAgeSelector();
