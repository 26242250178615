import React from 'react';
import { FormattedMessage } from 'react-intl';

const createEntry = (id, label, numeric = false, disableSort = false, disablePadding = false) => ({
  id,
  numeric,
  disablePadding,
  label: label && <FormattedMessage id={label} defaultMessage={id} />,
  disableSort
});

const NAME_ENTRY = createEntry('name', 'common.name', false, false, true);
const IDENTIFICATION_ENTRY = createEntry('identification', 'beneficiaries.identificationNumber');
const LOCATION_ENTRY = createEntry('location', 'project.details.location');
const STATUS_ENTRY = createEntry('status', 'common.status');

export const NATURAL_CONTACTS_HEAD = [
  NAME_ENTRY,
  IDENTIFICATION_ENTRY,
  LOCATION_ENTRY,
  createEntry('sex', 'beneficiaries.form.gender'),
  createEntry('age', 'beneficiaries.age'),
  createEntry('ethnicity', 'beneficiaries.ethnicity'),
  createEntry('educationalLevel', 'beneficiaries.educationalLevel'),
  createEntry('role', 'beneficiaries.form.profession'),
  STATUS_ENTRY
];

export const LEGAL_CONTACTS_HEAD = [
  NAME_ENTRY,
  createEntry('identification', 'beneficiaries.legal.identification'),
  createEntry('legalStatus', 'beneficiaries.legal.status'),
  createEntry('legalRepresentative', 'beneficiaries.form.section.legalRepresentative'),
  LOCATION_ENTRY,
  createEntry('activity', 'beneficiaries.form.activity'),
  STATUS_ENTRY
];

export const GROUPS_HEAD = [
  createEntry('name', 'group.name'),
  createEntry('type', 'group.type'),
  createEntry('mainContact', 'group.mainContact'),
  LOCATION_ENTRY,
  createEntry('members', 'group.memberNumber'),
  createEntry('activity', 'beneficiaries.form.activity'),
  STATUS_ENTRY
];

export const GROUPS_MEMBERS_HEAD = [
  createEntry('name', 'group.member'),
  IDENTIFICATION_ENTRY,
  createEntry('role', 'group.role'),
  createEntry('delete', '', false, true)
];

export const CONTACT_GROUPS_MEMBERS_HEAD = [
  createEntry('name', 'group'),
  createEntry('type', 'group.type'),
  createEntry('role', 'group.role'),
  createEntry('delete', '', false, true)
];

export const INITIAL_EXTRA_FILTERS_VALUES = {
  educational_level: '',
  profession_id: '',
  country_id: '',
  project_id: ''
};

export const INITIAL_FILTERS_VALUES = {
  age: [],
  ethnicity: '',
  sex: '',
  active: null,
  query: '',
  ...INITIAL_EXTRA_FILTERS_VALUES
};

export const MAX_AGE = 120;
export const MIN_AGE = 0;

export const ETHNICITY_TYPES = ['indigenous', 'mestizo', 'afrodescendant', 'white', 'mixed_multiracial', 'asian', 'other'];

export const GENDER_IDENTITY_TYPES = [
  'male',
  'female',
  'non_binary',
  'transgender',
  'transexual',
  'genderqueer',
  'genderfluid',
  'agender',
  'two_spirit',
  'other',
  'prefer_not_to_say',
  'decline_to_state'
];

export const SEXUAL_ORIENTATION_TYPES = [
  'heterosexual',
  'homosexual',
  'bisexual',
  'pansexual',
  'asexual',
  'demisexual',
  'queer',
  'other',
  'prefer_not_to_say',
  'decline_to_state',
  'not_sure'
];

export const MARITAL_STATUS_TYPES = [
  'single',
  'married',
  'divorced',
  'widowed',
  'separated',
  'domestic_partnership',
  'civil_union',
  'other_marital_status'
];
