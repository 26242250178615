import { get } from 'lodash';

import { getApi } from '../../api';

// Function to Get Bulk Administrative Divisions

export const getBulkAdministrativeDivisions = async (data = {}) => {
  let resp = {};
  let path = `countries/${data.countryId}/administrative_divisions?level=${data.level}`;
  if (!!data.selectedIds) {
    if (Array.isArray(data.selectedIds)) {
      data.selectedIds.map(id => (path = `${path}&parent_id[]=${id}`));
    } else {
      path = `${path}&parent_id=${data.selectedIds}`;
    }
  }

  try {
    resp = await getApi().get(path);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
