import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  {
    path: '/operative/projects/:projectId/',
    component: asyncComponent(() => import('./index'))
  },
  {
    path: '/operative/projects/:projectId/inspections',
    component: asyncComponent(() => import('./index'))
  },
  {
    path: '/operative/projects/:projectId/inspectionslineal/:inspectionId',
    component: asyncComponent(() => import('./components/details'))
  },
  {
    path: '/operative/projects/:projectId/inspectionspunctual/:inspectionId',
    component: asyncComponent(() => import('./components/punctual'))
  }
];

export default routes;
