import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { ProjectCustomFields } from './ProjectCustomFields';
import { InspectionDetailItem } from './InspectionDetailItem';
import { VariationPercentage } from './VariationPercentage';
import { FULL_DATE_FORMAT, parseStringDateToFormat } from '../../../../utils/utils';
import { useInspectionDetailStyles } from './MoreDetailInspectionContract.styles';

const calcVariation = (initialEndDate, currentEndDate) => {
  const additionalDays = currentEndDate - initialEndDate;
  const variation = parseFloat(((additionalDays * 100) / initialEndDate).toFixed(2));
  return !isNaN(variation) ? variation : 0;
};

const calcVariationDate = main_contract => {
  if (!main_contract || !main_contract.current_termination_date) {
    return 0;
  }

  const startDate = moment(main_contract.start_date, FULL_DATE_FORMAT);
  const endDate = moment(main_contract.original_termination_date, FULL_DATE_FORMAT);
  const currentEndDate = moment(main_contract.current_termination_date, FULL_DATE_FORMAT);

  const initialVariationInDays = endDate.diff(startDate, 'days');
  const currentVariationInDays = currentEndDate.diff(startDate, 'days');

  return calcVariation(initialVariationInDays, currentVariationInDays);
};

const parseAmountToFloat = amount => {
  const cleanCurrencyFormat = /\.*| .*/g;
  const formattingNumberToParseFloat = /,/g;

  return parseFloat(amount.replace(cleanCurrencyFormat, '').replace(formattingNumberToParseFloat, '.'));
};

const calcAmountVariance = main_contract => {
  const { formatted_original_amount, formatted_current_amount } = main_contract || {};
  const originalAmount = formatted_original_amount && parseAmountToFloat(formatted_original_amount);
  const currentAmount = formatted_current_amount ? parseAmountToFloat(formatted_current_amount) : originalAmount;

  return calcVariation(originalAmount, currentAmount);
};

export const MoreDetailInspectionContract = ({ project }) => {
  const classes = useInspectionDetailStyles();
  const { main_contract } = project || {};
  const { messages: intlMessages } = useIntl();

  const original_termination_date = parseStringDateToFormat(main_contract.original_termination_date, '-');
  const current_termination_date = parseStringDateToFormat(main_contract.current_termination_date, '-');

  const amountVariation = calcAmountVariance(main_contract);
  const dateVariation = calcVariationDate(main_contract);

  return (
    <Grid container direction="column" classes={classes}>
      <ProjectCustomFields customFields={project.cf_values} />
      {main_contract.contractor && (
        <InspectionDetailItem label={intlMessages['contract.contractor']} value={main_contract.contractor.name} />
      )}
      <hr />

      {main_contract.formatted_original_amount && (
        <InspectionDetailItem label={intlMessages['contract.formatted_original_amount']} value={main_contract.formatted_original_amount} />
      )}

      {main_contract.formatted_current_amount && (
        <InspectionDetailItem label={intlMessages['contract.formatted_current_amount']} value={main_contract.formatted_current_amount} />
      )}

      <InspectionDetailItem label={intlMessages['programs.inspection.variation']} value={<VariationPercentage value={amountVariation} />} />
      <hr />

      <InspectionDetailItem label={intlMessages['contract.original_termination_date']} value={original_termination_date} />
      <InspectionDetailItem label={intlMessages['contract.current_termination_date']} value={current_termination_date} />
      <InspectionDetailItem label={intlMessages['programs.inspection.variation']} value={<VariationPercentage value={dateVariation} />} />
    </Grid>
  );
};

MoreDetailInspectionContract.propTypes = {
  project: PropTypes.object.isRequired
};
