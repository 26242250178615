import { get, assign, map, isEmpty, set } from 'lodash';
import { Map } from 'immutable';
import { getAltimetryData } from './helpers';
import { notify } from '../../../components/toastMessage';

import {
  REQUEST_PROJECT,
  ADD_PROJECT,
  ADD,
  REQUEST_INSPECTION,
  RELOAD_INSPECTION,
  ADD_INSPECTION,
  HOVER_OBSERVATION,
  SELECT_OBSERVATION,
  SET_INSPECTION_DEFAULT,
  SET_INSPECTION_PUBLISHED,
  INSPECTION_PUBLISH_UPDATED,
  UPDATE_PROJECT,
  PROJECT_UPDATED,
  INSPECTION_UPDATED,
  GET_INSPECTION_KML,
  DOWNLOAD_INSPECTION_KML,
  DOWNLOADED_KML,
  REQUEST_INSPECTION_FILES,
  UPLOAD_INSPECTION_FILE,
  DELETE_INSPECTION_FILE,
  DOWNLOAD_INSPECTION_FILE,
  ADD_INSPECTION_FILES,
  DOWNLOAD_INSPECTION_PDF,
  ADD_OBSERVATION,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAIL,
  DELETE_OBSERVATION,
  DELETE_ASSET,
  ADD_ASSET,
  GET_CF_TYPES,
  ADD_CF_TYPES,
  SET_INSPECTION_STATUS,
  ADMIN_PROJECT_CREATED,
  CREATE_ADMIN_PROJECT,
  SYNC_WITH_THIRD_PARTY,
  SYNC_WITH_THIRD_PARTY_SUCCESS,
  GET_INSPECTIONS_BY_PROJECT,
  GET_INSPECTIONS_BY_PROJECT_SUCCESS,
  GET_INSPECTIONS_BY_PROJECT_FAIL,
  GET_INSPECTION_ASSETS,
  GET_INSPECTION_ASSETS_SUCCESS,
  GET_INSPECTION_ASSETS_FAIL
} from '../../../redux/action-types';

const initState = new Map({
  project: {
    inspections: [],
    isLoading: true
  },
  inspectionSelected: {
    observations: [],
    observationSelected: {},
    filesAttached: [],
    isLoading: true,
    isUpdating: false
  },
  cfTypes: {
    types: [],
    requested: false,
    isLoading: false
  },
  inspections: {
    list: [],
    isLoading: false,
    assets: []
  }
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const inspectionsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD:
      return state.set('inspections', get(action, 'payload', {}));
    case REQUEST_PROJECT:
      return state.set('project', {
        isLoading: true,
        inspections: []
      });
    case CREATE_ADMIN_PROJECT:
    case UPDATE_PROJECT:
      return state.set(
        'project',
        assign({}, state.get('project'), {
          isLoading: true
        })
      );
    case ADMIN_PROJECT_CREATED:
      return state.set(
        'project',
        assign({}, state.get('project'), {
          isLoading: false
        })
      );
    case PROJECT_UPDATED:
      return state.set(
        'project',
        assign({}, state.get('project'), get(action, 'payload', {}), {
          isLoading: false
        })
      );
    case ADD_PROJECT:
      return state.set(
        'project',
        assign({}, get(action, 'payload', {}), {
          isLoading: false
        })
      );
    case SET_INSPECTION_STATUS:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: true,
          isUpdating: true
        })
      );
    case REQUEST_INSPECTION:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          filesAttached: [],
          needReload: false,
          isLoading: true
        })
      );
    case RELOAD_INSPECTION:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          needReload: true
        })
      );
    case UPDATE_OBSERVATION_SUCCESS:
      let observationUpdated = null;
      if (!isEmpty(state.get('inspectionSelected').observations)) {
        const observations = state.get('inspectionSelected').observations;
        observationUpdated = observations.find(observation => observation.id === get(action, 'payload').id);
        set(observationUpdated, 'text', get(action, 'payload').text);
        set(state.get('inspectionSelected'), 'observationSelected', observationUpdated);
      }
      return state;
    case UPDATE_OBSERVATION_FAIL:
      notify('There has been a problem! The action could not be completed.', 'error');
      break;
    case SYNC_WITH_THIRD_PARTY:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: true
        })
      );
    case SYNC_WITH_THIRD_PARTY_SUCCESS:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: false
        })
      );
    case ADD_INSPECTION:
      return state.set(
        'inspectionSelected',
        assign({}, get(action, 'payload', {}), {
          observations: map(get(action, 'payload.observations', []), obs => ({
            ...obs,
            isHovered: false,
            isActive: false
          })),
          observationSelected: get(action, 'payload.observations', [])[0],
          isLoading: false,
          isUpdating: false,
          needReload: false,
          altimetryData: getAltimetryData(get(action, 'payload', {}))
        })
      );
    case HOVER_OBSERVATION:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          observations: map(state.get('inspectionSelected').observations, obs => {
            if (obs.id === action.id) {
              obs.isHovered = action.hover;
            }
            return obs;
          })
        })
      );
    case SELECT_OBSERVATION:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          observationSelected: action.observation
        })
      );
    case SET_INSPECTION_DEFAULT:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isUpdating: true
        })
      );
    case SET_INSPECTION_PUBLISHED:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isUpdating: true
        })
      );
    case INSPECTION_PUBLISH_UPDATED:
      // Adding the published flag to the correspond inspection
      const inspections = state.get('inspections');

      if (!isEmpty(inspections.list)) {
        const inspection = inspections.list.find(i => i.id === action.payload.id);
        if (!!inspection) {
          inspection.published = get(action, 'payload', {}).published;
        }
      }
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          published: get(action, 'payload', {}).published,
          isUpdating: false
        })
      );
    case INSPECTION_UPDATED:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          is_default: get(action, 'payload', {}).is_default,
          isUpdating: false
        })
      );
    case GET_INSPECTION_KML:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isUpdating: true
        })
      );
    case DOWNLOAD_INSPECTION_KML:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isUpdating: false,
          kml: get(action, 'payload', {}).data,
          fileType: get(action, 'payload', {}).type,
          fileName: get(action, 'payload', {}).name
        })
      );
    case DOWNLOADED_KML:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          kml: undefined
        })
      );
    case REQUEST_INSPECTION_FILES:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          filesAttached: [],
          isLoading: true
        })
      );
    case UPLOAD_INSPECTION_FILE:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: true
        })
      );
    case DELETE_INSPECTION_FILE:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: true
        })
      );
    case DOWNLOAD_INSPECTION_FILE:
      return state.set('inspectionSelected', assign({}, state.get('inspectionSelected'), {}));
    case ADD_INSPECTION_FILES:
      return state.set(
        'inspectionSelected',
        assign({}, state.get('inspectionSelected'), {
          isLoading: false,
          filesAttached: get(action, 'payload', {})
        })
      );
    case DOWNLOAD_INSPECTION_PDF:
      return state.set('inspectionSelected', assign({}, state.get('inspectionSelected'), {}));
    case GET_CF_TYPES:
      return state.set(
        'cfTypes',
        assign({}, state.get('cfTypes'), {
          isLoading: true
        })
      );
    case ADD_CF_TYPES:
      return state.set(
        'cfTypes',
        assign({}, state.get('cfTypes'), {
          isLoading: false,
          requested: !!get(action, 'payload', {}).success,
          types: !!get(action, 'payload', {}).success ? get(action, 'payload', {}).types : []
        })
      );
    case GET_INSPECTION_ASSETS:
    case GET_INSPECTIONS_BY_PROJECT:
      return state.set('inspections', assign({}, state.get('inspections'), { isLoading: true }));
    case GET_INSPECTIONS_BY_PROJECT_SUCCESS:
      return state.set('inspections', assign({}, state.get('inspections'), { list: get(action, 'payload.data', []), isLoading: false }));
    case GET_INSPECTIONS_BY_PROJECT_FAIL:
      return state.set('inspections', assign({}, state.get('inspections'), { list: [], isLoading: false }));
    case GET_INSPECTION_ASSETS_SUCCESS:
      return state.set('inspections', assign({}, state.get('inspections'), { assets: get(action, 'payload.data', []), isLoading: false }));
    case GET_INSPECTION_ASSETS_FAIL:
      return state.set('inspections', assign({}, state.get('inspections'), { assets: [], isLoading: false }));
    case SYNC_WITH_THIRD_PARTY:
    default:
      return state;
  }
};

// Action Creators

/**
 * Request project details
 */
export const requestProject = payload => ({
  type: REQUEST_PROJECT,
  payload
});

/**
 * Request a inspection
 */
export const requestInspection = payload => ({
  type: REQUEST_INSPECTION,
  payload
});

/**
 * Request the inspection files
 */
export const requestInspectionFiles = payload => ({
  type: REQUEST_INSPECTION_FILES,
  payload
});

/**
 * Upload an inspection file
 */
export const uploadInspectionFile = payload => ({
  type: UPLOAD_INSPECTION_FILE,
  payload
});

/**
 * Delete an inspection file
 */
export const deleteInspectionFile = payload => ({
  type: DELETE_INSPECTION_FILE,
  payload
});

/**
 * Download an inspection file
 */
export const downloadInspectionFile = payload => ({
  type: DOWNLOAD_INSPECTION_FILE,
  payload
});

/**
 * Add a inspection
 */
export const addInspection = () => ({
  type: ADD_INSPECTION
});

/**
 * Toggle hover state to as observation
 */
export const hoverObservation = (id, hover) => ({
  type: HOVER_OBSERVATION,
  id,
  hover
});

/**
 * Selects an observation
 */
export const selectObservation = observation => ({
  type: SELECT_OBSERVATION,
  observation
});

/**
 * set inspection default
 */

const setInspectionDefault = payload => ({
  type: SET_INSPECTION_DEFAULT,
  payload
});

/**
 * set inspection published
 */
const setInspectionPublished = payload => ({
  type: SET_INSPECTION_PUBLISHED,
  payload
});

const setProjectUpdate = payload => ({
  type: UPDATE_PROJECT,
  payload
});

const downloadInspectionKML = payload => ({
  type: GET_INSPECTION_KML,
  payload
});

const downloadedKml = () => ({
  type: DOWNLOADED_KML
});

const downloadInspectionPdf = payload => ({
  type: DOWNLOAD_INSPECTION_PDF,
  payload
});

const requestAddObservation = payload => ({
  type: ADD_OBSERVATION,
  payload
});

const requestUpdateObservation = payload => ({
  type: UPDATE_OBSERVATION,
  payload
});

const requestDeleteObservation = payload => ({
  type: DELETE_OBSERVATION,
  payload
});

const requestDeleteAsset = payload => ({
  type: DELETE_ASSET,
  payload
});

const requestAddAsset = payload => ({
  type: ADD_ASSET,
  payload
});

const requestCFTypes = () => ({
  type: GET_CF_TYPES
});

const requesteUpdateInspectionStatus = (id, status) => ({
  type: SET_INSPECTION_STATUS,
  payload: { id, status }
});

const requestSyncWithThirdParty = id => ({
  type: SYNC_WITH_THIRD_PARTY,
  payload: { id }
});

const requestProjectInspections = projectId => ({
  type: GET_INSPECTIONS_BY_PROJECT,
  payload: { projectId }
});

const requestInspectionAssets = inspectionId => ({
  type: GET_INSPECTION_ASSETS,
  payload: { inspectionId }
});

export const actionCreators = {
  requestProject,
  setProjectUpdate,
  addInspection,
  requestInspection,
  hoverObservation,
  selectObservation,
  setInspectionDefault,
  setInspectionPublished,
  downloadInspectionKML,
  downloadedKml,
  requestInspectionFiles,
  uploadInspectionFile,
  deleteInspectionFile,
  downloadInspectionFile,
  downloadInspectionPdf,
  requestAddObservation,
  requestUpdateObservation,
  requestDeleteObservation,
  requestDeleteAsset,
  requestAddAsset,
  requestCFTypes,
  requesteUpdateInspectionStatus,
  requestSyncWithThirdParty,
  requestProjectInspections,
  requestInspectionAssets
};

export default inspectionsReducer;
