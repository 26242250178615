import React from 'react';
import { MenuItem } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { SelectField } from './SelectField';

export const AssociatedSelector = ({ onChange, values, prefix, ...props }) => {
  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <SelectField onChange={handleChange} {...props}>
      {values.map((value, index) => (
        <MenuItem key={index} value={value.id}>
          {`${prefix || ''}${value.name || ''} ${value.nestedPositions}`}
        </MenuItem>
      ))}
    </SelectField>
  );
};

AssociatedSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};
