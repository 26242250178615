import {
  GET_SHARED_INFO_BY_TOKEN,
  GET_SHARED_PROJECTS,
  SET_TOKEN,
  RESET,
  GET_PROJECT_SHARED_PROJECTS,
  CREATE_SHARED_PROJECT,
  CREATE_PROJECT_DOCUMENT,
  GET_PROJECT_DOCUMENTS,
  EDIT_PROJECT_DOCUMENT,
  DOWNLOAD_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT,
  GET_FINANCIAL_PROGRESSES,
  GET_PHYSICAL_PROGRESSES,
  ADD_PHYSICAL_PROGRESS,
  ADD_FINANCIAL_PROGRESS,
  UPDATING_BUDGET
} from '../action-types';
// Action Creators

/**
 * Edit Project Document
 */
const editProjectDocument = (projectId, document) => ({
  type: EDIT_PROJECT_DOCUMENT,
  payload: {
    document,
    projectId
  }
});

/**
 * Delete Project Document
 */
const deleteProjectDocument = (projectId, documentId) => ({
  type: DELETE_PROJECT_DOCUMENT,
  payload: {
    documentId,
    projectId
  }
});

/**
 * Download Project Document
 */
const downloadProjectDocument = (projectId, documentId) => ({
  type: DOWNLOAD_PROJECT_DOCUMENT,
  payload: {
    documentId,
    projectId
  }
});

/**
 * Create Project Document
 */
const createProjectDocument = (projectId, document) => ({
  type: CREATE_PROJECT_DOCUMENT,
  payload: {
    document,
    projectId
  }
});

/**
 * Create Shared Project
 */
const requestCreateSharedProject = (projectId, shared_project) => ({
  type: CREATE_SHARED_PROJECT,
  payload: {
    shared_project,
    projectId
  }
});

/**
 * Get Shared Info Action
 */
const getSharedInfo = sharedToken => ({
  type: GET_SHARED_INFO_BY_TOKEN,
  payload: {
    sharedToken
  }
});

const getSharedProjects = sharedToken => ({
  type: GET_SHARED_PROJECTS,
  payload: {
    sharedToken
  }
});

/**
 * Get Physical Progresses
 */

const getPhysicalProgresses = (contractId, page, pageSize) => ({
  type: GET_PHYSICAL_PROGRESSES,
  payload: {
    contractId,
    page,
    pageSize
  }
});

/**
 * Get Financial Progresses
 */

const getFinancialProgresses = (contractId, page, pageSize) => ({
  type: GET_FINANCIAL_PROGRESSES,
  payload: {
    contractId,
    page,
    pageSize
  }
});

/**
 * Create Physical Progress
 */

const addPhysicalProgress = (contractId, params) => ({
  type: ADD_PHYSICAL_PROGRESS,
  payload: {
    contractId,
    params
  }
});

/**
 * Create Financial Progress
 */

const addFinancialProgress = (contractId, params) => ({
  type: ADD_FINANCIAL_PROGRESS,
  payload: {
    contractId,
    params
  }
});

const getProjectSharedProjects = projectId => ({
  type: GET_PROJECT_SHARED_PROJECTS,
  payload: {
    projectId
  }
});

/**
 * Get Project Documents
 */
const getProjectDocuments = (projectId, paginationParams, currentCategory) => ({
  type: GET_PROJECT_DOCUMENTS,
  payload: {
    projectId,
    paginationParams,
    currentCategory
  }
});

/**
 * Get Shared token
 */
const setToken = token => ({
  type: SET_TOKEN,
  payload: {
    token
  }
});

/**
 * Reset state
 */
const reset = () => ({
  type: RESET
});

const updatingBudget = isUpdating => ({
  type: UPDATING_BUDGET,
  payload: { isUpdating }
});

export const actionCreators = {
  requestCreateSharedProject,
  getSharedInfo,
  getSharedProjects,
  getPhysicalProgresses,
  getFinancialProgresses,
  setToken,
  getProjectSharedProjects,
  createProjectDocument,
  editProjectDocument,
  deleteProjectDocument,
  downloadProjectDocument,
  getProjectDocuments,
  addPhysicalProgress,
  addFinancialProgress,
  reset,
  updatingBudget
};
