import React from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflow: 'hidden',
    border: `1px solid ${palette.dimGray}`,
    borderRadius: lightThemeV2.spacing(1),
    minHeight: 40,
    maxHeight: 40,
    color: palette.dimGray,
    '&>div.active': {
      backgroundColor: palette.cerulean,
      color: palette.white,
      '&:hover': {
        backgroundColor: palette.cerulean
      }
    },
    '&>div': {
      userSelect: 'none',
      borderRight: `1px solid ${palette.dimGray}`,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
      padding: `0 ${lightThemeV2.spacing(2)}px`,
      minWidth: 115,
      height: '100%',
      '& *': {
        cursor: 'pointer'
      },
      '&:last-child': {
        borderRight: 0
      },
      '&:hover': {
        backgroundColor: palette.artic
      }
    }
  }
});

const TYPE_FILTERS_ID = {
  EQUALS_100: '100%',
  GREATER_THAN_75: '+ del 75%',
  GREATER_THAN_50: '+ del 50%',
  GREATER_THAN_25: '+ del 25%',
  EQUALS_0: '0%'
};

const FILTER_SETTINGS = [
  {
    id: TYPE_FILTERS_ID.EQUALS_100,
    label: '100%',
    value: 100,
    operation: '=='
  },
  {
    id: TYPE_FILTERS_ID.GREATER_THAN_75,
    label: '+ del 75%',
    value: 75,
    operation: '>='
  },
  {
    id: TYPE_FILTERS_ID.GREATER_THAN_50,
    label: '+ del 50%',
    value: 50,
    operation: '>='
  },
  {
    id: TYPE_FILTERS_ID.GREATER_THAN_25,
    label: '+ del 25%',
    value: 25,
    operation: '>='
  },
  {
    id: TYPE_FILTERS_ID.EQUALS_0,
    label: '0%',
    value: 0,
    operation: '=='
  }
];

export const FilterByPhysicalProgress = ({ onChange = () => {}, selected }) => {
  const classes = useStyle();

  const handleChange = id => {
    if (id === selected) {
      onChange();
      return;
    }

    onChange(FILTER_SETTINGS.find(filter => filter.id === id));
  };

  return (
    <Box className={classes.root}>
      {FILTER_SETTINGS.map(({ label, id }, index) => (
        <Box key={index} className={id === selected ? 'active' : ''} onClick={() => handleChange(id)}>
          <Typography>{label}</Typography>
        </Box>
      ))}
    </Box>
  );
};

FilterByPhysicalProgress.propTypes = {
  onChange: PropTypes.func,
  orgGroupsIds: PropTypes.string,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
