import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

class MainRouter extends Component {
  static propTypes = {
    classes: PropTypes.object,
    router: PropTypes.array
  };

  render() {
    const { router, classes } = this.props;

    return (
      <main className={classes.root}>
        {router.map(singleRoute => {
          const { path, exact, ...otherProps } = singleRoute;

          return (
            <Route
              exact={exact !== false}
              key={path}
              path={`${path}`}
              {...otherProps}
            />
          );
        })}
      </main>
    );
  }
}

export default withStyles(styles)(MainRouter);
