import React from 'react';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { SelectField } from './SelectField';
import { useGetProjects } from '../../queries/queryProjects';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';

/**
 * Receive the MuiSelector props
 * @param props
 * @param currentUser
 * @returns {JSX.Element}
 * @constructor
 */

const ProjectSelectorComponent = ({ currentUser, onChange, ...props }) => {
  const { data, isLoading, isFetching } = useGetProjects({ clientId: currentUser.client_id });
  /**
   * TODO: Create Project-Beneficiary Association Workflow
   * Problem: Using the default project index endpoint to load beneficiaries is slow and impacts overall performance.
   * Proposed Solution: Switch to using the BeneficiariesProject Model endpoint for more efficient data retrieval and filtering.
   */

  const { projects = [] } = data || {};
  const onChangeBehavior = event => {
    onChange &&
      onChange(
        event,
        projects.find(x => x.id === event.target.value)
      );
  };

  return (
    <SelectField {...props} onChange={onChangeBehavior} disabled={props.disabled || isLoading || isFetching}>
      {projects.map(({ name, id }) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

ProjectSelectorComponent.propTypes = {
  clientId: PropTypes.number
};

const mapToProps = state => ({ currentUser: getCurrentUser(state) });

export const ProjectSelector = connect(mapToProps)(ProjectSelectorComponent);
