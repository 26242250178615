import { TableCell, withStyles } from '@material-ui/core';
import { palette } from '../../../settings/themes/colors';
import { lightThemeV2 } from '../../../settings/themes/lightThemeV2';

export const BasicTableCell = withStyles({
  root: {
    color: palette.dimGray,
    padding: lightThemeV2.spacing(2),
    borderBottom: 0
  }
})(TableCell);
