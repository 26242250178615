import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { Card } from '../atoms/Card';
import { useDocumentsClasses } from './BeneficiaryAttachments.style';
import { LinkButton } from '../atoms/LinkButton';
import { SEPARATED_DATE } from '../../utils/utils';
import { useDeleteGroupDocument, useDownloadGroupDocument } from '../../queries/queryContactGroups';

export const GroupAttachments = ({ documents, groupId, onAssetsChange }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useDocumentsClasses();
  const { mutate: deleteGroupDocument } = useDeleteGroupDocument({ onSuccess: onAssetsChange });
  const { mutate: downloadGroupDocument } = useDownloadGroupDocument();

  const handleDelete = documentId => {
    deleteGroupDocument({ groupId, documentId });
  };
  const handleDownloadDocument = attachedDocument => {
    downloadGroupDocument({ groupId, attachedDocument });
  };

  const headingUI = (
    <Grid container className={classes.heading} alignItems="center">
      <Grid item xs={10}>
        <Typography className={classes.title}>{intlMessages['beneficiaries.lastDocumentsAttached']}</Typography>
      </Grid>
      <Grid item xs={2}>
        <LinkButton>{intlMessages['beneficiaries.showAll']}</LinkButton>
      </Grid>
    </Grid>
  );

  return (
    <>
      {headingUI}
      <Grid container className={classes.container} alignItems="center">
        {documents.map(({ id, created_at, name }) => (
          <Card key={id} className={classes.card}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={6}>
                {name}
              </Grid>
              <Grid item xs={2}>
                {moment(created_at).format(SEPARATED_DATE)}
              </Grid>
              <Grid item xs={2}>
                <LinkButton onClick={() => handleDownloadDocument({ id, name })}>{intlMessages['common.download']}</LinkButton>
              </Grid>
              <Grid item xs={2}>
                <LinkButton variant="danger" onClick={() => handleDelete(id)} key={id}>
                  {intlMessages['common.delete']}
                </LinkButton>
              </Grid>
            </Grid>
          </Card>
        ))}
      </Grid>
    </>
  );
};

GroupAttachments.propTypes = {
  documents: PropTypes.array.isRequired,
  groupId: PropTypes.number.isRequired,
  onAssetsChange: PropTypes.func.isRequired
};
