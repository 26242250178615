import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { FilterByGroupType } from './FilterByGroupType';
import { palette } from '../../settings/themes/colors';

const useStyles = makeStyles({
  root: {
    padding: '10px 20px',
    backgroundColor: palette.artic,
    width: '100%'
  }
});

const CHANGE_FILTER_TYPE = {
  ORG_GROUP: 'orgGroupsId',
  ADMINISTRARIVE_DIVISION: 'administrativeDivision'
};

const DEFAULT_FILTERS = {
  search: '',
  orgGroupsId: null
};

const ProgramFiltersComp = ({ onChange = () => {}, children }) => {
  const classes = useStyles();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handlerChange = (type, value) => {
    const newFilters = { ...filters, [type]: value };

    setFilters(newFilters);
    onChange(newFilters);
  };

  return (
    <Grid container className={classes.root}>
      <Grid item xs={8}>
        <FilterByGroupType onChange={x => handlerChange(CHANGE_FILTER_TYPE.ORG_GROUP, x)} />
      </Grid>
      {children}
    </Grid>
  );
};

ProgramFiltersComp.propTypes = {
  onChange: PropTypes.func,
  projectAlias: PropTypes.string,
  children: PropTypes.any
};

const mapToProps = state => {
  const currentUser = getCurrentUser(state);
  const { client_project_alias: clientProjectAlias = '' } = currentUser;

  return {
    projectAlias: (clientProjectAlias || '').toLowerCase()
  };
};

export const ProgramFilters = connect(mapToProps)(ProgramFiltersComp);
