import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';

export const useGetAlias = ({ clientId }) => {
  const queryFn = async context => {
    const clientIdFromQuery = context.queryKey[0].clientId;
    const result = await getApi().get(`clients/${clientIdFromQuery}/level_aliases`);

    return result.data.map(x => transformSnake2Camel(x));
  };

  return useQuery({
    queryFn,
    queryKey: [{ clientId }, 'GET_alias'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
