import React from 'react';
import { Grid, styled, Typography } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const Title = styled(Typography)({ color: palette.captudataBlue, marginBottom: lightThemeV2.spacing(4) });

export const CardSectionTitle = ({ actions, title }) => {
  return (
    <Grid container alignItems={'center'} justifyContent={'space-between'}>
      <Title variant={'subtitle1'}>{title}</Title>
      {actions}
    </Grid>
  );
};
