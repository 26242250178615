import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { REQUEST_PROGRAM, ADD_PROGRAM } from '../../../redux/action-types';
import { getProgram } from './helpers';

export function* programRequest() {
  yield takeEvery(REQUEST_PROGRAM, function*({ payload }) {
    const resp = yield call(getProgram, payload);

    yield put({
      type: ADD_PROGRAM,
      payload: resp
    });
  });
}

export default function* rootSaga() {
  yield all([fork(programRequest)]);
}
