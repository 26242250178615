import React from 'react';
import { MenuItem } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { SelectField } from './SelectField';

const SELECTOR_UNIT = ['kilograms', 'meters', 'square', 'liters', 'percentage', 'other'];

export const UnitSelector = ({ onChange, ...props }) => {
  const { messages: intlMessages } = useIntl();

  const handleChange = event => {
    onChange(event.target.value);
  };

  return (
    <SelectField onChange={handleChange} {...props}>
      {SELECTOR_UNIT.map((value, index) => (
        <MenuItem key={`${index}`} value={value}>
          {intlMessages[`selector.unit.${value}`]}
        </MenuItem>
      ))}
    </SelectField>
  );
};

UnitSelector.propTypes = {
  onChange: PropTypes.func.isRequired
};
