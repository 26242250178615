import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, Typography } from '@material-ui/core';
import { NumberSelector } from './NumberSelector';
import { useAgeSelectorClasses } from './AgeSelector.style';
import { MAX_AGE } from '../../containers/Beneficiaries/constants';

/**
 *  MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RANGES_LABELS = { min: 'min', max: 'max' };

export const CustomAgeRangeSelector = ({ ageTypes, setAgeTypes }) => {
  const { messages: intlMessages } = useIntl();
  const foundCustomRange = ageTypes[ageTypes.length - 1];
  const notCustomAgeTypes = ageTypes.filter(({ isCustomRange }) => !isCustomRange);
  const classes = useAgeSelectorClasses();
  const { min, max } = RANGES_LABELS;

  const updateCustomRange = (key, newValue) => {
    const updatedValue = { ...foundCustomRange.value, custom: true, [key]: newValue };

    if (
      (key === min && Number(newValue) > Number(foundCustomRange.value.max)) ||
      (key === max && Number(newValue) < Number(foundCustomRange.value.min))
    ) {
      updatedValue[key === min ? max : min] = newValue;
    }

    setAgeTypes([...notCustomAgeTypes, { ...foundCustomRange, value: updatedValue }]);
  };

  const onChangeMin = (_, newValue) => updateCustomRange(min, newValue);
  const onChangeMax = (_, newValue) => updateCustomRange(max, newValue);

  const customItemsConfig = [
    { xs: 2, IsNumberSelector: true, props: { label: min, value: foundCustomRange.value.min, onChange: onChangeMin, lastOne: MAX_AGE } },
    { xs: 1, content: '-', additionalProps: { variant: 'body1', className: classes.typography } },
    { xs: 2, IsNumberSelector: true, props: { label: max, value: foundCustomRange.value.max, onChange: onChangeMax, lastOne: MAX_AGE } },
    {
      xs: 1,
      content: intlMessages['common.years'],
      additionalProps: { variant: 'body1', className: classes.typography }
    }
  ];

  return (
    <Grid container spacing={1} className={classes.custom}>
      {customItemsConfig.map((config, index) => (
        <Grid item xs={config.xs} key={index}>
          {config.IsNumberSelector ? (
            <NumberSelector {...config.props} />
          ) : (
            <Typography {...config.additionalProps}>{config.content}</Typography>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

CustomAgeRangeSelector.propTypes = {
  setAgeTypes: PropTypes.func,
  ageTypes: PropTypes.array
};
