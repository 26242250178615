import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SelectField } from './SelectField';
import { useGetCurrencies } from '../../queries/queryCurrencies';

export const CurrencySelector = props => {
  const { data: currencies = [] } = useGetCurrencies();

  return (
    <SelectField {...props}>
      {currencies.map(({ iso_code: option, iso_numeric: id }, index) => (
        <MenuItem key={`currencies-${id}-${index}`} value={option}>
          {option}
        </MenuItem>
      ))}
    </SelectField>
  );
};
