import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';

export const useGetImplementingUnits = projectId => {
  const queryFn = async context => {
    const [projectIdParam] = context.queryKey;
    const result = await getApi().get(`/projects/${projectIdParam}/implementing_units`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [projectId, 'GET_partners'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
