import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useBeneficiaryTabsStyles } from './BeneficiariesTabs.styles';
import { NaturalBeneficiaries } from '../../containers/Beneficiaries/NaturalsBeneficiaries';
import { Groups } from '../../containers/Beneficiaries/Groups';
import { LegalBeneficiaries } from '../../containers/Beneficiaries/LegalBeneficiaries';

const TabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </Grid>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const a11yProps = index => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

const TabsConfig = [
  {
    key: 'naturals',
    component: NaturalBeneficiaries
  },
  {
    key: 'legal',
    component: LegalBeneficiaries
  },
  {
    key: 'groups',
    component: Groups
  }
];

export const BeneficiariesTabs = ({ selectedTab, handleTabChange }) => {
  const classes = useBeneficiaryTabsStyles();

  return (
    <>
      <AppBar position="static" color="primary" className={classes.tabPanel}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="Table tabs"
          classes={{ indicator: classes.indicator, root: classes.root }}
        >
          {TabsConfig.map(({ key }, index) => (
            <Tab
              key={`tab-${index}`}
              label={
                <Typography component="span" className={selectedTab === index ? classes.selected : classes.tabLabel}>
                  <FormattedMessage id={`beneficiaries.tab.${key}`} />
                </Typography>
              }
              {...a11yProps(index)}
              classes={{ root: classes.tab }}
            />
          ))}
        </Tabs>
      </AppBar>
      {TabsConfig.map(({ component: Component }, index) => (
        <TabPanel key={`tabpanel-${index}`} value={selectedTab} index={index} className={classes.tabContent}>
          <Component />
        </TabPanel>
      ))}
    </>
  );
};

BeneficiariesTabs.propTypes = {
  selectedTab: PropTypes.number.isRequired,
  handleTabChange: PropTypes.func.isRequired
};
