import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_FORMULARY_REPORT,
  GET_FORMULARY_REPORT_SUCCESS,
  GET_FORMULARY_REPORT_FAIL,
  CLEAN_FORMULARY_REPORT,
  GET_FORMULARY_SECTIONS,
  GET_FORMULARY_SECTIONS_SUCCESS,
  GET_FORMULARY_SECTIONS_FAIL,
  GET_FORMULARY_REPORTS_BY_QUESTION,
  GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS,
  GET_FORMULARY_REPORTS_BY_QUESTION_FAIL,
  RESET,
  GET_PROJECT_FORMULARIES_SUCCESS,
  GET_PROJECT_FORMULARIES,
  GET_PROJECT_FORMULARIES_FAIL,
  GET_PROJECT_FORMULARY,
  GET_PROJECT_FORMULARY_SUCCESS,
  GET_PROJECT_FORMULARY_FAIL,
  GET_PROJECT_FORMULARY_INSPECTIONS,
  GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS,
  GET_PROJECT_FORMULARY_INSPECTIONS_FAIL
} from '../action-types';

const initState = new Map({
  isLoading: false,
  formularyList: [],
  formularySections: [],
  formularyQuestions: {},
  projectFormularies: [],
  projectFormulary: { isLoading: true },
  projectFormularyInspections: []
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PROJECT_FORMULARIES:
    case GET_PROJECT_FORMULARY:
    case GET_PROJECT_FORMULARY_INSPECTIONS:
    case GET_FORMULARY_REPORT:
      return state.set('isLoading', true);
    case GET_FORMULARY_REPORT_SUCCESS:
      return state.set('isLoading', false).set('formularyList', assign([], get(action, 'payload.data', [])));
    case CLEAN_FORMULARY_REPORT:
    case GET_FORMULARY_REPORT_FAIL:
      return state.set('isLoading', false).set('formularyList', []);
    case GET_FORMULARY_SECTIONS:
      return state.set('isLoading', true);
    case GET_FORMULARY_SECTIONS_SUCCESS:
      return state.set('isLoading', false).set('formularySections', assign([], get(action, 'payload.data', [])));
    case GET_FORMULARY_SECTIONS_FAIL:
      return state.set('isLoading', false).set('formularySections', []);
    case GET_FORMULARY_REPORTS_BY_QUESTION:
      return state.set('isLoading', true);
    case GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS:
      return state.set('isLoading', false).set('formularyQuestions', assign({}, get(action, 'payload.data', {})));
    case GET_FORMULARY_REPORTS_BY_QUESTION_FAIL:
      return state.set('isLoading', false).set('formularyQuestions', {});
    case GET_PROJECT_FORMULARIES_SUCCESS:
      return state.set('isLoading', false).set('projectFormularies', assign([], get(action, 'payload.data', [])));
    case GET_PROJECT_FORMULARIES_FAIL:
      return state.set('isLoading', false).set('projectFormularies', []);
    case GET_PROJECT_FORMULARY_SUCCESS:
      return state.set('isLoading', false).set('projectFormulary', assign({}, get(action, 'payload.data', {})));
    case GET_PROJECT_FORMULARY_FAIL:
      return state.set('isLoading', false).set('projectFormulary', {});
    case GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS:
      return state.set('isLoading', false).set('projectFormularyInspections', assign([], get(action, 'payload.data', [])));
    case GET_PROJECT_FORMULARY_INSPECTIONS_FAIL:
      return state.set('isLoading', false).set('projectFormularyInspections', []);
    case RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
