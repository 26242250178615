import React, { useRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import { MuiThemeProvider } from '@material-ui/core';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { RequiredIcon } from '../../components/atoms/RequiredIcon';
import { getCurrentUser } from '../../utils/auth';
import { useCreateContactGroup } from '../../queries/queryContactGroups';
import { GroupForm } from '../../components/organims/GroupForm';

export const CreateGroupComponent = ({ clientId, history }) => {
  const { messages: intlMessages } = useIntl();
  const { mutate: createContactGroup } = useCreateContactGroup({
    onSuccess: () => {
      history.push(`/beneficiaries`);
      // TODO: Change this for refetching ContactGroups
      window.location.reload();
    }
  });
  const descriptionSection = (
    <>
      <FormattedMessage id={'group.newGroup.sectionDescription'} />
      <RequiredIcon />.
    </>
  );

  const submitRef = useRef();

  const handleSubmit = values => {
    createContactGroup({ clientId, data: values });
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent title={intlMessages['group.addGroup']} subtitle={descriptionSection}>
        <MuiButton
          variant={'contained'}
          onClick={() => {
            submitRef.current();
          }}
        >
          <FormattedMessage id={'group.addGroup'} />
        </MuiButton>
        <MuiButton variant={'outlined'} onClick={handleCancel}>
          <FormattedMessage id={'common.cancel'} />
        </MuiButton>
      </HeaderContent>
      <BodyContent>
        <GroupForm formRef={submitRef} onSubmit={handleSubmit} />
      </BodyContent>
    </MuiThemeProvider>
  );
};

CreateGroupComponent.propTypes = {
  clientId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired
};

const mapStateToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const CreateGroup = connect(mapStateToProps)(CreateGroupComponent);
