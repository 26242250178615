import PropTypes from 'prop-types';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useVariationPercentage } from './VariationPercentage.styles';

export const VariationPercentage = ({ value }) => {
  const { variationHighClasses, variationLowClasses, variationDefaultClasses } = useVariationPercentage();

  const valueConfig = [
    {
      condition: val => val === Infinity,
      content: () => '0%',
      typographyClass: variationDefaultClasses
    },
    {
      condition: val => val < 0,
      content: val => `↓ ${val}%`,
      typographyClass: variationLowClasses
    },
    {
      condition: val => val > 0,
      content: val => `↑ ${val}%`,
      typographyClass: variationHighClasses
    },
    {
      condition: _ => true,
      content: val => `${val}%`,
      typographyClass: variationDefaultClasses
    }
  ];

  const foundConfig = valueConfig.find(config => config.condition(value));
  const content = foundConfig.content(value);
  const typographyClass = foundConfig.typographyClass;

  return <Typography classes={typographyClass}>{content}</Typography>;
};

VariationPercentage.propTypes = {
  value: PropTypes.number.isRequired
};
