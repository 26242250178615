import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';

export const useGetStatistics = ({ clientId, params }) => {
  const queryFn = async context => {
    const clientIdFromQuery = context.queryKey[0];
    const result = await getApi().get(`clients/${clientIdFromQuery}/statistics`, { params: context.queryKey[1] });

    return transformSnake2Camel((result || {}).data);
  };

  return useQuery({
    queryFn,
    queryKey: [clientId, params, 'GET_alias'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
