import { makeStyles } from '@material-ui/core/styles';

export const useInspectionDetailItemStyles = makeStyles({
  root: {
    gap: 16,
    '& :nth-child(2)': {
      textAlign: 'right'
    },
    '&>p':{
      margin: '1em 0'
    }
  }
});
