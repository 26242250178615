import React, { memo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Card, CardContent, Divider, Grid, Modal, MuiThemeProvider, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { FormulationActivityForm } from './FormulationActivityForm';

// eslint-disable-next-line react/display-name
const DefaultTrigger = memo(props => <Button {...props}>Add Formulation</Button>);

const styles = makeStyles({
  root: {
    display: 'flex',
    '& i': {
      color: palette.candy
    },
    '& hr': {
      background: palette.yellow
    }
  },
  card: {
    margin: 'auto',
    minWidth: 760,
    maxWidth: 800,
    color: palette.dimGray
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(4),
    paddingTop: lightThemeV2.spacing(4),
    paddingLeft: lightThemeV2.spacing(8),
    paddingRight: lightThemeV2.spacing(8),
    '&:last-child': {
      paddingBottom: lightThemeV2.spacing(8)
    }
  }
});

const useFormulationActivityPopUp = () => styles();

export const FormulationActivityPopUp = ({
  formulationLevel,
  onClose = () => {},
  initialValues,
  triggerComponent: Triger = DefaultTrigger
}) => {
  const classes = useFormulationActivityPopUp();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const handlerSubmit = values => {
    onClose(values);
    setOpen(false);
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <Triger onClick={() => setOpen(true)} disabled={!formulationLevel.id} />
      <Modal open={open} onClose={handleClose} className={classes.root}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="column" spacing={2}>
              <Typography variant="h5">
                <FormattedMessage id="common.add" /> {formulationLevel.name}
              </Typography>
              <Typography>
                Los campos obligatorios están marcados con un asterisco <i>*</i> .
              </Typography>
            </Grid>
            <Divider />
            <FormulationActivityForm
              taskLevel={formulationLevel}
              initialValues={initialValues}
              onSubmit={handlerSubmit}
              onCancel={handleClose}
            />
          </CardContent>
        </Card>
      </Modal>
    </MuiThemeProvider>
  );
};

FormulationActivityPopUp.propTypes = {
  formulationLevel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string.isRequired,
    levelAliasId: PropTypes.number,
    parent: PropTypes.shape({
      name: PropTypes.string,
      tasks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          description: PropTypes.string,
          budget: PropTypes.number,
          plannedToStartAt: PropTypes.string,
          plannedToCompleteAt: PropTypes.string,
          responsible: PropTypes.shape({
            id: PropTypes.number,
            fullName: PropTypes.string
          })
        })
      )
    })
  }),
  initialValues: PropTypes.any,
  triggerComponent: PropTypes.any,
  onClose: PropTypes.func
};
