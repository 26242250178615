import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';
import { Chip, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useTableClasses } from './BeneficiariesTable.style';
import { BeneficiariesTableHead } from './BeneficiariesTableHead';
import { TableRow } from '../atoms/TableRow';
import { isEmpty } from 'lodash';
import DeleteMemberRow from '../molecules/DeleteMemberRow';

const descendingComparator = (a, b, orderBy) => {
  if (orderBy === 'name' && b[orderBy].label && a[orderBy].label) {
    return b[orderBy].label.localeCompare(a[orderBy].label);
  }
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
};

const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const GroupsTable = ({ data, dataColumns, pagination, onChangePagination, variant = 'checkbox' }) => {
  const { page, pageSize: rowsPerPage, count } = pagination || {};
  const classes = useTableClasses();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const { messages: intlMessages } = useIntl();

  const mapData = () =>
    data.map(item => {
      const { id, name, active, main_contact, contact_group_type, assigned_contacts, link, ...others } = item;
      const { name: mainContactName = '-', location = '-' } = main_contact || {};
      const { name: typeName = '-' } = contact_group_type || {};

      return {
        name: { link: link || `/groups/${id}`, label: name },
        status: active ? 'active' : 'inActive',
        location,
        mainContact: mainContactName,
        members: assigned_contacts,
        type: typeName,
        activity: '-',
        ...others
      };
    });

  const rows = mapData();
  const handleRequestSort = (_, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.identification);

      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, identification) => {
    const selectedIndex = selected.indexOf(identification);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, identification);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    onChangePagination && onChangePagination(newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = event => {
    onChangePagination && onChangePagination(0, parseInt(event.target.value, 10));
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const rowKeys = dataColumns.map(item => item.id);
  const labelDisplayedRows = ({ from, to, count }) =>
    `${from}-${to} ${intlMessages['table.of']} ${count !== -1 ? count : `more than ${to}`}`;

  const cellConfig = [
    {
      key: 'name',
      render: keyValue => (
        <TableCell component="th" id="name" scope="row" padding="normal">
          <Link className={classes.link} to={keyValue.link}>
            {keyValue.label}
          </Link>
        </TableCell>
      )
    },
    {
      key: 'status',
      render: keyValue => (
        <TableCell component="th" id="status" scope="row" padding="normal">
          <Chip label={intlMessages[`beneficiaries.${keyValue}`]} className={`${classes.chip} ${classes.chip}_${keyValue}`} />
        </TableCell>
      )
    },
    {
      key: 'type',
      render: keyValue => (
        <TableCell component="th" id="type" scope="row" padding="normal">
          <Chip label={keyValue} className={`${classes.chip} ${classes.chip}_type`} />
        </TableCell>
      )
    },
    {
      key: 'default',
      render: keyValue => (
        <TableCell align="left" padding="normal" id="default">
          {keyValue}
        </TableCell>
      )
    },
    {
      key: 'delete',
      render: props => <DeleteMemberRow {...props} />
    }
  ];

  return (
    <Grid className={variant === 'small' ? classes.small : classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <BeneficiariesTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={dataColumns}
              variant={variant}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                const isItemSelected = isSelected(row.identification);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.identification)}
                    role={variant}
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    {variant === 'checkbox' && (
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                    )}

                    {rowKeys.map(key => {
                      const config = cellConfig.find(conf => conf.key === key) || cellConfig.find(conf => conf.key === 'default');

                      return config.render(row[key]);
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!isEmpty(pagination) && (
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage={intlMessages['table.rowsPerPage']}
            labelDisplayedRows={labelDisplayedRows}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Grid>
  );
};

GroupsTable.propTypes = {
  data: PropTypes.array.isRequired,
  dataColumns: PropTypes.array.isRequired,
  pagination: PropTypes.object.isRequired,
  onChangePagination: PropTypes.func.isRequired,
  variant: PropTypes.string.isRequired
};
