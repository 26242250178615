import Auth from '../containers/Auth/ducks/reducer';
import Programs from '../containers/Programs/ducks/reducer';
import Projects from '../containers/_Projects/ducks/reducer';
import Inspections from '../containers/Inspections/ducks/reducer';
import Analytics from '../containers/Analytic/ducks/reducer';
import Admin from './admin/reducer';
import WorkOrders from './work-orders/reducer';
import Reports from './reports/reducer';
import CommonFilters from './common-filters/reducer';
import ProjectsInfo from './projects-info/reducer';
import bulkADLvlX from './bulkADLvlX/reducer';

export default {
  Auth,
  Programs,
  Projects,
  Inspections,
  Analytics,
  Admin,
  WorkOrders,
  Reports,
  CommonFilters,
  ProjectsInfo,
  bulkADLvlX
};
