import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SearchIcon from '@material-ui/icons/Search';
import { ProjectsMenu } from './ProjectsMenu';
import { palette } from '../../../../settings/themes/colors';
import { lightThemeV2 } from '../../../../settings/themes/lightThemeV2';
import { getCurrentUser } from '../../../../utils/auth';

const useStyle = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(2)
  },
  buttonContainer: {
    position: 'absolute',
    top: 0,
    right: '-56px',
    backgroundColor: `${palette.darkGray}66`,
    padding: lightThemeV2.spacing(2),
    borderTopRightRadius: lightThemeV2.spacing(1),
    borderBottomRightRadius: lightThemeV2.spacing(1),
    '@media (min-width: 1024px)': {
      display: 'none'
    }
  },
  arrowButton: {
    width: 36,
    height: 36,
    padding: 0,
    borderRadius: 4,
    border: `1px solid ${palette.cerulean}`,
    background: palette.white,
    '&:hover': {
      background: palette.white
    },
    '& .flip': {
      transform: 'rotate(180deg)'
    }
  },
  searchInput: {
    flex: 1,
    '& *[class^="MuiInputBase-root"]': {
      background: palette.white
    }
  },
  containerIl: {
    display: 'flex',
    width: 400,
    flexDirection: 'column',
    height: '100%',
    paddingTop: lightThemeV2.spacing(2),
    paddingBottom: lightThemeV2.spacing(2),
    paddingRight: lightThemeV2.spacing(3),
    paddingLeft: lightThemeV2.spacing(4),
    '@media (min-width: 1024px)': {
      width: 'auto'
    }
  }
});

const SIDEBAR_FILTER_TYPE = {
  SEARCH: 'search'
};

const DEFAULT_FILTERS = {
  search: '',
  orgGroupsIds: []
};

const SidebarComp = ({ projects = [], isOpen, onOpen, projectAlias, onChange = () => {} }) => {
  const classes = useStyle();
  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  const handlerChange = (keyFilter, value) => {
    const nextFilters = { ...filters, [keyFilter]: value };

    setFilters(nextFilters);
    if (keyFilter === SIDEBAR_FILTER_TYPE.SEARCH) {
      _.debounce(x => {
        onChange(x);
      }, 700)(nextFilters);
      return;
    }
    onChange(nextFilters);
  };

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <IconButton onClick={onOpen} className={classes.arrowButton}>
          <ArrowForwardIosIcon className={{ flip: isOpen }} />
        </IconButton>
      </div>
      {isOpen ? (
        <div className={classes.containerIl}>
          <Grid xs={12} className={classes.searchInput}>
            {isOpen ? (
              <FormControl>
                <FormattedMessage id="projects.search.placeholder" values={{ projectName: projectAlias }}>
                  {msg => (
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                      value={filters.search}
                      onChange={e => handlerChange(SIDEBAR_FILTER_TYPE.SEARCH, e.target.value)}
                      size="small"
                      placeholder={msg.pop()}
                    />
                  )}
                </FormattedMessage>
              </FormControl>
            ) : (
              ''
            )}
          </Grid>
          <ProjectsMenu items={projects} square={true} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

SidebarComp.propTypes = {
  classes: PropTypes.object,
  projects: PropTypes.array,
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  projectAlias: PropTypes.string,
  onChange: PropTypes.func
};

const mapToProps = state => {
  const currentUser = getCurrentUser(state);
  const { client_project_alias: clientProjectAlias = '' } = currentUser;

  return {
    projectAlias: (clientProjectAlias || '').toLowerCase()
  };
};

export const Sidebar = connect(mapToProps)(SidebarComp);
