import { Paper, styled } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

export const PaperContainer = styled(Paper)({
  width: 1024,
  padding: lightThemeV2.spacing(4),
  paddingBottom: lightThemeV2.spacing(12),
  gap: lightThemeV2.spacing(3),
  display: 'flex',
  flexDirection: 'column'
});
