import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import {
  GET_FORMULARY_REPORT,
  GET_FORMULARY_REPORT_SUCCESS,
  GET_FORMULARY_REPORT_FAIL,
  GET_FORMULARY_SECTIONS,
  GET_FORMULARY_SECTIONS_SUCCESS,
  GET_FORMULARY_SECTIONS_FAIL,
  GET_FORMULARY_REPORTS_BY_QUESTION,
  GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS,
  GET_FORMULARY_REPORTS_BY_QUESTION_FAIL,
  DOWNLOAD_FORMULARY_PROJECTS_EXCEL,
  GET_PROJECT_FORMULARIES,
  GET_PROJECT_FORMULARIES_SUCCESS,
  GET_PROJECT_FORMULARIES_FAIL,
  GET_PROJECT_FORMULARY,
  GET_PROJECT_FORMULARY_SUCCESS,
  GET_PROJECT_FORMULARY_FAIL,
  GET_PROJECT_FORMULARY_INSPECTIONS,
  GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS,
  GET_PROJECT_FORMULARY_INSPECTIONS_FAIL
} from '../action-types';
import {
  getFormularyReport,
  getFormularySections,
  getFormularyReportsByQuestion,
  getFormularyProjectsExcel,
  getProjectFormularies,
  getProjectFormulary,
  getProjectFormularyInspections
} from './helper';

export function* formularyReportRequest() {
  yield takeEvery(GET_FORMULARY_REPORT, function*({ payload }) {
    const resp = yield call(getFormularyReport, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_FORMULARY_REPORT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_FORMULARY_REPORT_FAIL
      });
    }
  });
}

export function* formularyReportSectionRequest() {
  yield takeEvery(GET_FORMULARY_SECTIONS, function*({ payload }) {
    const resp = yield call(getFormularySections, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_FORMULARY_SECTIONS_SUCCESS,
        payload: { data: resp.data }
      });
      yield put({
        type: GET_FORMULARY_REPORTS_BY_QUESTION,
        payload: {
          ...payload,
          sectionId: resp.data[0].id
        }
      });
    } else {
      yield put({
        type: GET_FORMULARY_SECTIONS_FAIL
      });
    }
  });
}

export function* formularyReportByQuestionsRequest() {
  yield takeEvery(GET_FORMULARY_REPORTS_BY_QUESTION, function*({ payload }) {
    const resp = yield call(getFormularyReportsByQuestion, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_FORMULARY_REPORTS_BY_QUESTION_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_FORMULARY_REPORTS_BY_QUESTION_FAIL
      });
    }
  });
}

export function* formularyProjectsDownloadExcelRequest() {
  yield takeEvery(DOWNLOAD_FORMULARY_PROJECTS_EXCEL, function*({ payload }) {
    const resp = yield call(getFormularyProjectsExcel, payload);
    if (!resp.data.errors) {
      const file = new File([resp.data], `report_f_${payload.formularyId}_p_${payload.projectIds.join('-')}.xlsx`, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(file);
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* projectFormulariesRequest() {
  yield takeEvery(GET_PROJECT_FORMULARIES, function*({ payload }) {
    const resp = yield call(getProjectFormularies, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_PROJECT_FORMULARIES_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PROJECT_FORMULARIES_FAIL
      });
    }
  });
}

export function* projectFormularyRequest() {
  yield takeEvery(GET_PROJECT_FORMULARY, function*({ payload }) {
    const resp = yield call(getProjectFormulary, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_PROJECT_FORMULARY_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PROJECT_FORMULARY_FAIL
      });
    }
  });
}

export function* projectFormularyInspectionsRequest() {
  yield takeEvery(GET_PROJECT_FORMULARY_INSPECTIONS, function*({ payload }) {
    const resp = yield call(getProjectFormularyInspections, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_PROJECT_FORMULARY_INSPECTIONS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PROJECT_FORMULARY_INSPECTIONS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(formularyReportRequest),
    fork(formularyReportSectionRequest),
    fork(formularyReportByQuestionsRequest),
    fork(formularyProjectsDownloadExcelRequest),
    fork(projectFormulariesRequest),
    fork(projectFormularyRequest),
    fork(projectFormularyInspectionsRequest)
  ]);
}
