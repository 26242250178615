import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { NaturalBeneficiaryForm } from './NaturalBeneficiaryForm';
import { LegalBeneficiaryForm } from './LegalBeneficiaryForm';
import { LEGALLY_INCORPORATED } from '../molecules/LegalStatusSelector';

export const EMPTY_FIELD = { component: () => <></>, xs: 8 };
const LEGAL_TYPE = 'legal';
const NATURAL_TYPE = 'natural';
const DEFAULT_CONTACT_TYPE = NATURAL_TYPE;

const useBeneficiaryForm = () => {
  const normalizeNaturalValues = (values = {}) => {
    const {
      contactType = NATURAL_TYPE,
      identification = null,
      firstName = null,
      lastName = null,
      secondSurname = null,
      sex = null,
      genderIdentity = null,
      sexualOrientation = null,
      maritalStatus = null,
      birthDate = null,
      averageMonthlyPersonalIncome = '',
      averageMonthlyHouseholdIncome = '',
      currency = '',
      ethnicity = '',
      educationalLevel = '',
      professionId = '',
      phoneNumber = '',
      email = '',
      streetAddress = '',
      note = ''
    } = values;

    return {
      contactType,
      identification,
      firstName,
      lastName,
      secondSurname,
      sex,
      genderIdentity,
      sexualOrientation,
      maritalStatus,
      birthDate,
      averageMonthlyPersonalIncome,
      averageMonthlyHouseholdIncome,
      currency,
      ethnicity,
      educationalLevel,
      professionId,
      phoneNumber,
      email,
      countryLocation: {
        country: values.country,
        level1: values.administrativeDivisionLevel1,
        level2: values.administrativeDivisionLevel2,
        level3: values.administrativeDivisionLevel3,
        level4: values.administrativeDivisionLevel4,
        level5: values.administrativeDivisionLevel5
      },
      streetAddress,
      note
    };
  };

  const normalizeLegalValues = (values = {}) => {
    const {
      contactType = LEGAL_TYPE,
      legalStatus,
      identification,
      incorporationDate = '',
      legalName = null,
      industryId = '',
      legalRepresentative,
      phoneNumber = null,
      email = null,
      mainContact,
      streetAddress = null,
      note = ''
    } = values;

    return {
      contactType,
      legalStatus: (!identification && legalStatus) || LEGALLY_INCORPORATED,
      identification: identification || '',
      incorporationDate,
      legalName,
      industryId,
      legalRepresentative: legalRepresentative || {
        identification: null,
        firstName: null,
        lastName: null,
        secondSurname: null
      },
      phoneNumber,
      email,
      mainContact: mainContact || {
        identification: null,
        firstName: null,
        lastName: null,
        secondSurname: null,
        phoneNumber: null,
        email: null
      },
      countryLocation: {
        country: values.country,
        level1: values.administrativeDivisionLevel1,
        level2: values.administrativeDivisionLevel2,
        level3: values.administrativeDivisionLevel3,
        level4: values.administrativeDivisionLevel4,
        level5: values.administrativeDivisionLevel5
      },
      streetAddress,
      note
    };
  };

  return { normalizeNaturalValues, normalizeLegalValues };
};

export const BeneficiaryForm = ({ formRef, onSubmit, defaultValues = {}, contactTypeHidden = false }) => {
  const { messages: intlMessages } = useIntl();
  const [contactType, setContactType] = useState(defaultValues.contactType || DEFAULT_CONTACT_TYPE);

  const { normalizeNaturalValues, normalizeLegalValues } = useBeneficiaryForm();

  return (
    <div>
      {!contactTypeHidden && (
        <FormControl>
          <FormLabel>
            <FormattedMessage id="beneficiaries.form.beneficiaryType" />
          </FormLabel>
          <RadioGroup
            row
            label={intlMessages['beneficiaries.form.beneficiaryType']}
            defaultValue={DEFAULT_CONTACT_TYPE}
            required
            onChange={v => {
              setContactType(v.target.value);
            }}
          >
            <FormControlLabel value={NATURAL_TYPE} control={<Radio />} label={intlMessages['beneficiaries.form.natural']} />
            <FormControlLabel value={LEGAL_TYPE} control={<Radio />} label={intlMessages['beneficiaries.form.legal']} />
          </RadioGroup>
        </FormControl>
      )}
      {contactType === NATURAL_TYPE ? (
        <NaturalBeneficiaryForm formRef={formRef} onSubmit={onSubmit} defaultValues={normalizeNaturalValues(defaultValues)} />
      ) : (
        <LegalBeneficiaryForm formRef={formRef} onSubmit={onSubmit} defaultValues={normalizeLegalValues(defaultValues)} />
      )}
    </div>
  );
};

BeneficiaryForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  contactTypeHidden: PropTypes.bool
};
