import { makeStyles } from '@material-ui/core';
import { lightThemeV2 } from '../../../settings/themes/lightThemeV2';
import { palette } from '../../../settings/themes/colors';

export const useStyles = makeStyles({
  headerCellPrimary: {
    backgroundColor: palette.captudataBlue,
    color: palette.white,
    padding: lightThemeV2.spacing(2),
    fontWeight: 700,
    '&:first-child': {
      borderTopLeftRadius: lightThemeV2.spacing(1)
    },
    '&:last-child': {
      borderTopRightRadius: lightThemeV2.spacing(1)
    }
  },
  headerCellSecondary: {
    backgroundColor: palette.captudataBlue50,
    color: palette.white,
    '&:first-child': {
      borderTopLeftRadius: 0
    },
    '&:last-child': {
      borderTopRightRadius: 0
    }
  },
  footerTableCellDefault: {
    padding: lightThemeV2.spacing(2),
    backgroundColor: palette.captuDataYellow,
    color: palette.white,
    borderBottom: 0
  },
  footerTableCellSecondary: {
    backgroundColor: palette.peakcock
  },
  actionIcon: {
    borderRadius: 0,
    padding: 0,
    minHeight: 42,
    minWidth: 42
  }
});
