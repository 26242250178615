const styles = theme => ({
  marker: {
    width: 32,
    height: 32,
    position: 'absolute',
    transform: 'translate(-50%, -100%)',

    '&__pin': {
      width: 32,
      height: 32,
      position: 'absolute',
      left: 0,
      transformOrigin: 'center bottom',

      '&--start, &--end': {
        width: 64,
        height: 64,
        bottom: -5,
        transform: 'translateX(-25%)'
      },

      path: {
        fill: theme.palette.secondary.light
      },

      '&--regular.hover path': {
        fill: theme.palette.secondary.red,
        zIndex: 99999999999,
        path: {
          fill: theme.palette.secondary.red
        }
      }
    },

    '&__pop-up': {
      width: '25em',
      position: 'absolute',
      right: '100%',
      top: '100%'
    }
  },

  zoomControl: {
    position: 'absolute',
    bottom: '1em',
    right: '1em',
    zIndex: 9,

    '&__item': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 35,
      height: 35,
      margin: '10px 20px',
      borderRadius: 0
    }
  }
});

export default styles;
