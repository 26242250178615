import { useMutation, useQuery } from '@tanstack/react-query';
import { transformSnake2Camel } from './utils';
import { getApi } from '../api';

export const useCreateInvoice = config =>
  useMutation(async ({ contractId, invoice }) => {
    const { number, effectiveDate, concept, invoiceType, supplierId, implementingUnitDetails = [], documentsAttributes = [] } = invoice;

    const formData = new FormData();
    formData.append('invoice[number]', number);
    formData.append('invoice[effective_date]', effectiveDate);
    formData.append('invoice[concept]', concept);
    formData.append('invoice[invoice_type]', invoiceType);
    formData.append('invoice[supplier_id]', supplierId);

    implementingUnitDetails.forEach(item => {
      formData.append(`invoice[implementing_unit_details][][amount]`, item.amount);
      formData.append(
        `invoice[implementing_unit_details][][task_budget_line_implementing_unit_detail_id]`,
        item.taskBudgetLineImplementingUnitDetailId
      );
    });

    documentsAttributes.forEach(item => {
      formData.append(`invoice[documents_attributes][][name]`, item.name);
      formData.append(`invoice[documents_attributes][][file]`, item.file);
      formData.append(`invoice[documents_attributes][][category_ids][]`, JSON.stringify(item.categoryIds[0]));
    });

    const axiosResult = await getApi().post(`/contracts/${contractId}/invoices`, formData);
    return axiosResult.data;
  }, config);

export const useGetInvoicesByBudgetLine = budgetLineId => {
  const queryFn = async context => {
    const [id] = context.queryKey;
    const result = await getApi().get(`task_budget_lines/${id}/invoices`);
    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [budgetLineId, 'GET_invoices_by_budget_line'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
