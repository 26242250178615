import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';

export const useGetProfessions = () => {
  const queryFn = async () => {
    const result = await getApi().get(`professions/`);

    return result.data;
  };

  return useQuery({
    queryFn,
    queryKey: ['GET_professions'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
