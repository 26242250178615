import { get } from 'lodash';

import { getApi } from '../../api';

export const getWorkOrders = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/work_orders`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const deleteWorkOrder = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().delete(`clients/${data.clientId}/work_orders/${data.id}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const createWorkOrder = async (data = {}) => {
  let resp = {};

  const params = {
    work_order: data.workOrder
  };

  try {
    resp = await getApi().post(`clients/${data.clientId}/work_orders`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const updateWorkOrder = async (data = {}) => {
  let resp = {};

  const params = {
    work_order: data.workOrder
  };

  try {
    resp = await getApi().put(`clients/${data.clientId}/work_orders/${data.workOrder.id}`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getWorkOrdersUsers = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/users`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getWorkOrdersProjects = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/projects`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getWorkOrdersFormularies = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/formularies`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
