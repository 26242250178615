import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  {
    path: '/formulary-reports',
    component: asyncComponent(() => import('./'))
  }
];

export default routes;
