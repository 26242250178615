import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, CardContent, Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { LinkButton } from '../atoms/LinkButton';
import { Modal } from '../molecules/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { ItemRecord } from '../molecules/ItemRecord';
import { ProjectMultiSelector } from '../molecules/ProjectAutocompleteSelector';
import { AssignProjectToBeneficiaryButton } from '../molecules/AssignProjectToBeneficiaryButton';
import { useGetBeneficiaryProjects, useUnassignProjectToBeneficiary } from '../../queries/queryBeneficiaries';
import { ListItems } from './ListItems';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';

export const useProjectsSectionStyles = () =>
  makeStyles({
    listContainer: {
      flex: 1,
      maxHeight: '226px',
      overflowY: 'scroll'
    },
    deleteIcon: {
      marginLeft: lightThemeV2.spacing(2),
      padding: lightThemeV2.spacing(1),
      color: palette.cerulean
    }
  })();

export const AssignProjectToBeneficiaryContent = ({ beneficiary }) => {
  const classes = useProjectsSectionStyles();
  const { data = [], refetch: refetchProjectBeneficiaries } = useGetBeneficiaryProjects({
    params: { only_main_attributes: true },
    beneficiaryId: beneficiary.id
  });
  const onSuccess = () => {
    refetchProjectBeneficiaries();
  };
  const { mutate: unassignProjectToBeneficiary } = useUnassignProjectToBeneficiary({ onSuccess });
  const { messages: intlMessages } = useIntl();
  const [openAssignProjectModal, setOpenAssignProjectModal] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const handleAssignToProject = () => {
    setOpenAssignProjectModal(!openAssignProjectModal);
  };

  const handleClose = () => {
    setOpenAssignProjectModal(false);
  };

  const handleOnChange = value => {
    setSelectedProjects(value);
  };

  const projectBeneficiaries = data.map(({ id, name, updatedAt }) => {
    const handleUnassignProject = () => {
      unassignProjectToBeneficiary({
        clientId: beneficiary.clientId,
        beneficiaryId: beneficiary.id,
        projectIds: [id]
      });
    };

    return {
      event: <Link to={`/projects/${id}`}>{name}</Link>,
      createdAt: updatedAt,
      controls: (
        <IconButton aria-label="delete card" onClick={handleUnassignProject} className={classes.deleteIcon}>
          <CloseIcon />
        </IconButton>
      )
    };
  });

  const labelUI = (
    <Typography>
      <FormattedMessage id="selector.project.assignAProject" />
      <Typography component="span">
        <i>*</i>
      </Typography>
    </Typography>
  );

  const buttonsUI = (
    <Grid container spacing={2}>
      <Grid item>
        <AssignProjectToBeneficiaryButton
          projects={selectedProjects}
          beneficiary={beneficiary}
          disabled={selectedProjects.length === 0}
          onClose={handleClose}
          refetchProjectBeneficiaries={refetchProjectBeneficiaries}
        />
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={handleClose}>
          <FormattedMessage id="common.cancel" />
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <CardContent>
      <CardSectionTitle
        title={intlMessages['beneficiaries.section.projectsSubtitle']}
        actions={<LinkButton onClick={handleAssignToProject}>{intlMessages['beneficiaries.linkProject']}</LinkButton>}
      />
      <ListItems
        dataSource={projectBeneficiaries}
        emptyMsg={intlMessages['beneficiaries.empty.projects']}
        component={ItemRecord}
        className={classes.listContainer}
      />
      {openAssignProjectModal && (
        <Modal
          open={openAssignProjectModal}
          onClose={handleClose}
          title={<FormattedMessage id="beneficiaries.associateProject" />}
          subtitle={
            <Typography>
              <FormattedMessage id="beneficiaries.form.mandatoryMessage" />
              <Typography component="span">
                <i>*</i> .
              </Typography>
            </Typography>
          }
        >
          <Grid container spacing={4} direction="column">
            <Grid item>
              <ProjectMultiSelector
                name={'project'}
                selectedProjects={selectedProjects || []}
                label={labelUI}
                onChange={c => handleOnChange(c)}
              />
            </Grid>
            <Grid item>{buttonsUI}</Grid>
          </Grid>
        </Modal>
      )}
    </CardContent>
  );
};

AssignProjectToBeneficiaryContent.propTypes = {
  beneficiary: PropTypes.object.isRequired
};
