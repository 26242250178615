import { get, assign } from 'lodash';
import { Map } from 'immutable';
import { storageSetData } from '../../../utils/storage';

import {
  REQUEST_ORG_TYPES,
  ADD_ORG_TYPES,
  REQUEST_ORG_GROUP,
  ADD_ORG_GROUP,
  SELECT_PROJECT,
  GET_FILTERED_PROJECTS,
  GET_FILTERED_PROJECTS_SUCCESS,
  GET_FILTERED_PROJECTS_FAIL,
  GET_FILTERED_PROJECTS_HEADERS,
  GET_FILTERED_PROJECTS_HEADERS_SUCCESS,
  GET_FILTERED_PROJECTS_HEADERS_FAIL,
  SET_FILTERED_PROJECTS_HISTORY_KEY
} from '../../../redux/action-types';

const initState = new Map({
  projects: [],
  filteredProjects: {
    projects: [],
    filters: {},
    pageSize: 25,
    page: 1,
    total: undefined,
    totalPages: 0,
    historyKey: undefined,
    isLoading: false,
    fail: undefined
  }
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const projectsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_ORG_TYPES:
      return state.set('orgGroupTypes', get(action, 'payload', {}));
    case REQUEST_ORG_GROUP:
      return state.set('isGroupsLoading', true);
    case ADD_ORG_GROUP:
      return state.set('isGroupsLoading', false).set(
        'orgGroups',
        assign({}, state.get('orgGroups'), {
          [action.id]: get(action, 'payload', {})
        })
      );
    case SET_FILTERED_PROJECTS_HISTORY_KEY:
      return state.set(
        'filteredProjects',
        assign({}, state.get('filteredProjects'), {
          historyKey: get(action, 'payload', {}).key
        })
      );
    case GET_FILTERED_PROJECTS_HEADERS:
    case GET_FILTERED_PROJECTS:
      const filters = get(action, 'payload', {});
      const storeGetFilter = assign({}, state.get('filteredProjects'), { filters });
      storageSetData('projectReducer-filteredProjects', storeGetFilter);
      return state.set(
        'filteredProjects',
        assign({}, state.get('filteredProjects'), {
          isLoading: true,
          fail: false,
          filters
        })
      );
    case GET_FILTERED_PROJECTS_HEADERS_SUCCESS:
      return state.set(
        'filteredProjects',
        assign({}, state.get('filteredProjects'), {
          isLoading: false,
          fail: false
          // Add other data
        })
      );
    case GET_FILTERED_PROJECTS_SUCCESS:
      const meta = get(action, 'payload', {}).data.meta;
      const projects = get(action, 'payload', {}).data.projects;
      return state.set(
        'filteredProjects',
        assign({}, state.get('filteredProjects'), {
          isLoading: false,
          fail: false,
          page: meta.current_page,
          total: meta.total_count,
          totalPages: meta.total_pages,
          projects
          // Add other data
        })
      );
    case GET_FILTERED_PROJECTS_HEADERS_FAIL:
    case GET_FILTERED_PROJECTS_FAIL:
      return state.set(
        'filteredProjects',
        assign({}, state.get('filteredProjects'), {
          isLoading: false,
          fail: true,
          page: 1,
          projects: []
        })
      );
    case SELECT_PROJECT:
      return state.set('SelectedProjectList', get(action, 'payload', {}));
    default:
      return state;
  }
};

// Action Creators

/**
 * Request all organization group types
 */
const requestOrgTypes = payload => ({
  type: REQUEST_ORG_TYPES,
  payload
});

/**
 * Request organization group
 */
const requestOrgGroup = payload => ({
  type: REQUEST_ORG_GROUP,
  payload
});

/**
 * selected project
 */
const setProjectSelected = payload => ({
  type: SELECT_PROJECT,
  payload
});

export const actionCreators = {
  requestOrgTypes,
  requestOrgGroup,
  setProjectSelected
};

export default projectsReducer;
