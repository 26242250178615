import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, MuiThemeProvider, Typography } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BeneficiariesTabs } from '../../components/organims/BeneficiariesTabs';
import { AddBeneficiaryButton } from '../../components/molecules/AddBeneficiaryButton';

const TABS_IDS = { NATURAL: 0, LEGAL: 1, GROUP: 2 };

export const Contacts = ({ history }) => {
  const { messages: intlMessages } = useIntl();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  const handleButtonClick = () => {
    history.push('/groups/create');
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent title={intlMessages.beneficiaries}>
        {(selectedTab === TABS_IDS.NATURAL || selectedTab === TABS_IDS.LEGAL) && <AddBeneficiaryButton />}
        {selectedTab === TABS_IDS.GROUP && (
          <Button variant="contained" onClick={handleButtonClick}>
            <Typography variant="button">{intlMessages['group.addGroup']}</Typography>
          </Button>
        )}
      </HeaderContent>
      <BeneficiariesTabs selectedTab={selectedTab} handleTabChange={handleTabChange} />
    </MuiThemeProvider>
  );
};

Contacts.propTypes = {
  currentUser: PropTypes.object,
  history: PropTypes.object
};
