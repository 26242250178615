import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGetCountries } from '../../queries/queryCountries';

/**
 * Receives MuiAutocomplete props. See more: https://v4.mui.com/components/autocomplete/
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CountryAutocompleteSelector = ({ onChange, clientId, label, value: country, ...otherProps }) => {
  const { data: countries = [], isLoading } = useGetCountries({ clientId });

  if (isLoading) {
    return <TextField label={label} value={'Loading...'} disabled />;
  }

  return (
    <Autocomplete
      options={countries}
      getOptionLabel={option => (typeof option === 'string' ? '' : option.name)}
      getOptionSelected={(option, value) => value === '' || option.id === value.id}
      onChange={(_, value) => onChange && onChange(value)}
      renderInput={params => <TextField label={label} {...params} />}
      value={countries.find(x => country && x.id === country.id) || ''}
      {...otherProps}
    />
  );
};

CountryAutocompleteSelector.propTypes = {
  clientId: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object
};
