import { FormattedMessage, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { lazy, object, string } from 'yup';
import { PropTypes } from 'prop-types';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@material-ui/core';
import { utils } from '../../containers/Projects/components/project/utils';
import { parseStringDateToFormat } from '../../utils/utils';
import { useBeneficiaryFormStyles } from './BeneficiaryForm.styles';
import { LocationSelector } from '../molecules/LocationSelector';
import { EducationalLevelSelector } from '../molecules/EducationalLevelSelector';
import { ProfessionsSelector } from '../molecules/ProfessionsSelector';
import { EMPTY_FIELD } from './BeneficiaryForm';
import { MappingFormFields } from './MappingFormFields';
import { CurrencySelector } from '../molecules/CurrencySelector';
import CurrencyInput from '../../containers/Projects/components/currencyField';
import { NativeDatePicker } from '../molecules/NativeDatePicker';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { SelectField } from '../molecules/SelectField';
import {
  ETHNICITY_TYPES,
  GENDER_IDENTITY_TYPES,
  MARITAL_STATUS_TYPES,
  SEXUAL_ORIENTATION_TYPES
} from '../../containers/Beneficiaries/constants';

export const NaturalBeneficiaryForm = ({ formRef, onSubmit, defaultValues = {} }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryFormStyles();
  const [unregistered, setUnregistered] = useState(!defaultValues.identification);

  const validationSchema = lazy(() => {
    const validationSchemeBase = {
      firstName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      lastName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      sex: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      birthDate: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      ethnicity: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      educationalLevel: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      professionId: string().required(<FormattedMessage id="beneficiaries.form.required" />)
    };

    if (unregistered) {
      return object().shape(validationSchemeBase);
    }

    return object().shape({
      identification: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      ...validationSchemeBase
    });
  });

  const { values, handleSubmit, getFieldProps, setValues, errors } = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submitValues => {
      const { birthDate, countryLocation, averageMonthlyPersonalIncome, averageMonthlyHouseholdIncome, ...otherValues } = submitValues;

      onSubmit({
        ...otherValues,
        averageMonthlyHouseholdIncome: utils.removeDecimalsFromAmount(averageMonthlyHouseholdIncome),
        averageMonthlyPersonalIncome: utils.removeDecimalsFromAmount(averageMonthlyPersonalIncome),
        countryId: countryLocation.country && countryLocation.country.id,
        administrative_division_level_1_id: countryLocation.level1 && countryLocation.level1.id,
        administrative_division_level_2_id: countryLocation.level2 && countryLocation.level2.id,
        administrative_division_level_3_id: countryLocation.level3 && countryLocation.level3.id,
        administrative_division_level_4_id: countryLocation.level4 && countryLocation.level4.id,
        birthDate: parseStringDateToFormat(birthDate)
      });
    }
  });

  formRef.current = () => {
    handleSubmit();
  };

  const dataFields = [
    { children: intlMessages['beneficiaries.form.section.information'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.identificationNumber'], name: 'identification', required: !unregistered },
    {
      label: intlMessages['beneficiaries.unregistered'],
      checked: unregistered,
      onChange: () => setUnregistered(isRegistered => !isRegistered),
      component: props => <FormControlLabel control={<Checkbox />} {...props} />
    },
    { ...EMPTY_FIELD, xs: 4 },
    { label: <WrapRequiredLabel label={intlMessages['common.name']} />, name: 'firstName' },
    { label: <WrapRequiredLabel label={intlMessages['beneficiaries.form.lastName']} />, name: 'lastName' },
    { label: intlMessages['beneficiaries.form.secondSurname'], name: 'secondSurname' },
    {
      name: 'sex',
      component: ({ required, error, helperText, ...props }) => (
        <FormControl>
          <FormLabel required={required}>
            <WrapRequiredLabel label={intlMessages['common.gender']} />
          </FormLabel>
          <RadioGroup row required={required} {...props}>
            <FormControlLabel value="male" control={<Radio />} label={intlMessages['common.male']} />
            <FormControlLabel value="female" control={<Radio />} label={intlMessages['common.female']} />
          </RadioGroup>
          {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
      )
    },
    {
      label: <FormattedMessage id="beneficiaries.form.genderIdentity" />,
      name: 'genderIdentity',
      customSettings: { keys: GENDER_IDENTITY_TYPES, translationSuffix: 'selector.genderIdentity.' },
      component: SelectField
    },
    {
      label: <FormattedMessage id="beneficiaries.form.sexualOrientation" />,
      name: 'sexualOrientation',
      customSettings: { keys: SEXUAL_ORIENTATION_TYPES, translationSuffix: 'selector.sexualOrientation.' },
      component: SelectField
    },
    {
      label: <FormattedMessage id="beneficiaries.form.maritalStatus" />,
      name: 'maritalStatus',
      customSettings: { keys: MARITAL_STATUS_TYPES, translationSuffix: 'selector.maritalStatus.' },
      component: SelectField
    },
    {
      label: <WrapRequiredLabel label={intlMessages['beneficiaries.form.birthdate']} />,
      name: 'birthDate',
      onChange: birthDate => setValues({ ...values, birthDate }),
      component: NativeDatePicker
    },
    { children: intlMessages['beneficiaries.form.section.economicSituation'], component: FormLabel, xs: 12 },
    {
      name: 'averageMonthlyPersonalIncome',
      label: <FormattedMessage id="beneficiaries.form.averageMonthlyPersonalIncome" />,
      required: false,
      component: CurrencyInput
    },
    {
      name: 'averageMonthlyHouseholdIncome',
      label: <FormattedMessage id="beneficiaries.form.averageMonthlyHouseholdIncome" />,
      required: false,
      component: CurrencyInput
    },
    { label: intlMessages['common.currency'], name: 'currency', component: CurrencySelector },
    { children: intlMessages['beneficiaries.form.section.additionalInformation'], component: FormLabel, xs: 12 },
    {
      label: <WrapRequiredLabel label={intlMessages['beneficiaries.form.ethnicity']} />,
      name: 'ethnicity',
      customSettings: { keys: ETHNICITY_TYPES, translationSuffix: 'beneficiaries.form.' },
      component: SelectField
    },
    {
      label: <WrapRequiredLabel label={intlMessages['beneficiaries.form.educationalLevel']} />,
      name: 'educationalLevel',
      component: EducationalLevelSelector
    },
    {
      label: <WrapRequiredLabel label={intlMessages['beneficiaries.form.profession']} />,
      name: 'professionId',
      component: ProfessionsSelector
    },
    { children: intlMessages['beneficiaries.section.contactInformation'], component: FormLabel, xs: 12 },
    { label: intlMessages['common.phone'], name: 'phoneNumber', type: 'tel' },
    { label: intlMessages['common.email'], name: 'email', type: 'email' },
    { children: intlMessages['beneficiaries.form.section.address'], component: FormLabel, xs: 12 },
    {
      name: 'countryLocation',
      xs: 12,
      defaultValue: defaultValues && defaultValues.countryLocation,
      onChange: countryLocation => setValues({ ...values, countryLocation }),
      component: LocationSelector
    },
    {
      label: intlMessages['beneficiaries.form.street'],
      name: 'streetAddress',
      xs: 12,
      multiline: true,
      minRows: 4
    },
    { children: intlMessages['beneficiaries.form.section.note'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.notes'], name: 'note', xs: 12, multiline: true, minRows: 4 }
  ];

  return (
    <form style={{ width: '100%' }} className={classes.root}>
      <MappingFormFields fields={dataFields} getFieldProps={getFieldProps} errors={errors} />
    </form>
  );
};

NaturalBeneficiaryForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object
};
