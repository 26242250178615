import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { palette } from '../../settings/themes/colors';
import { useGenerateProjectsView } from '../../queries/queryProjects';

const useButtonStyles = makeStyles(() => ({
  button: {
    color: palette.cerulean,
    textDecoration: 'underline'
  },
  label: {
    padding: '0 7px 0'
  }
}));

export const DownloadExcelButtonComponent = ({ filters, currentUser }) => {
  const classes = useButtonStyles();
  const { mutate: downloadView, isLoading } = useGenerateProjectsView();
  const { messages: intlMessages } = useIntl();
  const downloadFormularyExcel = () => {
    const { only_main_attributes: _, ...othersFilters } = filters;

    downloadView({ params: othersFilters, clientId: currentUser.client_id });
  };

  return (
    <Tooltip title={intlMessages['system.downloadView']}>
      <Button
        aria-label={intlMessages['system.downloadView']}
        onClick={() => downloadFormularyExcel()}
        variant="text"
        className={classes.button}
        disabled={isLoading || !filters.org_groups_ids}
      >
        <Typography variant="button" className={classes.label}>
          {intlMessages['system.downloadView']}
        </Typography>
      </Button>
    </Tooltip>
  );
};

DownloadExcelButtonComponent.propTypes = {
  currentUser: PropTypes.object,
  filters: PropTypes.object
};

const mapToProps = state => ({ currentUser: getCurrentUser(state) });

export const DownloadExcelButton = connect(mapToProps)(DownloadExcelButtonComponent);
