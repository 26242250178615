import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { FormLabel } from '@material-ui/core';
import { lazy, object, string } from 'yup';
import { PropTypes } from 'prop-types';
import { flattenErrors, parseStringDateToFormat } from '../../utils/utils';
import { useBeneficiaryFormStyles } from './BeneficiaryForm.styles';
import { LocationSelector } from '../molecules/LocationSelector';
import { EMPTY_FIELD } from './BeneficiaryForm';
import { MappingFormFields } from './MappingFormFields';
import { IndustrySelector } from '../molecules/IndustriesSelector';
import { LEGALLY_INCORPORATED, LegalStatusSelector } from '../molecules/LegalStatusSelector';
import { NativeDatePicker } from '../molecules/NativeDatePicker';

export const LegalBeneficiaryForm = ({ formRef, onSubmit, defaultValues = {} }) => {
  const [isLegallyIncorporated, setIsLegallyIncorporated] = useState(defaultValues.legalStatus === LEGALLY_INCORPORATED);
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryFormStyles();
  const isInitialRender = useRef(true);

  const validationSchema = lazy(() => {
    const validationSchemeBase = {
      legalName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      industryId: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      legalRepresentative: object().shape({
        firstName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        lastName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        secondSurname: string().required(<FormattedMessage id="beneficiaries.form.required" />)
      }),
      mainContact: object().shape({
        firstName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        lastName: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        secondSurname: string().required(<FormattedMessage id="beneficiaries.form.required" />)
      })
    };

    if (isLegallyIncorporated) {
      return object().shape({
        ...validationSchemeBase,
        identification: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        incorporationDate: string().required(<FormattedMessage id="beneficiaries.form.required" />)
      });
    }

    return object().shape(validationSchemeBase);
  });

  const { values, getFieldProps, setValues, errors, validateForm, handleSubmit } = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submitValues => {
      const { incorporationDate, countryLocation, ...otherValues } = submitValues;

      onSubmit({
        ...otherValues,
        countryId: countryLocation.country && countryLocation.country.id,
        administrative_division_level_1_id: countryLocation.level1 && countryLocation.level1.id,
        administrative_division_level_2_id: countryLocation.level2 && countryLocation.level2.id,
        administrative_division_level_3_id: countryLocation.level3 && countryLocation.level3.id,
        administrative_division_level_4_id: countryLocation.level4 && countryLocation.level4.id,
        incorporationDate: parseStringDateToFormat(incorporationDate)
      });
    }
  });

  formRef.current = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    validateForm(values).then();
    setIsLegallyIncorporated(values.legalStatus === LEGALLY_INCORPORATED);
  }, [values.legalStatus]);

  const dataFields = [
    { children: intlMessages['beneficiaries.form.section.information'], component: FormLabel, xs: 12 },
    {
      label: intlMessages['beneficiaries.legal.status'],
      name: 'legalStatus',
      component: LegalStatusSelector
    },
    {
      label: intlMessages['beneficiaries.form.identificationNumber'],
      disabled: !isLegallyIncorporated,
      required: isLegallyIncorporated,
      name: 'identification'
    },
    {
      label: intlMessages['beneficiaries.form.incorporationDate'],
      required: isLegallyIncorporated,
      disabled: !isLegallyIncorporated,
      name: 'incorporationDate',
      onChange: incorporationDate => setValues({ ...values, incorporationDate }),
      component: NativeDatePicker
    },
    { label: intlMessages['common.name'], name: 'legalName', required: true },
    {
      label: intlMessages['beneficiaries.form.activity'],
      name: 'industryId',
      required: true,
      component: IndustrySelector
    },
    { ...EMPTY_FIELD, xs: 4 },
    { children: intlMessages['beneficiaries.form.section.legalRepresentative'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.identificationNumber'], name: 'legalRepresentative.identification' },
    EMPTY_FIELD,
    { label: intlMessages['common.name'], name: 'legalRepresentative.firstName', required: true },
    { label: intlMessages['beneficiaries.form.lastName'], name: 'legalRepresentative.lastName', required: true },
    {
      label: intlMessages['beneficiaries.form.secondSurname'],
      name: 'legalRepresentative.secondSurname',
      required: true
    },
    { children: intlMessages['beneficiaries.section.contactInformation'], component: FormLabel, xs: 12 },
    { label: intlMessages['common.phone'], name: 'phoneNumber', type: 'tel' },
    { label: intlMessages['common.email'], name: 'email', type: 'email' },
    { children: intlMessages['beneficiaries.section.mainContactInformation'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.identificationNumber'], name: 'mainContact.identification' },
    EMPTY_FIELD,
    { label: intlMessages['common.name'], name: 'mainContact.firstName', required: true },
    { label: intlMessages['beneficiaries.form.lastName'], name: 'mainContact.lastName', required: true },
    { label: intlMessages['beneficiaries.form.secondSurname'], name: 'mainContact.secondSurname', required: true },
    { label: intlMessages['common.phone'], name: 'mainContact.phoneNumber', type: 'tel' },
    { label: intlMessages['common.email'], name: 'mainContact.email', type: 'email' },
    { children: intlMessages['beneficiaries.form.section.address'], component: FormLabel, xs: 12 },
    {
      name: 'countryLocation',
      xs: 12,
      defaultValue: defaultValues && defaultValues.countryLocation,
      onChange: countryLocation => setValues({ ...values, countryLocation }),
      component: LocationSelector
    },
    {
      label: intlMessages['beneficiaries.form.street'],
      name: 'streetAddress',
      xs: 12,
      multiline: true,
      minRows: 4
    },
    { children: intlMessages['beneficiaries.form.section.note'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.notes'], name: 'note', xs: 12, multiline: true, minRows: 4 }
  ];

  return (
    <form style={{ width: '100%' }} className={classes.root}>
      <MappingFormFields fields={dataFields} getFieldProps={getFieldProps} errors={flattenErrors(errors)} />
    </form>
  );
};

LegalBeneficiaryForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object
};
