import { Button, Divider, FormControl, Grid, InputLabel, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PropTypes } from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { SIMPLE_DASH_DATE_FORMAT, removeEmptyKeys } from '../../utils/utils';
import { useCreateTaskLevel, useUpdateTaskLevel } from '../../queries/queryFormulation';
import { FormulationActivityPopUp } from './FormulationActivityPopUp';
import { AliasSelector } from '../molecules/AliasSelector';
import { getCurrentUser } from '../../utils/auth';
import { FormulationActivityList } from './FormulationActivityList';
import { GOAL_TYPES } from './FormulationActivityForm';

const styles = makeStyles({
  subTitleSelect: {
    color: palette.dimGray
  },
  divider: {
    marginTop: lightThemeV2.spacing(4),
    marginBottom: lightThemeV2.spacing(4)
  },
  content: {
    padding: lightThemeV2.spacing(4)
  },
  description: {
    color: palette.dimGray
  }
});

const useFormulationTabPanel = () => styles();

const FormulationTabPanelComp = ({ level, onChangeAlias = () => {}, onUpdate = () => {}, currentUser, readOnly }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useFormulationTabPanel();
  const { mutate: createTask, data: taskAdded, isSuccess: taskAddedIsSucess } = useCreateTaskLevel();
  const { mutate: updateTask, data: lastTaskUpdated, isSuccess: taskUpdateIsSuccess } = useUpdateTaskLevel();

  const isFirstLevel = level.levelType === 'one';
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    if (taskUpdateIsSuccess) {
      onUpdate({
        ...level,
        tasks: level.tasks.map(x => {
          if (x.id === lastTaskUpdated.id) {
            return lastTaskUpdated;
          }
          return x;
        })
      });
    }
  }, [taskUpdateIsSuccess]);

  useEffect(() => {
    if (taskAddedIsSucess) {
      level.tasks.push(taskAdded);

      onUpdate({
        ...level
      });
    }
  }, [taskAddedIsSucess]);

  useEffect(() => {
    if (!taskToUpdate) return;

    triggerRef.current.click();
  }, [taskToUpdate]);

  const onHandleClose = formulationValues => {
    if (!formulationValues) {
      setTaskToUpdate(null);
      return;
    }

    const { plannedToStartAt, plannedToCompleteAt, quantifiable, ...otherValues } = formulationValues;

    if (taskToUpdate) {
      updateTask({
        levelId: level.id,
        task: {
          quantifiable: quantifiable === GOAL_TYPES.UNIT,
          plannedToStartAt,
          plannedToCompleteAt,
          responsibleId: currentUser.id,
          name: `${currentUser.name} - ${Date.now()}`,
          ...otherValues
        },
        taskId: taskToUpdate.taskId
      });

      setTaskToUpdate(null);
      return;
    }

    createTask({
      levelId: level.id,
      task: removeEmptyKeys({
        quantifiable: quantifiable === GOAL_TYPES.UNIT,
        plannedToStartAt,
        plannedToCompleteAt,
        responsibleId: currentUser.id,
        name: `${currentUser.name} - ${Date.now()}`,
        ...otherValues
      })
    });
  };

  const startUpdateTask = task => {
    const { id, description, plannedToStartAt, plannedToCompleteAt, budget, quantifiable, goalUnit, goal, goalDescription } = task;

    setTaskToUpdate({
      description,
      plannedToStartAt: moment(plannedToStartAt, SIMPLE_DASH_DATE_FORMAT),
      plannedToCompleteAt: moment(plannedToCompleteAt, SIMPLE_DASH_DATE_FORMAT),
      budget,
      quantifiable: quantifiable ? GOAL_TYPES.UNIT : GOAL_TYPES.OBJECTIVE,
      goalUnit,
      goal,
      taskId: id,
      goalDescription
    });
  };

  return (
    <Grid item xs={12}>
      {!readOnly && (
        <>
          <Grid container direction="row" justifyContent="space-between" className={classes.content}>
            <Grid item xs={8}>
              <Grid container direction="row" spacing={3}>
                <Grid item xs={5}>
                  <FormControl required className={classes.formControl} variant="outlined">
                    <InputLabel id="alias-selector-label" shrink>
                      <FormattedMessage id="formulation.alias" />
                    </InputLabel>
                    <AliasSelector
                      onChange={onChangeAlias}
                      value={level.levelAliasId || ''}
                      placeholder={intlMessages['common.selectOption']}
                      labelWidth={45}
                      labelId="alias-selector-label"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Typography className={classes.subTitleSelect} variant="body1">
                    <FormattedMessage id="formulation.aliasHelp" />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormulationActivityPopUp
                initialValues={taskToUpdate}
                formulationLevel={level}
                onClose={onHandleClose}
                triggerComponent={props => (
                  <Button
                    ref={triggerRef}
                    variant="outlined"
                    {...props}
                    disabled={!level.id || (!isFirstLevel && level.parent.tasks.length === 0)}
                  >
                    {intlMessages['common.add']} {level.name}
                  </Button>
                )}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
        </>
      )}
      <FormulationActivityList activities={level.tasks} level={level} onUpdate={startUpdateTask} readOnly={readOnly} />
    </Grid>
  );
};

const TaskShape = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  budget: PropTypes.number,
  plannedToStartAt: PropTypes.string,
  plannedToCompleteAt: PropTypes.string,
  responsible: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string
  })
});

FormulationTabPanelComp.propTypes = {
  level: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number,
    levelAliasId: PropTypes.number,
    levelType: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(TaskShape),
    parent: PropTypes.shape({
      name: PropTypes.string,
      tasks: PropTypes.arrayOf(TaskShape)
    })
  }),
  onChangeAlias: PropTypes.func,
  onUpdate: PropTypes.func,
  currentUser: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string
  }),
  readOnly: PropTypes.bool
};

const mapToProps = state => ({ currentUser: getCurrentUser(state) });

export const FormulationTabPanel = connect(mapToProps)(FormulationTabPanelComp);
