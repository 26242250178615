import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_BULK_ADMINISTRATIVE_DIVISIONS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL,
  RESET
} from '../action-types';

const adminDivisions = {
  level1: [],
  loadingLvl1: false,
  selectedLvl1: null,
  level2: [],
  loadingLvl2: false,
  selectedLvl2: null,
  level3: [],
  loadingLvl3: false,
  selectedLvl3: null,
  level4: [],
  loadingLvl4: false,
  selectedLvl4: null
};

const cfFilters = {
  loading: false,
  filters: []
};

// AD = Administrative Divisions
const initState = new Map({
  countries: [],
  selectedCountry: null,
  loadingCountries: false,
  adminDivisions,
  cfFilters
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */

const reducer = (state = initState, action) => {
  const level = get(action, 'payload.level', 0);
  const currentLevel = {};
  let levelArray = '';
  let loadingLevel = '';
  let selectedLevel = '';

  switch (action.type) {
    case GET_BULK_ADMINISTRATIVE_DIVISIONS:
      for (let i = level; i < 4; i++) {
        levelArray = `level${i}`;
        loadingLevel = `loadingLvl${i}`;
        selectedLevel = `selectedLvl${i}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      loadingLevel = `loadingLvl${level}`;
      currentLevel[loadingLevel] = true;
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS:
      levelArray = `level${level}`;
      loadingLevel = `loadingLvl${level}`;
      selectedLevel = `selectedLvl${parseInt(level - 1)}`;
      currentLevel[levelArray] = get(action, 'payload.data', []);
      currentLevel[loadingLevel] = false;
      currentLevel[selectedLevel] = get(action, 'payload.selectedId', null);
      for (let i = level; i < 4; i++) {
        levelArray = `level${i + 1}`;
        loadingLevel = `loadingLvl${i + 1}`;
        selectedLevel = `selectedLvl${i + 1}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL:
      levelArray = `level${level}`;
      loadingLevel = `loadingLvl${level}`;
      selectedLevel = `selectedLvl${level}`;
      currentLevel[levelArray] = [];
      currentLevel[loadingLevel] = false;
      currentLevel[selectedLevel] = null;
      for (let i = level; i < 4; i++) {
        levelArray = `level${i + 1}`;
        loadingLevel = `loadingLvl${i + 1}`;
        selectedLevel = `selectedLvl${i + 1}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
