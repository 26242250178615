import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useEmptyContentStyles } from './EmptyContent.styles';

/**
 * Recibe the MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const EmptyContent = ({ children, icon }) => {
  const classes = useEmptyContentStyles();

  return (
    <Grid className={classes.root}>
      {icon || <AccessTimeIcon />}
      {children}
    </Grid>
  );
};

EmptyContent.propTypes = {
  children: PropTypes.element,
  icon: PropTypes.object
};
