import { get } from 'lodash';

import { getApi } from '../../api';

export const getSharedInfo = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`shared_projects/${data.sharedToken}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getSharedProjects = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`shared_projects?token=${data.sharedToken}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getProjectSharedProjects = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`projects/${data.projectId}/shared_projects`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const createSharedProject = async (data = {}) => {
  let resp = {};
  const { projectId, shared_project } = data;
  const params = {
    shared_project
  };

  try {
    resp = await getApi().post(`projects/${projectId}/shared_projects`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const createProjectDocument = async (data = {}) => {
  let resp = {};
  const formData = new FormData();
  const { projectId, document } = data;

  formData.append('document[file]', document.file);
  formData.append('document[name]', document.name);

  try {
    resp = await getApi().post(`projects/${projectId}/documents`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const editProjectDocument = async (data = {}) => {
  let resp = {};

  const { projectId, document: currentDocument } = data;
  const { name, description, categories, id } = currentDocument;
  const category_ids = categories.map(c => c.id);

  const document = {
    name,
    description,
    category_ids
  };

  try {
    resp = await getApi().put(`projects/${projectId}/documents/${id}`, { document });
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const deleteProjectDocument = async (data = {}) => {
  let resp = {};

  const { projectId, documentId } = data;

  try {
    resp = await getApi().delete(`projects/${projectId}/documents/${documentId}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const downloadProjectDocument = async (data = {}) => {
  let resp = {};
  const { projectId, documentId } = data;

  try {
    resp = await getApi().get(
      `projects/${projectId}/documents/${documentId}/download`,
      {},
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status'),
    type: get(resp, 'headers')['content-type'],
    url: resp.request.responseURL
  };
};

export const getProjectDocuments = async (data = {}) => {
  let resp = {};
  const {
    projectId,
    paginationParams: { page },
    currentCategory: { id: categoryId }
  } = data;

  const sendCategoryParam = categoryId === 'default' ? '' : `&category_id=${categoryId}`;

  try {
    resp = await getApi().get(`projects/${projectId}/documents?page=${page}&per_page=5${sendCategoryParam}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getFinancialProgresses = async (data = {}) => {
  let resp = {};

  const { contractId, page, pageSize } = data || {};
  const params = `?page=${page || 1}&per_page=${pageSize || 20}`;

  try {
    resp = await getApi().get(`/contracts/${contractId}/financial_progresses${params}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const getPhysicalProgresses = async (data = {}) => {
  let resp = {};
  const { contractId, page, pageSize } = data || {};
  const params = `?page=${page || 1}&per_page=${pageSize || 20}`;

  try {
    resp = await getApi().get(`/contracts/${contractId}/physical_progresses${params}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const addPhysicalProgress = async (data = {}) => {
  let resp = {};
  const { contractId, params } = data || {};

  try {
    resp = await getApi().post(`/contracts/${contractId}/physical_progresses`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const addFinancialProgress = async (data = {}) => {
  let resp = {};

  const { contractId, params } = data || {};

  try {
    resp = await getApi().post(`/contracts/${contractId}/financial_progresses`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
