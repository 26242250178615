import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Autocomplete } from '@material-ui/lab';
import { useGetGroupTypeRoles } from '../../queries/queryGroups';

/**
 * Receive the MuiSelector props
 * @param props
 * @param currentUser
 * @returns {JSX.Element}
 * @constructor
 */

export const ContactGroupTypeRoleSelector = ({ contactGroupTypeId, onChange, value: groupTypeRole, ...props }) => {
  const { data: groupTypeRoles = [], isLoading, isFetching } = contactGroupTypeId
    ? useGetGroupTypeRoles({
        contactGroupTypeId
      })
    : {};
  const labelUI = (
    <Typography>
      <FormattedMessage id="group.linkContactGroupTypeRole" />
      <Typography component="span">
        <i>*</i>
      </Typography>
    </Typography>
  );

  return (
    <Autocomplete
      options={groupTypeRoles}
      getOptionLabel={option => (typeof option === 'string' ? '' : option.name)}
      getOptionSelected={(option, value) => value === '' || option.id === value.id}
      onChange={(_, value) => onChange && onChange(value)}
      renderInput={params => <TextField label={labelUI} {...params} />}
      value={groupTypeRoles.find(x => groupTypeRole && x.id === groupTypeRole.id) || ''}
      disabled={props.disabled || isLoading || isFetching}
      {...props}
    />
  );
};

ContactGroupTypeRoleSelector.propTypes = {
  clientId: PropTypes.number,
  disabled: PropTypes.bool,
  contactGroupTypeId: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.object
};
