import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

export const useGetSuppliers = clientId => {
  const queryFn = async context => {
    const [clientIdParam] = context.queryKey;
    const result = await getApi().get(`/clients/${clientIdParam}/suppliers`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryFn,
    queryKey: [clientId, 'GET_suppliers'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateSupplier = () =>
  useMutation(({ clientId, contact }) =>
    getApi()
      .post(`/clients/${clientId}/suppliers`, { contact: transformCamel2Snake(contact) })
      .then(axiosResult => axiosResult.data)
  );
