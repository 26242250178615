import { each, filter, isEmpty } from 'lodash';
import theme from '../../settings/themes/default';
import { getProjectColor } from '../../containers/Programs/components/utils';

/**
 * Returns the track point with a map format.
 *
 * @param {Object} props
 * @return {Array}
 */
export const getMapPoints = ({ maps, trackPoints }) => trackPoints.map(point => new maps.LatLng(point.latitude, point.longitude));

/**
 * Fits map to given project's track points
 *
 * @param {Object} map
 * @param {Object} maps
 * @param {Array} markers track points
 */
export const fitMapToPoints = ({ map, maps, points }) => {
  if (!map) {
    return;
  }

  if (isEmpty(points)) {
    return;
  }

  const bounds = new maps.LatLngBounds();

  each(points, point => {
    bounds.extend(new maps.LatLng(point.latitude, point.longitude));
  });

  map.fitBounds(bounds);
};

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4
};

/**
 * Prints project maps at map
 *
 * @param {Object} map destination
 * @param {Object} maps API
 * @param {Array} track points with latitude & longitude
 * @param {Object} element to send at mouse events
 * @param {string} route stroke color
 * @param {string} route stroke opacity
 * @param {string} route stroke weight
 * @param {Function} mouseover/mouseout handler
 */
export const printElementRoute = ({
  map,
  maps,
  trackPoints,
  element,
  color = theme.palette.primary.main, // primary (blue) as default color
  opacity = 1,
  weight = 7,
  onRouteHover
}) => {
  const mapRoute = new maps.Polyline({
    path: getMapPoints({ maps, trackPoints }),
    strokeColor: color,
    strokeOpacity: opacity,
    strokeWeight: weight,
    icons: [
      {
        icon: lineSymbol,
        offset: '0',
        repeat: '20px'
      }
    ]
  });

  mapRoute.setMap(map);

  if (onRouteHover) {
    maps.event.addListener(mapRoute, 'mouseover', onRouteHover(element));
    maps.event.addListener(mapRoute, 'mouseout', onRouteHover(null));
  }

  return mapRoute;
};

/*
 * Draw routes for given projects at given map
 *
 * @param {Array} all current projects
 * @param {Object} maps API
 * @param {Object} current map
 */
export const printProjectRoutes = ({ projects, onRouteHover, map, maps }) =>
  filter(projects, pj => pj.project_type === 'lineal').map(project =>
    printElementRoute({
      map,
      maps,
      trackPoints: project.track_points,
      element: project,
      color: getProjectColor(project),
      onRouteHover
    })
  );
