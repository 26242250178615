import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getProjectColorKey } from '../utils';
import ProgressBlock from './progressBlock';
import styles from './styles';
import { useGetProject } from '../../../../queries/queryProjects';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../../../utils/auth';

const InfoWindow = ({ projectId, currentUser, classes }) => {
  const { data: project, isLoading } = useGetProject({
    clientId: currentUser.client_id,
    projectId
  });

  if (isLoading) {
    return <></>;
  }

  const { main_contract } = project;

  if (!main_contract) {
    return (
      <div className={`${classes.markerInfo} ${classes.markerInfo}--${getProjectColorKey(project).toLowerCase()}`}>
        <h3 className={`${classes.markerInfo}__title`}>{project.name}</h3>
      </div>
    );
  }

  return (
    <div className={`${classes.markerInfo} ${classes.markerInfo}--${getProjectColorKey(project).toLowerCase()}`}>
      <h3 className={`${classes.markerInfo}__title`}>{project.name}</h3>
      <ProgressBlock
        value={main_contract.financial_progress_formatted}
        colorKey={getProjectColorKey(project)}
        i18nId="programs.inspection.financialProgress"
      />
      <ProgressBlock
        value={main_contract.physical_progress_formatted}
        colorKey={getProjectColorKey(project)}
        i18nId="programs.inspection.physicalProgress"
      />
      <ProgressBlock
        value={main_contract.execution_progress_formatted}
        colorKey={getProjectColorKey(project)}
        i18nId="programs.inspection.executionPeriod"
      />
    </div>
  );
};

InfoWindow.propTypes = {
  projectId: PropTypes.string,
  classes: PropTypes.object
};

export default connect(state => ({
  currentUser: getCurrentUser(state)
}))(withStyles(styles)(InfoWindow));
