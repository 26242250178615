import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GoogleMap from 'google-map-react';
import useSupercluster from 'use-supercluster';
import { makeStyles } from '@material-ui/core';
import { actionCreators } from '../../ducks/reducer';
import { fitMapToPoints } from '../../../../utils/map';
import config from '../../../../utils/map/config';
import Marker from '../../../../components/map/marker';
import InfoWindow from './infoWindow';
import ZoomControl from '../../../../components/map/zoomControl';
import { ClusterMarker } from '../../../../components/map/ClusterMarker';

const CR_COORDINATES = [8.3515683, -87.0531975];
const CLUSTER_OPTIONS = { radius: 75, maxZoom: 20 };
const DEFAULT_ZOOM = 7;

const useStyles = makeStyles({
  root: {
    height: '100%'
  }
});

const CaptudataMapComp = ({ projects: dirtyProjects, hoveredProject, idProjectSelected, showProject, saveIdProjectSelected }) => {
  const classes = useStyles();
  const [map, setMap] = useState(null);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [projects, setProjects] = useState([]);
  const [points, setPoints] = useState([]);
  const blockZoom = useRef(false);

  useEffect(() => {
    const cleanProjects = dirtyProjects.filter(({ longitude, latitude }) => longitude && latitude);

    setPoints(
      cleanProjects
        .filter(({ longitude, latitude }) => longitude && latitude)
        .map(project => ({
          type: 'Feature',
          properties: project,
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(project.longitude), parseFloat(project.latitude)]
          }
        }))
    );

    setProjects(cleanProjects);
    if (map) {
      fitMapToPoints({ map, maps: window.google.maps, points: cleanProjects });
    }
  }, [dirtyProjects]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: CLUSTER_OPTIONS
  });

  useEffect(() => {
    if (blockZoom.current) {
      blockZoom.current = false;
      return;
    }

    const project = projects.find(({ id }) => id === idProjectSelected);

    if (!project) return;
    fitMapToPoints({ map, maps: window.google.maps, points: [project] });
  }, [idProjectSelected]);

  const onApiLoaded = state => {
    fitMapToPoints({ map: state.map, maps: state.maps, points: projects });
    setMap(state.map);
  };

  const onChildMouseEnter = (key, { project }) => showProject(project);

  const onChildMouseLeave = () => showProject(null);

  return (
    <div className={classes.root}>
      {map && <ZoomControl map={map} />}
      <GoogleMap
        bootstrapURLKeys={{
          key: 'AIzaSyACZHrAGn2moZlseagDsbEp8rZJ6EVXwhA'
        }}
        defaultCenter={CR_COORDINATES}
        zoom={zoom}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={onApiLoaded}
        onChildMouseEnter={onChildMouseEnter}
        onChildMouseLeave={onChildMouseLeave}
        options={config.mapOptions}
        onChange={changes => {
          const { nw, se } = changes.bounds;

          setZoom(changes.zoom);
          setBounds([nw.lng, se.lat, se.lng, nw.lat]);
        }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates;
          const { cluster: isCluster, point_count: pointCount } = cluster.properties;

          if (isCluster) {
            return (
              <ClusterMarker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
                pointCount={pointCount}
                onClick={() => {
                  const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);

                  map.setZoom(expansionZoom);
                  map.panTo({ lat: latitude, lng: longitude });
                }}
              ></ClusterMarker>
            );
          }

          return (
            <Marker
              key={`${cluster.properties.id}-start`}
              lat={latitude}
              lng={longitude}
              project={cluster.properties}
              hover={hoveredProject && hoveredProject.id === cluster.properties.id}
              type="start"
              onClick={() => {
                blockZoom.current = true;
                saveIdProjectSelected(cluster.properties.id);
              }}
            >
              <InfoWindow projectId={cluster.properties.id} />
            </Marker>
          );
        })}
      </GoogleMap>
    </div>
  );
};

CaptudataMapComp.propTypes = {
  projects: PropTypes.array,
  center: PropTypes.array,
  defaultZoom: PropTypes.number,
  hoveredProject: PropTypes.object,
  idProjectSelected: PropTypes.object,
  showProject: PropTypes.func,
  saveIdProjectSelected: PropTypes.func
};

export const CaptudataMap = connect(
  state => ({
    hoveredProject: state.Programs.get('activeProject'),
    idProjectSelected: (state.Programs.get('idProjectSelected') || {}).idProjectSelected
  }),
  {
    showProject: actionCreators.showProject,
    saveIdProjectSelected: actionCreators.saveIdProjectSelected
  }
)(CaptudataMapComp);
