import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useGetFormulationLevelsWithTasks } from '../../../../../queries/queryFormulation';
import { groupByItems, groupTaskByLvl } from './utils';
import { useCreateBudgetLine, useDeleteBudgetLine, useGetItemBudgetInBulk, useUpdateBudgetLine } from '../../../../../queries/queryBudgets';
import { actionCreators } from '../../../../../redux/projects-info/actions';

const BudgetContext = createContext();

export const SUCCESS_ACTIONS = Object.freeze({
  CREATE_BUDGET_LINE: 'CREATE_BUDGET_LINE',
  UPDATE_BUDGET_LINE: 'UPDATE_BUDGET_LINE'
});

export const VIEW_MODE = Object.freeze({
  BY_LEVELS: 'BY_LEVELS',
  BY_ITEMS: 'BY_ITEMS'
});

export const SELECTION_LEVELS = Object.freeze({
  LEVEL_1: 'LEVEL_1',
  LEVEL_2: 'LEVEL_2',
  LEVEL_3: 'LEVEL_3'
});

export const BUDGET_STATUS = Object.freeze({
  DRAFT: 'draft',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  UNDER_REVIEW: 'under_review'
});

export const useBudgetContext = ({ onSuccess, onDelete, onChangeSelection } = { onSuccess: () => {}, onDelete: () => {} }) => {
  const {
    projectId,
    currency,
    budgetId,
    viewMode,
    budgetIsUpdating,
    revisionMode,
    selection,
    setSelection,
    status,
    contractId
  } = useContext(BudgetContext);
  const budgetLineBulkQuery = useRef({ ids: [] });
  const budgetLinesByItem = useRef([]);
  const { data: allBudgetLines = [], refetch: refetchBudgetLineInBulk } = useGetItemBudgetInBulk(budgetLineBulkQuery.current);

  if (!projectId) {
    throw new Error('useBudgetContext must be used within a BudgetProvider');
  }

  const [budgetsLineByLevel, setBudgetsLineByLevel] = useState([]);

  const { mutate: createBudgetLine } = useCreateBudgetLine({
    onSuccess: newBudgetLine => {
      budgetIsUpdating(true);
      onSuccess(SUCCESS_ACTIONS.CREATE_BUDGET_LINE, newBudgetLine);
      budgetIsUpdating(false);
    }
  });

  const { mutate: updateBudgetLine } = useUpdateBudgetLine({
    onSuccess: newBudgetLine => {
      budgetIsUpdating(true);
      onSuccess(SUCCESS_ACTIONS.UPDATE_BUDGET_LINE, newBudgetLine);
      budgetIsUpdating(false);
    }
  });

  const { mutate: deleteBudgetLine } = useDeleteBudgetLine({
    onSuccess: () => {
      onDelete();
      refetchBudgetLineInBulk();
    }
  });

  const { data: levelListResponse = [], refetch: refetchLevels } = useGetFormulationLevelsWithTasks(projectId, {
    onSuccess: (responseData = []) => {
      setBudgetsLineByLevel(groupTaskByLvl(responseData));
    }
  });

  const refetch = () => {
    refetchBudgetLineInBulk();
    refetchLevels();
  };

  useEffect(() => {
    if (onChangeSelection) {
      onChangeSelection(selection.filter(x => x.level === SELECTION_LEVELS.LEVEL_3).map(({ level, ...x }) => x));
    }
  }, [selection]);

  const getBudgetLines = id => {
    const result =
      viewMode === VIEW_MODE.BY_LEVELS
        ? allBudgetLines.filter(budgetLine => budgetLine.taskId === id)
        : allBudgetLines.filter(budgetLine => budgetLine.subBudgetCategoryId === id);

    const getlastListTask = levelListResponse[levelListResponse.length - 1].tasks;

    return result.map(budgetLine => ({
      ...budgetLine,
      task: getlastListTask.find(task => task.id === budgetLine.taskId),
      name: viewMode === VIEW_MODE.BY_LEVELS ? budgetLine.budgetCategory.name : budgetLine.description
    }));
  };

  if (levelListResponse.length > 0 && allBudgetLines.length === 0) {
    const getlastListTask = levelListResponse[levelListResponse.length - 1].tasks;

    budgetLineBulkQuery.current = { ids: getlastListTask.map(({ id }) => id) };
    refetchBudgetLineInBulk();
  }

  if (allBudgetLines.length > 0 && budgetLinesByItem.current.length === 0) {
    budgetLinesByItem.current = groupByItems(allBudgetLines);
  }

  useEffect(() => {
    if (Array.isArray(levelListResponse)) {
      setBudgetsLineByLevel(groupTaskByLvl(levelListResponse || []));
    }
  }, []);

  const selectItem = (selectedItem, level) => {
    const item = { ...selectedItem, level };

    if (level === SELECTION_LEVELS.LEVEL_1) {
      let newSelection = [...selection, item];

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          const budgetLines = getBudgetLines(child.id).map(budgetLine => ({ ...budgetLine, level: SELECTION_LEVELS.LEVEL_3 }));

          newSelection = [...newSelection, { ...child, level: SELECTION_LEVELS.LEVEL_2 }, ...budgetLines];
        });
      }

      setSelection(newSelection);

      onChangeSelection && onChangeSelection(newSelection);
      return;
    } else if (level === SELECTION_LEVELS.LEVEL_2) {
      const budgetLines = getBudgetLines(item.id).map(budgetLine => ({ ...budgetLine, level: SELECTION_LEVELS.LEVEL_3 }));

      setSelection([...selection, item, ...budgetLines]);
      onChangeSelection && onChangeSelection([...selection, item, ...budgetLines]);
      return;
    }
    setSelection([...selection, item]);
    onChangeSelection && onChangeSelection([...selection, item]);
  };

  const removeItem = (item, level) => {
    if (level === SELECTION_LEVELS.LEVEL_1) {
      let toRemove = selection.filter(x => x.id !== item.id);

      if (item.children && item.children.length > 0) {
        item.children.forEach(child => {
          toRemove = toRemove.filter(x => x.id !== child.id);
          const budgetLines = getBudgetLines(child.id);

          toRemove = toRemove.filter(x => !budgetLines.some(({ id }) => id === x.id));
        });
      }
      setSelection(toRemove);
      onChangeSelection && onChangeSelection(toRemove);
      return;
    } else if (level === SELECTION_LEVELS.LEVEL_2) {
      let toRemove = selection.filter(x => x.id !== item.id);
      const budgetLines = getBudgetLines(item.id);

      toRemove = toRemove.filter(x => !budgetLines.some(({ id }) => id === x.id));
      setSelection(toRemove);
      onChangeSelection && onChangeSelection(toRemove);
      return;
    }
    setSelection(selection.filter(x => x.id !== item.id));
    onChangeSelection && onChangeSelection(selection.filter(x => x.id !== item.id));
  };

  const multiSelectionEnabled = status === BUDGET_STATUS.APPROVED || revisionMode;

  return {
    dataSource: viewMode === VIEW_MODE.BY_LEVELS ? budgetsLineByLevel : budgetLinesByItem.current,
    createBudgetLine,
    updateBudgetLine,
    getBudgetLines,
    deleteBudgetLine,
    currency,
    projectId,
    budgetId,
    contractId,
    status,
    viewMode,
    multiSelectionEnabled,
    refetch,
    getSelection: () => selection.filter(x => x.level === SELECTION_LEVELS.LEVEL_3).map(({ level, ...x }) => x),
    selectItem,
    removeItem,
    isSelected: item => selection.some(x => x.id === item.id)
  };
};

const BudgetProviderComp = ({ children, projectId, currency, budgetId, budgetIsUpdating, viewMode, revisionMode, status, contractId }) => {
  const [selection, setSelection] = useState([]);

  return (
    <BudgetContext.Provider
      value={{ status, projectId, currency, budgetId, viewMode, budgetIsUpdating, revisionMode, setSelection, selection, contractId }}
    >
      {children}
    </BudgetContext.Provider>
  );
};

BudgetProviderComp.propTypes = {
  children: PropTypes.any,
  projectId: PropTypes.number,
  currency: PropTypes.string,
  budgetId: PropTypes.number,
  budgetIsUpdating: PropTypes.func,
  viewMode: PropTypes.string,
  revisionMode: PropTypes.bool,
  contractId: PropTypes.number
};

const mapDispatchToProps = dispatch => ({
  budgetIsUpdating: isUpdating => dispatch(actionCreators.updatingBudget(isUpdating))
});

export const BudgetProvider = connect(() => ({}), mapDispatchToProps)(BudgetProviderComp);
