import { USER_LOCALE_CHANGE_REQUEST, USER_LOCALE_CHANGE_ERROR } from '../action-types';
// Action Creators
/**
 * Request the locale location
 */
const changeLocale = (clientId, userId, locale) => ({
  type: USER_LOCALE_CHANGE_REQUEST,
  payload: {
    clientId,
    user: {
      id: userId,
      settings_locale: locale
    }
  }
});

/**
 * Apply the locale error action
 */
const changeLocaleFail = user => ({
  type: USER_LOCALE_CHANGE_ERROR
});

export const actionCreators = {
  changeLocale,
  changeLocaleFail
};
