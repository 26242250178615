import { get, set, isNull, isEmpty } from 'lodash';

/**
 * Performs CRUD operations in local storage.
 * Fetches data from local storage. If path is defined return its content
 * instead or null if not found.
 */
const STORAGE_PREFIX = '';

// export const storagePrefixKey = (prefix, key = '') => `${prefix}.${key}`;
export const storagePrefixKey = (prefix, key = '') => key;

/**
 * Fetches data from local storage. If path is defined return its content
 * instead or null if not found.
 *
 * @param  {string}  key
 * @param  {string}  path
 * @param  {*}       default value if not found
 * @return {object}  Data corresponding to the key stored || path data
 */

export const storageGetData = (key, path, myDefault = null) => {
  if (!key) {
    console.log('Error - no key provided');
    return null;
  }
  const data = localStorage.getItem(storagePrefixKey(STORAGE_PREFIX, key));

  if (!isNull(data)) {
    try {
      const parsed = JSON.parse(data);

      return path ? get(parsed, path, myDefault) : parsed;
    } catch (error) {
      // couldn't parse data, it should be regular string
      if (path) {
        console.log("Error - stored data can't be parsed to JSON", data, path);
        return null;
      }
      return data; // just return stored string
    }
  } else {
    return myDefault;
  }
};

/**
 * Get data from local storage. If the path is correctly defined,
 * returns content and it is deleted in local storage,
 * otherwise returns null.
 *
 * @param  {string}  key
 * @param  {string}  path
 * @param  {*}       default value if not found
 * @return {object}  Data corresponding to the key stored || path data
 */

export const storageReplaceSpecificData = (key, path, myDefault = null) => {
  if (!key) {
    console.log('Error - no key provided');
    return null;
  }
  const data = localStorage.getItem(storagePrefixKey(STORAGE_PREFIX, key));

  if (!isNull(data)) {
    try {
      const parsed = JSON.parse(data);
      let newData = '';
      if (path) {
        newData = get(parsed, path, null);
        set(parsed, path, myDefault);
        localStorage.setItem(storagePrefixKey(STORAGE_PREFIX, key), JSON.stringify(parsed));
        return newData;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

/**
 * Sets data to local storage.
 *
 * @param  {string} key
 * @param  {object} value to be stored (number, string or object)
 */
export const storageSetData = (key, value) => {
  if (!key) {
    return false;
  }

  localStorage.setItem(storagePrefixKey(STORAGE_PREFIX, key), JSON.stringify(value));
};

/**
 * Removes data from local storage.
 *
 * @param  {string}  key
 */
export const storageRemoveData = key => {
  if (!key) {
    return false;
  }

  localStorage.removeItem(storagePrefixKey(STORAGE_PREFIX, key));
};

/**
 * Returns boolean, true if key exists in storage.
 *
 * @param  {string}  Key
 * @return {boolean}
 */
export const storageHasData = key => {
  const data = localStorage.getItem(storagePrefixKey(STORAGE_PREFIX, key));

  return !isEmpty(data ? JSON.parse(data) : null);
};

export const getLocalStorage = () => localStorage;

// Clears the local storage.
export const storageClearAll = () => {
  console.log('StorageService: all data cleared');
  return localStorage.clear();
};

export const clearSession = () => {
  localStorage.removeItem('isLoggedIn');
  localStorage.removeItem('currentUser');
  localStorage.removeItem('authHeaders');
  localStorage.removeItem('projectReducer-filteredProjects');
};

export const Storage = {
  STORAGE_PREFIX,
  prefixKey: storagePrefixKey,
  getData: storageGetData,
  hasData: storageHasData,
  setData: storageSetData,
  removeData: storageRemoveData,
  clearAll: storageClearAll,
  clearSession,
  getLocalStorage
};
