import React from 'react';
import { CardContent, Divider, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { useCreateBeneficiaryNote, useGetBeneficiaryNote } from '../../queries/queryBeneficiaries';
import { getCurrentUser } from '../../utils/auth';
import { CardSectionTitle } from './CardSectionTitle';
import { ListItems } from '../organims/ListItems';
import { AddNote } from './AddNote';
import { Card } from '../atoms/Card';
import { ItemNote } from './ItemNote';

export const useNoteSectionStyles = () =>
  makeStyles({
    listContainer: {
      flex: 1,
      maxHeight: '256px',
      overflowY: 'scroll'
    }
  })();

export const NoteSectionComponent = ({ userId, beneficiaryId, clientId }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useNoteSectionStyles();
  const { data: notes = [], refetch } = useGetBeneficiaryNote({ clientId, beneficiaryId });
  const { mutate: createNote } = useCreateBeneficiaryNote({
    onSuccess: () => {
      refetch();
    }
  });

  const normalizedNotes = notes.map(note => ({
    note: note.text,
    responsible: note.creator.fullName,
    creationDate: note.createdAt,
    id: note.id,
    updateDate: note.updatedAt
  }));
  const handleAddNote = note => {
    createNote({
      note,
      clientId,
      beneficiaryId,
      creatorId: userId
    });
  };

  return (
    <Card variant={'stretch'} gap={2}>
      <CardContent>
        <CardSectionTitle title={intlMessages['beneficiaries.section.notes']} />
        <ListItems
          dataSource={normalizedNotes}
          className={classes.listContainer}
          emptyMsg={intlMessages['beneficiaries.empty.notes']}
          component={props => <ItemNote clientId={clientId} beneficiaryId={beneficiaryId} userId={userId} {...props} refetch={refetch} />}
        />
        <Divider />
        <AddNote onSubmit={handleAddNote} label={intlMessages['beneficiaries.addNoteLabel']} />
      </CardContent>
    </Card>
  );
};

NoteSectionComponent.propTypes = {
  userId: PropTypes.string.isRequired,
  beneficiaryId: PropTypes.string.isRequired,
  notes: PropTypes.array,
  clientId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({ userId: getCurrentUser(state).id, clientId: getCurrentUser(state).client_id });

export const NoteSection = connect(mapStateToProps)(NoteSectionComponent);
