import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  {
    path: '/admin/',
    component: asyncComponent(() => import('./'))
  }
];

export default routes;
