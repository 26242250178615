import React, { useState, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import { Storage } from '../../utils/storage';

import Spanish from '../../assets/locale/es.json';
import English from '../../assets/locale/en.json';

const Context = React.createContext();
// TODO: [CAP-701] remove the typescript code
const getLanguageOrDefault = (lang: string) => (['es', 'en'].includes(lang) ? lang : 'es');
const local = getLanguageOrDefault(Storage.getData('local_lang', '', navigator.language.split('-')[0]));

let lang;
if (local === 'en') {
  lang = English;
} else {
  lang = Spanish;
}

// TODO: [CAP-701] remove the typescript code
export const withI18NContext = (Component: any) => {
  return (props: any) => {
    const i18nContext = useContext(Context);

    return <Component i18nContext={i18nContext} {...props} />;
  };
};

const Wrapper = props => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  function selectLanguage(e) {
    const newLocale = e.target.value;
    setLocale(newLocale);
    Storage.setData('local_lang', newLocale);
    if (newLocale === 'en') {
      setMessages(English);
    } else {
      setMessages(Spanish);
    }
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};
export default Wrapper;
