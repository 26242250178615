import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, makeStyles } from '@material-ui/core';
import { CloseOutlined } from '@material-ui/icons';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const useTabStyles = () =>
  makeStyles(() => ({
    root: {
      paddingLeft: lightThemeV2.spacing(3),
      paddingRight: lightThemeV2.spacing(3),
      paddingTop: lightThemeV2.spacing(2),
      paddingBottom: lightThemeV2.spacing(2),
      position: 'relative',
      justifyContent: 'center',
      height: 'inherit',
      '& p': {
        whiteSpace: 'nowrap'
      }
    },
    hasRemoveBtn: {
      paddingLeft: lightThemeV2.spacing(9),
      justifyContent: 'space-between'
    },
    removeButton: {
      width: 24,
      height: 24,
      marginLeft: lightThemeV2.spacing(1)
    }
  }))();

const FormulationTabLabel = ({ isActive, label, onRemove = () => {}, onClick = () => {} }) => {
  const classes = useTabStyles();

  const handleClickRemove = event => {
    event.stopPropagation();
    onRemove();
  };

  return (
    <Grid
      container
      className={classNames(classes.root, onRemove && classes.hasRemoveBtn)}
      alignItems="center"
      wrap="nowrap"
      onClick={onClick}
    >
      <Typography variant={isActive ? 'body2' : 'body1'}>{label}</Typography>
      {onRemove && (
        <Box className={classes.removeButton} onClick={handleClickRemove} size="small">
          <CloseOutlined />
        </Box>
      )}
    </Grid>
  );
};

FormulationTabLabel.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  onClick: PropTypes.func.isRequired
};

export default FormulationTabLabel;
