import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../../../settings/themes/colors';

const makeInspectionStyles = makeStyles({
  inspectionContainer: {
    color: palette.cerulean,
    fontSize: '0.73rem',
    paddingLeft: '20px',
    paddingRight: '10px',
    paddingBottom: '10px',
    '&>hr': {
      display: 'block',
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
      borderStyle: 'inset',
      borderWidth: '1px',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.87)'
    },
    '& p': {
      fontSize: '0.73rem',
      margin: '0.5em 0'
    }
  },
  preloadingContainer: {
    width: '100%',
    '& div': {
      width: '100%'
    }
  }
});

export const useInspectionStyle = () => {
  const { preloadingContainer, inspectionContainer } = makeInspectionStyles();

  return {
    inspectionClasses: { root: inspectionContainer },
    preloadingContainerClasses: { root: preloadingContainer }
  };
};
