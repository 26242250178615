import * as React from 'react';
import { styled } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

export const RequiredIcon = styled(props => <span {...props} />)({
  color: palette.candy
});

RequiredIcon.defaultProps = {
  children: '*'
};
