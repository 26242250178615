import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useGetProjects } from '../../queries/queryProjects';
import { getCurrentUser } from '../../utils/auth';

/**
 * Receives MuiAutocomplete props. See more: https://v4.mui.com/components/autocomplete/
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ProjectAutocompleteSelectorComponent = ({ currentUser, onChange, clientId, label, selectedProjects, ...otherProps }) => {
  const { data, isLoading, isFetching } = useGetProjects({
    clientId: currentUser.client_id,
    params: { only_main_attributes: true }
  });
  const { projects } = data || {};

  if (isLoading) {
    return <TextField label={label} value={'Loading...'} disabled />;
  }

  return (
    <Autocomplete
      options={projects || []}
      getOptionLabel={option => (typeof option === 'string' ? '' : option.name)}
      getOptionSelected={(option, value) => value === '' || option.id === value.id}
      onChange={(_, value) => onChange && onChange(value)}
      renderInput={params => <TextField label={label} {...params} />}
      value={selectedProjects}
      disabled={otherProps.disabled || isLoading || isFetching}
      multiple
      filterSelectedOptions
      {...otherProps}
    />
  );
};

ProjectAutocompleteSelectorComponent.propTypes = {
  clientId: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  currentUser: PropTypes.object,
  selectedProjects: PropTypes.array
};

const mapToProps = state => ({ currentUser: getCurrentUser(state) });

export const ProjectMultiSelector = connect(mapToProps)(ProjectAutocompleteSelectorComponent);
