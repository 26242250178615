import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { cloneDeep } from 'lodash';

import { getClassNames } from './utils';
import styles from './styles';

function Button(props) {
  const spreadProps = cloneDeep(props);

  // Remove class items from props to be spread.
  delete spreadProps.className;
  delete spreadProps.classes;

  return (
    <MuiButton {...spreadProps} className={getClassNames(props)}>
      {props.children}
    </MuiButton>
  );
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  customClass: PropTypes.object
};

export default withStyles(styles)(Button);
