import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { LinkButton } from '../atoms/LinkButton';
import { Modal } from '../molecules/Modal';
import { useAssignMemberToGroup, useGetGroupMembers, useUnassignMemberToGroup } from '../../queries/queryGroups';
import { ContactMemberForm } from './ContactMemberForm';
import { GROUPS_MEMBERS_HEAD } from '../../containers/Beneficiaries/constants';
import { GroupsTable } from './GroupsTable';
import { EmptySection } from '../molecules/EmptySection';

export const AssignMemberToGroupContent = ({ group }) => {
  const [openAssignProjectModal, setOpenAssignProjectModal] = useState(false);
  const { data = [], refetch: refetchGroupMembers } = useGetGroupMembers({
    groupId: group.id
  });
  const onSuccess = () => {
    refetchGroupMembers();
  };
  const handleClose = () => {
    setOpenAssignProjectModal(false);
  };
  const onAssignSuccess = () => {
    refetchGroupMembers();
    handleClose();
  };
  const { mutate: unassignMemberToGroup } = useUnassignMemberToGroup({ onSuccess });
  const { mutate: assignMemberToGroup } = useAssignMemberToGroup({ onSuccess: onAssignSuccess });
  const { messages: intlMessages } = useIntl();

  const handleAssignToProject = () => {
    setOpenAssignProjectModal(!openAssignProjectModal);
  };

  const onSubmit = values => {
    assignMemberToGroup({
      groupId: group.id,
      params: values
    });
  };

  const groupMembers = data.map(
    ({
      id: memberId,
      contact_group: { name: groupName },
      contact: { name, id, identification },
      contact_group_type_role: { name: role }
    }) => {
      const handleUnassignMember = () => {
        unassignMemberToGroup({
          groupId: group.id,
          memberId
        });
      };

      return {
        name,
        link: `/beneficiaries/${id}`,
        identification,
        role,
        delete: { contactName: name, groupName, action: handleUnassignMember }
      };
    }
  );

  const modalUI = (
    <Modal
      open={openAssignProjectModal}
      onClose={handleClose}
      title={<FormattedMessage id="group.linkBeneficiary" />}
      subtitle={
        <Typography>
          <FormattedMessage id="beneficiaries.form.mandatoryMessage" />
          <Typography component="span">
            <i>*</i> .
          </Typography>
        </Typography>
      }
    >
      <Grid container>
        <ContactMemberForm onSubmit={onSubmit} contactGroupTypeId={group.contactGroupTypeId} handleClose={handleClose} />
      </Grid>
    </Modal>
  );

  return (
    <CardContent>
      <CardSectionTitle
        title={intlMessages['group.memberNumber']}
        actions={<LinkButton onClick={handleAssignToProject}>{intlMessages['group.linkBeneficiary']}</LinkButton>}
      />
      <Typography variant="subtitle2">
        {`${intlMessages['group.associatedMembersNumber']} `}
        <Typography component="span" variant="subtitle1">
          {groupMembers.length}
        </Typography>
      </Typography>
      {groupMembers.length > 0 && <GroupsTable data={groupMembers} dataColumns={GROUPS_MEMBERS_HEAD} variant="small" />}
      {groupMembers.length === 0 && <EmptySection>{intlMessages['group.empty.beneficiaries']}</EmptySection>}
      {openAssignProjectModal && modalUI}
    </CardContent>
  );
};

AssignMemberToGroupContent.propTypes = {
  group: PropTypes.object.isRequired
};
