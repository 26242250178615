const styles = theme => ({
  root: {
    '&__header': {
      background: 'white',
      border: 'none',
      boxShadow: `inset 0 -7px 0 0 ${theme.palette.primary.light}`,

      '&__toolbar': {
        minHeight: 55
      }
    },
    '&__header2': {
      background: 'white',
      border: 'none',
      boxShadow: `inset 0 -7px 0 0 ${theme.palette.primary.light}`,
      position: 'fixed',

      '&__toolbar': {
        minHeight: 55
      }
    },

    '&__logo': {
      '&--client': {
        maxHeight: 80,
        margin: 32
      },
      '&--cap': {
        maxHeight: 55
      }
    },
    '&__logo2': {
      '&--client2': {
        maxHeight: 65,
        margin: 32
      },
      '&--cap2': {
        maxHeight: 40,
        paddingRight: 20
      }
    },

    '&__input': {
      display: 'flex',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
      width: 300,

      '&__label': {
        color: theme.palette.primary.main,

        '&[data-shrink="true"]': {
          transform: 'translate(-10px, -5.5px)'
        }
      }
    },

    '&__form': {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px 15px 45px',
      alignItems: 'center'
    },

    '&__submit': {
      margin: '3em auto 0',
      minWidth: 100,
      '&_link': {
        marginTop: 10
      }
    }
  }
});

export default styles;
