import React from 'react';
import { Grid, styled, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { palette } from '../../settings/themes/colors';
import { CardSectionTitle } from './CardSectionTitle';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const Item = styled(Grid)({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  color: palette.dimGray
});

const Container = styled(Grid)({
  marginBottom: lightThemeV2.spacing(2)
});

export const InformationCard = ({ title, data = [] }) => (
  <>
    <CardSectionTitle title={title} />
    <Container container spacing={2}>
      {data.map(({ title: dataTitle, value, xs, component: Component = Typography, ...componentProps }, index) => (
        <Item item xs={xs || 6} key={index}>
          <Typography>{dataTitle}</Typography>
          <Component {...componentProps}>
            <strong>{value}</strong>
          </Component>
        </Item>
      ))}
    </Container>
  </>
);

InformationCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
      xs: PropTypes.number,
      component: PropTypes.elementType
    })
  )
};
