import { get } from 'lodash';

import { getApi } from '../../../api';

export const getOrgGroupTypes = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.client_id}/org_groups/types`);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getOrgGroup = async (data = {}) => {
  const params = {
    org_group_type_id: data.typeId
  };

  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.client_id}/org_groups`, {
      params
    });
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getPaginatedProjects = async (data = {}) => {
  const params = {
    page: data.page || 1,
    per_page: data.pageSize || 25
  };

  if (!!data.leafGroups && !!data.leafGroups.length) {
    params['org_groups_ids'] = data.leafGroups;
  }

  if (!!data.searchTerm) {
    params['query'] = data.searchTerm;
  }

  if (!!data.projectType) {
    params['project_type'] = data.projectType;
  }

  if (!!data.administrativeDivisionsIds) {
    if (!!data.administrativeDivisionsIds[0].id) {
      params['administrative_divisions_ids'] = [data.administrativeDivisionsIds[data.administrativeDivisionsIds.length - 1].id];
    } else {
      params['administrative_divisions_ids'] = data.administrativeDivisionsIds;
    }
  }

  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/projects`, {
      params
    });
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
