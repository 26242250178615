import React from 'react';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useGetCountries } from '../../queries/queryCountries';
import { SelectField } from './SelectField';

/**
 * Recibe the MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CountrySelector = props => {
  const { clientId } = props;

  const fetchCountries = () => (clientId ? useGetCountries({ clientId }) : {});

  const { data: countries = [], isLoading, isFetching } = fetchCountries();

  return (
    <SelectField {...props}>
      {!isFetching &&
        !isLoading &&
        countries.map(({ id, name }, index) => (
          <MenuItem key={index} value={id}>
            {name}
          </MenuItem>
        ))}
    </SelectField>
  );
};

CountrySelector.propTypes = {
  clientId: PropTypes.number
};
