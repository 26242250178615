import { InputBase, withStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

export const ReadOnlyInput = withStyles({
  root: {
    backgroundColor: palette.artic,
    color: palette.dimGray,
    padding: '0px 12px',
    borderRadius: '4px',
    width: '100%',
    '& input[class^="MuiInputBase-input"]': {
      padding: '10.5px 0px'
    }
  }
})(InputBase);

ReadOnlyInput.defaultProps = {
  defaultValue: '%',
  readOnly: true
};
