import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MainRouter from './MainRouter';
import { checkAuthState } from '../../utils/auth';

export class Main extends Component {
  static propTypes = {
    router: PropTypes.array,
    match: PropTypes.object
  };

  render() {
    const { router } = this.props;
    const { url } = this.props.match;
    return <MainRouter router={router} url={url} />;
  }
}

export default connect(state => ({
  isLoggedIn: checkAuthState(state)
}))(Main);
