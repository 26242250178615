const styles = theme => ({
  root: {
    '&$disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
      color: theme.palette.primary.main,
      boxShadow: 'none !important'
    }
  },
  // Default style
  default: {
    color: 'white',

    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  disabled: {}
});

export default styles;
