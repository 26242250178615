import React from 'react';
import { Chip, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { alpha, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import { FormattedMessage, useIntl } from 'react-intl';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { MultiAvatar } from '../molecules/MultiAvatar';
import { FormulationActivityField } from '../molecules/FormulationActivityField';
import { SIMPLE_DATE_FORMAT, parseStringDateToFormat } from '../../utils/utils';

const useFormulationActivityItemStyle = () =>
  makeStyles({
    root: {
      color: palette.dimGray,
      '&>*': {
        display: 'flex',
        flexDirection: 'column',
        gap: lightThemeV2.spacing(1),
        '&:first-child': {
          '&>*:first-child': {
            color: palette.captudataBlue
          },
          flexGrow: 1
        }
      }
    },
    responsibleContainer: {
      gap: lightThemeV2.spacing(1),
      display: 'flex'
    },
    actions: {
      gap: lightThemeV2.spacing(2),
      '& button': {
        padding: 0,
        width: 35,
        height: 35,
        borderRadius: 0,
        color: palette.cerulean
        // Uncomment when enable the indicator feature
        /* '&:first-child': {
          color: palette.white,
          backgroundColor: palette.cerulean
        } */
      }
    },
    chip: {
      borderRadius: 8,
      border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
      color: `${palette.white} !important`,
      backgroundColor: palette.captuDataYellow,
      fontWeight: 'bold'
    }
  })();

export const FormulationActivityItem = ({
  title,
  description,
  budget,
  plannedToStartAt,
  plannedToCompleteAt,
  responsible,
  onUpdate = () => {},
  readOnly,
  currency,
  parent
}) => {
  const classes = useFormulationActivityItemStyle();
  const { messages: intlMessages } = useIntl();
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2
  });

  const fields = [
    {
      title: intlMessages['formulation.plannedStartDate'],
      value: parseStringDateToFormat(plannedToStartAt, '', SIMPLE_DATE_FORMAT)
    },
    {
      title: intlMessages['formulation.plannedDeliveryDate'],
      value: parseStringDateToFormat(plannedToCompleteAt, '', SIMPLE_DATE_FORMAT)
    },
    {
      title: intlMessages['formulation.budget'],
      value: `${formatter.format(budget)} ${currency}`
    }
  ];

  return (
    <Grid container wrap="nowrap" className={classes.root}>
      <Grid item>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant={'subtitle1'}>{title}</Typography>
          </Grid>
          {parent && (
            <Tooltip title={parent.description} classes={classes.tooltipClasses} arrow>
              <Grid item>
                <Chip label={parent.name} className={classes.chip} />
              </Grid>
            </Tooltip>
          )}
        </Grid>
        <Typography>{description}</Typography>
        <Grid container spacing={4}>
          {fields.map((field, index) => (
            <Grid item xs={3} key={index}>
              <FormulationActivityField {...field} />
            </Grid>
          ))}
          <Grid item xs={3} className={classes.responsibleContainer}>
            <Typography variant={'body2'}>
              <FormattedMessage id="formulation.responsible" />
            </Typography>
            <MultiAvatar names={[responsible.fullName]} />
          </Grid>
        </Grid>
      </Grid>
      {!readOnly && (
        <Grid item className={classes.actions}>
          <IconButton onClick={() => onUpdate()}>
            <EditIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

FormulationActivityItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  budget: PropTypes.number,
  plannedToStartAt: PropTypes.string,
  plannedToCompleteAt: PropTypes.string,
  parent: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string
  }),
  responsible: PropTypes.shape({
    id: PropTypes.number,
    fullName: PropTypes.string
  }),
  onUpdate: PropTypes.func,
  readOnly: PropTypes.bool,
  currency: PropTypes.string
};
