import React, { useState } from 'react';
import { Box, Button, MenuItem, MenuList, Popover, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import { palette } from '../../settings/themes/colors';
import { ConfirmationModal } from './ConfirmationModal';

const styles = makeStyles({
  root: {
    position: 'relative',
    mt: '5px',
    borderRadius: '4px',
    background: 'white',
    boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.20);',
    '&::before': {
      backgroundColor: 'white',
      content: '""',
      display: 'block',
      position: 'absolute',
      width: 12,
      height: 12,
      top: '-6px',
      transform: 'rotate(45deg)',
      right: '10px'
    }
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0
  },
  menuItem: {
    color: palette.dimGray,
    '&:hover': {
      background: palette.artic,
      color: palette.cerulean
    }
  }
});

export const useStyles = () => styles();

export const MenuOptionsNote = ({ id, onEdit, onDelete }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const idRef = `menu-note-${id}`;

  return (
    <div>
      <Button aria-describedby={idRef} aria-controls="fade-menu" aria-haspopup="true" onClick={event => setAnchorEl(event.currentTarget)}>
        <MoreHorizIcon />
      </Button>
      <Popover
        id={idRef}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            borderRadius: '4',
            overflow: 'visible'
          }
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box className={classes.root}>
          <MenuList className={classes.menuList}>
            <MenuItem className={classes.menuItem} onClick={onEdit}>
              {intlMessages['beneficiares.editNote']}
            </MenuItem>
            <MenuItem className={classes.menuItem} onClick={() => setConfirmDeleteModal(true)}>
              {intlMessages['beneficiaries.deleteNote']}
            </MenuItem>
          </MenuList>
          {openConfirmDeleteModal && (
            <ConfirmationModal
              onConfirm={onDelete}
              onCancel={() => setConfirmDeleteModal(false)}
              open={openConfirmDeleteModal}
              hideCloseAndFooter
              tittle={<FormattedMessage id="beneficiaries.messageConfirmDelete" />}
            >
              <Typography>
                <FormattedMessage id="beneficiaries.messageDeleteSubtitle" />
              </Typography>
            </ConfirmationModal>
          )}
        </Box>
      </Popover>
    </div>
  );
};

MenuOptionsNote.propTypes = {
  id: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};
