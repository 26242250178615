import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../../../settings/themes/colors';

export const useInspectionWithContractStyles = () => {
  const { textUpdateAt, textStatus } = makeStyles({
    textUpdateAt: {
      color: palette.dimGray,
      marginBottom: '0.5em',
      '& p': {
        color: palette.dimGray,
        marginTop: 0
      }
    },
    textStatus: {
      color: palette.statusProject,
      fontWeight: 'bold',
      textAlign: 'right',
      textTransform: 'uppercase'
    }
  })();

  return {
    textUpdateAtClasses: {
      root: textUpdateAt
    },
    textStatusClasses: {
      body1: textStatus
    }
  };
};
