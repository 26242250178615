import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormik } from 'formik';
import { lazy, object, string } from 'yup';
import { Button, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { MappingFormFields } from './MappingFormFields';
import { UnitSelector } from '../molecules/UnitSelector';
import { NativeDatePicker } from '../molecules/NativeDatePicker';
import CurrencyInput from '../../containers/Projects/components/currencyField';
import { AssociatedSelector } from '../molecules/AssociatedSelector';
import { FORMULATION_LEVEL_TYPES } from '../../containers/Projects/Formulation';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { parseStringDateToFormat } from '../../utils/utils';
import { utils } from '../../containers/Projects/components/project/utils';

export const GOAL_TYPES = {
  UNIT: 'unit',
  OBJECTIVE: 'objective'
};

const CONDITIONAL_FIELDS_NAMES = {
  TASK_ID: 'taskId',
  PARENT_ID: 'parentId',
  GOAL_TYPE: 'quantifiable',
  GOAL_UNIT: 'goalUnit',
  GOAL: 'goal',
  GOAL_DESCRIPTION: 'goalDescription'
};

export const FormulationActivityForm = ({ taskLevel, initialValues, onSubmit, onCancel = () => {} }) => {
  const { messages: intlMessages } = useIntl();
  const isFirstLevel = taskLevel && taskLevel.levelType === FORMULATION_LEVEL_TYPES[1];

  const validationSchema = lazy(values => {
    const baseScheme = {
      description: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      plannedToStartAt: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      plannedToCompleteAt: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      budget: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      [CONDITIONAL_FIELDS_NAMES.GOAL_TYPE]: string().required(<FormattedMessage id="beneficiaries.form.required" />)
    };

    if (!isFirstLevel) {
      baseScheme[CONDITIONAL_FIELDS_NAMES.PARENT_ID] = string().required(<FormattedMessage id="beneficiaries.form.required" />);
    }

    if (values[CONDITIONAL_FIELDS_NAMES.GOAL_TYPE] === GOAL_TYPES.UNIT) {
      return object().shape({
        ...baseScheme,
        [CONDITIONAL_FIELDS_NAMES.GOAL]: string().required(<FormattedMessage id="beneficiaries.form.required" />),
        [CONDITIONAL_FIELDS_NAMES.GOAL_UNIT]: string().required(<FormattedMessage id="beneficiaries.form.required" />)
      });
    }

    return object().shape({
      ...baseScheme,
      [CONDITIONAL_FIELDS_NAMES.GOAL_DESCRIPTION]: string().required(<FormattedMessage id="beneficiaries.form.required" />)
    });
  });

  const { getFieldProps, setValues, values, errors, isValid, validateForm } = useFormik({
    initialValues: initialValues || {
      [CONDITIONAL_FIELDS_NAMES.GOAL_TYPE]: GOAL_TYPES.UNIT,
      description: undefined,
      [CONDITIONAL_FIELDS_NAMES.PARENT_ID]: '',
      plannedToStartAt: undefined,
      plannedToCompleteAt: undefined,
      budget: undefined,
      [CONDITIONAL_FIELDS_NAMES.GOAL_DESCRIPTION]: undefined,
      [CONDITIONAL_FIELDS_NAMES.GOAL]: undefined,
      [CONDITIONAL_FIELDS_NAMES.GOAL_UNIT]: ''
    },
    validationSchema,
    isInitialValid: false
  });

  const conditionalFilterFields = field => {
    if (values[CONDITIONAL_FIELDS_NAMES.GOAL_TYPE] === GOAL_TYPES.UNIT && field.name === CONDITIONAL_FIELDS_NAMES.GOAL_DESCRIPTION) {
      return false;
    }
    if (
      values[CONDITIONAL_FIELDS_NAMES.GOAL_TYPE] === GOAL_TYPES.OBJECTIVE &&
      (field.name === CONDITIONAL_FIELDS_NAMES.GOAL_UNIT || field.name === CONDITIONAL_FIELDS_NAMES.GOAL || !field.name)
    ) {
      return false;
    }

    if (isFirstLevel && field.name === CONDITIONAL_FIELDS_NAMES.PARENT_ID) {
      return false;
    }

    return true;
  };

  const formFields = [
    {
      label: <WrapRequiredLabel label={taskLevel.name} />,
      name: 'description',
      xs: 12,
      multiline: true,
      minRows: 4
    },
    {
      label: (
        <WrapRequiredLabel label={intlMessages['project.formulation.form.associateTo'].replace('{1}', (taskLevel.parent || {}).name)} />
      ),
      name: CONDITIONAL_FIELDS_NAMES.PARENT_ID,
      values: (taskLevel.parent || {}).tasks || [],
      prefix: (taskLevel.parent || {}).name,
      component: AssociatedSelector,
      onChange: taskId => setValues({ ...values, [CONDITIONAL_FIELDS_NAMES.PARENT_ID]: taskId }),
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={intlMessages['project.formulation.form.startDate']} />,
      name: 'plannedToStartAt',
      onChange: plannedToStartAt => setValues({ ...values, plannedToStartAt }),
      component: NativeDatePicker,
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={intlMessages['project.formulation.form.deliveryDate']} />,
      name: 'plannedToCompleteAt',
      onChange: plannedToCompleteAt => setValues({ ...values, plannedToCompleteAt }),
      component: NativeDatePicker,
      xs: 12
    },
    {
      label: <WrapRequiredLabel label={intlMessages['project.formulation.form.budget']} />,
      required: false,
      name: 'budget',
      component: CurrencyInput,
      xs: 12
    },
    {
      name: CONDITIONAL_FIELDS_NAMES.GOAL_TYPE,
      onChange: e => {
        setValues({
          ...values,
          [CONDITIONAL_FIELDS_NAMES.GOAL_TYPE]: e.target.value,
          [CONDITIONAL_FIELDS_NAMES.GOAL_DESCRIPTION]: undefined,
          [CONDITIONAL_FIELDS_NAMES.GOAL]: undefined,
          [CONDITIONAL_FIELDS_NAMES.GOAL_UNIT]: undefined
        });
      },
      component: props => (
        <FormControl>
          <RadioGroup row {...props}>
            <FormControlLabel value={GOAL_TYPES.UNIT} control={<Radio />} label={intlMessages['project.formulation.form.unit']} />
            <FormControlLabel value={GOAL_TYPES.OBJECTIVE} control={<Radio />} label={intlMessages['project.formulation.form.objective']} />
          </RadioGroup>
        </FormControl>
      ),
      xs: 8
    },
    {
      label: <WrapRequiredLabel label={intlMessages['project.formulation.form.objective']} />,
      name: CONDITIONAL_FIELDS_NAMES.GOAL_DESCRIPTION,
      xs: 12
    },
    {
      children: <WrapRequiredLabel label={intlMessages['project.formulation.form.goal']} />,
      component: FormLabel,
      xs: 12
    },
    { name: CONDITIONAL_FIELDS_NAMES.GOAL, xs: 2 },
    {
      label: <WrapRequiredLabel label={intlMessages['project.formulation.form.selectUnits']} />,
      name: CONDITIONAL_FIELDS_NAMES.GOAL_UNIT,
      component: UnitSelector,
      onChange: goalUnit => setValues({ ...values, [CONDITIONAL_FIELDS_NAMES.GOAL_UNIT]: goalUnit }),
      xs: 10
    }
  ].filter(conditionalFilterFields);

  const onHandleSubmit = () => {
    if (!isValid) {
      validateForm(values).then();
      return;
    }
    const { plannedToCompleteAt, plannedToStartAt, budget, ...otherValues } = values;

    onSubmit({
      ...otherValues,
      plannedToCompleteAt: parseStringDateToFormat(plannedToCompleteAt),
      plannedToStartAt: parseStringDateToFormat(plannedToStartAt),
      budget: utils.removeDecimalsFromAmount(budget)
    });
  };

  return (
    <Grid>
      <MappingFormFields fields={formFields} getFieldProps={getFieldProps} errors={errors} />
      <Grid container spacing={2}>
        <Grid item>
          <Button variant="contained" onClick={onHandleSubmit} type="submit">
            <FormattedMessage id="common.add" /> {taskLevel.name}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="outlined" onClick={() => onCancel()}>
            <FormattedMessage id="common.cancel" />
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

FormulationActivityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  initialValues: PropTypes.any,
  taskLevel: PropTypes.shape({
    name: PropTypes.string,
    levelType: PropTypes.string,
    parent: PropTypes.shape({
      name: PropTypes.string,
      tasks: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          description: PropTypes.string,
          budget: PropTypes.number,
          plannedToStartAt: PropTypes.string,
          plannedToCompleteAt: PropTypes.string,
          responsible: PropTypes.shape({
            id: PropTypes.number,
            fullName: PropTypes.string
          })
        })
      )
    })
  })
};
