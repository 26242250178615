import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

export const LINK_VARIATIONS = {
  DEFAULT: 'default',
  UPPERCASE: 'uppercase'
};

export const Link = styled(RouterLink)(({ variant = LINK_VARIATIONS.DEFAULT }) => ({
  fontFamily: 'Nunito',
  color: palette.cerulean,
  fontSize: 14,
  padding: lightThemeV2.spacing(2),
  letterSpacing: '0.15px',
  textDecoration: 'underline',
  fontWeight: 500,
  textTransform: variant === LINK_VARIATIONS.UPPERCASE ? 'uppercase' : 'initial',
  '&:hover': {
    color: palette.cerulean
  }
}));

Link.propTypes = {
  variant: PropTypes.string
};
