import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const useClusterMarkerStyles = makeStyles({
  root: {
    position: 'relative',
    width: 30,
    height: 30,
    borderRadius: '50%',
    border: `2px solid ${palette.cerulean}`,
    backgroundColor: palette.white,
    color: palette.cerulean,
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
    fontSize: 14
  },
  innerAura: {
    top: -4,
    borderRadius: '50%',
    border: `1px solid ${palette.cerulean}`,
    position: 'absolute',
    width: 34,
    height: 34
  },
  outerAura: {
    top: -7,
    borderRadius: '50%',
    border: `1px solid ${palette.cerulean}`,
    position: 'absolute',
    width: 40,
    height: 40
  }
});

export const ClusterMarker = ({ pointCount, onClick = () => {} }) => {
  const classes = useClusterMarkerStyles();

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.outerAura} />
      <div className={classes.innerAura} />
      {pointCount}
    </div>
  );
};

ClusterMarker.propTypes = {
  pointCount: PropTypes.number.isRequired,
  onClick: PropTypes.func
};
