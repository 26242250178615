import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, MenuItem } from '@material-ui/core';
import { SelectField } from './SelectField';
import { useAgeSelectorClasses } from './AgeSelector.style';
import { CustomAgeRangeSelector } from './CustomAgeRangeSelector';
import { MAX_AGE, MIN_AGE } from '../../containers/Beneficiaries/constants';

const AGE_RANGES = [
  { min: MIN_AGE, max: 1 },
  { min: 2, max: 12 },
  { min: 13, max: 17 },
  { min: 18, max: 35 },
  { min: 36, max: 64 },
  { min: 65, max: MAX_AGE }
];

const generateRange = (min, max) => ({
  label: max === MAX_AGE ? `+${min}` : `${min} - ${max}`,
  value: { min: min.toString(), max: max.toString() }
});

const AGE_TYPES = AGE_RANGES.map(range => generateRange(range.min, range.max)).concat({
  label: 'Rango personalizado',
  value: { custom: true, min: MIN_AGE.toString(), max: MAX_AGE.toString() },
  isCustomRange: true
});

/**
 *  MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

const DefaultMenuItems = ({ value, currentAge, label }) => (
  <>
    <Checkbox checked={currentAge.indexOf(value) > -1} />
    {label} <FormattedMessage id="common.years" defaultMessage="years" />
  </>
);

DefaultMenuItems.propTypes = {
  value: PropTypes.string,
  currentAge: PropTypes.string,
  label: PropTypes.string
};

const CustomMenuItem = ({ onCustomSelected, label, currentAge }) => {
  const isCurrentSelected = currentAge.some(value => value.custom);

  onCustomSelected(isCurrentSelected);

  return (
    <>
      <Checkbox checked={isCurrentSelected} />
      {label}
    </>
  );
};

CustomMenuItem.propTypes = {
  currentAge: PropTypes.array,
  label: PropTypes.string,
  onCustomSelected: PropTypes.func
};

export const AgeSelector = props => {
  const { filters } = props;
  const { values } = filters || {};
  const [currentAge, setCurrentAge] = useState(values.age);
  const [customSelected, setCustomSelected] = useState(false);
  const auxTypes = [...AGE_TYPES];
  const currentCustomType = auxTypes.pop();
  const customValue = values.age.find(value => value.custom) || currentCustomType.value;
  const updatedAgeTypes = [...auxTypes, { ...currentCustomType, value: customValue }];
  const [ageTypes, setAgeTypes] = useState(updatedAgeTypes);
  const { messages: intlMessages } = useIntl();
  const classes = useAgeSelectorClasses();
  const renderValues = useMemo(() => {
    const selectedAgeTypes = ageTypes.filter(({ value }) => currentAge.includes(value));

    if (selectedAgeTypes.length === 0) {
      return '';
    }

    const mainLabel = `${selectedAgeTypes[0].label} ${intlMessages['common.years']}`;
    const additionalCount = selectedAgeTypes.length - 1;
    const additionalLabel = additionalCount > 0 ? `+ ${additionalCount}` : '';

    return `${mainLabel} ${additionalLabel}`;
  }, [currentAge]);

  const onCustomSelected = isSelected => {
    setCustomSelected(isSelected);
  };
  const onChange = event => {
    setCurrentAge(event.target.value);
  };

  const onClose = () => {
    const filteredCurrentAge = currentAge.filter(value => !value.custom);
    let updatedAge = filteredCurrentAge;

    if (customSelected) {
      const customAgeRange = ageTypes.find(value => value.isCustomRange);

      updatedAge = [...filteredCurrentAge, customAgeRange.value];
    }

    filters.setValues({ ...values, age: updatedAge });
  };

  const renderMenuItemContent = (isCustomRange, label, value) => {
    if (isCustomRange) {
      return <CustomMenuItem label={label} currentAge={currentAge} onCustomSelected={onCustomSelected} />;
    }

    return <DefaultMenuItems label={label} value={value} currentAge={currentAge} />;
  };

  return (
    <SelectField
      {...props}
      multiple
      renderValue={() => renderValues}
      onChange={onChange}
      onClose={onClose}
      value={currentAge}
      MenuProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        getContentAnchorEl: null
      }}
    >
      {ageTypes.map(({ label, value, isCustomRange }, index) => (
        <MenuItem key={index} value={value} className={classes.menuItem}>
          {renderMenuItemContent(isCustomRange, label, value)}
        </MenuItem>
      ))}
      {customSelected && <CustomAgeRangeSelector ageTypes={ageTypes} setAgeTypes={setAgeTypes} />}
    </SelectField>
  );
};

AgeSelector.propTypes = {
  filters: PropTypes.shape({
    values: PropTypes.object,
    setValues: PropTypes.func
  })
};
