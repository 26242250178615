import { useMutation, useQuery } from '@tanstack/react-query';
import { isArray, isBoolean, isNumber, isString } from 'lodash';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

const mapBeneficiaryData = (beneficiary = {}) => {
  const { legalRepresentative, ...transformedData } = transformSnake2Camel(beneficiary);

  return {
    ...transformedData,
    legalRepresentative: legalRepresentative && transformSnake2Camel(legalRepresentative)
  };
};
const filterValidAges = ({ min, max }) => min && max;

const mapAgeToString = ({ min, max }) => `${min}..${max}`;

export const useGetBeneficiaryById = ({ clientId, beneficiaryId }) => {
  const queryKey = [{ clientId, beneficiaryId }, 'GET_beneficiaryById'];

  const queryFn = async context => {
    const [{ clientId, beneficiaryId }] = context.queryKey;
    const resultBeneficiary = await getApi().get(`/clients/${clientId}/beneficiaries/${beneficiaryId}`);

    return mapBeneficiaryData(resultBeneficiary.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetBeneficiaries = ({ clientId, params = {} }) => {
  const queryKey = [
    {
      clientId,
      params
    },
    'GET_beneficiaries'
  ];

  const queryFn = async context => {
    const [{ clientId, params }] = context.queryKey;
    const { age } = params;

    const mappedAge = age ? age.filter(filterValidAges).map(mapAgeToString) : undefined;

    const contactParams = {
      ...params,
      ...{ age: mappedAge }
    };

    const definedParams = {};

    Object.keys(contactParams)
      .filter(k => {
        const value = contactParams[k];
        const isDefinedNumber = isNumber(value);
        const isDefinedBoolean = isBoolean(value);
        const isDefinedArray = isArray(value);
        const isFilledString = isString(value) && value !== '';

        return isDefinedNumber || isDefinedBoolean || isFilledString || isDefinedArray;
      })
      .forEach(k => {
        definedParams[k] = contactParams[k];
      });

    const result = await getApi().get(`/clients/${clientId}/beneficiaries`, { params: definedParams });

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateBeneficiary = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ clientId, data }) =>
      getApi()
        .post(`clients/${clientId}/beneficiaries`, { contact: transformCamel2Snake(data) })
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useUpdateBeneficiary = ({ onSuccess = () => {} }) => {
  const updateBeneficiaryMutation = useMutation(
    ({ clientId, beneficiaryId, data }) =>
      getApi().put(`/clients/${clientId}/beneficiaries/${beneficiaryId}`, {
        contact: transformCamel2Snake(data)
      }),
    { onSuccess }
  );

  const { data, ...mutation } = updateBeneficiaryMutation;

  return { data: data ? data.data : undefined, ...mutation };
};

export const useUploadBeneficiaryDocument = () =>
  useMutation(({ clientId, beneficiaryId, file }) => {
    const formData = new FormData();
    const document = { file, name: file.name };

    Object.entries(document).forEach(([key, value]) => {
      formData.append(`document[${key}]`, value);
    });

    return getApi()
      .post(`clients/${clientId}/beneficiaries/${beneficiaryId}/attach_document`, formData, {
        headers: { 'Access-Control-Expose-Headers': 'Content-Disposition', 'Content-Type': 'multipart/form-data' }
      })
      .then(axiosResult => axiosResult.data);
  });

export const useDeleteBeneficiaryDocument = () =>
  useMutation(({ clientId, beneficiaryId, documentId }) =>
    getApi()
      .delete(`/clients/${clientId}/beneficiaries/${beneficiaryId}/documents/${documentId}/delete_document`)
      .then(axiosResult => axiosResult.data)
  );

export const useCreateBeneficiaryNote = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ clientId, beneficiaryId, creatorId, note }) =>
      getApi()
        .post(`/clients/${clientId}/beneficiaries/${beneficiaryId}/notes`, { note: { creator_id: creatorId, text: note } })
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useDeleteBeneficiaryNote = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ clientId, beneficiaryId, id }) =>
      getApi()
        .delete(`/clients/${clientId}/beneficiaries/${beneficiaryId}/notes/${id}`)
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );
export const useGetBeneficiaryNote = ({ clientId, beneficiaryId }) => {
  const queryKey = [{ clientId, beneficiaryId }, 'GET_beneficiaryNote'];
  const queryFn = async context => {
    const [{ clientId: contextClientId, beneficiaryId: contextBeneficiaryId }] = context.queryKey;
    const result = await getApi().get(`/clients/${contextClientId}/beneficiaries/${contextBeneficiaryId}/notes`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useUpdateBeneficiaryNote = ({ onSuccess = () => {} }) => {
  const updateBeneficiaryMutation = useMutation(
    ({ clientId, beneficiaryId, creatorId, note, id }) =>
      getApi().put(`/clients/${clientId}/beneficiaries/${beneficiaryId}/notes/${id}`, {
        note: { creator_id: creatorId, text: note }
      }),
    { onSuccess }
  );

  const { data, ...mutation } = updateBeneficiaryMutation;

  return { data: data ? data.data : undefined, ...mutation };
};

export const useGetBeneficiaryRecordList = ({ clientId, beneficiaryId }) => {
  const queryKey = [{ clientId, beneficiaryId }, 'GET_beneficiaryRecordList'];
  const queryFn = async context => {
    const [{ clientId: contextClientId, beneficiaryId: contextBeneficiaryId }] = context.queryKey;
    const result = await getApi().get(`/clients/${contextClientId}/beneficiaries/${contextBeneficiaryId}/history`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useDownloadBeneficiaryDocument = () =>
  useMutation(({ clientId, beneficiaryId, attachedDocument }) =>
    getApi()
      .get(`/clients/${clientId}/beneficiaries/${beneficiaryId}/documents/${attachedDocument.id}/download_document`, {
        responseType: 'blob'
      })
      .then(response => {
        if (!response.status === 200) {
          throw new Error('Network response was not ok');
        }
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', attachedDocument.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  );

export const useAssignProjectToBeneficiary = options =>
  useMutation(
    ({ clientId, beneficiaryId, projectIds }) =>
      getApi()
        .post(`/clients/${clientId}/beneficiaries/${beneficiaryId}/assign_to_projects`, { project_ids: projectIds })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useUnassignProjectToBeneficiary = options =>
  useMutation(
    ({ clientId, beneficiaryId, projectIds }) =>
      getApi()
        .post(`/clients/${clientId}/beneficiaries/${beneficiaryId}/unassign_to_projects`, { project_ids: projectIds })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useGetBeneficiaryProjects = ({ beneficiaryId, params }) => {
  const queryKey = [{ beneficiaryId, params }, 'GET_beneficiaryProjects'];
  const queryFn = async context => {
    const [{ beneficiaryId: contextBeneficiaryId, params: contextParams }] = context.queryKey;
    const result = await getApi().get(`/beneficiaries/${contextBeneficiaryId}/projects`, contextParams);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetContactGroupMembers = ({ contactId, params }) => {
  const queryFn = async context => {
    const [contactId, params] = context.queryKey;

    const result = await getApi().get(`beneficiaries/${contactId}/contact_group_members`, params);

    return result.data;
  };

  return useQuery({
    queryKey: [contactId, params, 'GET_ContactGroupMembers'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
