import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_SHARED_INFO_BY_TOKEN,
  GET_SHARED_INFO_BY_TOKEN_SUCCESS,
  GET_SHARED_INFO_BY_TOKEN_FAIL,
  GET_SHARED_PROJECTS,
  GET_SHARED_PROJECTS_SUCCESS,
  GET_SHARED_PROJECTS_FAIL,
  CLEAN_SHARED_INFO_BY_TOKEN,
  SET_TOKEN,
  RESET,
  GET_PROJECT_SHARED_PROJECTS_SUCCESS,
  GET_PROJECT_SHARED_PROJECTS_FAIL,
  GET_PROJECT_SHARED_PROJECTS,
  CREATE_SHARED_PROJECT_SUCCESS,
  CREATE_SHARED_PROJECT,
  CREATE_SHARED_PROJECT_FAIL,
  CREATE_PROJECT_DOCUMENT,
  CREATE_PROJECT_DOCUMENT_SUCCESS,
  CREATE_PROJECT_DOCUMENT_FAIL,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_FAIL,
  EDIT_PROJECT_DOCUMENT,
  EDIT_PROJECT_DOCUMENT_SUCCESS,
  EDIT_PROJECT_DOCUMENT_FAIL,
  DOWNLOAD_PROJECT_DOCUMENT,
  DOWNLOAD_PROJECT_DOCUMENT_SUCCESS,
  DOWNLOAD_PROJECT_DOCUMENT_FAIL,
  DELETE_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT_SUCCESS,
  DELETE_PROJECT_DOCUMENT_FAIL,
  GET_PHYSICAL_PROGRESSES,
  GET_FINANCIAL_PROGRESSES,
  GET_PHYSICAL_PROGRESSES_SUCCESS,
  GET_FINANCIAL_PROGRESSES_SUCCESS,
  GET_PHYSICAL_PROGRESSES_FAIL,
  GET_FINANCIAL_PROGRESSES_FAIL,
  ADD_PHYSICAL_PROGRESS,
  ADD_FINANCIAL_PROGRESS,
  ADD_PHYSICAL_PROGRESS_SUCCESS,
  ADD_FINANCIAL_PROGRESS_SUCCESS,
  ADD_PHYSICAL_PROGRESS_FAIL,
  ADD_FINANCIAL_PROGRESS_FAIL,
  UPDATING_BUDGET
} from '../action-types';

const initState = new Map({
  isLoading: false,
  failed: false,
  programShared: {
    projectList: []
  },
  projectShared: {
    id: null,
    comment: '',
    token: '',
    projectId: null,
    cfValues: {},
    projectName: '',
    trackpoints: [],
    observations: [],
    clientIcon: null,
    projectAlias: null
  },
  sharedProject: {},
  sharedProjects: [],
  projectSharedProjects: [],
  projectDocuments: { documents: [], meta: { current_page: 1, next_page: 2, prev_page: null, total_pages: null, total_count: null } },
  projectDocument: {},
  financialProgresses: {},
  financialProgress: {},
  physicalProgresses: {},
  physicalProgress: {},
  contract: {}
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initState, action) => {
  switch (action.type) {
    case EDIT_PROJECT_DOCUMENT:
    case CREATE_PROJECT_DOCUMENT:
    case DELETE_PROJECT_DOCUMENT:
      return state.set('isLoading', true).set('failed', false);
    case EDIT_PROJECT_DOCUMENT_SUCCESS:
    case CREATE_PROJECT_DOCUMENT_SUCCESS:
    case DELETE_PROJECT_DOCUMENT_SUCCESS:
      return state
        .set('isLoading', false)
        .set('projectDocument', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case EDIT_PROJECT_DOCUMENT_FAIL:
    case CREATE_PROJECT_DOCUMENT_FAIL:
    case DELETE_PROJECT_DOCUMENT_FAIL:
      return state
        .set('isLoading', false)
        .set('projectDocument', {})
        .set('failed', true);
    case CREATE_SHARED_PROJECT:
      return state
        .set('isLoading', true)
        .set('sharedProject', {})
        .set('failed', false);
    case DOWNLOAD_PROJECT_DOCUMENT:
      return state.set('isLoading', true).set('failed', false);
    case DOWNLOAD_PROJECT_DOCUMENT_SUCCESS:
      return state.set('isLoading', false).set('failed', false);
    case DOWNLOAD_PROJECT_DOCUMENT_FAIL:
      return state.set('isLoading', false).set('failed', true);
    case CREATE_SHARED_PROJECT_SUCCESS:
      return state
        .set('isLoading', false)
        .set('sharedProject', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case CREATE_SHARED_PROJECT_FAIL:
      return state
        .set('isLoading', false)
        .set('sharedProject', {})
        .set('failed', true);
    case GET_SHARED_INFO_BY_TOKEN:
    case GET_PHYSICAL_PROGRESSES:
    case ADD_PHYSICAL_PROGRESS:
    case ADD_FINANCIAL_PROGRESS:
    case GET_FINANCIAL_PROGRESSES:
      return state.set('isLoading', true).set('failed', false);
    case GET_SHARED_INFO_BY_TOKEN_SUCCESS:
      return state
        .set('isLoading', false)
        .set('projectShared', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case CLEAN_SHARED_INFO_BY_TOKEN:
      return state
        .set('isLoading', false)
        .set('projectShared', {})
        .set('failed', false);
    case GET_SHARED_INFO_BY_TOKEN_FAIL:
      return state
        .set('isLoading', false)
        .set('projectShared', {})
        .set('failed', true);
    case GET_SHARED_PROJECTS_SUCCESS:
      return state
        .set('isLoading', false)
        .set('sharedProjects', assign([], get(action, 'payload.data', [])))
        .set('failed', false);
    case GET_SHARED_PROJECTS:
      return state
        .set('isLoading', true)
        .set('sharedProjects', [])
        .set('failed', false);
    case GET_SHARED_PROJECTS_FAIL:
      return state
        .set('isLoading', false)
        .set('sharedProjects', [])
        .set('failed', true);
    case GET_PROJECT_SHARED_PROJECTS_SUCCESS:
      return state
        .set('isLoading', false)
        .set('projectSharedProjects', assign([], get(action, 'payload.data', [])))
        .set('failed', false);
    case GET_PROJECT_SHARED_PROJECTS:
      return state
        .set('isLoading', true)
        .set('projectSharedProjects', [])
        .set('failed', false);
    case GET_PROJECT_SHARED_PROJECTS_FAIL:
      return state
        .set('isLoading', false)
        .set('projectSharedProjects', [])
        .set('failed', true);
    case GET_PROJECT_DOCUMENTS_SUCCESS:
      return state
        .set('isLoading', false)
        .set('projectDocuments', assign({}, get(action, 'payload.data', [])))
        .set('failed', false);
    case GET_PHYSICAL_PROGRESSES_SUCCESS:
      return state
        .set('isLoading', false)
        .set('physicalProgresses', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case ADD_PHYSICAL_PROGRESS_SUCCESS:
      return state
        .set('isLoading', false)
        .set('physicalProgress', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case GET_FINANCIAL_PROGRESSES_SUCCESS:
      return state
        .set('isLoading', false)
        .set('financialProgresses', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case ADD_FINANCIAL_PROGRESS_SUCCESS:
      return state
        .set('isLoading', false)
        .set('financialProgress', assign({}, get(action, 'payload.data', {})))
        .set('failed', false);
    case GET_PHYSICAL_PROGRESSES_FAIL:
      return state
        .set('isLoading', false)
        .set('physicalProgresses', {})
        .set('failed', true);
    case GET_FINANCIAL_PROGRESSES_FAIL:
      return state
        .set('isLoading', false)
        .set('financialProgresses', {})
        .set('failed', true);
    case ADD_PHYSICAL_PROGRESS_FAIL:
      return state
        .set('isLoading', false)
        .set('physicalProgress', {})
        .set('failed', true);
    case ADD_FINANCIAL_PROGRESS_FAIL:
      return state
        .set('isLoading', false)
        .set('financialProgress', {})
        .set('failed', true);
    case GET_PROJECT_DOCUMENTS:
      return state.set('isLoading', true).set('failed', false);
    case GET_PROJECT_DOCUMENTS_FAIL:
      return state
        .set('isLoading', false)
        .set('projectDocuments', {})
        .set('failed', true);
    case SET_TOKEN:
      return state.set('token', get(action, 'payload.token', '')).set('failed', false);
    case RESET:
      return initState;
    case UPDATING_BUDGET:
      return state.set('updatingBudget', get(action, 'payload.isUpdating', false));
    default:
      return state;
  }
};

export default reducer;
