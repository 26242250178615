import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

// Styles
import styles from './styles';

const onZoomInClick = map => () => {
  map.setZoom(map.getZoom() + 1);
};

const onZoomOutClick = map => () => {
  map.setZoom(map.getZoom() - 1);
};

const ZoomControl = ({ map, classes }) => (
  <div className={classes.zoomControl}>
    <Fab onClick={onZoomInClick(map)} color="primary" className={`${classes.zoomControl}__item`}>
      <AddIcon />
    </Fab>
    <Fab onClick={onZoomOutClick(map)} color="primary" className={`${classes.zoomControl}__item`}>
      <RemoveIcon />
    </Fab>
  </div>
);

ZoomControl.propTypes = {
  map: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(styles)(ZoomControl);
