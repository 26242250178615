import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Box } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { MenuOptionsNote } from './MenuOptionsNote';
import { Avatar } from '../atoms/Avatar';
import { palette } from '../../settings/themes/colors';
import { ItemListBase } from '../atoms/ItemListBase';
import { SEPARATED_DATE } from '../../utils/utils';
import { AddNote } from './AddNote';
import { useDeleteBeneficiaryNote, useUpdateBeneficiaryNote } from '../../queries/queryBeneficiaries';

const styles = makeStyles(theme => ({
  root: {
    color: palette.dimGray,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    '&>*': {
      width: '100%'
    }
  },
  header: {
    display: 'flex',
    gap: theme.spacing(2),
    '& .title': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: theme.spacing(2),
      '&>*:first-child': {
        display: 'flex',
        gap: theme.spacing(2),
        alignItems: 'center',
        '& .titleContainer': {
          display: 'flex',
          gap: theme.spacing(2)
        }
      },
      '&>*:last-child': {
        textWrap: 'nowrap'
      }
    }
  },
  updateSuccess: {
    display: 'flex',
    wrap: 'no-wrap',
    gap: theme.spacing(1)
  }
}));

const useItemNoteStyles = () => styles();

export const ItemNote = ({
  note: defaultNote,
  clientId,
  userId,
  beneficiaryId,
  responsible,
  creationDate,
  id,
  updateDate: updateDateDefault,
  refetch
}) => {
  const { messages: intlMessages } = useIntl();
  const classes = useItemNoteStyles();
  const [isEditing, setIsEditing] = useState(false);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [note, setNote] = useState(defaultNote);
  const [updateDate, setUpdateDateDefault] = useState(updateDateDefault);
  const { mutate: deleteNote } = useDeleteBeneficiaryNote({
    onSuccess: () => {
      refetch();
    }
  });

  const { mutate: updateNote } = useUpdateBeneficiaryNote({
    onSuccess: ({ data }) => {
      setNote(data.text);
      setUpdateDateDefault(
        moment()
          .utc()
          .toISOString()
      );
      setIsEditing(false);

      setShowUpdateConfirmation(true);
      setTimeout(async () => {
        setShowUpdateConfirmation(false);
      }, 5000);
    }
  });

  const handleSubmit = newNote => {
    if (newNote === note) {
      setIsEditing(false);
      return;
    }
    updateNote({ clientId, beneficiaryId, creatorId: userId, note: newNote, id });
  };

  const handleDeleteNote = () => {
    deleteNote({
      clientId,
      beneficiaryId,
      id
    });
  };

  const handleEditClose = () => {
    setIsEditing(false);
  };
  const isEdited = updateDate && updateDate !== creationDate;
  const translationKey = isEdited ? 'beneficiaries.edited' : 'beneficiaries.added';

  return (
    <ItemListBase variant={'default'} className={classes.root}>
      <Box className={classes.header}>
        <Box className="title">
          <Box>
            <Avatar responsible={responsible} />
            <Box className="titleContainer">
              <Typography variant="body2" className="avatar">
                {responsible}
              </Typography>
              {showUpdateConfirmation && (
                <Box className={classes.updateSuccess}>
                  <CheckCircleRoundedIcon fontSize="small" style={{ color: 'green' }} />
                  <Typography>{intlMessages['beneficiares.editNote.confirmUpdated']}</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Typography>
            <FormattedMessage id={translationKey}>
              {formattedText => {
                const date = moment(updateDate || creationDate).format(SEPARATED_DATE);

                return `${formattedText} ${date}`;
              }}
            </FormattedMessage>
          </Typography>
        </Box>
        <MenuOptionsNote onEdit={() => setIsEditing(true)} onDelete={() => handleDeleteNote(id)} />
      </Box>
      {isEditing ? (
        <AddNote
          onSubmit={handleSubmit}
          onClose={handleEditClose}
          defaultValue={note}
          intlKeyLabel={'beneficiaries.saveNoteButton'}
          label={intlMessages['beneficiaries.addNoteLabel']}
        >
          <Typography>{note}</Typography>
        </AddNote>
      ) : (
        <Typography>{note}</Typography>
      )}
    </ItemListBase>
  );
};

ItemNote.propTypes = {
  note: PropTypes.string,
  responsible: PropTypes.string,
  creationDate: PropTypes.string,
  clientId: PropTypes.number,
  userId: PropTypes.number,
  beneficiaryId: PropTypes.number,
  id: PropTypes.number,
  updateDate: PropTypes.string,
  refetch: PropTypes.func
};
