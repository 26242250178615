import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Card } from '../atoms/Card';
import { InformationCard } from '../molecules/InformationCard';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Chip } from '../atoms/Chip';
import { Link } from '../atoms/Link';
import { withRouter } from 'react-router';

const GeneralInformationSectionComp = ({ beneficiary = {}, type = 'natural', match, location }) => {
  const classes = useBeneficiaryStyles();
  const { messages: intlMessages } = useIntl();

  const {
    id,
    birthDate,
    contactType,
    educationalLevel,
    email,
    ethnicity,
    identification,
    phoneNumber,
    sex,
    streetAddress,
    administrativeDivisionLevel1,
    administrativeDivisionLevel2,
    administrativeDivisionLevel3,
    profession,
    genderIdentity,
    sexualOrientation,
    industry,
    legalStatus,
    legalRepresentative,
    averageMonthlyHouseholdIncomeFormatted,
    averageMonthlyPersonalIncomeFormatted
  } = beneficiary || {};

  const naturalBeneficiaryInformation = [
    { title: `${intlMessages['beneficiaries.contactType']}:`, value: contactType, xs: 12 },
    { title: `${intlMessages['beneficiaries.identificationNumber']}:`, value: identification, xs: 4 },
    { title: `${intlMessages['common.birthday']}:`, value: birthDate, xs: 4 },
    { title: `${intlMessages['common.gender']}:`, value: intlMessages[`common.${sex}`], xs: 4 },
    {
      title: `${intlMessages['beneficiaries.ethnicity']}:`,
      value: intlMessages[`beneficiaries.form.${ethnicity}`],
      xs: 4
    },
    {
      title: `${intlMessages['beneficiaries.form.educationalLevel']}:`,
      value: intlMessages[`selector.educationLevel.${educationalLevel}`],
      xs: 4
    },
    {
      title: `${intlMessages['beneficiaries.form.profession']}:`,
      value: profession && profession.name,
      xs: 4
    },
    {
      title: <FormattedMessage id={'beneficiaries.form.genderIdentity'} />,
      value: genderIdentity ? <FormattedMessage id={`selector.genderIdentity.${genderIdentity}`} /> : '--',
      xs: 4
    },
    {
      title: <FormattedMessage id={'beneficiaries.form.sexualOrientation'} />,
      value: sexualOrientation ? <FormattedMessage id={`selector.sexualOrientation.${sexualOrientation}`} /> : '--',
      xs: 4
    },
    {
      title: <FormattedMessage id={'beneficiaries.form.averageMonthlyHouseholdIncome'} />,
      value: averageMonthlyHouseholdIncomeFormatted || '--',
      xs: 4
    },
    {
      title: <FormattedMessage id={'beneficiaries.form.averageMonthlyPersonalIncome'} />,
      value: averageMonthlyPersonalIncomeFormatted || '--',
      xs: 4
    }
  ];
  const legalBeneficiaryInformation = [
    {
      title: `${intlMessages['beneficiaries.contactType']}:`,
      value: intlMessages[`beneficiaries.form.${contactType}`],
      xs: 4
    },
    {
      title: `${intlMessages['beneficiaries.legal.status']}:`,
      label: intlMessages[`beneficiaries.${legalStatus}`],
      xs: 8,
      type: legalStatus,
      component: Chip
    },
    { title: `${intlMessages['beneficiaries.legal.identification']}:`, value: identification, xs: 4 },
    { title: `${intlMessages['beneficiaries.form.activity']}:`, value: industry ? industry.name : '--', xs: 4 },
    {
      title: `${intlMessages['beneficiaries.form.section.legalRepresentative']}:`,
      value: legalRepresentative && legalRepresentative.name ? legalRepresentative.name : '--',
      xs: 4
    },
    { title: `${intlMessages['common.phone']}:`, value: phoneNumber, xs: 4 },
    { title: `${intlMessages['common.email']}:`, value: email, xs: 4 }
  ];

  const naturalContactInformation = [
    { title: `${intlMessages['common.phone']}:`, value: phoneNumber, xs: 4 },
    { title: `${intlMessages['common.email']}:`, value: email, xs: 4 }
  ];
  const legalContactInformation = [
    { title: `${intlMessages['common.name']}:`, value: legalRepresentative && legalRepresentative.name, xs: 4 },
    {
      title: `${intlMessages['beneficiaries.identificationNumber']}:`,
      value: legalRepresentative && legalRepresentative.identification,
      xs: 8
    },
    { title: `${intlMessages['common.phone']}:`, value: legalRepresentative && legalRepresentative.phoneNumber, xs: 4 },
    { title: `${intlMessages['common.email']}:`, value: legalRepresentative && legalRepresentative.email, xs: 4 }
  ];

  const encodeCurrentUrl = encodeURIComponent(`${location.pathname}`);

  return (
    <Grid container direction={'column'}>
      <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
        <Typography variant={'h6'} className={classes.heading}>
          <FormattedMessage id="beneficiaries.section.generalInformation" />
        </Typography>
        <Link to={`${id}/edit?from=${encodeCurrentUrl}`}>
          <FormattedMessage id="common.edit" />
        </Link>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card variant={'stretch'}>
            <CardContent>
              <InformationCard
                title={
                  type === 'natural' ? intlMessages['beneficiaries.personalInformation'] : intlMessages['beneficiaries.legalInformation']
                }
                data={type === 'natural' ? naturalBeneficiaryInformation : legalBeneficiaryInformation}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant={'stretch'}>
            <CardContent>
              <InformationCard
                title={
                  type === 'natural'
                    ? intlMessages['beneficiaries.section.contactInformation']
                    : intlMessages['beneficiaries.section.mainContactInformation']
                }
                data={type === 'natural' ? naturalContactInformation : legalContactInformation}
              />
              <InformationCard
                title={intlMessages['beneficiaries.form.section.address']}
                data={[
                  {
                    title: `${intlMessages['beneficiaries.form.administrativeRegion']}:`,
                    value: administrativeDivisionLevel1 && administrativeDivisionLevel1.name,
                    xs: 4
                  },
                  {
                    title: `${intlMessages['common.municipality']}:`,
                    value: administrativeDivisionLevel2 && administrativeDivisionLevel2.name,
                    xs: 4
                  },
                  {
                    title: `${intlMessages['beneficiaries.form.administrativeDivisionLevel3']}:`,
                    value: administrativeDivisionLevel3 && administrativeDivisionLevel3.name,
                    xs: 4
                  },
                  { title: `${intlMessages['beneficiaries.form.street']}:`, value: streetAddress, xs: 12 }
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const GeneralInformationSection = withRouter(GeneralInformationSectionComp);
