import { get } from 'lodash';

import { getApi } from '../../api';

// function to get filtered administrative divisions

export const getFilteredAdministrativeDivisions = async (data = {}) => {
  let resp = {};
  let path = `countries/${data.countryId}/administrative_divisions?level=${data.level}`;
  path = !!data.selectedId ? `${path}&parent_id=${data.selectedId}` : path;
  try {
    resp = await getApi().get(path);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

// Function to get the Custom Fields filters

export const getCfFilters = async (data = {}) => {
  let resp = [];
  let path = `/clients/${data.clientId}/cf_definitions/project_filters`;
  path = (!!data.formularyId && `${path}?formulary_id=${data.formularyId}`) || path;
  path = !!data.orgGroupId
    ? (!!data.formularyId && `${path}&org_group_id=${data.orgGroupId}`) || `${path}?org_group_id=${data.orgGroupId}`
    : path;
  try {
    resp = await getApi().get(path);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

// function to get countries

export const getCountries = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/countries`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

// Function to get the Categories filter

export const getCategories = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/categories`);
  } catch (err) {
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};
