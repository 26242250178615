import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

/**
 * Receives MuiAutocomplete props. See more: https://v4.mui.com/components/autocomplete/
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const NumberSelector = ({ label, lastOne, ...otherProps }) => {
  const numbers = Array.from({ length: lastOne + 1 }, (_, index) => `${index}`);

  return (
    <Autocomplete
      options={numbers}
      getOptionLabel={option => option.toString()}
      renderInput={params => <TextField {...params} label={label} variant="standard" />}
      {...otherProps}
    />
  );
};

NumberSelector.propTypes = {
  label: PropTypes.string,
  lastOne: PropTypes.number
};
