import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import {
  LOGOUT,
  REQUEST_PROJECT,
  UPDATE_PROJECT,
  REQUEST_INSPECTION,
  RELOAD_INSPECTION,
  ADD_PROJECT,
  ADD_INSPECTION,
  SET_INSPECTION_PUBLISHED,
  INSPECTION_PUBLISH_UPDATED,
  SET_INSPECTION_DEFAULT,
  INSPECTION_UPDATED,
  GET_INSPECTION_KML,
  DOWNLOAD_INSPECTION_KML,
  PROJECT_UPDATED,
  REQUEST_INSPECTION_FILES,
  UPLOAD_INSPECTION_FILE,
  DELETE_INSPECTION_FILE,
  DOWNLOAD_INSPECTION_FILE,
  ADD_INSPECTION_FILES,
  DOWNLOAD_INSPECTION_PDF,
  ADD_OBSERVATION,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAIL,
  DELETE_OBSERVATION,
  DELETE_ASSET,
  ADD_ASSET,
  GET_CF_TYPES,
  ADD_CF_TYPES,
  SET_INSPECTION_STATUS,
  SYNC_WITH_THIRD_PARTY,
  SYNC_WITH_THIRD_PARTY_SUCCESS,
  GET_INSPECTIONS_BY_PROJECT,
  GET_INSPECTIONS_BY_PROJECT_SUCCESS,
  GET_INSPECTIONS_BY_PROJECT_FAIL,
  GET_INSPECTION_ASSETS,
  GET_INSPECTION_ASSETS_SUCCESS,
  GET_INSPECTION_ASSETS_FAIL
} from '../../../redux/action-types';
import {
  getProjectDetails,
  getInspection,
  setInspectionDefault,
  setInspectionPublished,
  getInspectionKML,
  setProjectUpdate,
  getInspectionFiles,
  downloadInspectionFile,
  uploadInspectionFile,
  deleteInspectionFile,
  getInspectionPdf,
  createObservation,
  getCFTypes,
  setObservationUpdate,
  deleteObservation,
  deleteObservationAsset,
  createObservationAsset,
  setInspectionStatus,
  setThirdPartySynchronize,
  getProjectInspections,
  getInspectionAssets
} from './helpers';

export function* projectRequest() {
  yield takeEvery(REQUEST_PROJECT, function*({ payload }) {
    const resp = yield call(getProjectDetails, payload);

    if (!resp.data.errors) {
      yield put({
        type: ADD_PROJECT,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* projectUpdate() {
  yield takeEvery(UPDATE_PROJECT, function*({ payload }) {
    const resp = yield call(setProjectUpdate, payload);

    if (!resp.data.errors) {
      yield put({
        type: PROJECT_UPDATED,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* inspectionRequest() {
  yield takeEvery(REQUEST_INSPECTION, function*({ payload }) {
    const resp = yield call(getInspection, payload);

    if (!resp.data.errors) {
      yield put({
        type: ADD_INSPECTION,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* inspectionDefault() {
  yield takeEvery(SET_INSPECTION_DEFAULT, function*({ payload }) {
    const resp = yield call(setInspectionDefault, payload);

    if (!resp.data.errors) {
      yield put({
        type: INSPECTION_UPDATED,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* inspectionPublished() {
  yield takeEvery(SET_INSPECTION_PUBLISHED, function*({ payload }) {
    const resp = yield call(setInspectionPublished, payload);

    if (!resp.data.errors) {
      yield put({
        type: INSPECTION_PUBLISH_UPDATED,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* downloadInspectionKML() {
  yield takeEvery(GET_INSPECTION_KML, function*({ payload }) {
    const resp = yield call(getInspectionKML, payload);

    if (!resp.data.errors) {
      yield put({
        type: DOWNLOAD_INSPECTION_KML,
        payload: resp
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* inspectionRequestFiles() {
  yield takeEvery(REQUEST_INSPECTION_FILES, function*({ payload }) {
    const resp = yield call(getInspectionFiles, payload);

    if (!resp.data.errors) {
      yield put({
        type: ADD_INSPECTION_FILES,
        payload: resp.data
      });
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* inspectionUploadFile() {
  yield takeEvery(UPLOAD_INSPECTION_FILE, function*({ payload }) {
    const resp = yield call(uploadInspectionFile, payload);

    if (!resp.data.errors) {
      yield put({
        type: REQUEST_INSPECTION_FILES,
        payload
      });
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* inspectionDeleteFile() {
  yield takeEvery(DELETE_INSPECTION_FILE, function*({ payload }) {
    const resp = yield call(deleteInspectionFile, payload);

    if (!resp.data.errors) {
      yield put({
        type: REQUEST_INSPECTION_FILES,
        payload
      });
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* inspectionDownloadFile() {
  yield takeEvery(DOWNLOAD_INSPECTION_FILE, function*({ payload }) {
    const resp = yield call(downloadInspectionFile, payload);

    if (!resp.data.errors) {
      window.open(resp.url, '_blank');
    } else if (resp.status === 401) {
      yield put({
        type: LOGOUT
      });
    }
  });
}

export function* downloadInspectionPdf() {
  yield takeEvery(DOWNLOAD_INSPECTION_PDF, function*({ payload }) {
    const resp = yield call(getInspectionPdf, payload);

    if (!resp.data.errors) {
      const file = new Blob([resp.data], { type: 'application/pdf' });
      saveAs(file, `inspection-${payload.inspectionId}.pdf`);
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* addObservation() {
  yield takeEvery(ADD_OBSERVATION, function*({ payload }) {
    const resp = yield call(createObservation, payload);

    if (!resp.data.errors) {
      yield put({
        type: RELOAD_INSPECTION
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* updateObservation() {
  yield takeEvery(UPDATE_OBSERVATION, function*({ payload }) {
    const resp = yield call(setObservationUpdate, payload);
    if (resp.status === 204) {
      yield put({
        type: UPDATE_OBSERVATION_SUCCESS,
        payload: payload
      });
    } else {
      yield put({
        type: UPDATE_OBSERVATION_FAIL
      });
    }
  });
}

export function* deleteObservationRequest() {
  yield takeEvery(DELETE_OBSERVATION, function*({ payload }) {
    const resp = yield call(deleteObservation, payload);
    if (!resp.data.errors) {
      yield put({
        type: RELOAD_INSPECTION
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* deleteAsset() {
  yield takeEvery(DELETE_ASSET, function*({ payload }) {
    const resp = yield call(deleteObservationAsset, payload);
    if (!resp.data.errors) {
      yield put({
        type: RELOAD_INSPECTION
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* addAsset() {
  yield takeEvery(ADD_ASSET, function*({ payload }) {
    const resp = yield call(createObservationAsset, payload);

    if (!resp.data.errors) {
      yield put({
        type: RELOAD_INSPECTION
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* requestCFTypes() {
  yield takeEvery(GET_CF_TYPES, function*({ payload }) {
    const resp = yield call(getCFTypes);

    if (!resp.data.errors) {
      yield put({
        type: ADD_CF_TYPES,
        payload: { types: resp.data, success: true }
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* inspectionUpdateStatus() {
  yield takeEvery(SET_INSPECTION_STATUS, function*({ payload }) {
    const resp = yield call(setInspectionStatus, payload);

    if (!resp.data.errors) {
      yield put({
        type: RELOAD_INSPECTION
      });
    } else if (resp.status === 401) {
      console.log('error');
    }
  });
}

export function* inspectionThirdPartyRequest() {
  yield takeEvery(SYNC_WITH_THIRD_PARTY, function*({ payload }) {
    const resp = yield call(setThirdPartySynchronize, payload.id);
    yield put({
      type: SYNC_WITH_THIRD_PARTY_SUCCESS
    });
  });
}

export function* getProjectInspectionsRequest() {
  yield takeEvery(GET_INSPECTIONS_BY_PROJECT, function*({ payload }) {
    const resp = yield call(getProjectInspections, { payload });

    if (resp.status === 200) {
      yield put({
        type: GET_INSPECTIONS_BY_PROJECT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_INSPECTIONS_BY_PROJECT_FAIL
      });
    }
  });
}

export function* getInspectionAssetsRequest() {
  yield takeEvery(GET_INSPECTION_ASSETS, function*({ payload }) {
    const resp = yield call(getInspectionAssets, { payload });

    if (resp.status === 200) {
      yield put({
        type: GET_INSPECTION_ASSETS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_INSPECTION_ASSETS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(inspectionRequest),
    fork(projectRequest),
    fork(projectUpdate),
    fork(inspectionDefault),
    fork(inspectionPublished),
    fork(downloadInspectionKML),
    fork(inspectionRequestFiles),
    fork(inspectionUploadFile),
    fork(inspectionDeleteFile),
    fork(inspectionDownloadFile),
    fork(downloadInspectionPdf),
    fork(addObservation),
    fork(updateObservation),
    fork(deleteObservationRequest),
    fork(deleteAsset),
    fork(addAsset),
    fork(requestCFTypes),
    fork(inspectionUpdateStatus),
    fork(inspectionThirdPartyRequest),
    fork(getProjectInspectionsRequest),
    fork(getInspectionAssetsRequest)
  ]);
}
