import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { useGetGroupsTypes, useGetOrgGroupsMetaTypes } from '../../queries/queryGroups';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';

const useStyles = makeStyles({
  containerItems: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    minWidth: '296px'
  },
  nameLabel: {
    paddingRight: lightThemeV2.spacing(3),
    color: palette.captudataBlue
  },
  filterSelector: {
    '& *[class*="MuiInput-root"]': {
      color: palette.cerulean,
      fontWeight: 'bold',

      '&:before, &:after ': {
        borderBottom: `1px solid ${palette.cerulean}`
      }
    }
  }
});

const FilterByGroupTypeComp = ({ clientId, onChange = () => {}, reset = false }) => {
  const classes = useStyles();
  const { messages: intlMessages } = useIntl();
  const { data: orgGroupTypes } = useGetOrgGroupsMetaTypes({ clientId });
  const { data: groups = [] } = useGetGroupsTypes({ clientId, orgGroupMeta: orgGroupTypes, enabled: !!orgGroupTypes });
  const [selection, setSelection] = useState([]);

  if (reset && selection.length > 0) {
    setSelection([]);
    onChange();
  }

  const changeHandler = (value, id) => {
    const selectionIndex = selection.findIndex(x => x.id === id);

    if (selectionIndex < 0) {
      setSelection([...selection, { id, value }]);
      return;
    }

    let nextValue = selection.map(item => (item.id === id ? { id, value } : item));

    nextValue = nextValue.slice(0, selectionIndex + 1);
    setSelection(nextValue);
  };

  useEffect(() => {
    if (selection.length > 0) {
      onChange(selection[selection.length - 1].value);
    }
  }, [selection]);

  const filteredGroups = groups.map((x, index) => {
    if (x.parentId) {
      return {
        ...x,
        types: x.types.filter(type => {
          if (index > selection.length) return false;

          return type.parentId === selection[index - 1].value.id;
        })
      };
    }

    return x;
  });

  return (
    <Grid container spacing={2}>
      {filteredGroups.map(({ types, id, name }, index) => (
        <Grid item key={`filter-type-${id}-item-${index}`} className={classes.containerItems}>
          <label className={classes.nameLabel}>
            <Typography variant="subtitle1">{`${name}:`}</Typography>
          </label>
          <FormControl>
            <Autocomplete
              className={classes.filterSelector}
              disabled={index > selection.length}
              onChange={(_, value) => changeHandler(value, id)}
              disableClearable
              value={selection[index] ? selection[index].value : null}
              options={types}
              noOptionsText={intlMessages['common.noOptions']}
              getOptionSelected={(option, value = {}) => option.id === value.id}
              getOptionLabel={option => option.name || ''}
              renderInput={params => <TextField variant="standard" placeholder={intlMessages['common.selectOption']} {...params} />}
            />
          </FormControl>
        </Grid>
      ))}
    </Grid>
  );
};

FilterByGroupTypeComp.propTypes = {
  clientId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  reset: PropTypes.bool
};

const mapToProps = state => ({
  clientId: getCurrentUser(state).client_id
});

export const FilterByGroupType = connect(mapToProps)(FilterByGroupTypeComp);
