import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_CUSTOM_FIELD_FILTERS,
  GET_CUSTOM_FIELD_FILTERS_SUCCESS,
  GET_CUSTOM_FIELD_FILTERS_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL
} from '../action-types';
import { getFilteredAdministrativeDivisions, getCountries, getCfFilters, getCategories } from './helper';

export function* getAdminDivisionsRequest() {
  yield takeEvery(GET_FILTERED_ADMINISTRATIVE_DIVISIONS, function*({ payload }) {
    const countryId = payload.countryId;
    const resp = yield call(getFilteredAdministrativeDivisions, { countryId, ...payload });

    if (resp.status === 200) {
      yield put({
        type: GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS,
        payload: { data: resp.data, ...payload }
      });
    } else {
      yield put({
        type: GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL
      });
    }
  });
}

export function* getCfFiltersRequest() {
  yield takeEvery(GET_CUSTOM_FIELD_FILTERS, function*({ payload }) {
    const resp = yield call(getCfFilters, { ...payload });

    if (resp.status === 200) {
      yield put({
        type: GET_CUSTOM_FIELD_FILTERS_SUCCESS,
        payload: { data: resp.data, ...payload }
      });
    } else {
      yield put({
        type: GET_CUSTOM_FIELD_FILTERS_FAIL
      });
    }
  });
}

export function* getCountriesRequest() {
  yield takeEvery(GET_COUNTRIES, function*({ payload }) {
    const resp = yield call(getCountries, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_COUNTRIES_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_COUNTRIES_FAIL
      });
    }
  });
}

export function* getCategoriesRequest() {
  yield takeEvery(GET_CATEGORIES, function*({ payload }) {
    const resp = yield call(getCategories, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_CATEGORIES_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_CATEGORIES_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getAdminDivisionsRequest), fork(getCountriesRequest), fork(getCfFiltersRequest), fork(getCategoriesRequest)]);
}
