import React, { useRef } from 'react';
import { MuiThemeProvider, Typography, LinearProgress, Grid } from '@material-ui/core';
import MuiButton from '@material-ui/core/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { getCurrentUser } from '../../utils/auth';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { RequiredIcon } from '../../components/atoms/RequiredIcon';
import { useBeneficiaryStyles } from './index.styles';
import { ContactGroupForm } from '../../components/organims/ContactGroupForm';
import { useGetGroupById, useUpdateContactGroup } from '../../queries/queryContactGroups';

export const EditContactGroupComponent = ({ clientId, history, location, match }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryStyles();
  const { groupId } = match.params;

  const { mutate: updateContactGroup } = useUpdateContactGroup({
    onSuccess: () => {
      const urlParams = new URLSearchParams(location.search);
      const fromUrl = decodeURIComponent(urlParams.get('from'));
      const hasQueryParams = new URL(`http://${fromUrl}`).search !== '';
      const redirectToUrl = fromUrl ? `${fromUrl}${hasQueryParams ? '&' : '?'}updated=true` : `/contact_groups/${groupId}`;

      history.push(redirectToUrl);
    }
  });

  const { data: contactGroup = {} } = useGetGroupById({ clientId, groupId });
  const submitRef = useRef();
  const handleSubmit = data => {
    updateContactGroup({ clientId, groupId, data });
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent
        title={intlMessages['group.editGroup']}
        subtitle={
          <>
            <FormattedMessage id={'beneficiaries.newBeneficiary.sectionDescription'} />
            <RequiredIcon />.
          </>
        }
      />
      <BodyContent>
        <Typography variant={'h6'} className={classes.heading}>
          <FormattedMessage id={'group.editGroup'} />
        </Typography>
        {contactGroup && contactGroup.id ? (
          <ContactGroupForm formRef={submitRef} onSubmit={handleSubmit} defaultValues={contactGroup} />
        ) : (
          <LinearProgress />
        )}
        <Grid container spacing={2}>
          <Grid item>
            <MuiButton variant={'contained'} onClick={() => submitRef.current()}>
              <FormattedMessage id={'beneficiaries.saveChanges'} />
            </MuiButton>
          </Grid>
          <Grid item>
            <MuiButton variant={'outlined'} onClick={handleCancel}>
              <FormattedMessage id={'common.cancel'} />
            </MuiButton>
          </Grid>
        </Grid>
      </BodyContent>
    </MuiThemeProvider>
  );
};

EditContactGroupComponent.propTypes = {
  clientId: PropTypes.number.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

const mapStateToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const EditContactGroup = withRouter(connect(mapStateToProps)(EditContactGroupComponent));
