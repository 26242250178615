import { get, assign } from 'lodash';
import { Map } from 'immutable';
import {
  REQUEST_PROGRAM,
  ADD_PROGRAM,
  SHOW_PROJECT,
  SELECT_PROJECT_ID,
  REMOVE_PROGRAM
} from '../../../redux/action-types';

const initState = new Map({ program: {} });

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const programsReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_PROGRAM:
      return state.set('program', get(action, 'payload', {}));
    case SHOW_PROJECT:
      return action.project
        ? state.set('activeProject', action.project)
        : state.set('activeProject', null);
    case SELECT_PROJECT_ID:
      return state.set(
        'idProjectSelected',
        assign({}, state.get('idProjectSelected'), {
          idProjectSelected: action.projectId
        })
      );
    case REMOVE_PROGRAM:
      return state.set('program', {});
    default:
      return state;
  }
};

// Action Creators

/**
 * Request all organization group types
 */
const requestProgram = payload => ({
  type: REQUEST_PROGRAM,
  payload
});

/**
 * Toggle hover state to as observation
 * @param {Number} project id
 * @param {Boolean} show or hide
 */
export const showProject = (project, show) => ({
  type: SHOW_PROJECT,
  project,
  show
});

/**
 * Select inspection
 *
 */
export const saveIdProjectSelected = projectId => ({
  type: SELECT_PROJECT_ID,
  projectId
});

/**
 * clean or remove selected program
 */
const removeProgram = payload => ({
  type: REMOVE_PROGRAM,
  payload
});

export const actionCreators = {
  requestProgram,
  showProject,
  saveIdProjectSelected,
  removeProgram
};

export default programsReducer;
