import { CircularProgress as MuiCircularProgress, styled } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';

export const CircularProgress = styled(MuiCircularProgress)(({ variant = 'centered', margin = 'auto' }) => {
  switch (variant) {
    case 'centered':
      return {
        margin,
        color: palette.captudataBlue,
        marginTop: lightThemeV2.spacing(4)
      };
    default:
  }
});
