import React, { useState } from 'react';
import { Button, Divider as MuiDivider, styled, TextField } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';

const InputBase = styled(TextField)({
  gap: lightThemeV2.spacing(4),
  '& textarea[class*="MuiOutlinedInput-inputAdornedEnd"]': {
    paddingRight: lightThemeV2.spacing(2),
    paddingLeft: 0,
    marginTop: lightThemeV2.spacing(1),
    marginBottom: lightThemeV2.spacing(1)
  },
  '& div[class*="MuiOutlinedInput-adornedEnd"]': {
    padding: 0,
    paddingRight: lightThemeV2.spacing(2),
    paddingLeft: lightThemeV2.spacing(2)
  }
});

const Divider = styled(MuiDivider)({
  marginTop: lightThemeV2.spacing(2),
  marginBottom: lightThemeV2.spacing(2),
  marginRight: lightThemeV2.spacing(1),
  backgroundColor: palette.dimGray
});

const ButtonSave = styled(Button)({
  borderBottomRightRadius: 4,
  borderTopRightRadius: 4,
  margin: lightThemeV2.spacing(1),
  padding: 0,
  minHeight: 46,
  color: palette.cerulean,
  '&:hover': {
    backgroundColor: 'transparent'
  }
});

export const AddNote = ({ onSubmit = () => {}, defaultValue = '', intlKeyLabel = 'beneficiaries.addNoteButton', ...props }) => {
  const [value, setValue] = useState(defaultValue);
  const submitNote = () => {
    onSubmit(value);
    setValue('');
  };

  const isEnableToSave = value && value.length > 0;

  return (
    <InputBase
      size={'medium'}
      value={value}
      onChange={e => setValue(e.target.value)}
      InputProps={{
        multiline: true,
        maxRows: 4,
        minRows: 1,
        endAdornment: (
          <>
            <Divider orientation="vertical" flexItem />
            <ButtonSave onClick={submitNote} disabled={!isEnableToSave}>
              <FormattedMessage id={intlKeyLabel} />
            </ButtonSave>
          </>
        )
      }}
      {...props}
    />
  );
};

AddNote.propTypes = {
  onSubmit: PropTypes.func,
  defaultValue: PropTypes.string,
  intlKeyLabel: PropTypes.string
};
