import { createTheme } from '@material-ui/core/styles';
import { palette } from './colors';

export const lightThemeV2 = createTheme({
  spacing: 5,
  typography: {
    fontFamily: ['Nunito'].join(','),
    h3: {
      fontSize: 26,
      fontWeight: 900,
      letterSpacing: '0.45px'
    },
    h4: {
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: '0.3px'
    },
    h5: {
      fontSize: 20,
      fontWeight: 900,
      letterSpacing: '0.45px'
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: '0.3px'
    },
    body1: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: '0.15px'
    },
    body2: {
      // BodyBold
      fontSize: 14,
      fontWeight: 700,
      letterSpacing: '0.4px'
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 700,
      letterSpacing: '0.15px'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: '0.15px'
      },
      contained: {
        backgroundColor: palette.cerulean,
        color: palette.white,
        '&:hover': {
          backgroundColor: palette.cerulean
        }
      },
      outlined: {
        borderRadius: 4,
        border: '1px solid #4A90E2',
        color: palette.cerulean
      }
    },
    MuiInputBase: {
      root: {
        '&[class*="MuiOutlinedInput"]': {
          color: palette.dimGray,
          '&:not(.Mui-disabled)>fieldset': {
            border: `1px solid ${palette.dimGray}`
          },
          '&.Mui-disabled': {
            '&>input:disabled': {
              color: palette.silver
            },
            '&>fieldset': {
              border: `1px dashed ${palette.silver}`
            }
          }
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: palette.dimGray
      }
    },
    MuiRadio: {
      root: {
        '&[class*="MuiRadio-colorPrimary"]': {
          color: palette.dimGray,
          '&.Mui-checked': {
            color: palette.cerulean
          }
        }
      }
    },
    MuiCheckbox: {
      root: {
        '&[class*="MuiCheckbox-colorPrimary"]': {
          color: palette.dimGray,
          '&.Mui-checked': {
            color: palette.cerulean
          }
        }
      }
    },
    MuiFormControl: {
      root: {
        width: '100%',
        '&>label[class*="MuiInputLabel-outlined"]': {
          color: palette.dimGray,
          '& span[class*="MuiInputLabel-asterisk"]': {
            color: palette.candy
          },
          '&.Mui-focused': {
            color: palette.dimGray
          },
          '&.Mui-disabled': {
            color: palette.silver
          }
        }
      }
    },
    MuiTabs: {
      root: {
        borderRadius: '4px 4px 0px 0px',
        overflow: 'hidden',
        width: 'fit-content',
        '& *': {
          fontWeight: 500
        },
        '& .Mui-selected': {
          backgroundColor: palette.artic,
          color: palette.captudataBlue,
          '& *': {
            fontWeight: 900
          }
        }
      },
      indicator: {
        height: 0
      }
    },
    MuiTab: {
      root: {
        backgroundColor: palette.captudataBlue,
        textTransform: 'capitalize'
      },
      textColorInherit: {
        opacity: 1,
        color: palette.white
      }
    },
    MuiCard: {
      root: {
        '&:focus-visible': {
          outlineWidth: 0
        }
      }
    }
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      size: 'small',
      InputLabelProps: { shrink: true }
    },
    MuiFormControl: {
      variant: 'outlined',
      size: 'small'
    },
    MuiRadio: {
      color: 'primary'
    },
    MuiCheckbox: {
      color: 'primary'
    }
  }
});
