import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

// Styles
import styles from './styles';

class Footer extends Component {
  static propTypes = {
    classes: PropTypes.object
  };

  render() {
    const { classes } = this.props;

    return (
      <Paper className={classes.root} square={true}>
        <Typography variant="body2" className={`${classes.root}__text`}>
          © Copyright {new Date().getFullYear()}
        </Typography>
      </Paper>
    );
  }
}

// Redux connection
export default withStyles(styles)(Footer);
