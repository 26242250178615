import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const makeBodyContentStyles = makeStyles({
  root: {
    display: 'flex',
    backgroundColor: palette.artic,
    paddingTop: lightThemeV2.spacing(4),
    paddingBottom: lightThemeV2.spacing(12),
    alignItems: 'center',
    flexDirection: 'column'
  }
});

export const useBodyContentStyles = () => makeBodyContentStyles();
