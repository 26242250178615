import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const makeBeneficiaryTabsStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px 4px 0px 0px',
    overflow: 'hidden',
    width: 'fit-content'
  },
  tabPanel: {
    backgroundColor: palette.white,
    fontWeight: 'bold',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    width: 'auto',
    boxShadow: 'none',
    textTransform: 'capitalize'
  },
  tabContent: {
    backgroundColor: palette.artic
  },
  tab: {
    position: 'relative',
    backgroundColor: palette.captudataBlue,
    textTransform: 'capitalize',
    fontSize: 14,
    fontWeight: 900,
    lineHeight: 'normal',
    letterSpacing: '0.056 px',
    opacity: 1,
    overflow: 'hidden',
    width: 167,
    height: 42
  },
  indicator: {
    backgroundColor: palette.artic,
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 0
  },
  tabLabel: {
    zIndex: 1,
    position: 'relative',
    fontWeight: 500,
    color: palette.white,
    letterSpacing: '0.15px'
  },
  selected: {
    zIndex: 1,
    position: 'relative',
    color: palette.captudataBlue,
    fontWeight: 900
  }
}));

export const useBeneficiaryTabsStyles = () => makeBeneficiaryTabsStyles();
