import React from 'react';
import { Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';

export const FormulationActivityField = ({ title, value }) => (
  <div>
    <Typography variant={'body2'}>{title}</Typography>
    <Typography>{value}</Typography>
  </div>
);

FormulationActivityField.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
