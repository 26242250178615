import React from 'react';
import { PropTypes } from 'prop-types';
import { palette } from '../../settings/themes/colors';

export const WrapRequiredLabel = ({ label }) => (
  <>
    {label} <i style={{ color: palette.candy }}>*</i>
  </>
);

WrapRequiredLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};
