import { get } from 'lodash';

import { getApi } from '../../../api';

export const getAnalyticData = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.client_id}/projects/plain`, {});

    // if (resp.status === 200) {
    //   resp.data = resp.data.map(project => ({
    //     id: project.id,
    //     name: project.name,
    //     lat: project.latitud,
    //     lng: project.longitud
    //   }));
    // }
  } catch (err) {
    console.log('Error:', err);
    return get('resp', []);
  }

  return get(resp, 'data');
};

export const getAnalyticHeaders = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.client_id}/projects/plain_headers`, {});
  } catch (err) {
    console.log('Error:', err);
    return get('resp', []);
  }

  return get(resp, 'data');
};

export const getExcel = async (data = {}) => {
  let resp = {};
  const { clientId } = data;
  const params = {
    client_id: clientId
  };

  try {
    resp = await getApi().get(
      `clients/${clientId}/projects/plain_excel`,
      { params },
      { headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' } }
    );
  } catch (err) {
    resp = err.response;
  }

  return {
    data: get(resp, 'data'),
    name: ''
  };
};
