import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs, Tab, Grid, Button, makeStyles } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { isArray, isUndefined } from 'lodash';
import FormulationTabLabel from '../atoms/FormulationTabLabel';
import { palette } from '../../settings/themes/colors';

const styles = makeStyles(theme => ({
  tab: {
    width: theme.spacing(40),
    height: 42,
    padding: 0,
    '&>span[class^="MuiTab-wrapper"]': {
      height: '100%'
    }
  },
  addTabButton: {
    height: theme.spacing(8),
    color: palette.cerulean,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  rootTab: {
    borderBottom: '2px',
    height: 42,
    "& button[aria-selected='true']": {
      color: palette.cerulean
    },
    "& button[aria-selected='false']": {
      borderBottom: `2px solid ${palette.dimGray}`
    }
  },
  tabIndicator: {
    backgroundColor: palette.cerulean
  }
}));

const useTabStyles = () => styles();

export const FormulationTabs = ({
  tabs,
  readOnly,
  onChange = () => {},
  onAddTab = () => {},
  onSelected = () => {},
  onDelete = () => {}
}) => {
  const classes = useTabStyles();
  const { messages: intlMessages } = useIntl();

  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    setSelectedTab(tabs.length - 1);
  }, []);

  useEffect(() => {
    onSelected(selectedTab);
  }, [selectedTab]);

  const handleChange = index => {
    setSelectedTab(index);
  };

  const handleAddTab = () => {
    onAddTab();
  };

  const handleRemoveTab = index => {
    onDelete(tabs[index]);

    const newTabs = tabs.filter((_, i) => i !== index);

    setSelectedTab(newTabs.length - 1);
    onChange(newTabs);
  };

  const isLastTab = tabs.length === 4;

  const { tasks: currentTasks } = tabs[tabs.length - 1] || {};
  const canRemove = isUndefined(currentTasks) || (isArray(currentTasks) && currentTasks.length === 0);

  return (
    <Grid container spacing={4} alignItems="center">
      <Grid item>
        <Tabs value={selectedTab} classes={{ indicator: classes.tabIndicator, root: classes.rootTab }}>
          {tabs.map((tab, index) => (
            <Tab
              key={crypto.randomUUID()}
              className={classes.tab}
              label={
                <FormulationTabLabel
                  isActive={selectedTab === index}
                  label={tab.name}
                  onClick={() => handleChange(index)}
                  onRemove={canRemove && tabs.length - 1 === index && !readOnly ? () => handleRemoveTab(index) : null}
                />
              }
            />
          ))}
        </Tabs>
      </Grid>
      {!isLastTab && !readOnly && (
        <Button className={classes.addTabButton} startIcon={<AddOutlined />} onClick={handleAddTab}>
          {intlMessages['common.addLevel']}
        </Button>
      )}
    </Grid>
  );
};

FormulationTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onAddTab: PropTypes.func,
  onSelected: PropTypes.func,
  onDelete: PropTypes.func,
  readOnly: PropTypes.bool
};
