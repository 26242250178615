import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './app';
import registerServiceWorker from './registerServiceWorker';
import Wrapper from './components/i18n/i18nWrapper';

require('typeface-roboto');

/**
 * Verifies auth credentials
 */

ReactDOM.render(
  <Wrapper>
    <App />
  </Wrapper>,
  document.getElementById('root')
);

/**
 * Hot Module Replacement
 * https://webpack.js.org/guides/hot-module-replacement/
 */
if (module.hot) {
  module.hot.accept('./app.js', () => {
    const NextApp = require('./app').default;

    ReactDOM.render(<NextApp />, document.getElementById('root'));
  });
}

registerServiceWorker();
