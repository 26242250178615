import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Radio, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { getCurrentUser } from '../../utils/auth';

const useStyle = makeStyles({
  root: {
    minHeight: 90,
    height: '100%',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: lightThemeV2.spacing(1),
    padding: lightThemeV2.spacing(2),
    display: 'flex',
    gap: lightThemeV2.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.white,
    color: palette.captudataBlue,
    textAlign: 'center',
    '&>*:first-child': {
      color: palette.captuDataYellow
    },
    '&:hover>.radioControl': {
      display: 'block'
    }
  },
  radio: {
    position: 'absolute',
    display: 'none',
    top: 0,
    left: 0,
    '&.active': {
      display: 'block'
    }
  }
});

const StatisticItemComp = ({ label, value, onClick = () => {}, active = false }) => {
  const classes = useStyle();

  return (
    <Box className={classes.root} onClick={() => onClick()}>
      <Typography variant="h3">{value}</Typography>
      <Typography variant="subtitle1">{label}</Typography>
      <Radio className={classNames(classes.radio, 'radioControl', active ? 'active' : '')} checked={active} />
    </Box>
  );
};

StatisticItemComp.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
  onClick: PropTypes.func,
  active: PropTypes.bool
};

const mapToProps = state => ({
  clientId: getCurrentUser(state).client_id
});

export const StatisticItem = connect(mapToProps)(StatisticItemComp);
