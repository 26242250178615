import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../../../settings/themes/colors';

const makeAccordionStyles = makeStyles(theme => ({
  accordion: {
    border: 'none',
    boxShadow: 'none',
    padding: '0px',
    width: '100%'
  },
  accordionSummary: {
    padding: '0px',
    minHeight: '30px',
    height: '15px',
    '&.Mui-expanded': {
      margin: 0,
      minHeight: '30px'
    }
  },
  accordionDetails: {
    backgroundColor: palette.artic,
    padding: '0px 10px'
  },
  typography: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightExtra,
    color: palette.cerulean
  }
}));

export const useCollapsibleContainerStyles = () => {
  const { accordion, accordionSummary, accordionDetails, typography } = makeAccordionStyles();

  return {
    accordionClasses: { root: accordion },
    accordionSummaryClasses: { root: accordionSummary },
    accordionDetailsClasses: { root: accordionDetails },
    typographyClasses: { root: typography }
  };
};
