import { Box, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const useStyle = makeStyles({
  label: {
    fontSize: 12,
    fontWeight: 400,
    color: lightThemeV2.palette.text.secondary,
    lineHeight: '16px',
    '&__required:after': {
      content: '" *"',
      color: lightThemeV2.palette.error.main
    }
  },
  fieldContainer: {
    border: `1px solid ${lightThemeV2.palette.primary.main}`,
    borderRadius: 6,
    width: '100%',
    padding: '6px 1px 11px 10px',
    maxHeight: 38
  }
});

const FieldLabel = ({ children, required }) => {
  const classes = useStyle();

  return <Typography className={`${classes.label} ${required ? `${classes.label}__required` : ''}`}>{children}</Typography>;
};

const LoadingField = ({ label, required }) => {
  const classes = useStyle();

  return (
    <>
      <FieldLabel required={required}>{label}</FieldLabel>
      <Box className={classes.fieldContainer}>
        <CircularProgress size={18} />
      </Box>
    </>
  );
};

export const AutoCompleteSelectorOptions = ({
  onChange,
  filterOptions,
  data,
  label,
  required,
  createOption,
  InputProps,
  keyId = 'id',
  keyLabel = 'name',
  isLoading,
  ...otherProps
}) => {
  const onChangeBehavior = async (_, value) => {
    if (value && value.newValue && createOption && filterOptions) {
      const result = await createOption(value.newValue);

      if (onChange) onChange(result);
      return;
    }

    if (onChange) onChange(value);
  };

  const options = useMemo(() => data.map(option => option[keyId]), [data]);

  const autoCompleteProps = useMemo(
    () => ({
      options,
      getOptionLabel: option => {
        if (option.newValue) return option.name;
        const foundItem = data.find(x => x[keyId] === option);

        return (foundItem || {})[keyLabel] || '';
      },
      getOptionSelected: (option, value) => option === value,
      onChange: onChangeBehavior,
      fullWidth: true,
      size: 'small',
      filterOptions: (options, params) => {
        const filtered = createFilterOptions()(options, params);
        const isExactMatch = params.inputValue !== '' && !options.find(option => option.name === params.inputValue);

        return filterOptions
          ? filterOptions(filtered, {
              isExactMatch,
              criteria: params.inputValue
            })
          : filtered;
      },
      renderInput: params => <TextField {...params} variant="outlined" name={otherProps.name} {...InputProps} />,
      ...otherProps
    }),
    [data, otherProps]
  );

  if (isLoading) {
    return <LoadingField label={label} required={otherProps.required} />;
  }
  const currentProps = { ...autoCompleteProps, ...otherProps };

  return (
    <>
      <Typography>{label}</Typography>
      <Autocomplete {...autoCompleteProps} />
    </>
  );
};

AutoCompleteSelectorOptions.propTypes = {
  onChange: PropTypes.func.isRequired,
  createOption: PropTypes.func,
  isLoading: PropTypes.bool,
  filterOptions: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  InputProps: PropTypes.object,
  keyId: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool
};
