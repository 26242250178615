import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  {
    path: '/projects',
    component: asyncComponent(() => import('./'))
  },
  {
    path: '/projects/:projectId',
    component: asyncComponent(() => import('./components/project'))
  }
];

export default routes;
