import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { useAssignProjectToGroup } from '../../queries/queryContactGroups';

const AssignProjectToGroupButtonComponent = ({
  variant = 'contained',
  projects,
  group,
  disabled,
  onClose,
  refetchProjectGroups,
  currentUser
}) => {
  const { id: groupId } = group || {};
  const onSuccess = () => {
    refetchProjectGroups();
    onClose();
  };
  const { mutate: AssignProjectToGroup, isLoading } = useAssignProjectToGroup({ onSuccess });
  const { client_id: clientId } = currentUser;
  const projectIds = projects.map(p => p.id);
  const { messages: intlMessages } = useIntl();
  const handleButtonClick = () => {
    AssignProjectToGroup({
      clientId,
      groupId,
      projectIds
    });
  };

  return (
    <Button variant={variant} onClick={handleButtonClick} disabled={disabled || isLoading}>
      <Typography variant="button">{intlMessages['beneficiaries.associateProject']}</Typography>
    </Button>
  );
};

AssignProjectToGroupButtonComponent.propTypes = {
  variant: PropTypes.string,
  projects: PropTypes.array,
  group: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  refetchProjectGroups: PropTypes.func,
  currentUser: PropTypes.object.isRequired
};

const mapStateToProps = state => ({ currentUser: getCurrentUser(state) });

export const AssignProjectToGroupButton = connect(mapStateToProps)(AssignProjectToGroupButtonComponent);
