import { makeStyles } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';

const makeHeaderContentStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: lightThemeV2.spacing(2),
    padding: 20
  },
  controls: {
    display: 'flex',
    gap: lightThemeV2.spacing(2)
  },
  heading: {
    color: palette.captudataBlue
  },
  subtitle: {
    display: 'inline-flex',
    alignContent: 'center',
    gap: lightThemeV2.spacing(1)
  }
});

export const useHeaderContentStyles = () => {
  return makeHeaderContentStyles();
};
