import { get } from 'lodash';

import { getApi } from '../../../api';

export const getProgram = async (data = {}) => {
  let resp = {};

  try {
    let url = '';

    switch (data.filter) {
      case 'annual': {
        url = `clients/${data.clientId}/projects/annual`;
        break;
      }
      case 'completed': {
        url = `clients/${data.clientId}/projects/completed`;
        break;
      }
      case 'outdated': {
        url = `clients/${data.clientId}/projects/outdated`;
        break;
      }
      default: {
        url = `clients/${data.clientId}/org_groups/${data.programId}`;
        break;
      }
    }

    if (!!data.orgGroupId) {
      url = `${url}?org_group_id=${data.orgGroupId}`;
    }

    resp = await getApi().get(url);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};
