import { alpha, makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const useTableStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  small: {
    maxHeight: theme.spacing(60),
    overflowY: 'scroll',
    marginTop: theme.spacing(3)
  },
  paper: {
    width: '100%',
    overflow: 'hidden'
  },
  table: {
    '&_header': {
      backgroundColor: palette.captudataBlue,
      '&_cell': {
        color: `${palette.white} !important`,
        fontWeight: 'Bold',
        padding: 2
      }
    }
  },
  activeHead: {
    color: `${palette.white} !important`,
    backgroundColor: palette.azure
  },
  icon: {
    '&_active': {
      opacity: 1,
      color: `${palette.white} !important`,
      marginLeft: 'auto'
    },
    '&_inactive': {
      opacity: 0.4,
      color: `${palette.white} !important`,
      marginLeft: 'auto'
    }
  },
  checkbox: {
    color: `${palette.white} !important`
  },
  sortLabel: {
    width: '100%',
    fontWeight: 'Bold !important',
    padding: '16px 10px',
    borderRadius: 3,
    '&:hover': {
      color: `${palette.white} !important`
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  chip: {
    borderRadius: 8,
    border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
    color: `${palette.white} !important`,
    fontWeight: 'bold',
    '&_active': {
      backgroundColor: palette.captuDataYellow
    },
    '&_inactive': {
      backgroundColor: palette.silver
    },
    '&_type': {
      backgroundColor: palette.lavender
    },
    '&_legally_incorporated': {
      backgroundColor: palette.emerald,
      color: palette.white
    },
    '&_unincorporated': {
      backgroundColor: palette.white,
      color: `${palette.dimGray} !important`,
      border: `1px solid ${palette.dimGray}`
    },
    '&_in_the_process_of_incorporation': {
      backgroundColor: palette.white,
      color: `${palette.emerald} !important`,
      border: `1px solid ${palette.emerald}`
    }
  },
  tableRow: {
    backgroundColor: `${palette.white} !important`
  },
  link: {
    color: palette.captudataBlue,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

export const useTableClasses = () => useTableStyles();
