import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Backdrop, Box, CircularProgress, FormControlLabel, Grid, Switch, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Sidebar } from './components/sidebar';
import { CaptudataMap } from './components/map';
import { useGetProjects } from '../../queries/queryProjects';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { ProgramFilters } from '../../components/organims/ProgramFilters';
import { Statistic } from '../../components/organims/Statistic';
import { FilterByPhysicalProgress } from '../../components/organims/FilterByPhysicalProgress';
import { useGetStatistics } from '../../queries/queryStatistics';
import { DownloadExcelButton } from '../../components/molecules/DownloadExcelButton';

const useProgramStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(2),
    '&>.content': {
      height: '100%',
      position: 'relative',
      '&>.sidebarContainer': {
        height: '100%',
        zIndex: 1
      },
      '&>.mapContainer': {
        height: '100%'
      },
      '@media (min-width: 1024px)': {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        '&>.mapContainer': {
          gridColumnStart: 2
        },
        '&>.sidebarContainer': {
          width: '100%',
          gridColumnStart: 1,
          gridColumnEnd: 1
        }
      }
    }
  },
  sidebar: {
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'visible',
    backgroundColor: `${palette.darkGray}66`
  },
  backdrop: {
    position: 'absolute',
    zIndex: lightThemeV2.zIndex.drawer + 1,
    color: '#fff'
  },
  containerFilterPhysic: {
    display: 'flex',
    paddingLeft: lightThemeV2.spacing(4),
    paddingRight: lightThemeV2.spacing(4),
    minWidth: '690px',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  titlePhysical: {
    color: palette.captudataBlue
  }
});

const DEFAULT_PARAMS = {
  only_main_attributes: true
};

const QUERIES_TO_REMOVE_FROM_EACH_REQUEST = [
  'physical_progress_equal_to',
  'physical_progress_greater_than_or_equal_to',
  'physical_progress_greater_than',
  'with_termination_date_extension',
  'physical_progress_less_than',
  'ending_in_year'
];

const ProgramsComponent = ({ clientId }) => {
  const { messages: intlMessages } = useIntl();
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const { data: projectsResponse = {}, refetch, isLoading, isFetching } = useGetProjects({ clientId, params });
  const { data: statisticData } = useGetStatistics({ clientId, params: { query: params.query, org_groups_ids: params.org_groups_ids } });
  const [open, setOpen] = useState(true);
  const classes = useProgramStyles();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [filterSelected, setFilterSelected] = useState();

  useEffect(() => {
    refetch();
  }, [params]);

  const handleOpenProjectBar = () => {
    setOpen(!open);
  };

  const onChangeFilter = ({ orgGroupsId, ...filters }) => {
    setParams({
      ...params,
      org_groups_ids: (orgGroupsId || {}).id,
      ...filters
    });
  };

  const getPhysicalProgressQueryFilter = (operation, value) => {
    switch (operation) {
      case '==':
        return { physical_progress_equal_to: value };
      case '>=':
        return { physical_progress_greater_than_or_equal_to: value };
      case '>':
        return { physical_progress_greater_than: value };
      default:
        return undefined;
    }
  };

  const onChangePhysicalFilter = ({ value, operation, query, id } = {}) => {
    setFilterSelected(id);
    const paramsCloned = { ...params };

    QUERIES_TO_REMOVE_FROM_EACH_REQUEST.forEach(key => delete paramsCloned[key]);

    if (query) {
      setParams({
        ...paramsCloned,
        ...query
      });
      return;
    }

    setParams({
      ...paramsCloned,
      ...getPhysicalProgressQueryFilter(operation, value)
    });
  };

  const onChangeSearch = ({ search }) => {
    setParams({
      ...params,
      query: search
    });
    setSearchInputValue(search);
  };

  const [showInspections, setShowInspections] = useState(false);

  const hidePhysicalProgressFilter =
    statisticData &&
    statisticData.totalProjects === 0 &&
    statisticData.projectsDone === 0 &&
    statisticData.projectsInProgress === 0 &&
    statisticData.projectsTodo === 0 &&
    statisticData.withTerminationDateExtension === 0;

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <Box className={classes.root}>
        <Grid container direction="column">
          <ProgramFilters onChange={onChangeFilter}>
            <Grid item xs={4}>
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <FormControlLabel
                  control={<Switch color="primary" checked={showInspections} onChange={() => setShowInspections(!showInspections)} />}
                  label={<FormattedMessage id="executive.statistics.inspections.indicators" />}
                />
                <DownloadExcelButton filters={params} />
              </Box>
            </Grid>
          </ProgramFilters>
          <Statistic
            showInspections={showInspections}
            data={statisticData}
            onSelect={filter => onChangePhysicalFilter(filter)}
            selected={filterSelected}
          />
        </Grid>
        {!hidePhysicalProgressFilter && (
          <div className={classes.containerFilterPhysic}>
            <Typography variant="subtitle1" className={classes.titlePhysical}>
              {intlMessages['common.advacePhysical']}
            </Typography>
            <FilterByPhysicalProgress onChange={filter => onChangePhysicalFilter(filter)} selected={filterSelected} />
          </div>
        )}
        <Box className="content">
          {(isLoading || isFetching) && (
            <Backdrop className={classes.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Box className={classNames(classes.sidebar, 'sidebarContainer')}>
            <Sidebar
              onChange={onChangeSearch}
              projects={projectsResponse.projects}
              isOpen={open}
              onOpen={handleOpenProjectBar}
              searchInputValue={searchInputValue}
            />
          </Box>
          {(!isLoading || !isFetching) && (
            <div className="mapContainer">
              <CaptudataMap projects={projectsResponse.projects || []} />
            </div>
          )}
        </Box>
      </Box>
    </MuiThemeProvider>
  );
};

ProgramsComponent.propTypes = {
  clientId: PropTypes.number.isRequired
};

export default ProgramsComponent;
