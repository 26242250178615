import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_WORK_ORDERS,
  GET_WORK_ORDERS_SUCCESS,
  GET_WORK_ORDERS_FAIL,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAIL,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAIL,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER_FAIL,
  GET_WORK_ORDERS_USERS,
  GET_WORK_ORDERS_USERS_SUCCESS,
  GET_WORK_ORDERS_PROJECTS,
  GET_WORK_ORDERS_PROJECTS_SUCCESS,
  GET_WORK_ORDERS_FORMULARIES,
  GET_WORK_ORDERS_FORMULARIES_SUCCESS
} from '../action-types';
import {
  getWorkOrders,
  deleteWorkOrder,
  createWorkOrder,
  updateWorkOrder,
  getWorkOrdersUsers,
  getWorkOrdersProjects,
  getWorkOrdersFormularies
} from './helper';

export function* ordersRequest() {
  yield takeEvery(GET_WORK_ORDERS, function*({ payload }) {
    const resp = yield call(getWorkOrders, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_WORK_ORDERS_FAIL
      });
    }
  });
}

export function* deleteOrderRequest() {
  yield takeEvery(DELETE_WORK_ORDER, function*({ payload }) {
    const resp = yield call(deleteWorkOrder, payload);

    yield put({
      type: resp.status === 204 ? DELETE_WORK_ORDER_SUCCESS : DELETE_WORK_ORDER_FAIL
    });

    if (resp.status === 204) {
      yield put({
        type: GET_WORK_ORDERS,
        payload
      });
    }
  });
}

export function* createOrderRequest() {
  yield takeEvery(CREATE_WORK_ORDER, function*({ payload }) {
    const resp = yield call(createWorkOrder, payload);

    yield put({
      type: resp.status === 200 ? CREATE_WORK_ORDER_SUCCESS : CREATE_WORK_ORDER_FAIL
    });

    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS,
        payload
      });
    }
  });
}

export function* updateOrderRequest() {
  yield takeEvery(UPDATE_WORK_ORDER, function*({ payload }) {
    const resp = yield call(updateWorkOrder, payload);
    yield put({
      type: resp.status === 200 ? UPDATE_WORK_ORDER_SUCCESS : UPDATE_WORK_ORDER_FAIL
    });
    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS,
        payload
      });
    }
  });
}

export function* ordersUsersRequest() {
  yield takeEvery(GET_WORK_ORDERS_USERS, function*({ payload }) {
    const resp = yield call(getWorkOrdersUsers, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS_USERS_SUCCESS,
        payload: { data: resp.data }
      });
    }
  });
}

export function* ordersProjectsRequest() {
  yield takeEvery(GET_WORK_ORDERS_PROJECTS, function*({ payload }) {
    const resp = yield call(getWorkOrdersProjects, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS_PROJECTS_SUCCESS,
        payload: { data: resp.data }
      });
    }
  });
}

export function* ordersFormulariesRequest() {
  yield takeEvery(GET_WORK_ORDERS_FORMULARIES, function*({ payload }) {
    const resp = yield call(getWorkOrdersFormularies, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_WORK_ORDERS_FORMULARIES_SUCCESS,
        payload: { data: resp.data }
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(ordersRequest),
    fork(deleteOrderRequest),
    fork(createOrderRequest),
    fork(updateOrderRequest),
    fork(ordersUsersRequest),
    fork(ordersProjectsRequest),
    fork(ordersFormulariesRequest)
  ]);
}
