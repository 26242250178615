import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';

export const useGetCurrencies = () => {
  const queryKey = ["GET_currency"];
  const queryFn = async () => {
    const result = await getApi().get('currencies/');

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
