import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const useContactsFiltersStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    paddingBottom: 0
  },
  paper: {
    padding: theme.spacing(4)
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  heading: {
    color: palette.captudataBlue
  },
  fieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  SearchLabel: {
    marginRight: 17
  },
  label: {
    marginRight: 8
  },
  divider: {
    backgroundColor: palette.captudataBlue,
    height: 40,
    width: 1,
    marginRight: 6
  },
  filterButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  moveLeft: {
    marginLeft: 'auto'
  },
  button: {
    color: palette.cerulean,
    textDecoration: 'underline'
  },
  searchInput: {
    width: theme.spacing(60)
  },
  extraFiltersContainer: {
    borderRadius: 4,
    position: 'relative',
    width: '100%',
    margin: 'auto',
    marginTop: theme.spacing(2),
    border: `1px solid ${palette.silver}`,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: palette.silver,
      right: theme.spacing(2),
      top: -theme.spacing(2),
      clipPath: 'polygon(50% 0%, 100% 50%, 0% 50%)'
    }
  }
}));

export const useContactsFiltersClasses = () => useContactsFiltersStyles();
