import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const makeEmptyContentStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: palette.dimGray,
    gap: theme.spacing(2),
    padding: theme.spacing(14),
    minHeight: '80vh'
  }
}));

export const useEmptyContentStyles = () => makeEmptyContentStyles();
