import React from 'react';
import { ListItem } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const itemListStyles = makeStyles(theme => ({
  default: {
    border: `1px solid ${palette.darkGray}33`,
    borderRadius: '4px',
    padding: theme.spacing(2)
  }
}));

const ITEM_LIST_VARIANT = {
  DEFAULT: 'default'
};

const useItemListStyles = () => itemListStyles();

export const ItemListBase = ({ className, variant = ITEM_LIST_VARIANT.DEFAULT, ...otherProps }) => {
  const classes = useItemListStyles();
  return <ListItem className={classNames(classes[variant], className)} {...otherProps} />;
};
