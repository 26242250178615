import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const useContactsStyles = makeStyles(theme => ({
  root: {
    backgroundColor: palette.artic,
    marginBottom: 'auto'
  },
  paper: {
    margin: theme.spacing(4)
  }
}));

export const useContactsClasses = () => useContactsStyles();
