import { palette } from '../../../../settings/themes/colors';
import { lightThemeV2 } from '../../../../settings/themes/lightThemeV2';

const projectsMenuStyles = theme => ({
  root: {
    display: 'flex',
    gap: lightThemeV2.spacing(2),
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    '&__paper': {
      marginBottom: lightThemeV2.spacing(1),
      padding: lightThemeV2.spacing(2)
    },

    '&__menu': {
      width: '100%',
      '&__text': {
        color: palette.black,
        fontSize: '0.8571428571428571rem',
        fontWeight: 400,
        lineHeight: 1.2
      }
    }
  },

  item: {
    paddingTop: 4,
    paddingBottom: 4,
    height: 'auto',
    color: palette.dimGray,
    fontSize: '0.8rem',
    fontWeight: 500,
    lineHeight: 1.2,
    whiteSpace: 'normal',
    paddingRight: '5px',
    paddingLeft: '5px',

    '&__link': {
      display: 'block',
      margin: '4px 0',
      position: 'relative',
      backgroundColor: theme.palette.primary.light,
      textDecoration: 'none',

      '&__icon': {
        width: '.7em',
        position: 'absolute',
        right: 5,
        top: '50%',
        transform: 'translateY(-50%)',
        color: theme.palette.primary.dark
      }
    },

    '&--link': {
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: 32,
      color: theme.palette.primary.dark
    },

    '&--small': {
      fontSize: '0.8571428571428571rem',

      '&:nth-of-type(even):not(.selected)': {
        backgroundColor: theme.palette.primary.light,

        '&:hover': {
          backgroundColor: palette.dimGray
        }
      }
    },

    '&.selected': {
      color: palette.black
    }
  }
});

export default projectsMenuStyles;
