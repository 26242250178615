import React, { Fragment } from 'react';
import { IconButton, TableCell, Tooltip, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { COLUMNS_WIDTH_SETTINGS } from '../../../containers/Projects/components/project/Budget/constants';
import { useStyles as useStylesTable } from './useStyles';
import { palette } from '../../../settings/themes/colors';

const useStyles = makeStyles({
  actionsCells: {
    padding: 0,
    '&>.container-buttons': {
      display: 'flex',
      borderRadius: 0,
      padding: 0,
      minHeight: 42,
      minWidth: 84,
      justifyContent: 'flex-end'
    }
  },
  actionIcon: {
    color: palette.cerulean
  }
});

export const ActionsCell = ({ actions = [], className = '', width }) => {
  const classes = useStyles();
  const classesTable = useStylesTable();

  return (
    <TableCell width={width || COLUMNS_WIDTH_SETTINGS.actionCell} className={classNames(classes.actionsCells, className)} align="right">
      <div className="container-buttons">
        {actions.map((action, index) => {
          const Wrapper = action.tooltip ? Tooltip : Fragment;

          const wrapperProps = action.tooltip ? { title: action.tooltip, arrow: true } : {};

          return (
            <Wrapper {...wrapperProps} key={index}>
              <IconButton
                size="small"
                className={classNames(classesTable.actionIcon, classes.actionIcon)}
                onClick={() => action.onclick && action.onclick()}
              >
                {action.children}
              </IconButton>
            </Wrapper>
          );
        })}
      </div>
    </TableCell>
  );
};

ActionsCell.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      tooltip: PropTypes.string,
      onclick: PropTypes.func,
      children: PropTypes.node.isRequired
    })
  )
};
