import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL,
  RESET,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  SELECT_COUNTRY,
  CLEAR_DIVISIONS_FILTERS,
  GET_CUSTOM_FIELD_FILTERS,
  GET_CUSTOM_FIELD_FILTERS_SUCCESS,
  GET_CUSTOM_FIELD_FILTERS_FAIL,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL
} from '../action-types';

const adminDivisions = {
  level1: [],
  loadingLvl1: false,
  selectedLvl1: null,
  level2: [],
  loadingLvl2: false,
  selectedLvl2: null,
  level3: [],
  loadingLvl3: false,
  selectedLvl3: null,
  level4: [],
  loadingLvl4: false,
  selectedLvl4: null
};

const cfFilters = {
  loading: false,
  filters: []
};

// AD = Administrative Divisions
const initState = new Map({
  countries: [],
  selectedCountry: null,
  loadingCountries: false,
  adminDivisions,
  cfFilters,
  loadingCategories: false,
  categories: []
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */

const reducer = (state = initState, action) => {
  const level = get(action, 'payload.level', 0);
  const currentLevel = {};
  let levelArray = '';
  let loadingLevel = '';
  let selectedLevel = '';

  switch (action.type) {
    case GET_FILTERED_ADMINISTRATIVE_DIVISIONS:
      for (let i = level; i < 4; i++) {
        levelArray = `level${i}`;
        loadingLevel = `loadingLvl${i}`;
        selectedLevel = `selectedLvl${i}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      loadingLevel = `loadingLvl${level}`;
      currentLevel[loadingLevel] = true;
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case GET_FILTERED_ADMINISTRATIVE_DIVISIONS_SUCCESS:
      levelArray = `level${level}`;
      loadingLevel = `loadingLvl${level}`;
      selectedLevel = `selectedLvl${parseInt(level - 1)}`;
      currentLevel[levelArray] = get(action, 'payload.data', []);
      currentLevel[loadingLevel] = false;
      currentLevel[selectedLevel] = get(action, 'payload.selectedId', null);
      for (let i = level; i < 4; i++) {
        levelArray = `level${i + 1}`;
        loadingLevel = `loadingLvl${i + 1}`;
        selectedLevel = `selectedLvl${i + 1}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case GET_FILTERED_ADMINISTRATIVE_DIVISIONS_FAIL:
      levelArray = `level${level}`;
      loadingLevel = `loadingLvl${level}`;
      selectedLevel = `selectedLvl${level}`;
      currentLevel[levelArray] = [];
      currentLevel[loadingLevel] = false;
      currentLevel[selectedLevel] = null;
      for (let i = level; i < 4; i++) {
        levelArray = `level${i + 1}`;
        loadingLevel = `loadingLvl${i + 1}`;
        selectedLevel = `selectedLvl${i + 1}`;
        currentLevel[levelArray] = [];
        currentLevel[loadingLevel] = false;
        currentLevel[selectedLevel] = null;
      }
      return state.set('adminDivisions', assign({}, state.get('adminDivisions'), currentLevel));
    case GET_CUSTOM_FIELD_FILTERS:
      return state.set('cfFilters', assign(state.get('cfFilters'), { loading: true }));
    case GET_CUSTOM_FIELD_FILTERS_SUCCESS:
      return state.set('cfFilters', assign(state.get('cfFilters'), { loading: false, filters: get(action, 'payload.data', []) }));
    case GET_CUSTOM_FIELD_FILTERS_FAIL:
      return state.set('cfFilters', assign({}, cfFilters));
    case GET_COUNTRIES:
      return state.set('loadingCountries', true);
    case GET_COUNTRIES_SUCCESS:
      return state.set('loadingCountries', false).set('countries', assign([], get(action, 'payload.data', [])));
    case GET_COUNTRIES_FAIL:
      return state.set('loadingCountries', false).set('countries', []);
    case SELECT_COUNTRY:
      return state.set('selectedCountry', get(action, 'payload.countryId', null)).set('adminDivisions', assign({}, adminDivisions));
    case CLEAR_DIVISIONS_FILTERS:
      return state.set('selectedCountry', null).set('adminDivisions', assign({}, adminDivisions));
    case GET_CATEGORIES:
      return state.set('loadingCategories', true);
    case GET_CATEGORIES_SUCCESS:
      return state.set('loadingCategories', false).set('categories', assign([], get(action, 'payload.data', [])));
    case GET_CATEGORIES_FAIL:
      return state.set('loadingCategories', false).set('categories', []);
    case RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
