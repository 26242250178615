import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, makeStyles, Modal as BaseModal, Card, CardContent, IconButton, Divider } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

const styles = makeStyles({
  root: {
    display: 'flex',
    height: '100%',
    overflow: 'auto',
    '& i': {
      color: palette.candy
    },
    '&>*': {
      margin: 'auto'
    }
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 760,
    maxWidth: 800,
    color: palette.dimGray,
    paddingTop: lightThemeV2.spacing(4),
    paddingLeft: lightThemeV2.spacing(8),
    paddingRight: lightThemeV2.spacing(8),
    paddingBottom: lightThemeV2.spacing(4)
  },
  closeButton: {
    color: palette.cerulean,
    padding: lightThemeV2.spacing(1)
  },
  divider: {
    background: palette.yellow,
    marginTop: lightThemeV2.spacing(4),
    marginBottom: lightThemeV2.spacing(4)
  }
});

const useModalStyle = () => styles();

export const Modal = ({ open = false, onClose, title, children, subtitle, className }) => {
  const classes = useModalStyle();

  return (
    <BaseModal open={open} onClose={onClose} className={classes.root}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent="flex-end">
            <IconButton className={classNames(classes.closeButton, className)} onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Grid item style={{ width: '100%' }}>
              <Typography variant="h6">{title}</Typography>
            </Grid>
          </Grid>
          <Grid>{subtitle}</Grid>
          <Divider className={classes.divider} />
          {children}
        </CardContent>
      </Card>
    </BaseModal>
  );
};

Modal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string
};
