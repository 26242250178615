import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { ReactComponent as Pin } from '../../assets/images/icons/pin.svg';
import { ReactComponent as Start } from '../../assets/images/icons/start.svg';
import { ReactComponent as End } from '../../assets/images/icons/end.svg';
// Styles
import styles from './styles';

const Marker = ({ type, children, classes, hover, open, onClick }) => {
  const getClassNames = () =>
    `${classes.marker}__pin ${classes.marker}__pin--${type} ${open || hover ? 'hover' : ''} ${
      type === 'regular' && (open || hover) ? 'jump' : ''
    }`;

  const typeIcon = {
    regular: <Pin className={getClassNames()} />,
    start: <Start className={getClassNames()} />,
    end: <End className={getClassNames()} />
  };

  return (
    <div className={classes.marker} onClick={onClick}>
      {type ? typeIcon[type] : typeIcon.start}
      {children && (open || hover) ? <div className={`${classes.marker}__pop-up`}>{children}</div> : null}
    </div>
  );
};

Marker.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.any,
  hover: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.object
};

Marker.defaultProps = {
  type: 'regular'
};

export default withStyles(styles)(Marker);
