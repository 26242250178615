import { palette } from '../../../settings/themes/colors';

export const styles = theme => ({
  progressBlock: {
    position: 'relative',
    padding: '.5em 0',
    fontFamily: '"Roboto", Arial, Helvetica',

    '&__text': {
      color: palette.cerulean,
      fontSize: '1em',
      fontWeight: 700,
      lineHeight: 1.5
    },

    '&__progress-bar': {
      background: 'rgba(185, 185, 185, 0.8)',
      width: 'calc(96% - 5em)',
      height: 5,
      position: 'relative',
      border: 'none',

      '&__bar': {
        width: 0,
        height: 5,
        position: 'absolute',
        left: 0,
        top: 0
      }
    },

    '&__stat': {
      display: 'block',
      minWidth: '2em',
      padding: '.25em .5em .1em',
      position: 'absolute',
      right: 0,
      top: '.7em',
      color: palette.cerulean,
      fontWeight: 500,
      fontSize: '1.25em',
      textAlign: 'center',
      lineHeight: 1.2
    }
  }
});
