import React from 'react';
import { PropTypes } from 'prop-types';
import { TextField } from '@material-ui/core';

export const FormField = ({ component: Component = TextField, errors, ...otherProps }) => {
  const normalizedPros = { ...otherProps };

  if (errors) {
    normalizedPros.helperText = errors;
    normalizedPros.error = true;
  }

  return <Component {...normalizedPros} />;
};

FormField.propTypes = {
  component: PropTypes.elementType,
  errors: PropTypes.string
};
