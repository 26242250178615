import { FormattedMessage, useIntl } from 'react-intl';
import React, { useRef } from 'react';
import { useFormik } from 'formik';
import { object } from 'yup';
import { PropTypes } from 'prop-types';
import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { useBeneficiaryFormStyles } from './BeneficiaryForm.styles';
import { MappingFormFields } from './MappingFormFields';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { BeneficiarySelector } from '../molecules/BeneficiarySelector';
import { ContactGroupTypeRoleSelector } from '../molecules/contactGroupTypeRoleSelector';
import { ContactGroupSelector } from '../molecules/ContactGroupSelector';

export const ContactMemberForm = ({ handleClose, onSubmit, contactGroupTypeId, defaultValues = {}, addTo = 'group' }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryFormStyles();
  const submitRef = useRef();
  const validationShapes = {
    beneficiary: {
      group: object().required(<FormattedMessage id="beneficiaries.form.required" />)
    },
    group: {
      beneficiary: object().required(<FormattedMessage id="beneficiaries.form.required" />)
    }
  };
  const validationSchema = () =>
    object().shape({
      ...validationShapes[addTo],
      contactGroupTypeRole: object().required(<FormattedMessage id="beneficiaries.form.required" />)
    });
  const { values, handleSubmit, getFieldProps, setValues, errors } = useFormik({
    validateOnMount: true,
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submitValues => {
      const { beneficiary, contactGroupTypeRole, isMainContact, group, ...otherValues } = submitValues;

      onSubmit({
        ...otherValues,
        groupId: group && group.id,
        contact_id: beneficiary && beneficiary.id,
        contact_group_type_role_id: contactGroupTypeRole.id,
        is_main_contact: isMainContact
      });
    }
  });

  const Buttons = ({ messageId }) => (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          onClick={() => {
            submitRef.current();
          }}
          disabled={!isEmpty(errors)}
        >
          <FormattedMessage id={messageId} />
        </Button>
      </Grid>
      <Grid item>
        <Button variant="outlined" onClick={handleClose}>
          <FormattedMessage id="common.cancel" />
        </Button>
      </Grid>
    </Grid>
  );

  submitRef.current = () => {
    handleSubmit();
  };
  const contactGroupTypeRoleInput = {
    label: <WrapRequiredLabel label={intlMessages['group.linkContactGroupTypeRole']} />,
    name: 'contactGroupTypeRole',
    onChange: contactGroupTypeRole => setValues({ ...values, contactGroupTypeRole })
  };

  const addToGroupInputs = [
    {
      label: <WrapRequiredLabel label={intlMessages['group.linkBeneficiary']} />,
      name: 'beneficiary',
      onChange: beneficiary => setValues({ ...values, beneficiary }),
      component: BeneficiarySelector,
      xs: 4
    },
    {
      ...contactGroupTypeRoleInput,
      component: ({ ...props }) => (
        <>{contactGroupTypeId && <ContactGroupTypeRoleSelector contactGroupTypeId={contactGroupTypeId} {...props} />}</>
      ),
      xs: 4
    },
    {
      label: intlMessages['group.mainContact'],
      name: 'isMainContact',
      checked: values.isMainContact,
      xs: 4,
      onChange: (_, isMainContact) => setValues({ ...values, isMainContact }),
      component: props => <FormControlLabel control={<Checkbox />} {...props} />
    },
    {
      component: () => <Buttons messageId="group.linkBeneficiary" />,
      xs: 12
    }
  ];

  const addToBeneficiaryInputs = [
    {
      label: <WrapRequiredLabel label={intlMessages['group.chooseGroup']} />,
      name: 'group',
      onChange: group => setValues({ ...values, group }),
      component: ContactGroupSelector,
      xs: 6
    },
    {
      ...contactGroupTypeRoleInput,
      component: ({ ...props }) => {
        const { group } = values;
        const { contact_group_type: contactGroupType } = group || {};
        const { id: groupTypeId } = contactGroupType || {};

        return <ContactGroupTypeRoleSelector contactGroupTypeId={groupTypeId} disabled={!groupTypeId} {...props} />;
      },
      xs: 4
    },
    {
      label: intlMessages['group.mainContact'],
      name: 'isMainContact',
      checked: values.isMainContact,
      xs: 4,
      onChange: (_, isMainContact) => setValues({ ...values, isMainContact }),
      component: props => <FormControlLabel control={<Checkbox />} {...props} />
    },
    {
      component: () => <Buttons messageId="group.associateGroup" />,
      xs: 12
    }
  ];

  const fieldsConfig = {
    group: {
      fields: addToGroupInputs
    },
    beneficiary: {
      fields: addToBeneficiaryInputs
    }
  };

  return (
    <form style={{ width: '850px' }} className={classes.root}>
      <MappingFormFields fields={fieldsConfig[addTo].fields} getFieldProps={getFieldProps} errors={errors} />
    </form>
  );
};

ContactMemberForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  addTo: PropTypes.string.isRequired,
  contactGroupTypeId: PropTypes.number,
  defaultValues: PropTypes.object
};
