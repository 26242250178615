import { palette } from '../../../../settings/themes/colors';

const styles = theme => ({
  markerInfo: {
    padding: '1em',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: 3,
    color: '#fff',
    fontSize: '.8rem',

    '&:before': {
      background: theme.palette.secondary.main,
      content: '" "',
      display: 'block',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      filter: 'saturate(120%) brightness(85%)'
    },

    '&--highest': {
      '&:before': {
        background: palette.progressLevel.HIGHEST
      }
    },

    '&--high': {
      '&:before': {
        background: palette.progressLevel.HIGH
      }
    },

    '&--medium_high': {
      '&:before': {
        background: palette.progressLevel.MEDIUM_HIGH
      }
    },

    '&--medium_low': {
      '&:before': {
        background: palette.progressLevel.MEDIUM_LOW
      }
    },

    '&--low': {
      '&:before': {
        background: palette.progressLevel.LOW
      }
    },

    '&--lowest': {
      '&:before': {
        background: palette.progressLevel.LOWEST
      }
    },

    '&__title': {
      marginTop: 0,
      position: 'relative',
      color: '#fff',
      fontSize: '1.1em',
      fontWeight: 600
    }
  },

  progressBlock: {
    position: 'relative',
    padding: '.5em 0',
    fontFamily: '"Roboto", Arial, Helvetica',

    '&__text': {
      color: '#fff',
      fontSize: '1em',
      fontWeight: 500,
      lineHeight: 1.5
    },

    '&__progress-bar': {
      width: 'calc(90% - 5em)',
      height: 5,
      position: 'relative',
      background: theme.palette.secondary.main,
      border: 'none',

      '&__bar': {
        width: 0,
        height: 5,
        position: 'absolute',
        left: 0,
        top: 0,
        background: 'rgba(255,255,255,.8)'
      }
    },

    '&__stat': {
      display: 'block',
      minWidth: '2em',
      padding: '.25em .5em .1em',
      position: 'absolute',
      right: 0,
      top: '.7em',
      color: '#fff',
      fontWeight: 500,
      fontSize: '1.25em',
      textAlign: 'center',
      lineHeight: 1.2,
      borderRadius: '0.75em',
      background: theme.palette.secondary.main
    }
  }
});

export default styles;
