import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Card } from '../atoms/Card';
import { InformationCard } from '../molecules/InformationCard';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '../atoms/Link';
import { withRouter } from 'react-router';

const GroupGeneralInformationSectionComp = ({ group = {}, location }) => {
  const classes = useBeneficiaryStyles();
  const { messages: intlMessages } = useIntl();

  const { id, mainContact, contactGroupType, groupObject, phoneNumber: groupPhoneNumber, email: groupEmail } = group;
  const { name: contactGroupTypeName } = contactGroupType || {};
  const {
    phoneNumber,
    email,
    name,
    streetAddress,
    identification,
    administrativeDivisionLevel1,
    administrativeDivisionLevel2,
    administrativeDivisionLevel3
  } = mainContact || {};

  const groupInformation = [
    { title: `${intlMessages['group.form.section.type']}:`, value: contactGroupTypeName, xs: 12 },
    { title: `${intlMessages['group.form.section.object']}:`, value: groupObject, xs: 12 },
    { title: `${intlMessages['common.phone']}:`, value: groupPhoneNumber, xs: 6 },
    { title: `${intlMessages['common.email']}:`, value: groupEmail, xs: 6 }
  ];

  const contactInformation = [
    { title: `${intlMessages['common.name']}:`, value: name, xs: 4 },
    { title: `${intlMessages['beneficiaries.identificationNumber']}:`, value: identification, xs: 4 },
    { title: '', value: '', xs: 4 },
    { title: `${intlMessages['common.phone']}:`, value: phoneNumber, xs: 4 },
    { title: `${intlMessages['common.email']}:`, value: email, xs: 4 },
    { title: '', value: '', xs: 4 }
  ];

  const locationInformation = [
    {
      title: `${intlMessages['beneficiaries.form.administrativeRegion']}:`,
      value: administrativeDivisionLevel1 && administrativeDivisionLevel1.name,
      xs: 4
    },
    {
      title: `${intlMessages['common.municipality']}:`,
      value: administrativeDivisionLevel2 && administrativeDivisionLevel2.name,
      xs: 4
    },
    {
      title: `${intlMessages['beneficiaries.form.administrativeDivisionLevel3']}:`,
      value: administrativeDivisionLevel3 && administrativeDivisionLevel3.name,
      xs: 4
    },
    { title: `${intlMessages['beneficiaries.form.street']}:`, value: streetAddress, xs: 12 }
  ];

  const encodeCurrentUrl = encodeURIComponent(`${location.pathname}`);

  return (
    <Grid container direction={'column'}>
      <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
        <Typography variant={'h6'} className={classes.heading}>
          <FormattedMessage id="beneficiaries.section.generalInformation" />
        </Typography>
        <Link to={`${id}/edit?from=${encodeCurrentUrl}`}>
          <FormattedMessage id="common.edit" />
        </Link>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card variant={'stretch'}>
            <CardContent>
              <InformationCard title={intlMessages['group.groupInformation']} data={groupInformation} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant={'stretch'}>
            <CardContent>
              <InformationCard title={intlMessages['group.mainContactInfo']} data={contactInformation} />
              <InformationCard title={intlMessages['project.details.location']} data={locationInformation} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const GroupGeneralInformationSection = withRouter(GroupGeneralInformationSectionComp);
