import { get } from 'lodash';
import { Map } from 'immutable';

import {
  CHECK_AUTHORIZATION,
  LOGIN_SUCCESS,
  LOGIN_ADD,
  LOGIN_ERROR,
  LOGOUT,
  USER_LOCALE_CHANGE_REQUEST,
  USER_LOCALE_CHANGE_ERROR,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  SET_NEW_PASSWORD,
  RESET,
  RESET_PASSWORD_SUCCESS,
  SET_NEW_PASSWORD_FAILURE,
  SET_NEW_PASSWORD_SUCCESS
} from '../../../redux/action-types';
import { notify } from '../../../components/toastMessage';

const initState = new Map({
  currentUser: {},
  localeStatus: ''
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initState, action) => {
  let newState;
  switch (action.type) {
    case LOGIN_ADD:
      return {
        currentUser: { ...get(action, 'payload.data', {}) },
        isLoggedIn: get(action, 'payload.isLoggedIn', true)
      };
    case USER_LOCALE_CHANGE_REQUEST:
    case USER_LOCALE_CHANGE_ERROR:
      return {
        ...state,
        localeStatus: action.type
      };
    case RESET_PASSWORD_REQUEST:
      newState = {
        ...state,
        status: RESET_PASSWORD_REQUEST,
        loading: true
      };
      return newState;
    case SET_NEW_PASSWORD_SUCCESS:
    case RESET_PASSWORD_SUCCESS:
      return notify(get(action, 'payload.data.message'), 'success');
    case SET_NEW_PASSWORD_FAILURE:
    case RESET_PASSWORD_FAILURE:
      return notify(get(action, 'payload.data.errors[0]'));
    case RESET:
      return initState;
    default:
      return state;
  }
};

// Action Creators

/**
 * Check authorization action
 */
const checkAuthorization = () => ({
  type: CHECK_AUTHORIZATION
});

/**
 * Login action
 */
const loginSuccess = (payload, history) => ({
  type: LOGIN_SUCCESS,
  payload,
  history
});

/**
 * Logout action
 */
const logout = history => ({
  type: LOGOUT,
  history
});

/**
 * Reset state
 */
const reset = () => ({
  type: RESET
});

/**
 * Logout error
 */
const loginError = () => ({
  type: LOGIN_ERROR
});

const resetPassword = email => {
  return {
    type: RESET_PASSWORD_REQUEST,
    payload: email
  };
};

const setNewPassword = (password, passwordConfirmation) => ({
  type: SET_NEW_PASSWORD,
  payload: { password, passwordConfirmation }
});

export const actionCreators = {
  checkAuthorization,
  loginSuccess,
  logout,
  loginError,
  resetPassword,
  setNewPassword,
  reset
};

export default reducer;
