import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import InitRoutes from './router';
import { getTheme } from './settings/themes';
import Boot from './redux/boot';
import { store, history } from './redux/store';
import { ClearCacheProvider } from 'react-clear-cache';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={getTheme()}>
        <Provider store={store}>
          <ClearCacheProvider auto>
            <QueryClientProvider client={queryClient}>
              <InitRoutes history={history} />
            </QueryClientProvider>
          </ClearCacheProvider>
        </Provider>
      </MuiThemeProvider>
    </Fragment>
  );
};
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
