import { get } from 'lodash';

import { getApi } from '../../api';

export const getCustomFields = fields => {
  return fields.map(f => ({ cf_definition_id: f.cf_definition_id, value: f.value }));
};

export const getClientUsers = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/users`);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getModelCfDefinitions = async (data = {}) => {
  const { type, id } = data;

  let resp = {};

  try {
    resp = await getApi().get(`/clients/${id}/definitions_by_class`, { params: { target_class: type } });
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getModelUsers = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;

  let resp = {};

  try {
    resp = await getApi().get(`${type}/${data.id}/users`);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const addModelUsers = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;
  const params = { user_ids: data.users };
  let resp = {};

  try {
    resp = await getApi().post(`clients/${data.clientId}/${type}/${data.id}/assign_users`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const removeModelUsers = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;
  const params = { user_ids: data.users };
  let resp = {};

  try {
    resp = await getApi().post(`clients/${data.clientId}/${type}/${data.id}/unassign_users`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getUserModels = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;

  let resp = {};

  try {
    resp = await getApi().get(`users/${data.id}/${type}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const addUserModels = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;
  const paramKey =
    data.type === 'projects'
      ? 'project_ids'
      : data.type === 'orgGroups'
      ? 'org_group_ids'
      : data.type === 'formularies'
      ? 'formulary_ids'
      : undefined;
  const params = {};
  params[paramKey] = data.models;
  let resp = {};

  try {
    resp = await getApi().post(`users/${data.id}/assign_${type}`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const removeUserModels = async (data = {}) => {
  const type =
    data.type === 'projects'
      ? 'projects'
      : data.type === 'formularies'
      ? 'formularies'
      : data.type === 'orgGroups'
      ? 'org_groups'
      : undefined;
  const paramKey =
    data.type === 'projects'
      ? 'project_ids'
      : data.type === 'orgGroups'
      ? 'org_group_ids'
      : data.type === 'formularies'
      ? 'formulary_ids'
      : undefined;
  const params = {};
  params[paramKey] = data.models;
  let resp = {};

  try {
    resp = await getApi().post(`users/${data.id}/unassign_${type}`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const getModelModels = async (data = {}) => {
  const sourceType = data.sourceType === 'orgGroups' ? 'org_groups' : data.sourceType;
  const destinyType = data.destinyType === 'orgGroups' ? 'org_groups' : data.destinyType;

  let resp = {};

  try {
    resp = await getApi().get(`${sourceType}/${data.id}/${destinyType}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const addModelModels = async (data = {}) => {
  const sourceType = data.sourceType === 'orgGroups' ? 'org_groups' : data.sourceType;
  const destinyType = data.destinyType === 'orgGroups' ? 'org_groups' : data.destinyType;
  const paramKey =
    data.destinyType === 'projects'
      ? 'project_ids'
      : data.destinyType === 'orgGroups'
      ? 'org_group_ids'
      : data.destinyType === 'formularies'
      ? 'formulary_ids'
      : undefined;
  const params = {};
  params[paramKey] = data.models;
  let resp = {};

  try {
    resp = await getApi().post(`${sourceType}/${data.id}/assign_${destinyType}`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const removeModelModels = async (data = {}) => {
  const sourceType = data.sourceType === 'orgGroups' ? 'org_groups' : data.sourceType;
  const destinyType = data.destinyType === 'orgGroups' ? 'org_groups' : data.destinyType;
  const paramKey =
    data.destinyType === 'projects'
      ? 'project_ids'
      : data.destinyType === 'orgGroups'
      ? 'org_group_ids'
      : data.destinyType === 'formularies'
      ? 'formulary_ids'
      : undefined;
  const params = {};
  params[paramKey] = data.models;
  let resp = {};

  try {
    resp = await getApi().post(`${sourceType}/${data.id}/unassign_${destinyType}`, params);
  } catch (err) {
    console.log('Error:', err);
  }

  return get(resp, 'data');
};

export const createNewProject = async (data = {}) => {
  let resp = {};

  const { clientId, name, description, projectType, cfValues, orgGroupId, externalId, manage_contract } = data;

  const params = {
    cf_values: getCustomFields(cfValues),
    name,
    description,
    project_type: projectType,
    external_id: externalId,
    org_group_id: orgGroupId,
    manage_contract
  };

  try {
    resp = await getApi().post(`clients/${clientId}/projects/`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const createUser = async (data = {}) => {
  let resp = {};

  const { clientId, user } = data;

  const params = {
    user: {
      ...user,
      client_id: clientId
    }
  };

  try {
    resp = await getApi().post(`users/`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const updateUser = async (data = {}) => {
  let resp = {};

  const { clientId, user } = data;

  const params = {
    user: {
      ...user,
      client_id: clientId
    }
  };

  try {
    resp = await getApi().put(`users/${user.id}`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    status: get(resp, 'status')
  };
};

export const deleteUser = async (data = {}) => {
  let resp = {};

  const { id } = data;

  try {
    resp = await getApi().delete(`users/${id}`);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    status: get(resp, 'status')
  };
};

export const resetUserPassword = async (data = {}) => {
  let resp = {};

  const { id } = data;

  try {
    resp = await getApi().post(`users/${id}/generate_password`);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    status: get(resp, 'status')
  };
};

export const createOrgGroup = async (data = {}) => {
  let resp = {};

  const { clientId, orgGroup, typeId, parentId } = data;

  const params = {
    ...orgGroup,
    org_group_type_id: typeId,
    parent_id: parentId
  };

  try {
    resp = await getApi().post(`clients/${clientId}/org_groups/`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    data: get(resp, 'data'),
    status: get(resp, 'status')
  };
};

export const updateOrgGroup = async (data = {}) => {
  let resp = {};

  const { clientId, orgGroup, parentId } = data;

  const params = {
    ...orgGroup,
    parent_id: parentId
  };

  try {
    resp = await getApi().put(`clients/${clientId}/org_groups/${orgGroup.id}`, params);
  } catch (err) {
    resp = err.response;
    console.log('Error:', err);
  }

  return {
    status: get(resp, 'status')
  };
};

export const getFormularies = async (data = {}) => {
  let resp = null;

  try {
    resp = await getApi().get(`clients/${data.clientId}/formularies`);
  } catch (err) {
    console.log('Error:', err);
  }

  return { data: get(resp, 'data'), status: get(resp, 'status') };
};

export const getSelectedProject = async (data = {}) => {
  let resp = {};

  try {
    resp = await getApi().get(`clients/${data.clientId}/projects/${data.id}`);
  } catch (err) {
    console.log('Error:', err);
  }

  return { data: get(resp, 'data'), status: get(resp, 'status') };
};

export const getUserAPICredentials = async () => {
  let resp = {};

  try {
    resp = await getApi().get('/users/logged_user');
  } catch (err) {
    console.log('Error:', err);
  }

  return { data: get(resp, 'headers'), status: get(resp, 'status') };
};
