import { get } from 'lodash';
import { createTheme } from '@material-ui/core';

/**
 * Colors palette definition
 * https://material-ui.com/customization/themes/
 */
const palette = {
  primary: {
    light: '#E5F2FB', // Light blue
    main: '#5E96BF',
    dark: '#2A6D9E',
    contrastText: '#fff'
  },
  secondary: {
    light: '#f3bb5c', // Orange
    main: '#F1AA34',
    dark: '#D48223',
    contrastText: '#fff',
    green: '#48D552',
    red: '#A23D48',
    hover_red: '#6F0721',
    gray: '#8B8B8B',
    lightGray: '#EEE',
    darkGray: '#555',
    backgroundGray: '#CECECE',
    labelGray: '#6D6D6D'
  },
  default: {
    color: '#6A696A',
    background: '#E5F2FB',
    black: '#000'
  },
  latest: {
    primary: '#4A90E2',
    popperBackground: '#616161',
    financial: '#F3BB1C',
    physical: '#43BC64',
    divider: '#67E389',
    asterisk: '#F13434'
  },
  background: {
    paper: '#fff',
    default: '#fff',
    transparent: 'rgba(0, 0, 0, 0)'
  },
  shape: {
    borderRadius: 40
  },

  // progress colors
  progressLevel: {
    HIGHEST: '#5E96BF',
    HIGH: '#58AA84',
    MEDIUM_HIGH: '#A0A749',
    MEDIUM_LOW: '#E09F3F',
    LOW: '#A23D48',
    LOWEST: '#74A0C4'
  },

  progressType: {
    financial: '#F3BB1C',
    physical: ' #3CC13B',
    execution: ' #FF8383'
  },

  // priority colors
  priorityLevel: {
    HIGHEST: '#CE0000',
    HIGH: '#EA4444',
    MEDIUM: '#EA7D24',
    LOW: '#2A8735',
    LOWEST: '#55A557'
  },

  // status colors
  statusLevel: {
    TODO: '#AAAAAA',
    COMPLETE: '#58AA84',
    IN_PROGRESS: '#E09F3F',
    CANCEL: '#A23D48'
  }
};

/**
 * Typography definition
 * https://material-ui.com/style/typography/
 */
const typography = {
  // Use the system font instead of the default Roboto font.
  fontFamily: "'Roboto', 'Helvetica', 'Arial'",
  fontSize: 12,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightExtra: 700,
  useNextVariants: true,

  headline: {
    color: `${get(palette, 'primary.dark')}`,
    marginBottom: 28,
    fontSize: 32,
    fontWeight: 800
  },

  title: {
    color: `${get(palette, 'primary.main')}`,
    marginBottom: 8,
    fontSize: 24,
    fontWeight: 800
  },

  body2: {
    fontSize: 16
  },

  body1: {
    fontSize: 16
  },

  caption: {
    fontSize: "0.8rem"
  }
};

/**
 * Overrides UI elements
 * For documentation to get all UI component's name go to:
 * https://material-ui.com/
 * Props: https://material-ui.com/customization/default-theme/
 */
const overrides = {
  /**
   * Buttons
   * https://material-ui.com/api/button/
   */
  MuiButton: {
    root: {
      textTransform: 'none',
      minHeight: 36
    },
    text: {
      padding: '8px 16px'
    },
    contained: {
      boxShadow: 'none',
      fontSize: 16,
      fontWeight: 800
    }
  },
  /**
   * Panels
   * https://material-ui.com/api/paper/
   */
  MuiPaper: {
    root: {
      border: `1px solid ${get(palette, 'primary.main')}`,
      borderRadius: 1,
      boxShadow: 'none'
    }
  },
  /**
   * Inputs
   * https://material-ui.com/api/input/
   */
  MuiInput: {
    root: {
      border: `1px solid ${get(palette, 'primary.main')}`,
      padding: '3px 1px 3px 10px',
      borderRadius: 6,
      '&:after': {
        content: 'none !important'
      },
      '&:before': {
        content: 'none !important'
      }
    }
  },
  MuiFormLabel: {
    root: {
      marginLeft: 10,
      marginTop: 4
    }
  },
  /**
   * Typography
   * https://material-ui.com/api/typography/
   */
  MuiTypography: {
    h5: typography.headline,
    h6: typography.title,
    body2: typography.body2,
    body1: typography.body1,
    caption: typography.caption
  }
};

const theme = createTheme({
  palette,
  typography,
  overrides
});

export default theme;
