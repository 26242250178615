import { makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const makeBeneficiaryStyles = makeStyles({
  heading: {
    color: palette.captudataBlue
  },
  listContainer: {
    flex: 1,
    maxHeight: '345px',
    overflowY: 'scroll'
  }
});

export const useBeneficiaryStyles = () => makeBeneficiaryStyles();
