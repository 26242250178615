import { makeStyles } from '@material-ui/core/styles';

export const useInspectionDetailStyles = makeStyles({
  root: {
    '&>hr': {
      display: 'block',
      marginBlockStart: '0.5em',
      marginBlockEnd: '0.5em',
      borderStyle: 'inset',
      borderWidth: '1px',
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0.87)'
    }
  }
});
