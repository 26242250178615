import { Button, alpha, styled } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

export const LinkButton = styled(Button)(({ variant }) => {
  switch (variant) {
    case 'danger':
      return {
        color: palette.white,
        backgroundColor: palette.LightRed,
        textTransform: 'capitalize',
        '&:hover': {
          backgroundColor: alpha(palette.LightRed, 0.5)
        }
      };
    default:
      return {
        color: palette.cerulean,
        textTransform: 'capitalize',
        textDecoration: 'underline',
        '&:hover': {
          textDecoration: 'underline'
        }
      };
  }
});
