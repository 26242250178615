import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_WORK_ORDERS,
  GET_WORK_ORDERS_SUCCESS,
  GET_WORK_ORDERS_FAIL,
  CREATE_WORK_ORDER,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAIL,
  UPDATE_WORK_ORDER,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAIL,
  DELETE_WORK_ORDER,
  DELETE_WORK_ORDER_SUCCESS,
  DELETE_WORK_ORDER_FAIL,
  GET_WORK_ORDERS_USERS_SUCCESS,
  GET_WORK_ORDERS_PROJECTS_SUCCESS,
  GET_WORK_ORDERS_FORMULARIES_SUCCESS,
  RESET
} from '../action-types';

const initState = new Map({
  isLoading: false,
  orders: [],
  users: [],
  projects: [],
  formularies: []
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initState, action) => {
  switch (action.type) {
    case GET_WORK_ORDERS:
    case CREATE_WORK_ORDER:
    case UPDATE_WORK_ORDER:
    case DELETE_WORK_ORDER:
      return state.set('isLoading', true);
    case GET_WORK_ORDERS_SUCCESS:
      return state.set('isLoading', false).set('orders', assign([], get(action, 'payload.data', [])));
    case GET_WORK_ORDERS_FAIL:
    case CREATE_WORK_ORDER_FAIL:
    case UPDATE_WORK_ORDER_FAIL:
    case DELETE_WORK_ORDER_FAIL:
    case CREATE_WORK_ORDER_SUCCESS:
    case UPDATE_WORK_ORDER_SUCCESS:
    case DELETE_WORK_ORDER_SUCCESS:
      return state.set('isLoading', false);
    case GET_WORK_ORDERS_USERS_SUCCESS:
      return state.set('users', assign([], get(action, 'payload.data', [])));
    case GET_WORK_ORDERS_PROJECTS_SUCCESS:
      return state.set('projects', assign([], get(action, 'payload.data', [])));
    case GET_WORK_ORDERS_FORMULARIES_SUCCESS:
      return state.set('formularies', assign([], get(action, 'payload.data', [])));
    case RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
