import { Grid, styled } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

export const WideContainer = styled(Grid)({
  width: '100%',
  paddingLeft: lightThemeV2.spacing(4),
  paddingRight: lightThemeV2.spacing(4),
  gap: lightThemeV2.spacing(4),
  display: 'flex',
  flexDirection: 'column'
});
