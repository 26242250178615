import React from 'react';
import { PropTypes } from 'prop-types';
import { ContactGroupForm } from './ContactGroupForm';

const useGroupForm = () => {
  const normalizeGroupValues = (values = {}) => {
    const { name = null, email = null, groupObject = null, phoneNumber = null, contactGroupTypeId = null, note = null } = values;

    return {
      name,
      email,
      groupObject,
      phoneNumber,
      contactGroupTypeId,
      note
    };
  };

  return { normalizeGroupValues };
};

export const GroupForm = ({ formRef, onSubmit, defaultValues = {} }) => {
  const { normalizeGroupValues } = useGroupForm();

  return <ContactGroupForm formRef={formRef} onSubmit={onSubmit} defaultValues={normalizeGroupValues(defaultValues)} />;
};

GroupForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object
};
