import { Grid, Paper, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';
import Search from '@material-ui/icons/Search';
import { BeneficiariesFilters } from './BeneficiariesFilters';
import { BeneficiariesTable } from './BeneficiariesTable';
import { getCurrentUser } from '../../utils/auth';
import { useGetBeneficiaries } from '../../queries/queryBeneficiaries';
import { EmptyContent } from '../molecules/EmptyContent';
import { useContactsClasses } from './Beneficiaries.style';
import { NATURAL_CONTACTS_HEAD, INITIAL_FILTERS_VALUES, LEGAL_CONTACTS_HEAD } from '../../containers/Beneficiaries/constants';
import { AddBeneficiaryButton } from '../molecules/AddBeneficiaryButton';
import { LoadingCircularProgress } from '../atoms/LoadingCircularProgress';

export const BeneficiariesContentComponent = ({ currentUser, contactType, emptyContentMessage }) => {
  const contactTypeConfig = {
    natural: {
      head: NATURAL_CONTACTS_HEAD,
      initialValues: INITIAL_FILTERS_VALUES
    },
    legal: {
      head: LEGAL_CONTACTS_HEAD,
      initialValues: {}
    }
  };

  const { head, initialValues } = contactTypeConfig[contactType];

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const classes = useContactsClasses();
  const formikFilters = useFormik({
    initialValues,
    onSubmit: () => {}
  });
  const { client_id: clientId } = currentUser || {};
  const currentValues = formikFilters.values;
  const { data: beneficiaries, isLoading, refetch } = useGetBeneficiaries({
    clientId,
    params: { ...currentValues, page: page + 1, per_page: pageSize, contact_type: contactType }
  });
  const { contacts = [], meta } = beneficiaries || {};
  const onChangePagination = (currentPage, currentPageSize) => {
    setPage(currentPage);
    setPageSize(currentPageSize);
  };
  const hasFilters = () => Object.values(currentValues).some(value => value);

  const renderNaturalBeneficiaries = () => {
    const filterHeadUI = <BeneficiariesFilters filters={formikFilters} refetch={refetch} clientId={clientId} contactType={contactType} />;

    if (isLoading) {
      return (
        <>
          {filterHeadUI}
          <Grid container>
            <LoadingCircularProgress />
          </Grid>
        </>
      );
    }

    return (
      <>
        {filterHeadUI}
        {contacts.length > 0 && (
          <BeneficiariesTable
            data={contacts}
            dataColumns={head}
            onChangePagination={onChangePagination}
            pagination={{ page, pageSize, count: meta.total_count }}
          />
        )}

        {contacts.length <= 0 && hasFilters() && (
          <Paper className={classes.paper}>
            <EmptyContent icon={<Search />}>
              <Typography variant="body1">
                <FormattedMessage id={`beneficiaries.filters.${contactType}.emptyMessage`} />
              </Typography>
            </EmptyContent>
          </Paper>
        )}

        {contacts.length <= 0 && !hasFilters() && (
          <EmptyContent>
            {emptyContentMessage}
            <AddBeneficiaryButton variant="outlined" />
          </EmptyContent>
        )}
      </>
    );
  };

  return <Grid classes={{ root: classes.root }}>{renderNaturalBeneficiaries()}</Grid>;
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
});

BeneficiariesContentComponent.propTypes = {
  currentUser: PropTypes.object,
  contactType: PropTypes.string,
  emptyContentMessage: PropTypes.string
};

export const BeneficiariesContent = withRouter(connect(mapStateToProps, {})(BeneficiariesContentComponent));
