import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useGetAdminDivisions } from '../../queries/queryCountries';

export const DivisionSelector = ({ countryId, level, parent, disabled, onChange, label, value, ...otherProps }) => {
  const { data = [], isLoading } = useGetAdminDivisions({ countryId, level, parentId: parent });

  return (
    <Autocomplete
      options={data}
      getOptionLabel={option => (typeof option === 'string' ? '' : option.name)}
      getOptionSelected={(option, selection) => selection === '' || option.id === selection.id}
      disabled={disabled || isLoading || !level}
      onChange={(_, selection) => onChange && onChange(selection)}
      renderInput={params => <TextField label={label} {...params} />}
      value={data.find(x => value && x.id === value.id) || ''}
      {...otherProps}
    />
  );
};

DivisionSelector.propTypes = {
  countryId: PropTypes.number,
  level: PropTypes.number,
  parent: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.object
};
