import React, { useEffect } from 'react';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Card } from '../atoms/Card';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { EmptySection } from '../molecules/EmptySection';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { BeneficiaryAttachments } from './BeneficiaryAttachments';
import { UploadDocumentButton } from '../molecules/UploadDocumentButton';
import { useUploadBeneficiaryDocument } from '../../queries/queryBeneficiaries';
import { getCurrentUser } from '../../utils/auth';
import { CircularProgress } from '../atoms/CircularProgress';
import { AssignProjectToBeneficiaryContent } from './AssingProjectToBeneficiaryContent';
import { isEmpty } from 'lodash';
import { AssignGroupToBeneficiaryContent } from './AssignGroupToBeneficiaryContent';

const BeneficiaryAssetsSectionComponent = ({ beneficiary, currentUser, onAssetsChange }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryStyles();
  const { data: updatedAsset, mutate: uploadBeneficiaryDocument, isLoading } = useUploadBeneficiaryDocument();
  const { attachedDocuments = [], id: beneficiaryId } = beneficiary;
  const { client_id: clientId } = currentUser || {};
  const handleSelectDocument = e => {
    uploadBeneficiaryDocument({ clientId, beneficiaryId, file: e.target.files[0] });
  };

  useEffect(() => {
    if (updatedAsset) {
      onAssetsChange(updatedAsset);
    }
  }, [updatedAsset]);

  return (
    <Grid container direction={'column'}>
      <Typography variant={'h6'} className={classes.heading}>
        {intlMessages['beneficiaries.section.beneficiaryAssets']}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card variant={'stretch'}>{!isEmpty(beneficiary) && <AssignProjectToBeneficiaryContent beneficiary={beneficiary} />}</Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant="stretch">{!isLoading && <AssignGroupToBeneficiaryContent beneficiary={beneficiary} />}</Card>
        </Grid>
        <Grid item xs={4}>
          <Card variant={'stretch'}>
            <CardContent>
              <CardSectionTitle
                title={intlMessages['beneficiaries.section.documentsSubtitle']}
                actions={
                  <UploadDocumentButton label={intlMessages['beneficiaries.attachDocument']} handleSelectDocument={handleSelectDocument} />
                }
              />
              {attachedDocuments.length > 0 ? (
                <>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <BeneficiaryAttachments
                      clientId={clientId}
                      beneficiaryId={beneficiaryId}
                      documents={attachedDocuments}
                      onAssetsChange={onAssetsChange}
                    />
                  )}
                </>
              ) : (
                <EmptySection>{intlMessages['beneficiaries.empty.documents']}</EmptySection>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

BeneficiaryAssetsSectionComponent.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  onAssetsChange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({ currentUser: getCurrentUser(state) });

export const BeneficiaryAssetsSection = connect(mapStateToProps)(BeneficiaryAssetsSectionComponent);
