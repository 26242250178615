import React from 'react';
import { push } from 'react-router-redux';
import moment from 'moment';
import { history } from '../redux/store';
import { headerMenu, userTypes, validRoutesPaths } from './constants';
import { transformSnake2Camel } from '../queries/utils';

export const redirectToPermissionUrls = permissions => {
  const currentPath = history.location.pathname;
  let permissionRoutes = permissions
    .filter(p => p.can && p.permission.permission_type === 'menu' && p.permission_action.name === 'View')
    .map(p => headerMenu[p.permission.name].path);

  permissionRoutes = permissionRoutes.concat(validRoutesPaths);
  const firstMatch = permissionRoutes.find(r => currentPath.indexOf(r) > -1);

  if (!firstMatch) {
    history.push(permissionRoutes[0]);
    push(permissionRoutes[0]);
  }
};

/* Auxiliar function to get the users that are in the main array and not in the secondary array */
export const usersDifference = (mainUsers, secondaryUsers) => {
  const mappedSecUsers = secondaryUsers.map(u => u.id);

  return mainUsers.filter(mu => !mappedSecUsers.includes(mu.id));
};

export const usersFormatter = users => users.map(u => ({ ...u, userType: getUserType(u) }));

const getUserType = user => userTypes[user.user_type];

export const onlyUserTypes = (users, types) => users.filter(u => types.includes(u.user_type));

export const FULL_DATE_FORMAT = 'YYYY-MM-DDThh:mm:ss.sssZ';
export const SIMPLE_DATE_FORMAT = 'DD/MM/YYYY';
export const SIMPLE_DASH_DATE_FORMAT = 'YYYY-MM-DD';
export const CONTRACTED_DATE_FORMAT = 'DD/MM/YY';
export const SEPARATED_DATE = 'DD MMM YYYY';

export const parseStringDateToFormat = (stringDate, defaultValue, format = SIMPLE_DASH_DATE_FORMAT) =>
  (stringDate &&
    moment(stringDate)
      .utc()
      .format(format)) ||
  defaultValue;

export const removeEmptyKeys = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};

export const replaceStrings = (str, arr) => str.replace(/{(\d+)}/g, (match, index) => arr[index] || match);

export const flattenErrors = errors => {
  const flattenErrorsObj = {};

  Object.keys(errors).forEach(key => {
    if (typeof errors[key] === 'object' && !React.isValidElement(errors[key])) {
      const nestedErrors = flattenErrors(errors[key]);

      Object.keys(nestedErrors).forEach(nestedKey => {
        flattenErrorsObj[`${key}.${nestedKey}`] = nestedErrors[nestedKey];
      });
    } else {
      flattenErrorsObj[key] = errors[key];
    }
  });

  return flattenErrorsObj;
};

export const orgTypesObjToArr = orgTypesObj => {
  const orgTypesArr = [];

  const convertToArr = parent => {
    if (!parent) {
      return;
    }

    const { child, ...othersAtr } = parent;

    orgTypesArr.push(transformSnake2Camel(othersAtr));
    convertToArr(child);
  };

  convertToArr(orgTypesObj);
  return orgTypesArr;
};
