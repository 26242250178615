import { Card as MuiCard, styled } from '@material-ui/core';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';

export const Card = styled(MuiCard)(({ variant = 'default', gap = 0 }) => {
  switch (variant) {
    case 'stretch':
      return {
        padding: lightThemeV2.spacing(2),
        paddingBottom: lightThemeV2.spacing(4),
        height: '100%',
        '&>div[class*="MuiCardContent-root"]': {
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap: lightThemeV2.spacing(gap),
          padding: 0
        }
      };
    default:
  }
});
