import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../../settings/themes/default';
import { FormattedMessage } from 'react-intl';

// Styles
import styles from './styles';

const ProgressBlock = ({ label, value, colorKey, classes, defaultMessage, i18nId }) => (
  <div className={classes.progressBlock}>
    <span className={`${classes.progressBlock}__text`}>{label || <FormattedMessage id={i18nId} defaultMessage={defaultMessage} />}</span>
    <div
      className={`${classes.progressBlock}__progress-bar`}
      style={{
        background: `${theme.palette.progressLevel[colorKey]}`
      }}
    >
      <div
        className={`${classes.progressBlock}__progress-bar__bar`}
        style={{
          width: `${value.split(',')[0]}%`
        }}
      />
    </div>
    <span
      className={`${classes.progressBlock}__stat`}
      style={{
        background: `${theme.palette.progressLevel[colorKey]}`
      }}
    >
      {value}
    </span>
  </div>
);

ProgressBlock.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  colorKey: PropTypes.string,
  classes: PropTypes.object,
  defaultMessage: PropTypes.string,
  i18nId: PropTypes.string
};

export default withStyles(styles)(ProgressBlock);
