import React from 'react';
import PropTypes from 'prop-types';
import { useInspectionStyle } from './Inspection.styles';
import { useGetProject } from '../../../../queries/queryProjects';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../../../utils/auth';
import { ButtonInspection } from './ButtonInspection';
import { SummaryInspectionContract } from './SummaryInspectionContract';
import { Divider, Grid, LinearProgress } from '@material-ui/core';
import { CollapsibleContainer } from './CollapsibleContainer';
import { FormattedMessage } from 'react-intl';
import { MoreDetailInspectionContract } from './MoreDetailInspectionContract';

const InspectionComponent = ({ projectId, currentUser }) => {
  const { data: project, isLoading, isFetching } = useGetProject({
    clientId: currentUser.client_id,
    projectId
  });
  const { inspectionClasses, preloadingContainerClasses } = useInspectionStyle();

  if (isLoading || isFetching) {
    return (
      <Grid container classes={preloadingContainerClasses}>
        <LinearProgress />
      </Grid>
    );
  }

  return (
    <Grid container classes={inspectionClasses} direction="column">
      {project.main_contract && (
        <>
          <SummaryInspectionContract project={project} />
          <CollapsibleContainer title={<FormattedMessage id='programs.inspection.details' />}>
            <MoreDetailInspectionContract project={project} />
          </CollapsibleContainer>
          <Divider />
        </>
      )}
      <ButtonInspection project={project} />
    </Grid>
  );
};

InspectionComponent.propTypes = {
  currentUser: PropTypes.object,
  projectId: PropTypes.number
};

export const Inspection = connect(state => ({
  currentUser: getCurrentUser(state)
}))(InspectionComponent);
