import React from 'react';
import { CardContent, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Card } from '../atoms/Card';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { NoteSection } from '../molecules/NoteSection';
import { ListItems } from './ListItems';
import { ItemRecord } from '../molecules/ItemRecord';
import { LinkButton } from '../atoms/LinkButton';
import { useGetBeneficiaryRecordList } from '../../queries/queryBeneficiaries';
import { useRecordTransform } from '../../queries/utils';

const records = [];

const styles = makeStyles({
  recordsEmptyList: {
    '&>div:first-child': {
      height: 'initial'
    }
  },
  recordsList: {
    maxHeight: '325px'
  }
});

const useActionRecordSection = () => styles();

export const BeneficiaryActionRecordSection = ({ beneficiaryId, clientId }) => {
  const { messages: intlMessages } = useIntl();
  const beneficiaryClasses = useBeneficiaryStyles();
  const classes = useActionRecordSection();
  const { data: record = [] } = useGetBeneficiaryRecordList({ clientId, beneficiaryId });
  const { transformRecordToItemData } = useRecordTransform();

  return (
    <Grid container direction={'column'}>
      <Typography variant={'h6'} className={beneficiaryClasses.heading}>
        {intlMessages['beneficiaries.section.actionRecords']}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <NoteSection beneficiaryId={beneficiaryId} />
        </Grid>
        <Grid item xs={6}>
          <Card variant={'stretch'}>
            <CardContent>
              <CardSectionTitle
                title={intlMessages['beneficiaries.section.recordsSubtitle']}
                actions={
                  <LinkButton>
                    <FormattedMessage id="beneficiaries.linkTimeline" />
                  </LinkButton>
                }
              />
              <ListItems
                dataSource={transformRecordToItemData(record)}
                className={classNames(
                  beneficiaryClasses.listContainer,
                  classes.recordsEmptyList,
                  records.length <= 0 ? classes.recordsEmptyList : ''
                )}
                component={ItemRecord}
                emptyMsg={intlMessages['beneficiaries.empty.records']}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

BeneficiaryActionRecordSection.propTypes = {
  beneficiaryId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};
