import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem, Typography, makeStyles } from '@material-ui/core';
import { CloudUpload, Description as DescriptionIcon } from '@material-ui/icons';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { lightThemeV2 } from '../../../../../settings/themes/lightThemeV2';
import { SelectField } from '../../../../../components/molecules/SelectField';
import { WrapRequiredLabel } from '../../../../../components/atoms/WrapRequiredLabel';
import { palette } from '../../../../../settings/themes/colors';
import { useGetClientCategories } from '../../../../../queries/queryClientCategories';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../../../../utils/auth';

const useStyles = makeStyles({
  root: {
    border: '1px dashed #D9D9D9',
    borderRadius: lightThemeV2.spacing(1),
    padding: lightThemeV2.spacing(4),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& .uploadLink': {
        textDecoration: 'underline'
      }
    }
  },
  dragDropBoxSimple: {
    border: '1px dashed #D9D9D9',
    borderRadius: lightThemeV2.spacing(1),
    padding: lightThemeV2.spacing(1),
    marginTop: lightThemeV2.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& .uploadLink': {
        textDecoration: 'underline'
      }
    }
  },
  onDrop: {
    backgroundColor: '#F5F5F5'
  },
  helpMsg: {
    color: '#1890FF',
    cursor: 'pointer'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cells: {
    display: 'flex',
    alignItems: 'center'
  },
  fileIcon: {
    color: palette.captudataBlue
  }
});

const DefaultDragAndDropMsg = ({ isDragActive }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      className={classNames(classes.root, isDragActive ? classes.onDrop : null)}
    >
      <Grid item>
        <CloudUpload />
      </Grid>
      <Grid item>
        <Typography>
          <span className={classNames(classes.helpMsg, 'uploadLink')}>Seleccione el archivo</span> o arraste y suelte aquí
        </Typography>
        <Typography>SVG, PNG, JPG, GIF, PDF, DOC o XLSX (max. [3MB])</Typography>
      </Grid>
    </Grid>
  );
};

const DragAndDropFieldComponent = ({ value, onChange, clientId }) => {
  const classes = useStyles();
  const { data: clientCategories = [] } = useGetClientCategories({ clientId });
  const filesSelected = value || [];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles = []) => {
      const acceptedFilesValues = acceptedFiles.map(file => ({
        name: file.name,
        file,
        categoryIds: clientCategories || clientCategories.length > 0 ? [clientCategories[0].id] : []
      }));

      onChange([...filesSelected, ...acceptedFilesValues]);
    }
  });

  const onRemoveFile = index => {
    const acceptedFiles = filesSelected.filter((_, i) => i !== index);

    onChange(acceptedFiles);
  };

  const onChangeFileType = (type, index) => {
    let newAcceptedFiles = filesSelected;
    newAcceptedFiles[index].categoryIds = [type];
    onChange(newAcceptedFiles);
  };

  return (
    <Grid container direction="column">
      {filesSelected && filesSelected.length > 0 && (
        <Grid container className={classNames(isDragActive ? classes.onDrop : null)} spacing={4}>
          {filesSelected.map(({ name, categoryIds }, index) => (
            <Fragment key={index}>
              <Grid item xs={6} className={classes.cells}>
                <Grid container spacing={2} alignItems="center" wrap="nowrap">
                  <Grid item>
                    <DescriptionIcon className={classes.fileIcon} />
                  </Grid>
                  <Grid item className={classes.ellipsis}>
                    <Typography variant="body2" className={classes.ellipsis}>
                      {name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5} className={classes.cells}>
                <SelectField
                  value={categoryIds[0]}
                  label={<WrapRequiredLabel label={<FormattedMessage id="project.budget.invoice.register.form.documentType" />} />}
                  onChange={e => onChangeFileType(e.target.value, index)}
                >
                  {clientCategories.map(category => (
                    <MenuItem value={category.id} key={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={1} className={classes.cells}>
                <CloseIcon onClick={() => onRemoveFile(index)} style={{ cursor: 'pointer' }} />
              </Grid>
            </Fragment>
          ))}
        </Grid>
      )}
      <div {...getRootProps()}>
        <input {...getInputProps()} />

        {filesSelected && filesSelected.length > 0 ? (
          <Grid items className={classNames(classes.dragDropBoxSimple, isDragActive ? classes.onDrop : null)}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item>
                <CloudUpload />
              </Grid>
              <Grid item>
                <Typography>
                  <span className={classNames(classes.helpMsg, 'uploadLink')}>Seleccione el archivo</span> o arraste y suelte aquí
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <DefaultDragAndDropMsg isDragActive={isDragActive}></DefaultDragAndDropMsg>
        )}
      </div>
    </Grid>
  );
};

DefaultDragAndDropMsg.propTypes = {
  isDragActive: PropTypes.bool
};

DragAndDropFieldComponent.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func
};

export const DragAndDropField = connect(state => ({
  clientId: getCurrentUser(state).client_id
}))(DragAndDropFieldComponent);
