import { makeStyles } from '@material-ui/core';
import { lightThemeV2 } from '../../../../../settings/themes/lightThemeV2';
import { palette } from '../../../../../settings/themes/colors';

export const useStyles = makeStyles({
  cellWidthByLevel: {
    width: 142
  },
  cellWidthByItem: {
    width: 125
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: lightThemeV2.spacing(2),
    padding: lightThemeV2.spacing(4),
    color: palette.dimGray,
    '& h4': {
      color: palette.captudataBlue
    },
    '& .wrapper-actions': {
      display: 'flex',
      gap: lightThemeV2.spacing(2)
    },
    '& .content': {
      gap: lightThemeV2.spacing(4),
      '&>*:first-child': {
        maxWidth: 660,
        textAlign: 'justify',
        fontSize: 12
      }
    }
  },
  tipNote: {
    fontSize: 12
  },
  fieldsDivider: {
    marginTop: lightThemeV2.spacing(4),
    marginBottom: lightThemeV2.spacing(4)
  }
});
