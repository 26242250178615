import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { palette } from '../../settings/themes/colors';

const BreadCrumbStyles = makeStyles({
  root: {
    color: palette.cerulean,
    display: 'flex',

    '& a': {
      color: palette.cerulean,
      textDecoration: 'none'
    },
    '& p': {
      paddingRight: '5px'
    },
    '& > *:last-child > a': {
      color: palette.dimGray
    }
  }
});

const Breadcrumb = ({ items }) => {
  const classes = BreadCrumbStyles();

  return (
    <div className={classes.root}>
      {items.map((item, index) => (
        <Typography key={index}>
          {item.link ? <Link to={item.link}>{item.name}</Link> : item.name}
          {index < items.length - 1 ? ' / ' : ''}
        </Typography>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  classes: PropTypes.object,
  items: PropTypes.array,
  baseClass: PropTypes.string
};
export default Breadcrumb;
