import { alpha, makeStyles } from '@material-ui/core';
import { palette } from '../../settings/themes/colors';

const useDocumentsStyles = makeStyles(theme => ({
  /* TODO: Create a Card component for reusing code. Base on figma UI this will be in more contacts details components */
  card: {
    backgroundColor: palette.platinum,
    color: palette.dimGray,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1.5),
    boxShadow: 'none',
    fontSize: 14,
    border: `1px solid ${alpha(palette.dimGray, 0.2)}`,
    width: '100%',
    maxHeight: theme.spacing(8.5)
  },
  heading: {
    color: palette.dimGray,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  title: {
    fontWeight: 700,
    letterSpacing: '0.4px'
  },
  container: {
    maxHeight: 250,
    overflowY: 'scroll'
  },
  link: {
    color: palette.azure
  }
}));

export const useDocumentsClasses = () => useDocumentsStyles();
