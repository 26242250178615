import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

const CurrencyInput = ({ label, value, onChange, suffix, prefix, required, ...otherProps }) => {
  const materialUITextFieldProps = {
    fullWidth: true,
    label,
    size: 'small',
    InputLabelProps: { shrink: true },
    ...otherProps
  };

  return (
    <NumberFormat
      value={value}
      required={required === undefined ? true : required}
      name="currency"
      allowLeadingZeros
      thousandSeparator=","
      customInput={TextField}
      onChange={onChange}
      suffix={` ${suffix || ''}`}
      prefix={`${prefix || ''} `}
      {...materialUITextFieldProps}
    />
  );
};

CurrencyInput.propTypes = {
  label: PropTypes.any,
  onChange: PropTypes.func,
  value: PropTypes.any,
  suffix: PropTypes.string,
  prefix: PropTypes.string,
  required: PropTypes.bool
};

export default CurrencyInput;
