import React from 'react';
import { CardContent, Divider, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Card } from '../atoms/Card';
import { useBeneficiaryStyles } from '../../containers/Beneficiaries/index.styles';
import { CardSectionTitle } from '../molecules/CardSectionTitle';
import { ListItems } from './ListItems';
import { ItemRecord } from '../molecules/ItemRecord';
import { LinkButton } from '../atoms/LinkButton';
import { GroupNoteSection } from '../molecules/GroupNoteSection';
import { useRecordTransform } from '../../queries/utils';
import { useGetContactGroupRecordList } from '../../queries/queryContactGroups';

const styles = makeStyles({
  recordsEmptyList: {
    '&>div:first-child': {
      height: 'initial'
    }
  },
  recordsList: {
    maxHeight: '325px'
  }
});

const useActionRecordSection = () => styles();

export const GroupActionRecordSection = ({ groupId, userId, clientId }) => {
  const { messages: intlMessages } = useIntl();
  const beneficiaryClasses = useBeneficiaryStyles();
  const classes = useActionRecordSection();
  const { data: records = [] } = useGetContactGroupRecordList({ clientId, groupId });
  const { transformRecordToItemData } = useRecordTransform();
  const historyUI = (
    <CardContent>
      <CardSectionTitle
        title={intlMessages['group.section.recordsSubtitle']}
        actions={
          <LinkButton>
            <FormattedMessage id="beneficiaries.linkTimeline" />
          </LinkButton>
        }
      />
      <ListItems
        dataSource={transformRecordToItemData(records)}
        className={classNames(
          beneficiaryClasses.listContainer,
          classes.recordsEmptyList,
          records.length <= 0 ? classes.recordsEmptyList : ''
        )}
        component={ItemRecord}
        emptyMsg={intlMessages['beneficiaries.empty.records']}
      />
    </CardContent>
  );

  return (
    <Grid container direction={'column'}>
      <Typography variant={'h6'} className={beneficiaryClasses.heading}>
        {intlMessages['beneficiaries.section.actionRecords']}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Card variant={'stretch'} gap={2}>
            <GroupNoteSection groupId={groupId} userId={userId} />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant={'stretch'}>{historyUI}</Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

GroupActionRecordSection.propTypes = {
  groupId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired
};
