import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TableCell, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmationModal } from './ConfirmationModal';
import { palette } from '../../settings/themes/colors';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    padding: theme.spacing(3)
  },
  action: {
    color: palette.captudataBlue
  }
}));

const DeleteMemberRow = ({ action, contactName, groupName }) => {
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const classes = useStyles();
  const { messages: intlMessages } = useIntl();

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false);
  };

  const handleConfirmation = () => {
    action();
    handleConfirmationModalClose();
  };

  return (
    <TableCell align="left" padding="normal" id="delete">
      <IconButton aria-label="delete card" onClick={() => setConfirmationModalOpen(true)} className={classes.deleteIcon}>
        <CloseIcon className={classes.action} />
      </IconButton>
      <ConfirmationModal
        tittle={intlMessages['group.confirmDeleteTitle'].replace('{1}', contactName)}
        onConfirm={handleConfirmation}
        onCancel={handleConfirmationModalClose}
        onClose={handleConfirmationModalClose}
        open={confirmationModalOpen}
      >
        {intlMessages['group.confirmDeleteDescription'].replace('{1}', groupName)}
      </ConfirmationModal>
    </TableCell>
  );
};

DeleteMemberRow.propTypes = {
  action: PropTypes.func.isRequired,
  contactName: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired
};

export default DeleteMemberRow;
