import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_SHARED_INFO_BY_TOKEN,
  GET_SHARED_INFO_BY_TOKEN_SUCCESS,
  GET_SHARED_INFO_BY_TOKEN_FAIL,
  GET_SHARED_PROJECTS,
  GET_SHARED_PROJECTS_SUCCESS,
  GET_SHARED_PROJECTS_FAIL,
  GET_PROJECT_SHARED_PROJECTS,
  GET_PROJECT_SHARED_PROJECTS_SUCCESS,
  GET_PROJECT_SHARED_PROJECTS_FAIL,
  CREATE_SHARED_PROJECT,
  CREATE_SHARED_PROJECT_SUCCESS,
  CREATE_SHARED_PROJECT_FAIL,
  CREATE_PROJECT_DOCUMENT,
  CREATE_PROJECT_DOCUMENT_SUCCESS,
  CREATE_PROJECT_DOCUMENT_FAIL,
  GET_PROJECT_DOCUMENTS,
  GET_PROJECT_DOCUMENTS_SUCCESS,
  GET_PROJECT_DOCUMENTS_FAIL,
  EDIT_PROJECT_DOCUMENT,
  EDIT_PROJECT_DOCUMENT_SUCCESS,
  EDIT_PROJECT_DOCUMENT_FAIL,
  DOWNLOAD_PROJECT_DOCUMENT,
  DOWNLOAD_PROJECT_DOCUMENT_SUCCESS,
  DOWNLOAD_PROJECT_DOCUMENT_FAIL,
  DELETE_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT_SUCCESS,
  DELETE_PROJECT_DOCUMENT_FAIL,
  GET_FINANCIAL_PROGRESSES,
  GET_FINANCIAL_PROGRESSES_SUCCESS,
  GET_FINANCIAL_PROGRESSES_FAIL,
  GET_PHYSICAL_PROGRESSES,
  GET_PHYSICAL_PROGRESSES_SUCCESS,
  GET_PHYSICAL_PROGRESSES_FAIL,
  ADD_FINANCIAL_PROGRESS,
  ADD_PHYSICAL_PROGRESS,
  ADD_PHYSICAL_PROGRESS_SUCCESS,
  ADD_PHYSICAL_PROGRESS_FAIL,
  ADD_FINANCIAL_PROGRESS_FAIL,
  ADD_FINANCIAL_PROGRESS_SUCCESS
} from '../action-types';
import {
  addFinancialProgress,
  addPhysicalProgress,
  createProjectDocument,
  createSharedProject,
  deleteProjectDocument,
  downloadProjectDocument,
  editProjectDocument,
  getFinancialProgresses,
  getPhysicalProgresses,
  getProjectDocuments,
  getProjectSharedProjects,
  getSharedInfo,
  getSharedProjects
} from './helper';

export function* sharedInfoRequest() {
  yield takeEvery(GET_SHARED_INFO_BY_TOKEN, function*({ payload }) {
    const resp = yield call(getSharedInfo, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_SHARED_INFO_BY_TOKEN_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_SHARED_INFO_BY_TOKEN_FAIL
      });
    }
  });
}

export function* sharedProjectsRequest() {
  yield takeEvery(GET_SHARED_PROJECTS, function*({ payload }) {
    const resp = yield call(getSharedProjects, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_SHARED_PROJECTS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_SHARED_PROJECTS_FAIL
      });
    }
  });
}

export function* financialProgressesRequest() {
  yield takeEvery(GET_FINANCIAL_PROGRESSES, function*({ payload }) {
    const resp = yield call(getFinancialProgresses, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_FINANCIAL_PROGRESSES_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_FINANCIAL_PROGRESSES_FAIL
      });
    }
  });
}

export function* financialProgressRequest() {
  yield takeEvery(ADD_FINANCIAL_PROGRESS, function*({ payload }) {
    const resp = yield call(addFinancialProgress, payload);

    if (resp.status === 200) {
      yield put({
        type: ADD_FINANCIAL_PROGRESS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: ADD_FINANCIAL_PROGRESS_FAIL
      });
    }
  });
}

export function* physicalProgressesRequest() {
  yield takeEvery(GET_PHYSICAL_PROGRESSES, function*({ payload }) {
    const resp = yield call(getPhysicalProgresses, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_PHYSICAL_PROGRESSES_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PHYSICAL_PROGRESSES_FAIL
      });
    }
  });
}

export function* physicalProgressRequest() {
  yield takeEvery(ADD_PHYSICAL_PROGRESS, function*({ payload }) {
    const resp = yield call(addPhysicalProgress, payload);

    if (resp.status === 200) {
      yield put({
        type: ADD_PHYSICAL_PROGRESS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: ADD_PHYSICAL_PROGRESS_FAIL
      });
    }
  });
}
export function* projectSharedProjectsRequest() {
  yield takeEvery(GET_PROJECT_SHARED_PROJECTS, function*({ payload }) {
    const resp = yield call(getProjectSharedProjects, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_PROJECT_SHARED_PROJECTS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PROJECT_SHARED_PROJECTS_FAIL
      });
    }
  });
}

export function* createSharedProjectsRequest() {
  yield takeEvery(CREATE_SHARED_PROJECT, function*({ payload }) {
    const resp = yield call(createSharedProject, payload);

    if (resp.status === 200) {
      yield put({
        type: CREATE_SHARED_PROJECT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: CREATE_SHARED_PROJECT_FAIL
      });
    }
  });
}

export function* createDocumentProjectRequest() {
  yield takeEvery(CREATE_PROJECT_DOCUMENT, function*({ payload }) {
    const resp = yield call(createProjectDocument, payload);

    if (resp.status === 200) {
      yield put({
        type: CREATE_PROJECT_DOCUMENT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: CREATE_PROJECT_DOCUMENT_FAIL
      });
    }
  });
}

export function* editDocumentProjectRequest() {
  yield takeEvery(EDIT_PROJECT_DOCUMENT, function*({ payload }) {
    const resp = yield call(editProjectDocument, payload);

    if (resp.status === 200) {
      yield put({
        type: EDIT_PROJECT_DOCUMENT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: EDIT_PROJECT_DOCUMENT_FAIL
      });
    }
  });
}

export function* deleteDocumentProjectRequest() {
  yield takeEvery(DELETE_PROJECT_DOCUMENT, function*({ payload }) {
    const resp = yield call(deleteProjectDocument, payload);

    if (resp.status === 200) {
      yield put({
        type: DELETE_PROJECT_DOCUMENT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: DELETE_PROJECT_DOCUMENT_FAIL
      });
    }
  });
}

export function* downloadDocumentProjectRequest() {
  yield takeEvery(DOWNLOAD_PROJECT_DOCUMENT, function*({ payload }) {
    const resp = yield call(downloadProjectDocument, payload);

    if (resp.status === 200) {
      window.open(resp.url, '_blank');
      yield put({
        type: DOWNLOAD_PROJECT_DOCUMENT_SUCCESS
      });
    } else {
      yield put({
        type: DOWNLOAD_PROJECT_DOCUMENT_FAIL
      });
    }
  });
}

export function* projectDocumentsRequest() {
  yield takeEvery(GET_PROJECT_DOCUMENTS, function*({ payload }) {
    const resp = yield call(getProjectDocuments, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_PROJECT_DOCUMENTS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_PROJECT_DOCUMENTS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(sharedInfoRequest),
    fork(sharedProjectsRequest),
    fork(projectSharedProjectsRequest),
    fork(createSharedProjectsRequest),
    fork(createDocumentProjectRequest),
    fork(editDocumentProjectRequest),
    fork(deleteDocumentProjectRequest),
    fork(downloadDocumentProjectRequest),
    fork(projectDocumentsRequest),
    fork(financialProgressesRequest),
    fork(financialProgressRequest),
    fork(physicalProgressesRequest),
    fork(physicalProgressRequest)
  ]);
}
