import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';

export const useGetCountries = ({ clientId }) => {
  const queryKey = [{ clientId }, 'GET_countries'];
  const queryFn = async context => {
    const [{ clientId: contextClientId }] = context.queryKey;
    const result = await getApi().get(`/clients/${contextClientId}/countries`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetAdminDivisions = ({ countryId, level, parentId }) => {
  const queryKey = [{ countryId, level, parentId }];

  const queryFn = async context => {
    const [params] = context.queryKey;
    let path = `/countries/${params.countryId}/administrative_divisions?level=${params.level}`;

    if (params.parentId) {
      path += `&parent_id=${params.parentId}`;
    }
    const result = await getApi().get(path);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
