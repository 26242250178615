import React from 'react';
import { Box, styled } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Avatar } from '../atoms/Avatar';

const Root = styled(Box)({
  display: 'flex',
  '&>*': {
    marginRight: '-10px'
  }
});

export const MultiAvatar = ({ names = [] }) => (
  <Root>
    {names.map((name, index) => (
      <Avatar key={index} responsible={name} variant={index % 2 === 0 ? 'primary' : 'secondary'} />
    ))}
  </Root>
);

MultiAvatar.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string)
};
