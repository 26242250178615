import { assign, get } from 'lodash';
import { Map } from 'immutable';

import {
  GET_ADMIN_USERS,
  ADD_ADMIN_USERS,
  CREATE_ADMIN_USERS,
  UPDATE_ADMIN_USERS,
  DELETE_ADMIN_USERS,
  ADD_ADMIN_MODEL_USERS,
  GET_ADMIN_MODEL_USERS,
  INCLUDE_ADMIN_MODEL_USERS,
  REMOVE_ADMIN_MODEL_USERS,
  GET_ADMIN_CF_DEFINITIONS,
  ADD_ADMIN_CF_DEFINITIONS,
  ADD_ADMIN_USER_MODELS,
  GET_ADMIN_USER_MODELS,
  INCLUDE_ADMIN_USER_MODELS,
  REMOVE_ADMIN_USER_MODELS,
  ADD_ADMIN_MODEL_MODELS,
  GET_ADMIN_MODEL_MODELS,
  INCLUDE_ADMIN_MODEL_MODELS,
  REMOVE_ADMIN_MODEL_MODELS,
  ADMIN_USER_RESET_PASSWORD,
  ADMIN_USER_RESET_PASSWORD_SUCCESS,
  ADMIN_USER_RESET_PASSWORD_CLEAR,
  CREATE_ADMIN_ORG_GROUP,
  UPDATE_ADMIN_ORG_GROUP,
  CREATE_ADMIN_ORG_GROUP_SUCCESS,
  UPDATE_ADMIN_ORG_GROUP_SUCCESS,
  CREATE_ADMIN_ORG_GROUP_FAIL,
  UPDATE_ADMIN_ORG_GROUP_FAIL,
  GET_ADMIN_FORMULARIES,
  GET_ADMIN_FORMULARIES_SUCCESS,
  GET_ADMIN_FORMULARIES_FAIL,
  GET_ADMIN_SELECTED_PROJECT_SUCCESS,
  GET_ADMIN_SELECTED_PROJECT_FAIL,
  GET_ADMIN_SELECTED_PROJECT,
  GET_ADMIN_API_CREDENTIALS,
  GET_ADMIN_API_CREDENTIALS_SUCCESS,
  GET_ADMIN_API_CREDENTIALS_FAIL,
  RESET
} from '../action-types';

const initState = new Map({
  users: [],
  isLoading: false,
  modelUsers: {
    isLoading: false,
    projects: {},
    orgGroups: {},
    formularies: {}
  },
  modelModels: {
    isLoading: false,
    projects: {},
    formularies: {}
  },
  cfDefinitions: {
    isLoading: false,
    projectsLoaded: false,
    projects: [],
    orgGroupsLoaded: false,
    orgGroups: [],
    selectedProjectLoaded: false,
    selectedProject: null
  },
  userModels: {
    isLoading: false,
    users: {}
  },
  resetUserPassword: {
    isLoading: false,
    wasSuccess: false
  },
  orgGroups: {
    isLoading: false,
    lastRequest: ''
  },
  formularies: {
    isLoading: false,
    wasSuccess: false,
    formularies: []
  },
  APICredentials: {
    isLoading: false,
    wasSuccess: false,
    amazonS3Config: {}
  }
});

/**
 * Reducer
 *
 * @param {Object} state
 * @param {Object} action
 */
const reducer = (state = initState, action) => {
  switch (action.type) {
    case CREATE_ADMIN_USERS:
    case UPDATE_ADMIN_USERS:
    case DELETE_ADMIN_USERS:
    case GET_ADMIN_USERS:
    case GET_ADMIN_API_CREDENTIALS:
      return state.set('isLoading', true);
    case ADD_ADMIN_USERS:
      return state.set('isLoading', false).set('users', assign([], get(action, 'payload.data', [])));
    case ADD_ADMIN_MODEL_USERS:
      const modelUsers = state.get('modelUsers');
      const { users, type, id } = get(action, 'payload', {});
      const newTypeBase = {};
      const modelUsersBase = { ...modelUsers[type] };
      modelUsersBase[id] = users;
      newTypeBase[type] = modelUsersBase;

      return state.set(
        'modelUsers',
        assign(
          {},
          modelUsers,
          {
            isLoading: false
          },
          newTypeBase
        )
      );
    case GET_ADMIN_MODEL_USERS:
    case INCLUDE_ADMIN_MODEL_USERS:
    case REMOVE_ADMIN_MODEL_USERS:
      return state.set(
        'modelUsers',
        assign({}, state.get('modelUsers'), {
          isLoading: true
        })
      );
    case ADD_ADMIN_USER_MODELS:
      const userModels = state.get('userModels');
      const { models, type: userType, id: userId } = get(action, 'payload', {});
      const newUserBase = {};
      const userModelsBase = { ...userModels[userId] };
      userModelsBase[userType] = models;
      newUserBase[userId] = userModelsBase;

      return state.set(
        'userModels',
        assign(
          {},
          {
            users: {
              ...userModels.users,
              ...newUserBase
            },
            isLoading: false
          }
        )
      );
    case GET_ADMIN_USER_MODELS:
    case INCLUDE_ADMIN_USER_MODELS:
    case REMOVE_ADMIN_USER_MODELS:
      return state.set(
        'userModels',
        assign({}, state.get('userModels'), {
          isLoading: true
        })
      );
    case ADD_ADMIN_MODEL_MODELS:
      const modelModels = state.get('modelModels');
      const { destinyModels, sourceType, destinyType, sourceId } = get(action, 'payload', {});
      const newTypeModelBase = {};
      const modelModelsBase = { ...modelModels[sourceType] };
      if (!modelModelsBase[sourceId]) {
        modelModelsBase[sourceId] = {};
      }
      modelModelsBase[sourceId][destinyType] = destinyModels;
      newTypeModelBase[sourceType] = modelModelsBase;

      return state.set(
        'modelModels',
        assign(
          {},
          modelModels,
          {
            isLoading: false
          },
          newTypeModelBase
        )
      );
    case GET_ADMIN_MODEL_MODELS:
    case INCLUDE_ADMIN_MODEL_MODELS:
    case REMOVE_ADMIN_MODEL_MODELS:
      return state.set(
        'modelModels',
        assign({}, state.get('modelModels'), {
          isLoading: true
        })
      );
    case GET_ADMIN_CF_DEFINITIONS:
    case GET_ADMIN_SELECTED_PROJECT:
      return state.set(
        'cfDefinitions',
        assign({}, state.get('cfDefinitions'), {
          isLoading: true
        })
      );
    case ADD_ADMIN_CF_DEFINITIONS:
      const cfPayload = get(action, 'payload', {});
      const newCfDefinitions = {};
      newCfDefinitions[`${cfPayload.type}Loaded`] = true;
      newCfDefinitions[cfPayload.type] = cfPayload.data;

      return state.set(
        'cfDefinitions',
        assign(
          {},
          state.get('cfDefinitions'),
          {
            isLoading: false
          },
          newCfDefinitions
        )
      );
    case GET_ADMIN_SELECTED_PROJECT_SUCCESS:
      return state.set(
        'cfDefinitions',
        assign({}, state.get('cfDefinitions'), {
          isLoading: false,
          selectedProjectLoaded: true,
          selectedProject: get(action, 'payload', {}).data
        })
      );

    case GET_ADMIN_SELECTED_PROJECT_FAIL:
      return state.set(
        'cfDefinitions',
        assign({}, state.get('cfDefinitions'), {
          isLoading: false,
          selectedProjectLoaded: false,
          selectedProject: null
        })
      );
    case ADMIN_USER_RESET_PASSWORD:
      return state.set(
        'resetUserPassword',
        assign({}, state.get('resetUserPassword'), {
          isLoading: true
        })
      );
    case ADMIN_USER_RESET_PASSWORD_SUCCESS:
      const resetSuccessPayload = get(action, 'payload', {});
      return state.set(
        'resetUserPassword',
        assign({}, state.get('resetUserPassword'), {
          isLoading: false,
          wasSuccess: resetSuccessPayload.wasSuccess
        })
      );
    case ADMIN_USER_RESET_PASSWORD_CLEAR:
      return state.set(
        'resetUserPassword',
        assign({}, state.get('resetUserPassword'), {
          isLoading: false,
          wasSuccess: false
        })
      );
    case CREATE_ADMIN_ORG_GROUP:
    case UPDATE_ADMIN_ORG_GROUP:
      return state.set(
        'orgGroups',
        assign({}, state.get('orgGroups'), {
          isLoading: true,
          lastRequest: ''
        })
      );
    case CREATE_ADMIN_ORG_GROUP_SUCCESS:
    case UPDATE_ADMIN_ORG_GROUP_SUCCESS:
    case CREATE_ADMIN_ORG_GROUP_FAIL:
    case UPDATE_ADMIN_ORG_GROUP_FAIL:
      return state.set(
        'orgGroups',
        assign({}, state.get('orgGroups'), {
          isLoading: false,
          lastRequest: action.type
        })
      );
    case GET_ADMIN_FORMULARIES:
      return state.set(
        'formularies',
        assign({}, state.get('formularies'), {
          isLoading: true,
          wasSuccess: false
        })
      );
    case GET_ADMIN_FORMULARIES_SUCCESS:
      return state.set(
        'formularies',
        assign({}, state.get('formularies'), {
          isLoading: false,
          wasSuccess: true,
          formularies: get(action, 'payload', {}).formularies
        })
      );
    case GET_ADMIN_FORMULARIES_FAIL:
      return state.set(
        'formularies',
        assign({}, state.get('formularies'), {
          isLoading: false,
          wasSuccess: false,
          formularies: []
        })
      );
    case GET_ADMIN_API_CREDENTIALS_SUCCESS: {
      const { as3_access: secretAccessKey, as3_bucket: bucketName, as3_key_id: accessKeyId } = get(action, 'payload', {}).data || {};

      const amazonS3Config = {
        secretAccessKey,
        bucketName,
        accessKeyId,
        s3Url: `${process.env.REACT_APP_AS3_URL}${bucketName}`,
        region: process.env.REACT_APP_AS3_REGION
      };

      return state.set(
        'APICredentials',
        assign({}, state.get('APICredentials'), {
          isLoading: false,
          wasSuccess: true,
          amazonS3Config
        })
      );
    }
    case GET_ADMIN_API_CREDENTIALS_FAIL:
      return state.set(
        'APICredentials',
        assign({}, state.get('APICredentials'), {
          isLoading: false,
          wasSuccess: false,
          amazonS3Config: {}
        })
      );
    case RESET:
      return initState;
    default:
      return state;
  }
};

export default reducer;
