import React from 'react';
import { Grid, styled, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { palette } from '../../settings/themes/colors';

const Root = styled(Grid)({
  minHeight: 100,
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  color: palette.dimGray,
  gap: '10px'
});

export const EmptySection = ({ children }) => (
  <Root container>
    <AccessTimeIcon />
    <Typography>
      <strong>{children}</strong>
    </Typography>
  </Root>
);

EmptySection.propTypes = {
  children: PropTypes.any
};
