import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { WideContainer } from '../../components/atoms/WideContainer';
import { getCurrentUser } from '../../utils/auth';
import { useGetGroupById } from '../../queries/queryContactGroups';
import { GroupGeneralInformationSection } from '../../components/organims/GroupGeneralInformationSection';
import { GroupActionRecordSection } from '../../components/organims/GroupActionRecordSection';
import { GroupAssetsSection } from '../../components/organims/GroupAssetsSection';

const GroupDetailComponent = ({ currentUser, match, location }) => {
  const { groupId } = match.params;
  const { client_id: clientId, id: userId } = currentUser;
  const { data: group = {}, refetch, isLoading } = useGetGroupById({ clientId, groupId });

  useEffect(() => {
    const param = new URLSearchParams(location.search);
    const updated = param.get('updated');

    if (updated === 'true') {
      refetch();
    }
  }, []);

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent title={group.name} />
      <BodyContent component={WideContainer}>
        <GroupGeneralInformationSection group={group} />
        <GroupAssetsSection group={group} isLoading={isLoading} />
        <GroupActionRecordSection groupId={groupId} userId={userId} clientId={clientId} />
      </BodyContent>
    </MuiThemeProvider>
  );
};

GroupDetailComponent.propTypes = {
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired
};

export const GroupDetail = connect(state => ({ currentUser: getCurrentUser(state) }))(GroupDetailComponent);
