import React from 'react';
import PropTypes from 'prop-types';
import { InspectionDetailItem } from './InspectionDetailItem';

export const ProjectCustomFields = ({ customFields }) => {
  if (!customFields || !customFields.length) {
    return null;
  }

  return (
    <>
      {customFields.map((cf, index) => (
        <InspectionDetailItem label={cf.definition_name} value={cf.humanize_value} key={index} />
      ))}
    </>
  );
};

ProjectCustomFields.propTypes = {
  customFields: PropTypes.array.isRequired
};
