import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SelectField } from './SelectField';
import { useGetProfessions } from '../../queries/queryProfessions';

/**
 * Receive the MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ProfessionsSelector = props => {
  const { data: professions = [] } = useGetProfessions();

  return (
    <SelectField {...props}>
      {professions.map(({ id, name }, index) => (
        <MenuItem key={index} value={id}>
          {name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
