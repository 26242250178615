import { get, assign } from 'lodash';

import { Storage } from '../../../utils/storage';
import { getApi } from '../../../api';

const Auth = require('j-toker');

export const clearAuth = () => {
  Storage.clearSession();
};

export const setAuth = data => {
  Storage.setData('currentUser', {
    ...get(data, 'data')
  });

  Storage.setData('isLoggedIn', get(data, 'data.signedIn', true));
};

export const getAuth = () => Storage.getData('currentUser');

export const login = async data => {
  if (!data.payload.username || !data.payload.password) {
    return { error: 'please fill in the input' };
  }

  const params = {
    email: data.payload.username,
    password: data.payload.password
  };

  let resp = {};

  try {
    resp = await getApi().post('auth/sign_in', params);
  } catch (err) {
    console.log('Login error:', err);
  }

  return assign({}, resp, {
    data: get(resp, 'data.data'),
    history: get(data, 'payload.history')
  });
};

export const resetPassword = async email => {
  const resp = await getApi().post('auth/password', { email: email }, '');
  return resp;
};

export const setNewPassword = async ({ password, passwordConfirmation }) => {
  const resp = await getApi().put('auth/password', { password, password_confirmation: passwordConfirmation }, '');
  return resp;
};

export const checkAuth = async () => {
  let resp;

  try {
    resp = await Auth.validateToken();
  } catch (err) {
    console.log('Verification token has failed: ', err);
  }

  return resp;
};

export const getPermissions = async () => {
  let resp;

  try {
    resp = await getApi().get('users/logged_user');
  } catch (err) {
    console.log('Permissions failed: ', err);
  }

  return resp;
};
