import React, { useState } from 'react';
import { Button, Grid, MuiThemeProvider, Table, TableBody, TableHead, TableRow, Typography, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { palette } from '../../settings/themes/colors';
import { HeaderContent } from '../../components/molecules/HeaderContent';
import { BodyContent } from '../../components/molecules/BodyContent';
import { WideContainer } from '../../components/atoms/WideContainer';
import { HeadCell } from '../../components/molecules/Table/HeadCell';
import { BasicTableCell } from '../../components/molecules/Table/BasicTableCell';
import { COLUMNS_WIDTH_SETTINGS } from './components/project/Budget/constants';
import { ActionsCell } from '../../components/molecules/Table/ActionsCell';
import { ConfirmationModal } from '../../components/molecules/ConfirmationModal';
import { Avatar } from '../../components/atoms/Avatar';
import Breadcrumb from '../../components/breadcrumb';
import { useGetInvoicesByBudgetLine } from '../../queries/queryInvoices';
import { replaceStrings, SIMPLE_DASH_DATE_FORMAT } from '../../utils/utils';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { useGetProject } from '../../queries/queryProjects';
import { InvoiceModalForm } from './components/project/Budget/InvoiceModalForm/InvoiceModalForm';

const useStyles = makeStyles({
  root: {
    height: '70vh',
    '& tr:not(:last-child) > td': {
      borderBottom: `1px solid ${palette.darkGrayTable}`
    },
    '& tr:last-child > td': {
      padding: lightThemeV2.spacing(1)
    },
    '& tr:last-child > td:first-child': {
      borderBottomLeftRadius: lightThemeV2.spacing(1)
    },
    '& tr:last-child > td:last-child': {
      borderBottomRightRadius: lightThemeV2.spacing(1)
    }
  },
  dataCell: {
    backgroundColor: palette.white
  }
});

const COLUMNS_TYPES = {
  AVATAR: 'avatar',
  CONSECUTIVE: 'consecutive'
};

const getObjectValue = (object, key) => {
  const keyChildren = key.split('.');
  let value = object;

  for (let i = 0; i < keyChildren.length; i++) {
    value = value[keyChildren[i]];
  }

  return value;
};

const InvoicesComponent = ({ match, clientId }) => {
  const projectId = match.params.projectId;
  const budgetLineId = match.params.budgetLineId;
  const classes = useStyles();
  const { data: invoices = [] } = useGetInvoicesByBudgetLine(budgetLineId);
  const {
    data: project = {
      main_contract: {
        contractor: {
          name: 'waiting for main contract'
        }
      }
    }
  } = useGetProject({ clientId, projectId });
  const { messages: intlMessages } = useIntl();

  const [editModalSettings, setEditModalSettings] = useState({
    isOpen: false,
    onClose: () => console.log('Close edit modal'),
    taskName: 'Editando factura - mock',
    budgetLineName: 'Actividad 1 - mock',
    projectId: 82578,
    executors: []
  });

  const [invoiceIdToDelete, setInvoiceIdToDelete] = useState();

  const handleButtonClick = () => {};

  const columnDefs = [
    {
      key: 'createdAt',
      intl: 'project.budget.invoice.list.column.creationDate',
      formatValue: value => moment(value).format(SIMPLE_DASH_DATE_FORMAT),
      sort: true
    },
    {
      key: 'effectiveDate',
      intl: 'project.budget.invoice.list.column.billingDate',
      sort: true
    },
    {
      key: 'number',
      intl: 'project.budget.invoice.register.form.label.consecutive',
      type: COLUMNS_TYPES.CONSECUTIVE,
      sort: true
    },
    {
      key: 'formattedTotal',
      intl: 'common.amount',
      sort: true
    },
    {
      key: 'concept',
      intl: 'common.concept',
      sort: true
    },
    /* {
       key: 'executor',
       intl: 'project.budget.invoice.register.form.label.executor',
       sort: true
     },*/
    {
      key: 'supplier.firstName',
      intl: 'project.budget.invoice.register.form.label.supplier',
      sort: true
    }
    /* {
       key: 'responsible',
       intl: 'common.responsible',
       type: COLUMNS_TYPES.AVATAR,
       sort: true
     }*/
  ];

  const breadscrumb = [
    {
      name: project.main_contract.contractor.name,
      link: '/'
    },
    {
      name: `${intlMessages['common.project']}s`,
      link: '/projects'
    },
    {
      name: project.name || 'waiting for project name',
      link: `/projects/${projectId}`
    },
    {
      name: intlMessages['project.budget'],
      link: `/projects/${projectId}?tab=7`
    },
    {
      name: intlMessages['common.invoices'],
      link: `/projects/${projectId}/budgets/invoices`
    }
  ];

  return (
    <MuiThemeProvider theme={lightThemeV2}>
      <HeaderContent title={<FormattedMessage id="common.invoices" />} breadcrumb={<Breadcrumb items={breadscrumb} />}>
        {/* <Button variant="contained" onClick={handleButtonClick}>
          <Typography variant="button">
            <FormattedMessage id="project.budget.invoice.register.title" />
          </Typography>
        </Button> */}
      </HeaderContent>
      <BodyContent component={WideContainer}>
        <div className={classes.root}>
          <Table>
            <TableHead>
              <TableRow>
                {columnDefs.map(({ intl }, index) => (
                  <HeadCell key={index}>
                    <Typography>
                      <FormattedMessage id={intl} />
                    </Typography>
                  </HeadCell>
                ))}

                {/*    <HeadCell width={COLUMNS_WIDTH_SETTINGS.actionCell} align="right">
                  <Typography>
                    <FormattedMessage id="common.actions" />
                  </Typography>
                </HeadCell> */}
              </TableRow>
            </TableHead>

            <TableBody>
              {invoices.length === 0 && (
                <TableRow>
                  <BasicTableCell colSpan={columnDefs.length + 1} align="center" className={classes.dataCell}>
                    <FormattedMessage id="common.emptyRow" />
                  </BasicTableCell>
                </TableRow>
              )}
              {invoices.map((invoice, indexRow) => (
                <TableRow key={indexRow}>
                  {columnDefs.map(({ key, type, formatValue }, indexCol) => (
                    <BasicTableCell className={classes.dataCell} key={indexCol}>
                      {!type && (
                        <Typography>{formatValue ? formatValue(getObjectValue(invoice, key)) : getObjectValue(invoice, key)}</Typography>
                      )}
                      {type === COLUMNS_TYPES.AVATAR && (
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Avatar responsible={getObjectValue(invoice, key)} size="small" />
                        </Grid>
                      )}
                      {type === COLUMNS_TYPES.CONSECUTIVE && <Typography>#{getObjectValue(invoice, key)}</Typography>}
                    </BasicTableCell>
                  ))}
                  {/* <ActionsCell
                    className={classes.dataCell}
                    actions={[
                      {
                        onclick: () => setEditModalSettings({ ...editModalSettings, isOpen: true }),
                        tooltip: <FormattedMessage id="project.budget.invoice.list.column.actionEdit" />,
                        children: <EditIcon className={classes.colorIcons} />
                      },
                      {
                        onclick: () => setInvoiceIdToDelete(invoice.id),
                        tooltip: <FormattedMessage id="project.budget.invoice.list.column.actionDelete" />,
                        children: <DeleteIcon className={classes.colorIcons} />
                      }
                    ]}
                  ></ActionsCell> */}
                </TableRow>
              ))}
              <TableRow>
                <BasicTableCell colSpan={columnDefs.length + 1} className={classes.dataCell}></BasicTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </BodyContent>

      {editModalSettings.isOpen && <InvoiceModalForm {...editModalSettings} />}

      <ConfirmationModal
        onConfirm={() => setInvoiceIdToDelete(false)}
        onCancel={() => setInvoiceIdToDelete(false)}
        hideCloseAndFooter
        open={invoiceIdToDelete}
        confirmText={<FormattedMessage id="project.budget.invoice.list.column.actionDelete" />}
        tittle={replaceStrings(intlMessages['project.budget.invoice.delete.confirmationMsg'], [
          invoiceIdToDelete,
          'Actividad 1.1.1.1  Staff and other personnel'
        ])}
      >
        <Typography>
          <FormattedMessage id="project.budget.invoice.confirmationMsg" />
        </Typography>
      </ConfirmationModal>
    </MuiThemeProvider>
  );
};

InvoicesComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      projectId: PropTypes.number,
      budgetLineId: PropTypes.number
    })
  }),
  clientId: PropTypes.number
};

const mapToProps = state => ({ clientId: getCurrentUser(state).client_id });

export const Invoices = connect(mapToProps)(InvoicesComponent);
