import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useInspectionDetailItemStyles } from './InspectionDetailItem.styles';

export const InspectionDetailItem = ({ label, value }) => {
  const classes = useInspectionDetailItemStyles();
  return (
    <Grid container justifyContent="space-between" wrap={'nowrap'} classes={classes}>
      <Typography>{label}</Typography>
      {React.isValidElement(value) ? value : <Typography>{value}</Typography>}
    </Grid>
  );
};
