import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Button from '../../../../components/button';
import { Tooltip } from '@material-ui/core';
import React from 'react';

export const ButtonInspection = ({ project }) => {
  const { last_inspected_at } = project;

  if (last_inspected_at) {
    return (
      <Link to={`/operative/projects/${project.id}`} style={{textAlign: "center", margin: "0.5em 0"}}>
        <FormattedMessage id="programs.inspection.viewLastInspection">
          {msg => (
            <Button color="primary" variant="contained">
              {msg[0]}
            </Button>
          )}
        </FormattedMessage>
      </Link>
    );
  }

  return (
    <FormattedMessage id="programs.inspection.noInspections">
      {msgTooltip => (
        <Tooltip title={msgTooltip[0]} interactive>
          <span>
            <FormattedMessage id="programs.inspection.viewLastInspection">
              {msg => (
                <Button color="primary" disabled variant="contained">
                  {msg[0]}
                </Button>
              )}
            </FormattedMessage>
          </span>
        </Tooltip>
      )}
    </FormattedMessage>
  );
};

ButtonInspection.propTypes = {
  project: PropTypes.object.isRequired
};
