import { find } from 'lodash';
import theme from '../../../settings/themes/default';

/**
 * Threshold definition for project's physical progress
 */
export const progressLevels = [
  {
    label: 'Todas',
    id: 'programs.inspection.progressLevels.all'
  },
  {
    progress: 100,
    comparison: '=',
    label: '100%',
    colorKey: 'HIGHEST',
    id: 'programs.inspection.progressLevels.100'
  },
  {
    progress: 75,
    comparison: '>',
    label: '+ del 75%',
    colorKey: 'HIGH',
    id: 'programs.inspection.progressLevels.75'
  },
  {
    progress: 50,
    comparison: '>',
    label: '+ del 50%',
    colorKey: 'MEDIUM_HIGH',
    id: 'programs.inspection.progressLevels.50'
  },
  {
    progress: 25,
    comparison: '>',
    label: '+ del 25%',
    colorKey: 'MEDIUM_LOW',
    id: 'programs.inspection.progressLevels.25'
  },
  {
    progress: 0,
    comparison: '>',
    label: '+ del 0%',
    colorKey: 'LOW',
    id: 'programs.inspection.progressLevels.moreThan0'
  },
  {
    progress: 0,
    comparison: '=',
    label: '0%',
    colorKey: 'LOWEST',
    id: 'programs.inspection.progressLevels.0'
  }
];

/**
 * Returns color key according to project physical progress
 *
 * @param {Object} project
 * @return {string} color hex
 */
export const getProjectColorKey = project => {
  const { main_contract } = project;

  if (!main_contract || (!main_contract.physical_progress && main_contract.physical_progress !== 0)) {
    return '';
  }

  const physical_progress = parseFloat(main_contract.physical_progress);

  return find(progressLevels, level => {
    return (
      (level.comparison === '=' && physical_progress === level.progress) ||
      (level.comparison === '>' && physical_progress > level.progress) ||
      (level.comparison === '>=' && physical_progress >= level.progress)
    );
  }).colorKey;
};

/**
 * Returns color according to project physical progress
 *
 * @param {Object} project
 * @return {string} color hex
 */
export const getProjectColor = project => theme.palette.progressLevel[getProjectColorKey(project)];
