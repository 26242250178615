import React from 'react';
import { List as ListMui, makeStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { ItemNote } from '../molecules/ItemNote';
import { EmptySection } from '../molecules/EmptySection';

const styles = makeStyles(theme => ({
  root: {
    '&>*': {
      marginBottom: theme.spacing(2),
      '&:last-child': {
        marginBottom: 0
      }
    }
  }
}));
const useListStyles = () => styles();

export const ListItems = ({ dataSource = [], component: Component = ItemNote, emptyMsg = '', className, maxHeight }) => {
  const classes = useListStyles();
  const overrideStyle = maxHeight && { maxHeight };

  return (
    <ListMui className={classNames(classes.root, className)} style={overrideStyle}>
      {dataSource.length < 1 && <EmptySection>{emptyMsg}</EmptySection>}
      {dataSource.map((item, id) => (
        <Component key={id} {...item} />
      ))}
    </ListMui>
  );
};

ListItems.propTypes = {
  dataSource: PropTypes.array,
  component: PropTypes.elementType,
  emptyMsg: PropTypes.string,
  className: PropTypes.string,
  maxHeight: PropTypes.string
};
