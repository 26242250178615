import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';
import { useStyles } from './useStyles';

export const VARIANT_HEAD_CELL = {
  default: 'default',
  secondary: 'secondary'
};

export const HeadCell = ({ children, variant = VARIANT_HEAD_CELL.default, classNames, ...others }) => {
  const classes = useStyles();

  return (
    <TableCell
      className={classnames(
        classes.headerCellPrimary,
        variant === VARIANT_HEAD_CELL.secondary ? classes.headerCellSecondary : undefined,
        classNames
      )}
      {...others}
    >
      {children}
    </TableCell>
  );
};

HeadCell.propTypes = {
  variant: PropTypes.oneOf(Object.values(VARIANT_HEAD_CELL)),
  children: PropTypes.any,
  classNames: PropTypes.string
};
