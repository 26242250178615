import { split, forEach, get, assign, isEmpty } from 'lodash';
import classNames from 'classnames';

export const getClassNames = props => {
  const { classes } = props;

  const clsNames = split(get(props, 'className', null), ' ');

  if (!clsNames) {
    return '';
  }

  let clsNamesObj = {};

  forEach(clsNames, cls => {
    if (get(classes, cls, null)) {
      clsNamesObj = assign({}, clsNamesObj, {
        [get(classes, cls)]: true
      });
    } else {
      clsNamesObj = assign({}, clsNamesObj, {
        [cls]: true
      });
    }
  });

  if (!clsNamesObj || isEmpty(clsNamesObj)) {
    return '';
  }

  return classNames(clsNamesObj);
};
