import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Checkbox, InputAdornment } from '@material-ui/core';
import { dateFormatOptions as dateOptions } from '../constants';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';

export const NumberFormatCustom = props => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export const CFEditComponent = ({ cfTypes, customValue, ...rest }) => {
  const type = getCFType(cfTypes, customValue);

  let component = null;
  switch (type.name) {
    // Integer
    case 'Integer':
    case 'Hour': {
      component = <TextField {...rest} type="number" step={1} min={0} />;
      break;
    }
    // Float
    case 'Percentage': {
      component = (
        <TextField
          {...rest}
          type="number"
          step={0.01}
          min={0}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            ...rest.InputProps
          }}
        />
      );
      break;
    }
    case 'Float': {
      component = <TextField {...rest} step={0.01} min={0} />;
      break;
    }
    // Datetime
    case 'Datetime': {
      //component = (<TextField {...rest} type="datetime-local"/>);
      component = <TextField {...rest} type="date" />;
      break;
    }
    // Date
    case 'Date': {
      component = <TextField {...rest} type="date" />;
      break;
    }
    // Boolean
    case 'Boolean': {
      component = <Checkbox {...rest} />;
      break;
    }
    // String
    default: {
      component = <TextField {...rest} />;
      break;
    }
  }
  return component;
};

// Parse value for component use
export const parseValue = (cfTypes, customValue) => {
  const type = getCFType(cfTypes, customValue);
  let value = null;
  switch (type.name) {
    // Datetime
    case 'Datetime': {
      //value = customValue.value.replace(' ', 'T').replace(/\//g, '-');
      value = !!customValue.value
        ? customValue.value
            .replace(/\s.*/, '')
            .replace(/\//g, '-')
            .replace(/T.*/, '')
        : '-';
      break;
    }
    // Date
    case 'Date': {
      value = !!customValue.value ? customValue.value.replace(' 00:00:00', '').replace(/\//g, '-') : '-';
      break;
    }
    // String
    default: {
      value = customValue.value;
      break;
    }
  }
  return value;
};

// Format value for user view
export const formatValue = (cfTypes, customValue, unit) => {
  const type = getCFType(cfTypes, customValue);
  let value = null;
  switch (type.name) {
    // Datetime
    case 'Datetime': {
      //value = customValue.value.replace('T', ' ').replace(/-/g, '/');
      value = !!customValue.value
        ? customValue.value
            .replace('T', ' ')
            .replace(/-/g, '/')
            .replace(/\s.*/, '')
        : '-';
      break;
    }
    // Date
    case 'Date': {
      value = getDateFormat(customValue.value);
      break;
    }
    // Boolean
    case 'Boolean': {
      value =
        customValue.value.toString() === 'true' ? (
          <FormattedMessage id="system.actions.yes" defaultMessage="Si" />
        ) : (
          <FormattedMessage id="system.actions.no" defaultMessage="No" />
        );
      break;
    }
    // String
    default: {
      value = customValue.value;
      break;
    }
  }
  return !!unit ? `${value}${unit}` : value;
};

/* Private
	Auxiliar function to get the custom field Type 
*/
const getCFType = (cfTypes, customValue) => cfTypes.find(type => type.id === customValue.definition_type_id);

export const getDateFormat = value => (!!value ? new Date(value).toLocaleDateString('ja-JP', dateOptions) : '-');

export const getCheckedArrayIds = array => {
  let arrayResult = [];
  array.map(checkItem => {
    if (checkItem.checked) {
      arrayResult.push(checkItem.id);
    }
  });
  return arrayResult;
};
