import React from 'react';
import PropTypes from 'prop-types';
import ExpandLess from '@material-ui/icons/ExpandLess';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';

export const BeneficiariesTableHead = ({
  classes,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  columns,
  variant
}) => {
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={`${classes.table}_header`}>
      <TableRow>
        {variant === 'checkbox' && (
          <TableCell padding={variant} className={`${classes.table}_header_cell`}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
              className={classes.checkbox}
            />
          </TableCell>
        )}
        {columns.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`${classes.table}_header_cell`}
          >
            {!headCell.disableSort && (
              <TableSortLabel
                classes={{
                  root: classes.sortLabel,
                  active: classes.activeHead,
                  icon: orderBy === headCell.id ? `${classes.icon}_active` : `${classes.icon}_inactive`
                }}
                IconComponent={orderBy === headCell.id ? ExpandLess : UnfoldMore}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id && (
                  <Typography component="span" className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Typography>
                )}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

BeneficiariesTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  rowCount: PropTypes.number.isRequired,
  variant: PropTypes.string.isRequired
};
