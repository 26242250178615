import React from 'react';
import { MenuItem } from '@material-ui/core';
import { SelectField } from './SelectField';
import { useGetIndustries } from '../../queries/queryIndustries';

export const IndustrySelector = props => {
  const { data: industries = [] } = useGetIndustries();

  return (
    <SelectField {...props}>
      {industries.map(({ id, name }, index) => (
        <MenuItem key={index} value={id}>
          {name}
        </MenuItem>
      ))}
    </SelectField>
  );
};
