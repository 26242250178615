import { useMutation, useQuery } from '@tanstack/react-query';
import { isArray, isBoolean, isNumber, isString } from 'lodash';
import { getApi } from '../api';
import { transformCamel2Snake, transformSnake2Camel } from './utils';

export const useGetContactGroups = ({ clientId, params }) => {
  const queryKey = [
    {
      clientId,
      params
    },
    'GET_contact_groups'
  ];

  const queryFn = async context => {
    const [{ clientId, params }] = context.queryKey;

    const contactParams = {
      ...params
    };

    const definedParams = {};

    Object.keys(contactParams)
      .filter(k => {
        const value = contactParams[k];
        const isDefinedNumber = isNumber(value);
        const isDefinedBoolean = isBoolean(value);
        const isDefinedArray = isArray(value);
        const isFilledString = isString(value) && value !== '';

        return isDefinedNumber || isDefinedBoolean || isFilledString || isDefinedArray;
      })
      .forEach(k => {
        definedParams[k] = contactParams[k];
      });

    const result = await getApi().get(`/clients/${clientId}/contact_groups`, { params: definedParams });

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetGroupTypes = ({ clientId }) => {
  const queryKey = [
    {
      clientId
    },
    'GET_group_types'
  ];

  const queryFn = async context => {
    const [{ clientId }] = context.queryKey;
    const result = await getApi().get(`/clients/${clientId}/contact_group_types`);

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateContactGroup = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ clientId, data }) =>
      getApi()
        .post(`clients/${clientId}/contact_groups`, { contact_group: transformCamel2Snake(data) })
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useGetGroupById = ({ clientId, groupId }) => {
  const queryKey = [{ clientId, groupId }, 'GET_groupById'];

  const queryFn = async context => {
    const [{ clientId, groupId }] = context.queryKey;
    const resultGroup = await getApi().get(`/clients/${clientId}/contact_groups/${groupId}`);

    return transformSnake2Camel(resultGroup.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useUploadGroupDocument = options =>
  useMutation(({ groupId, file }) => {
    const formData = new FormData();
    const document = { file, name: file.name };

    Object.entries(document).forEach(([key, value]) => {
      formData.append(`document[${key}]`, value);
    });

    return getApi()
      .post(`contact_groups/${groupId}/documents`, formData, {
        headers: { 'Access-Control-Expose-Headers': 'Content-Disposition', 'Content-Type': 'multipart/form-data' }
      })
      .then(axiosResult => axiosResult.data);
  }, options);

export const useDownloadGroupDocument = () =>
  useMutation(({ groupId, attachedDocument }) =>
    getApi()
      .get(`/contact_groups/${groupId}/documents/${attachedDocument.id}/download`, {
        responseType: 'blob'
      })
      .then(response => {
        if (!response.status === 200) {
          throw new Error('Network response was not ok');
        }
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', attachedDocument.name);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
  );

export const useDeleteGroupDocument = options =>
  useMutation(
    ({ groupId, documentId }) =>
      getApi()
        .delete(`/contact_groups/${groupId}/documents/${documentId}`)
        .then(axiosResult => axiosResult.data),
    options
  );

export const useGetContactGroupDocuments = ({ groupId, params }) => {
  const queryKey = [
    {
      groupId,
      params
    },
    'GET_contact_groups'
  ];

  const queryFn = async context => {
    const [{ groupId, params }] = context.queryKey;

    const contactParams = {
      ...params
    };

    const definedParams = {};

    Object.keys(contactParams)
      .filter(k => {
        const value = contactParams[k];
        const isDefinedNumber = isNumber(value);
        const isDefinedBoolean = isBoolean(value);
        const isDefinedArray = isArray(value);
        const isFilledString = isString(value) && value !== '';

        return isDefinedNumber || isDefinedBoolean || isFilledString || isDefinedArray;
      })
      .forEach(k => {
        definedParams[k] = contactParams[k];
      });

    const result = await getApi().get(`/contact_groups/${groupId}/documents`, { params: definedParams });

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    enabled: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetGroupProjects = ({ groupId, params }) => {
  const queryKey = [{ groupId, params }, 'GET_groupProjects'];
  const queryFn = async context => {
    const [{ groupId: contextGroupId, params: contextParams }] = context.queryKey;
    const result = await getApi().get(`/contact_groups/${contextGroupId}/projects`, contextParams);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useAssignProjectToGroup = options =>
  useMutation(
    ({ clientId, groupId, projectIds }) =>
      getApi()
        .post(`/clients/${clientId}/contact_groups/${groupId}/assign_to_projects`, { project_ids: projectIds })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useUnassignProjectToGroup = options =>
  useMutation(
    ({ clientId, groupId, projectIds }) =>
      getApi()
        .post(`/clients/${clientId}/contact_groups/${groupId}/unassign_to_projects`, { project_ids: projectIds })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useGetContactGroupNotes = ({ groupId }) => {
  const queryKey = [{ groupId }, 'GET_contactGroupNotes'];
  const queryFn = async context => {
    const [{ groupId }] = context.queryKey;
    const result = await getApi().get(`/contact_groups/${groupId}/notes`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateContactGroupNote = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ groupId, creatorId, note }) =>
      getApi()
        .post(`/contact_groups/${groupId}/notes`, { note: { creator_id: creatorId, text: note } })
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useDeleteContactGroupNote = ({ onSuccess = () => {} }) =>
  useMutation(
    ({ groupId, id }) =>
      getApi()
        .delete(`/contact_groups/${groupId}/notes/${id}`)
        .then(axiosResult => axiosResult.data),
    { onSuccess }
  );

export const useUpdateContactGroupNote = ({ onSuccess = () => {} }) => {
  const updateBeneficiaryMutation = useMutation(
    ({ groupId, creatorId, note, id }) =>
      getApi().put(`/contact_groups/${groupId}/notes/${id}`, {
        note: { creator_id: creatorId, text: note }
      }),
    { onSuccess }
  );

  const { data, ...mutation } = updateBeneficiaryMutation;

  return { data: data ? data.data : undefined, ...mutation };
};

export const useGetContactGroupRecordList = ({ clientId, groupId }) => {
  const queryKey = [{ clientId, groupId }, 'GET_contactGroupRecordList'];
  const queryFn = async context => {
    const [{ clientId: contextClientId, groupId: contextGroupId }] = context.queryKey;
    const result = await getApi().get(`/clients/${contextClientId}/contact_groups/${contextGroupId}/history`);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useUpdateContactGroup = ({ onSuccess = () => {} }) => {
  const updateContactGroupMutation = useMutation(
    ({ clientId, groupId, data }) =>
      getApi().put(`/clients/${clientId}/contact_groups/${groupId}`, {
        contact_group: transformCamel2Snake(data)
      }),
    { onSuccess }
  );

  const { data, ...mutation } = updateContactGroupMutation;

  return { data: data ? data.data : undefined, ...mutation };
};
