import React, { useMemo } from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { useIntl } from 'react-intl';

export const SelectField = ({ label, required, error, helperText, children, customSettings, ...props }) => {
  const { messages: intlMessages } = useIntl();
  const customizedItems = useMemo(() => {
    const { keys: itemsKeys = [], translationSuffix = '' } = customSettings || {};

    if (itemsKeys.length === 0) return;

    return itemsKeys.map((key, index) => (
      <MenuItem key={index} value={key}>
        {intlMessages[`${translationSuffix}${key}`]}
      </MenuItem>
    ));
  }, [customSettings]);

  return (
    <FormControl variant="outlined" required={required}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} {...props}>
        {customizedItems || children}
      </Select>
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

SelectField.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  customSettings: PropTypes.shape({
    keys: PropTypes.arrayOf(PropTypes.string),
    translationSuffix: PropTypes.string
  })
};
