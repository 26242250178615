import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { actionCreators } from '../ducks/reducer';
import {
  Container,
  Button,
  Box,
  withStyles,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  FormLabel
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { setResetHeaders } from '../../../utils/auth';

// Load images
import capLogo from '../../../assets/images/logo.svg';

// Styles
import styles from './styles';

const ResetPassword = ({ classes, history, setNewPassword, ...props }) => {
  const params = `{"${decodeURI(history.location.search.slice(1))
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')}"}`;

  const useQuery = () => {
    const headers = params !== '{""}' ? JSON.parse(params) : {};
    if (!!headers['uid']) {
      headers['uid'] = decodeURIComponent(headers['uid']);
      setResetHeaders(headers);
    }
  };
  useQuery();

  const [resultMessage, setResultMessage] = useState({
    isVisible: false,
    tittle: '',
    message: ''
  });
  const [password, setPassword] = useState('');
  const [confirmation, setConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const MIN_LENGTH = 8;
  const passwordIsValid = (pwd1, pwd2) => {
    let validationResult = false;
    let validationMessage = '';
    if (!pwd1) {
      validationMessage = 'Password not provided';
    } else if (!pwd2) {
      validationMessage = 'Password confirmation not provided';
    } else if (pwd1 !== pwd2) {
      validationMessage = 'Password confirmation do not match';
    } else if (pwd1.length < MIN_LENGTH) {
      validationMessage = 'Password must be ' + MIN_LENGTH + ' characters or longer';
    } else {
      validationResult = true;
    }
    setResultMessage({
      isVisible: !validationResult,
      tittle: validationResult ? '' : 'Invalid password',
      message: validationMessage
    });
    return validationResult;
  };

  const submitChangePassword = () => {
    if (passwordIsValid(password, confirmation)) {
      setNewPassword(password, confirmation);
      history.replace('/login');
    }
  };

  const renderSetPassword = classes => {
    return (
      <Grid container alignItems="center" justify="center" style={{ marginTop: '150px' }}>
        <Grid item className={`${classes.root}__wrap`}>
          <Paper className={`${classes.root}__form`} elevation={0}>
            <FormControl className={`${classes.root}__input`}>
              <InputLabel htmlFor="adornmentPassword" className={`${classes.root}__input__label`}>
                <FormattedMessage id="login.resetPassword.newPassword" defaultMessage="New Password" />
              </InputLabel>
              <Input
                id="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <FormControl className={`${classes.root}__input`}>
              <InputLabel htmlFor="adornmentPassword" className={`${classes.root}__input__label`}>
                <FormattedMessage id="login.resetPassword.passwordConfirmation" defaultMessage="Password Confirmation" />
              </InputLabel>
              <Input
                id="confirmation"
                type={showPassword1 ? 'text' : 'password'}
                value={confirmation}
                onChange={e => setConfirmation(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={() => setShowPassword1(!showPassword1)}
                      onMouseDown={e => e.preventDefault()}
                    >
                      {showPassword1 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {resultMessage.isVisible && <FormLabel error>{resultMessage.message}</FormLabel>}

            <Button color="primary" variant="contained" className={`${classes.root}__submit`} onClick={submitChangePassword}>
              {<FormattedMessage id="login.resetPassword.changePassword" defaultMessage="Change Password" />}
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <AppBar position="static" className={`${classes.root}__header`}>
        <Toolbar className={`${classes.root}__header__toolbar`}>
          <img src={capLogo} alt="Logo" className={`${classes.root}__logo--cap`} />
        </Toolbar>
      </AppBar>
      {renderSetPassword(classes)}
    </Fragment>
  );
};

// Redux connection
export default connect(state => ({}), {
  setNewPassword: actionCreators.setNewPassword
})(withStyles(styles)(ResetPassword));
