import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Typography } from '@material-ui/core';
import { useAssignProjectToBeneficiary } from '../../queries/queryBeneficiaries';

export const AssignProjectToBeneficiaryButton = ({
  variant = 'contained',
  projects,
  beneficiary,
  disabled,
  onClose,
  refetchProjectBeneficiaries
}) => {
  const { id: beneficiaryId, clientId } = beneficiary || {};
  const onSuccess = () => {
    refetchProjectBeneficiaries();
    onClose();
  };
  const { mutate: AssignProjectToBeneficiary, isLoading } = useAssignProjectToBeneficiary({ onSuccess });
  const projectIds = projects.map(p => p.id);
  const { messages: intlMessages } = useIntl();
  const handleButtonClick = () => {
    AssignProjectToBeneficiary({
      clientId,
      beneficiaryId,
      projectIds
    });
  };

  return (
    <Button variant={variant} onClick={handleButtonClick} disabled={disabled || isLoading}>
      <Typography variant="button">{intlMessages['beneficiaries.associateProject']}</Typography>
    </Button>
  );
};

AssignProjectToBeneficiaryButton.propTypes = {
  variant: PropTypes.string,
  projects: PropTypes.array,
  beneficiary: PropTypes.object,
  disabled: PropTypes.bool,
  onClose: PropTypes.func,
  refetchProjectBeneficiaries: PropTypes.func
};
