import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';

export const useGetClientCategories = ({ clientId }) => {
  const queryKey = [{ clientId }, 'GET_client_categories'];

  const queryFn = async context => {
    const [{ clientId: contextClientId }] = context.queryKey;
    const result = await getApi().get(`/clients/${contextClientId}/categories`);

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
