import React from 'react';
import { MenuItem } from '@material-ui/core';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../utils/auth';
import { SelectField } from './SelectField';
import { useGetGroupTypes } from '../../queries/queryContactGroups';

/**
 * Receive the MuiSelector props
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GroupTypesSelectorComponent = props => {
  const { currentUser } = props;
  const { client_id: clientId } = currentUser || {};
  const { data: groupTypes = [] } = useGetGroupTypes({ clientId });

  return (
    <SelectField {...props}>
      {groupTypes.map(({ id, name }, index) => (
        <MenuItem key={index} value={id}>
          {name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
});

GroupTypesSelectorComponent.propTypes = {
  currentUser: PropTypes.object
};

export const GroupTypesSelector = withRouter(connect(mapStateToProps, {})(GroupTypesSelectorComponent));
