import { get } from 'lodash';

import { apiConfig } from '../settings';
import { Storage } from '../utils/storage';

const Auth = require('j-toker');

Auth.configure({
  apiUrl: apiConfig.apiUrl,
  signOutPath: `${apiConfig.authPath}/sign_out`,
  emailSignInPath: `${apiConfig.authPath}/sign_in`,
  emailRegistrationPath: '/registration',
  accountUpdatePath: '/update',
  accountDeletePath: '/delete',
  passwordResetPath: '/reset_password',
  passwordUpdatePath: '/update_password',
  tokenValidationPath: `${apiConfig.authPath}/validate_token`,
  proxyIf() {
    return false;
  },
  proxyUrl: '/proxy',
  validateOnPageLoad: false,
  forceHardRedirect: false,
  storage: 'localStorage',
  cookieExpiry: 14,
  cookiePath: '/',

  passwordResetSuccessUrl() {
    return window.location.href;
  },

  confirmationSuccessUrl() {
    return window.location.href;
  },

  tokenFormat: {
    'access-token': 'access-token',
    'token-type': 'Bearer',
    client: 'client',
    expiry: 'expiry',
    uid: 'uid'
  },

  parseExpiry(headers) {
    // convert from ruby time (seconds) to js time (millis)
    return parseInt(headers.expiry, 10) * 1000 || null;
  },

  handleLoginResponse(resp) {
    return resp.data;
  },

  handleAccountUpdateResponse(resp) {
    return resp.data;
  },

  handleTokenValidationResponse(resp) {
    return resp.data;
  },

  authProviderPaths: {
    github: '/github',
    facebook: '/facebook',
    google: '/google_oauth2'
  }
});

export const checkAuthState = () => Storage.getData('isLoggedIn');

export const checkAuthLoading = state => get(state, 'Auth.isVerified');

export const getCurrentUser = state => get(state, 'Auth.currentUser');

export const getPermissions = state => get(state, 'Auth.currentUser.permissions');

export const setResetHeaders = headers => Storage.setData('authHeaders', { ...headers });
