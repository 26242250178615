import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  // {
  //   path: '/projects',
  //   component: asyncComponent(() => import('../Projects/admin'))
  // },
  {
    path: '/operative/',
    component: asyncComponent(() => import('../Operative'))
  },
  // {
  //   path: '/operative/projects/:projectId/inspections',
  //   component: asyncComponent(() => import('../Inspections'))
  // },
  {
    path: '/operative/projects/:projectId/',
    component: asyncComponent(() => import('../Inspections'))
  }
  // {
  //   path: '/projects/:projectId/',
  //   component: asyncComponent(() => import('../Inspections'))
  // }
];

export default routes;
