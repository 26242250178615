import React from 'react';
import { Grid, Typography, makeStyles, styled } from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';

import { palette } from '../../settings/themes/colors';
import { lightThemeV2 } from '../../settings/themes/lightThemeV2';
import { SEPARATED_DATE } from '../../utils/utils';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: palette.dimGray,
    '&>*:first-child': {
      flex: 1
    },
    '& a': {
      marginLeft: theme.spacing(1),
      color: palette.cerulean,
      textDecoration: 'underline'
    }
  },
  date: {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: '0.15px',
    paddingLeft: lightThemeV2.spacing(4)
  }
}));

const useHistoryStyles = () => styles();

const ItemCommon = styled(Grid)({
  border: `1px solid ${palette.darkGray}33`,
  background: palette.platinum,
  borderRadius: 4,
  paddingTop: lightThemeV2.spacing(1),
  paddingBottom: lightThemeV2.spacing(1),
  paddingLeft: lightThemeV2.spacing(2),
  paddingRight: lightThemeV2.spacing(2),
  marginBottom: lightThemeV2.spacing(1)
});

export const ItemRecord = ({ event, link, createdAt, controls }) => {
  const classes = useHistoryStyles();

  return (
    <ItemCommon container className={classes.root}>
      <Typography>
        {event}
        <a href="">{link}</a>
      </Typography>
      <Typography className={classes.date}>{moment(createdAt).format(SEPARATED_DATE)}</Typography>
      {controls}
    </ItemCommon>
  );
};

ItemRecord.propTypes = {
  event: PropTypes.string,
  link: PropTypes.string,
  createdAt: PropTypes.string,
  controls: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};
