import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Inspection } from './Inspection';
import projectsMenuStyles from './ProjectsMenu.styles';

const ProjectMenuItemComponent = ({ classes, label, active, linkTo, onItemMouseOver, onItemMouseOut, project, onClick }) => {
  const menuItem = (
    <div>
      <MenuItem
        className={`${classes.item} ${classes.item}--small ${linkTo ? `${classes.item}--link` : ''} ${active ? 'selected' : ''}`}
        onClick={() => onClick()}
        onMouseOver={onItemMouseOver || null}
        onMouseOut={onItemMouseOut || null}
      >
        <Grid container>
          <Grid item md={12} xs={12}>
            {label}
          </Grid>
          <Grid item md={12} xs={12} />
        </Grid>
      </MenuItem>
      {active ? <Inspection projectId={project.id} key={project.id} /> : null}
    </div>
  );

  return (
    <Paper className={`${classes.root}__paper`} elevation={0} square={true}>
      {linkTo ? (
        <Link className={`${classes.item}__link`} to={linkTo}>
          {menuItem}
          <ArrowForwardIosIcon className={`${classes.item}__link__icon`} />
        </Link>
      ) : (
        menuItem
      )}
    </Paper>
  );
};

ProjectMenuItemComponent.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string,
  active: PropTypes.bool,
  linkTo: PropTypes.string,
  onItemMouseOver: PropTypes.func,
  onItemMouseOut: PropTypes.func,
  project: PropTypes.object,
  onClick: PropTypes.func
};

export const ProjectMenuItem = withStyles(projectsMenuStyles)(ProjectMenuItemComponent);
