import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { PropTypes } from 'prop-types';
import { FormLabel } from '@material-ui/core';
import { useBeneficiaryFormStyles } from './BeneficiaryForm.styles';
import { MappingFormFields } from './MappingFormFields';
import { WrapRequiredLabel } from '../atoms/WrapRequiredLabel';
import { GroupTypesSelector } from '../molecules/GroupTypesSelector';

export const ContactGroupForm = ({ formRef, onSubmit, defaultValues = {} }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useBeneficiaryFormStyles();

  const validationSchema = () =>
    object().shape({
      name: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      email: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      contactGroupTypeId: string().required(<FormattedMessage id="beneficiaries.form.required" />),
      phoneNumber: string().required(<FormattedMessage id="beneficiaries.form.required" />)
    });
  const { handleSubmit, getFieldProps, errors } = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: submitValues => {
      const { name, email, contactGroupTypeId, groupObject, phoneNumber, note, ...otherValues } = submitValues;

      onSubmit({
        ...otherValues,
        name,
        email,
        contactGroupTypeId,
        object: groupObject,
        note,
        phoneNumber
      });
    }
  });

  formRef.current = () => {
    handleSubmit();
  };

  const dataFields = [
    { children: intlMessages['group.form.section.information'], component: FormLabel, xs: 12 },
    { label: <WrapRequiredLabel label={intlMessages['group.name']} />, name: 'name', xs: 6 },
    {
      label: <WrapRequiredLabel label={intlMessages['group.form.section.type']} />,
      name: 'contactGroupTypeId',
      component: GroupTypesSelector,
      xs: 6
    },
    { label: intlMessages['group.form.section.object'], name: 'groupObject', xs: 12, multiline: true, minRows: 4 },
    { children: intlMessages['beneficiaries.section.contactInformation'], component: FormLabel, xs: 12 },
    { label: <WrapRequiredLabel label={intlMessages['common.email']} />, name: 'email', xs: 6 },
    { label: <WrapRequiredLabel label={intlMessages['common.phone']} />, name: 'phoneNumber', type: 'tel', xs: 6 },
    { children: intlMessages['beneficiaries.form.section.note'], component: FormLabel, xs: 12 },
    { label: intlMessages['beneficiaries.form.notes'], name: 'note', xs: 12, multiline: true, minRows: 4 }
  ];

  return (
    <form style={{ width: '100%' }} className={classes.root}>
      <MappingFormFields fields={dataFields} getFieldProps={getFieldProps} errors={errors} />
    </form>
  );
};

ContactGroupForm.propTypes = {
  formRef: PropTypes.shape({
    current: PropTypes.func
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object
};
