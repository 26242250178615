import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  error: {
    marginLeft: 14,
    marginRight: 14,
    fontSize: '0.75rem',
    fontWeight: 400
  }
});

export const ErrorMessage = ({ error, id = 'common.field.required', children }) => {
  const classes = useStyles();

  return (
    <>
      {error && (
        <Typography className={classes.error} color="error">
          {children || <FormattedMessage id={id} />}
        </Typography>
      )}
    </>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string])
};
