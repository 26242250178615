import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_BULK_ADMINISTRATIVE_DIVISIONS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS,
  GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL
} from '../action-types';

import { getBulkAdministrativeDivisions } from './helper';

export function* getBulkAdminDivisionsRequest() {
  yield takeEvery(GET_BULK_ADMINISTRATIVE_DIVISIONS, function*({ payload }) {
    const countryId = payload.countryId;
    const resp = yield call(getBulkAdministrativeDivisions, { countryId, ...payload });

    if (resp.status === 200) {
      yield put({
        type: GET_BULK_ADMINISTRATIVE_DIVISIONS_SUCCESS,
        payload: { data: resp.data, ...payload }
      });
    } else {
      yield put({
        type: GET_BULK_ADMINISTRATIVE_DIVISIONS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(getBulkAdminDivisionsRequest)]);
}
