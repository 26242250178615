import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { transformSnake2Camel } from './utils';
import { orgTypesObjToArr } from '../utils/utils';

export const useGetGroups = ({ clientId, params }) => {
  const queryFn = async context => {
    const [clientId, params] = context.queryKey;
    const result = await getApi().get(`clients/${clientId}/org_groups`, params);

    return result.data;
  };

  return useQuery({
    queryKey: [clientId, params, 'useGetGroups'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetOrgGroupsMetaTypes = ({ clientId, params }) => {
  const queryFn = async context => {
    const queryKey = context.queryKey;
    const result = await getApi().get(`clients/${queryKey[0]}/org_groups/types`, queryKey[1]);

    return transformSnake2Camel(result.data);
  };

  return useQuery({
    queryKey: [clientId, params, 'useGetOrgGroupsMetaTypes'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetGroupsTypes = ({ clientId, orgGroupMeta, enabled = false }) => {
  const queryFn = async context => {
    const queryKey = context.queryKey;
    const metaTypes = orgTypesObjToArr(queryKey[1]);

    const result = await Promise.all(
      metaTypes.map(async meta => {
        const { data } = await getApi().get(`clients/${queryKey[0]}/org_groups`, { params: { org_group_type_id: meta.id } });

        return {
          ...meta,
          types: transformSnake2Camel(data)
        };
      })
    );

    return result;
  };

  return useQuery({
    queryKey: [clientId, orgGroupMeta, 'useGetGroupsTypes'],
    queryFn,
    enabled,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetGroupMembers = ({ groupId, params }) => {
  const queryFn = async context => {
    const [clientId, params] = context.queryKey;

    const result = await getApi().get(`contact_groups/${groupId}/contact_group_members`, params);

    return result.data;
  };

  return useQuery({
    queryKey: [groupId, params, 'GET_GroupMembers'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useUnassignMemberToGroup = options =>
  useMutation(
    ({ groupId, memberId }) =>
      getApi()
        .delete(`/contact_groups/${groupId}/contact_group_members/${memberId}`)
        .then(axiosResult => axiosResult.data),
    options
  );

export const useAssignMemberToGroup = options =>
  useMutation(
    ({ groupId, params }) =>
      getApi()
        .post(`/contact_groups/${groupId}/contact_group_members/`, { contact_group_member: params })
        .then(axiosResult => axiosResult.data),
    options
  );

export const useGetGroupTypeRoles = ({ contactGroupTypeId, params }) => {
  const queryFn = async context => {
    const [contactGroupTypeId, params] = context.queryKey;

    const result = await getApi().get(`contact_group_type/${contactGroupTypeId}/contact_group_type_roles`, params);

    return result.data;
  };

  return useQuery({
    queryKey: [contactGroupTypeId, params, 'GET_GroupTypeRoles'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
