import { makeStyles } from '@material-ui/core/styles';
import { palette } from '../../../../settings/themes/colors';

const useStyles = makeStyles(theme => ({
  lowStyle: {
    color: palette.progressLevel.LOW,
    fontWeight: theme.typography.fontWeightExtra,
    paddingLeft: 3
  },
  highStyle: {
    color: palette.progressLevel.HIGH,
    fontWeight: theme.typography.fontWeightExtra,
    paddingLeft: 3
  },
  defaultStyle: {
    fontWeight: theme.typography.fontWeightExtra,
    paddingLeft: 3
  }
}));

export const useVariationPercentage = () => {
  const { lowStyle, highStyle, defaultStyle } = useStyles();

  return {
    variationLowClasses: {
      body1: highStyle
    },
    variationHighClasses: {
      body1: lowStyle
    },
    variationDefaultClasses: {
      body1: defaultStyle
    }
  };
};
