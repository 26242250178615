import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { SelectField } from './SelectField';

export const LEGALLY_INCORPORATED = 'legally_incorporated';
export const UNINCORPORATED = 'unincorporated';
export const IN_PROCESS = 'in_the_process_of_incorporation';

const LEGAL_STATUS_TYPES = [LEGALLY_INCORPORATED, UNINCORPORATED, IN_PROCESS];

export const LegalStatusSelector = props => (
  <SelectField {...props}>
    {LEGAL_STATUS_TYPES.map((value, index) => (
      <MenuItem key={index} value={value}>
        <FormattedMessage id={`beneficiaries.${value}`} />
      </MenuItem>
    ))}
  </SelectField>
);
