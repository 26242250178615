import { useMutation, useQuery } from '@tanstack/react-query';
import { getApi } from '../api';
import { getCustomFields } from '../redux/admin/helper';

const MAPPING_PROJECT_FIELDS = {
  name: 'name',
  description: 'description',
  projectType: 'project_type',
  cfValues: 'cf_values',
  orgGroupId: 'org_group_id',
  externalId: 'external_id',
  manage_contract: 'manage_contract',
  mainContract: 'main_contract'
};

const MAPPING_MAIN_CONTRACT = {
  contractObject: 'contract_object',
  contractorId: 'contractor_id',
  originalTermInDays: 'original_term_in_days',
  originalTerminationDate: 'original_termination_date',
  contractNumber: 'contract_number',
  referenceNumber1: 'reference_number1',
  referenceNumber2: 'reference_number2',
  isMain: 'main_contract',
  signingDate: 'signing_date',
  startDate: 'start_date',
  responsibleId: 'responsible_id',
  status: 'status',
  currency: 'currency',
  financialProgressMode: 'financial_progress_mode',
  originalAmount: 'original_amount'
};

const serializeMainContract = data => {
  const params = {};

  Object.entries(MAPPING_MAIN_CONTRACT).forEach(([key, outputKey]) => {
    params[outputKey] = data[key];
  });

  return params;
};

const serializeParams = data => {
  const params = {};

  Object.entries(MAPPING_PROJECT_FIELDS).forEach(([key, outputKey]) => {
    if (key === 'cfValues') {
      params[outputKey] = getCustomFields(data[key]);
      return;
    }
    if (key === 'mainContract') {
      params[outputKey] = serializeMainContract(data[key]);
      return;
    }

    if (data[key] === '') {
      params[outputKey] = null;
      return;
    }

    params[outputKey] = data[key];
  });

  return params;
};

export const useGetProject = ({ clientId, projectId }) => {
  const queryKey = [{ clientId, projectId }, 'GET_project'];

  const queryFn = async context => {
    const [query] = context.queryKey;
    const result = await getApi().get(`clients/${query.clientId}/projects/${query.projectId}/`);

    return result.data;
  };

  return useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useGetProjects = ({ clientId, params }) => {
  const queryFn = async context => {
    const queryKey = context.queryKey;
    const result = await getApi().get(`clients/${queryKey[0]}/projects/`, { params: queryKey[1] });

    if (!result.data.projects) {
      return {
        meta: {
          current_page: null,
          next_page: null,
          prev_page: null,
          total_count: null,
          total_pages: null
        },
        projects: result.data
      };
    }

    return result.data;
  };

  return useQuery({
    queryKey: [clientId, params, 'GET_projects'],
    queryFn,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};

export const useCreateProject = () => {
  const { data, mutate } = useMutation(async ({ clientId, params }) =>
    getApi().post(`clients/${clientId}/projects/`, serializeParams(params))
  );

  return { ...data, mutate };
};

export const useUpdateProject = () => {
  const { data, mutate } = useMutation(async ({ clientId, projectId, params }) =>
    getApi().put(`clients/${clientId}/projects/${projectId}/`, serializeParams(params))
  );

  return { ...data, mutate };
};

// TODO: Do more Research to see if we can improve it avoiding creating a html a link for downloading xlsx.
export const useGenerateProjectsView = () => {
  const { mutate } = useMutation(async ({ clientId, params }) => {
    const response = await getApi().post(`clients/${clientId}/projects/xlsx`, params, {
      headers: { 'Access-Control-Expose-Headers': 'Content-Disposition' },
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;

    const contentDisposition = response.headers['content-disposition'];
    let filename = 'file.xlsx';

    if (contentDisposition) {
      const match = contentDisposition.match(/filename="(.+)"/i);

      if (match && match[1]) filename = match[1];
    }

    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  });

  return { mutate };
};
