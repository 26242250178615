export const removeDecimalsFromAmount = amount => {
  if (!amount) return;
  if (typeof amount === 'number') {
    return amount;
  }

  return parseFloat(amount.replace(/,/g, '')) || 0;
};

export const debounce = (func, delay) => {
  let timeoutId;

  return function(...args) {
    const context = this;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export const utils = {
  removeDecimalsFromAmount
};
