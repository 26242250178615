import asyncComponent from '../../utils/AsyncFunc';

const routes = [
  {
    path: '/analytic/',
    component: asyncComponent(() => import('../Analytic'))
  }
];

export default routes;
