import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../utils/auth';
import { DivisionSelector } from './DivisionSelector';
import { CountryAutocompleteSelector } from './CountryAutocompleteSelector';

const DEFAULT_LOCATION = {
  country: undefined,
  level1: undefined,
  level2: undefined,
  level3: undefined,
  level4: undefined,
  level5: undefined
};

const LocationSelectorComponent = ({ onChange = () => {}, currentUser, defaultValue, ...props }) => {
  const [locationSelection, setLocationSelection] = useState(DEFAULT_LOCATION);
  const { messages: intlMessages } = useIntl();

  useEffect(() => {
    if (!defaultValue) {
      return;
    }

    setLocationSelection({
      ...DEFAULT_LOCATION,
      country: defaultValue.country,
      level1: defaultValue.level1,
      level2: defaultValue.level2,
      level3: defaultValue.level3,
      level4: defaultValue.level4,
      level5: defaultValue.level5
    });
  }, []);

  const changeBehavior = (value, key) => {
    if (/^level[0-9]$/.test(key)) {
      const levelNumber = parseInt(key.replace('level', ''), 10);
      const resetSelection = {};

      for (let i = levelNumber + 1; i <= 5; i++) {
        resetSelection[`level${i}`] = undefined;
      }
      const nextLocationSelection = { ...locationSelection, ...resetSelection, [key]: value };

      setLocationSelection(nextLocationSelection);
      onChange(nextLocationSelection);
      return;
    }

    const nextLocationSelection = { ...DEFAULT_LOCATION, [key]: value };

    onChange(nextLocationSelection);
    setLocationSelection(nextLocationSelection);
  };

  const administrativeDivisions = useMemo(() => {
    if (!locationSelection.country || locationSelection.country.maxLevels <= 0) return;

    const divisionsComponents = [];

    for (let i = 1; i <= locationSelection.country.maxLevels; i++) {
      divisionsComponents.push(
        <Grid item xs={4} key={`administrative_division-${i}`}>
          <DivisionSelector
            level={i}
            parent={i !== 1 && locationSelection[`level${i - 1}`] && locationSelection[`level${i - 1}`].id}
            label={locationSelection.country[`level_${i}_name`]}
            countryId={locationSelection.country.id}
            onChange={v => changeBehavior(v, `level${i}`)}
            value={locationSelection && locationSelection[`level${i}`]}
          />
        </Grid>
      );
    }

    return divisionsComponents;
  }, [locationSelection]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <CountryAutocompleteSelector
          clientId={currentUser.client_id}
          name={props.name}
          onBlur={props.onBlur}
          value={locationSelection.country}
          label={props.label || intlMessages['common.country']}
          onChange={c => changeBehavior(c, 'country')}
        />
      </Grid>
      {administrativeDivisions}
    </Grid>
  );
};

const LevelType = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string
};

LocationSelectorComponent.propTypes = {
  defaultValue: PropTypes.shape({
    country: PropTypes.shape({
      maxLevels: PropTypes.number.isRequired,
      id: PropTypes.number,
      name: PropTypes.string
    }),
    level1: PropTypes.shape(LevelType),
    level2: PropTypes.shape(LevelType),
    level3: PropTypes.shape(LevelType),
    level4: PropTypes.shape(LevelType),
    level5: PropTypes.shape(LevelType)
  }),
  name: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  currentUser: PropTypes.object
};

export const LocationSelector = connect(state => ({ currentUser: getCurrentUser(state) }))(LocationSelectorComponent);
