import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../settings/themes/default';
import { styles } from './ProgressBar.style';

const ProgressBarComponent = ({ value, colorKey, classes, children }) => (
  <div className={classes.progressBlock}>
    <span className={`${classes.progressBlock}__text`}>{children}</span>
    <div className={`${classes.progressBlock}__progress-bar`}>
      <div
        className={`${classes.progressBlock}__progress-bar__bar`}
        style={{
          width: `${value.split(',')[0]}%`,
          background: `${theme.palette.progressLevel[colorKey]}`
        }}
      />
    </div>
    <span className={`${classes.progressBlock}__stat`}>{value}</span>
  </div>
);

ProgressBarComponent.propTypes = {
  value: PropTypes.string,
  colorKey: PropTypes.string,
  classes: PropTypes.object,
  children: PropTypes.element
};

export const ProgressBar = withStyles(styles)(ProgressBarComponent);
