import { useQuery } from '@tanstack/react-query';
import { getApi } from '../api';

export const useGetIndustries = () => {
  const queryFn = async () => {
    const result = await getApi().get(`industries/`);
    return result.data;
  };

  return useQuery({
    queryFn,
    queryKey: ['GET_industries'],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  });
};
