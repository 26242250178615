import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser } from '../../utils/auth';
import { FormattedMessage } from 'react-intl';
import { useGetBeneficiaries } from '../../queries/queryBeneficiaries';
import { Autocomplete } from '@material-ui/lab';

/**
 * Receive the MuiSelector props
 * @param props
 * @param currentUser
 * @returns {JSX.Element}
 * @constructor
 */

const BeneficiarySelectorComponent = ({ currentUser, onChange, value: beneficiary, ...props }) => {
  const { data: beneficiaries = [], isLoading, isFetching } = useGetBeneficiaries({
    clientId: currentUser.client_id
  });

  const labelUI = (
    <Typography>
      <FormattedMessage id="beneficiaries.selectBeneficiary" />
      <Typography component="span">
        <i>*</i>
      </Typography>
    </Typography>
  );

  return (
    <Autocomplete
      options={beneficiaries}
      getOptionLabel={option => (typeof option === 'string' ? '' : option.name)}
      getOptionSelected={(option, value) => value === '' || option.id === value.id}
      onChange={(_, value) => onChange && onChange(value)}
      renderInput={params => <TextField label={labelUI} {...params} />}
      value={beneficiaries.find(x => beneficiary && x.id === beneficiary.id) || ''}
      disabled={props.disabled || isLoading || isFetching}
      {...props}
    />
  );
};

BeneficiarySelectorComponent.propTypes = {
  clientId: PropTypes.number,
  disabled: PropTypes.bool,
  currentUser: PropTypes.object,
  onChange: PropTypes.func,
  value: PropTypes.object
};

const mapToProps = state => ({ currentUser: getCurrentUser(state) });

export const BeneficiarySelector = connect(mapToProps)(BeneficiarySelectorComponent);
