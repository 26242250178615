import React from 'react';
import { CardContent, Divider, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { getCurrentUser } from '../../utils/auth';
import { CardSectionTitle } from './CardSectionTitle';
import { ListItems } from '../organims/ListItems';
import { AddNote } from './AddNote';
import { Card } from '../atoms/Card';
import { useCreateContactGroupNote, useGetContactGroupNotes } from '../../queries/queryContactGroups';
import { ItemContactGroupNote } from './ItemContactGroupNote';

export const useNoteSectionStyles = () =>
  makeStyles({
    listContainer: {
      flex: 1,
      maxHeight: '256px',
      overflowY: 'scroll'
    }
  })();

export const GroupNoteSectionComponent = ({ userId, groupId }) => {
  const { messages: intlMessages } = useIntl();
  const classes = useNoteSectionStyles();
  const { data: notes = [], refetch } = useGetContactGroupNotes({ groupId });
  const { mutate: createNote } = useCreateContactGroupNote({
    onSuccess: () => {
      refetch();
    }
  });

  const normalizedNotes = notes.map(note => ({
    note: note.text,
    responsible: note.creator.fullName,
    creationDate: note.createdAt,
    id: note.id,
    updateDate: note.updatedAt
  }));
  const handleAddNote = note => {
    createNote({
      note,
      groupId,
      creatorId: userId
    });
  };

  return (
    <Card variant={'stretch'} gap={2}>
      <CardContent>
        <CardSectionTitle title={intlMessages['beneficiaries.section.notes']} />
        <ListItems
          dataSource={normalizedNotes}
          className={classes.listContainer}
          emptyMsg={intlMessages['beneficiaries.empty.notes']}
          component={props => <ItemContactGroupNote groupId={groupId} userId={userId} {...props} refetch={refetch} />}
        />
        <Divider />
        <AddNote onSubmit={handleAddNote} label={intlMessages['beneficiaries.addNoteLabel']} />
      </CardContent>
    </Card>
  );
};

GroupNoteSectionComponent.propTypes = {
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  notes: PropTypes.array
};

const mapStateToProps = state => ({ userId: getCurrentUser(state).id, clientId: getCurrentUser(state).client_id });

export const GroupNoteSection = connect(mapStateToProps)(GroupNoteSectionComponent);
