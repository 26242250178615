import { Button, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { getCurrentUser } from '../../utils/auth';
import { EmptyContent } from '../molecules/EmptyContent';
import { useContactsClasses } from './Beneficiaries.style';
import { GROUPS_HEAD } from '../../containers/Beneficiaries/constants';
import { LoadingCircularProgress } from '../atoms/LoadingCircularProgress';
import { useGetContactGroups } from '../../queries/queryContactGroups';
import { GroupsTable } from './GroupsTable';

export const GroupsContentComponent = ({ history, currentUser }) => {
  const head = GROUPS_HEAD;
  const { messages: intlMessages } = useIntl();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const classes = useContactsClasses();
  const handleButtonClick = () => {
    history.push('/groups/create');
  };

  const { client_id: clientId } = currentUser || {};
  const { data: contactGroups, isLoading } = useGetContactGroups({
    clientId,
    params: { page: page + 1, per_page: pageSize }
  });
  const { contact_groups: groups = [], meta } = contactGroups || {};
  const onChangePagination = (currentPage, currentPageSize) => {
    setPage(currentPage);
    setPageSize(currentPageSize);
  };

  const renderGroups = () => {
    if (isLoading) {
      return (
        <Grid container>
          <LoadingCircularProgress />
        </Grid>
      );
    }

    return (
      <>
        {groups.length > 0 && (
          <GroupsTable
            data={groups}
            dataColumns={head}
            onChangePagination={onChangePagination}
            pagination={{ page, pageSize, count: meta.total_count }}
          />
        )}

        {groups.length <= 0 && (
          <EmptyContent>
            {intlMessages['beneficiaries.groupsEmptyContentMessage']}
            <Button variant="outlined" onClick={handleButtonClick}>
              <Typography variant="button">{intlMessages['group.addGroup']}</Typography>
            </Button>
          </EmptyContent>
        )}
      </>
    );
  };

  return <Grid classes={{ root: classes.root }}>{renderGroups()}</Grid>;
};

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state)
});

GroupsContentComponent.propTypes = {
  currentUser: PropTypes.object,
  contactType: PropTypes.string,
  emptyContentMessage: PropTypes.string,
  history: PropTypes.object
};

export const GroupsContent = withRouter(connect(mapStateToProps, {})(GroupsContentComponent));
