const mapOptions = {
  scrollwheel: false,
  disableDefaultUI: true,
  fullscreenControl: false,
  mapTypeControl: true
};

export default {
  mapOptions
};
