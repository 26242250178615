import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { MenuItem } from '@material-ui/core';
import { SelectField } from '../../../../../components/molecules/SelectField.jsx';

export const ExecutorFieldInvoice = ({ implementingUnits, label, value, ...otherProps }) => {
  const { messages: intlMessages } = useIntl();

  const defaultPlaceholder = intlMessages['project.budget.invoice.addImplementingUnit'];
  const defaultLabel = intlMessages['project.budget.invoice.register.form.label.executor'];

  const labelToRender = label || defaultLabel;

  return (
    <SelectField label={!value || value === '' ? defaultPlaceholder : labelToRender} value={value} {...otherProps}>
      {implementingUnits.map((implementingUnit, index) => (
        <MenuItem key={index} value={implementingUnit.id}>
          {implementingUnit.name}
        </MenuItem>
      ))}
    </SelectField>
  );
};

ExecutorFieldInvoice.propsTypes = {
  implementingUnits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      amount: PropTypes.number,
      contact: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
      })
    })
  ).isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string
};
