import React from 'react';
import { Box } from '@material-ui/core';
import { useBodyContentStyles } from './BodyContent.styles';
import { PaperContainer } from '../atoms/PaperContainer';

export const BodyContent = ({ children, component: Component = PaperContainer }) => {
  const classes = useBodyContentStyles();

  return (
    <Box className={classes.root}>
      <Component>{children}</Component>
    </Box>
  );
};
