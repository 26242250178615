import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  ADD_ADMIN_USERS,
  ADD_ADMIN_MODEL_USERS,
  GET_ADMIN_USERS,
  CREATE_ADMIN_USERS,
  UPDATE_ADMIN_USERS,
  DELETE_ADMIN_USERS,
  GET_ADMIN_MODEL_USERS,
  INCLUDE_ADMIN_MODEL_USERS,
  REMOVE_ADMIN_MODEL_USERS,
  GET_ADMIN_CF_DEFINITIONS,
  ADD_ADMIN_CF_DEFINITIONS,
  CREATE_ADMIN_PROJECT,
  ADMIN_PROJECT_CREATED,
  ADD_ADMIN_USER_MODELS,
  GET_ADMIN_USER_MODELS,
  INCLUDE_ADMIN_USER_MODELS,
  REMOVE_ADMIN_USER_MODELS,
  ADD_ADMIN_MODEL_MODELS,
  GET_ADMIN_MODEL_MODELS,
  INCLUDE_ADMIN_MODEL_MODELS,
  REMOVE_ADMIN_MODEL_MODELS,
  ADMIN_USER_RESET_PASSWORD,
  ADMIN_USER_RESET_PASSWORD_SUCCESS,
  CREATE_ADMIN_ORG_GROUP,
  UPDATE_ADMIN_ORG_GROUP,
  CREATE_ADMIN_ORG_GROUP_SUCCESS,
  UPDATE_ADMIN_ORG_GROUP_SUCCESS,
  CREATE_ADMIN_ORG_GROUP_FAIL,
  UPDATE_ADMIN_ORG_GROUP_FAIL,
  GET_ADMIN_FORMULARIES,
  GET_ADMIN_FORMULARIES_SUCCESS,
  GET_ADMIN_FORMULARIES_FAIL,
  GET_ADMIN_SELECTED_PROJECT_SUCCESS,
  GET_ADMIN_SELECTED_PROJECT_FAIL,
  GET_ADMIN_SELECTED_PROJECT,
  GET_ADMIN_API_CREDENTIALS,
  GET_ADMIN_API_CREDENTIALS_SUCCESS,
  GET_ADMIN_API_CREDENTIALS_FAIL
} from '../action-types';
import {
  getClientUsers,
  getModelCfDefinitions,
  getModelUsers,
  addModelUsers,
  removeModelUsers,
  createNewProject,
  createUser,
  updateUser,
  deleteUser,
  getUserModels,
  addUserModels,
  removeUserModels,
  getModelModels,
  addModelModels,
  removeModelModels,
  resetUserPassword,
  createOrgGroup,
  updateOrgGroup,
  getFormularies,
  getSelectedProject,
  getUserAPICredentials
} from './helper';

export function* usersRequest() {
  yield takeEvery(GET_ADMIN_USERS, function*({ payload }) {
    const resp = yield call(getClientUsers, payload);

    yield put({
      type: ADD_ADMIN_USERS,
      payload: { data: resp }
    });
  });
}

export function* createUserRequest() {
  yield takeEvery(CREATE_ADMIN_USERS, function*({ payload }) {
    yield call(createUser, payload);
    yield put({ type: GET_ADMIN_USERS, payload });
  });
}

export function* updateUserRequest() {
  yield takeEvery(UPDATE_ADMIN_USERS, function*({ payload }) {
    yield call(updateUser, payload);
    yield put({ type: GET_ADMIN_USERS, payload });
  });
}

export function* deleteUserRequest() {
  yield takeEvery(DELETE_ADMIN_USERS, function*({ payload }) {
    yield call(deleteUser, payload);
    yield put({ type: GET_ADMIN_USERS, payload });
  });
}

export function* modelCfDefinitionsRequest() {
  yield takeEvery(GET_ADMIN_CF_DEFINITIONS, function*({ payload }) {
    const { type } = payload;
    const resp = yield call(getModelCfDefinitions, payload);

    yield put({
      type: ADD_ADMIN_CF_DEFINITIONS,
      payload: {
        type,
        data: resp
      }
    });
  });
}

export function* modelUsersRequest() {
  yield takeEvery(GET_ADMIN_MODEL_USERS, function*({ payload }) {
    const { id, type } = payload;
    const resp = yield call(getModelUsers, payload);

    yield put({
      type: ADD_ADMIN_MODEL_USERS,
      payload: {
        id,
        type,
        users: resp
      }
    });
  });
}

export function* modelUsersIncludeRequest() {
  yield takeEvery(INCLUDE_ADMIN_MODEL_USERS, function*({ payload }) {
    const { id, type } = payload;
    yield call(addModelUsers, payload);

    yield put({
      type: GET_ADMIN_MODEL_USERS,
      payload: {
        id,
        type
      }
    });
  });
}

export function* modelUsersRemoveRequest() {
  yield takeEvery(REMOVE_ADMIN_MODEL_USERS, function*({ payload }) {
    const { id, type } = payload;
    yield call(removeModelUsers, payload);

    yield put({
      type: GET_ADMIN_MODEL_USERS,
      payload: {
        id,
        type
      }
    });
  });
}

export function* userModelsRequest() {
  yield takeEvery(GET_ADMIN_USER_MODELS, function*({ payload }) {
    const { id, type } = payload;
    const resp = yield call(getUserModels, payload);

    yield put({
      type: ADD_ADMIN_USER_MODELS,
      payload: {
        id,
        type,
        models: resp
      }
    });
  });
}

export function* userModelsIncludeRequest() {
  yield takeEvery(INCLUDE_ADMIN_USER_MODELS, function*({ payload }) {
    const { id, type } = payload;
    yield call(addUserModels, payload);

    yield put({
      type: GET_ADMIN_USER_MODELS,
      payload: {
        id,
        type
      }
    });
  });
}

export function* userModelsRemoveRequest() {
  yield takeEvery(REMOVE_ADMIN_USER_MODELS, function*({ payload }) {
    const { id, type } = payload;
    yield call(removeUserModels, payload);

    yield put({
      type: GET_ADMIN_USER_MODELS,
      payload: {
        id,
        type
      }
    });
  });
}

export function* modelModelsRequest() {
  yield takeEvery(GET_ADMIN_MODEL_MODELS, function*({ payload }) {
    const { id, sourceType, destinyType } = payload;
    const resp = yield call(getModelModels, payload);

    yield put({
      type: ADD_ADMIN_MODEL_MODELS,
      payload: {
        sourceId: id,
        sourceType,
        destinyType,
        destinyModels: resp
      }
    });
  });
}

export function* modelModelsIncludeRequest() {
  yield takeEvery(INCLUDE_ADMIN_MODEL_MODELS, function*({ payload }) {
    const { id, sourceType, destinyType } = payload;
    yield call(addModelModels, payload);

    yield put({
      type: GET_ADMIN_MODEL_MODELS,
      payload: {
        id,
        sourceType,
        destinyType
      }
    });
  });
}

export function* modelModelsRemoveRequest() {
  yield takeEvery(REMOVE_ADMIN_MODEL_MODELS, function*({ payload }) {
    const { id, sourceType, destinyType } = payload;
    yield call(removeModelModels, payload);

    yield put({
      type: GET_ADMIN_MODEL_MODELS,
      payload: {
        id,
        sourceType,
        destinyType
      }
    });
  });
}

export function* createProjectRequest() {
  yield takeEvery(CREATE_ADMIN_PROJECT, function*({ payload }) {
    yield call(createNewProject, payload);
    yield put({ type: ADMIN_PROJECT_CREATED });
  });
}

export function* userResetPassword() {
  yield takeEvery(ADMIN_USER_RESET_PASSWORD, function*({ payload }) {
    const resp = yield call(resetUserPassword, payload);
    yield put({
      type: ADMIN_USER_RESET_PASSWORD_SUCCESS,
      payload: {
        wasSuccess: resp.status === 204
      }
    });
  });
}

export function* createOrgGroupRequest() {
  yield takeEvery(CREATE_ADMIN_ORG_GROUP, function*({ payload }) {
    const resp = yield call(createOrgGroup, payload);
    yield put({
      type: resp.status === 201 ? CREATE_ADMIN_ORG_GROUP_SUCCESS : CREATE_ADMIN_ORG_GROUP_FAIL
    });
  });
}

export function* updateOrgGroupRequest() {
  yield takeEvery(UPDATE_ADMIN_ORG_GROUP, function*({ payload }) {
    const resp = yield call(updateOrgGroup, payload);
    yield put({
      type: resp.status === 204 ? UPDATE_ADMIN_ORG_GROUP_SUCCESS : UPDATE_ADMIN_ORG_GROUP_FAIL
    });
  });
}

export function* getFormulariesRequest() {
  yield takeEvery(GET_ADMIN_FORMULARIES, function*({ payload }) {
    const resp = yield call(getFormularies, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_ADMIN_FORMULARIES_SUCCESS,
        payload: { formularies: resp.data }
      });
    } else {
      yield put({
        type: GET_ADMIN_FORMULARIES_FAIL
      });
    }
  });
}

export function* getSelectedProjectRequest() {
  yield takeEvery(GET_ADMIN_SELECTED_PROJECT, function*({ payload }) {
    const resp = yield call(getSelectedProject, payload);
    if (resp.status === 200) {
      yield put({
        type: GET_ADMIN_SELECTED_PROJECT_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_ADMIN_SELECTED_PROJECT_FAIL
      });
    }
  });
}

export function* getUserAPICredentialsRequest() {
  yield takeEvery(GET_ADMIN_API_CREDENTIALS, function*({ payload }) {
    const resp = yield call(getUserAPICredentials, payload);

    if (resp.status === 200) {
      yield put({
        type: GET_ADMIN_API_CREDENTIALS_SUCCESS,
        payload: { data: resp.data }
      });
    } else {
      yield put({
        type: GET_ADMIN_API_CREDENTIALS_FAIL
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(usersRequest),
    fork(modelUsersRequest),
    fork(modelUsersIncludeRequest),
    fork(modelUsersRemoveRequest),
    fork(modelCfDefinitionsRequest),
    fork(createProjectRequest),
    fork(createUserRequest),
    fork(updateUserRequest),
    fork(deleteUserRequest),
    fork(userModelsRequest),
    fork(userModelsIncludeRequest),
    fork(userModelsRemoveRequest),
    fork(modelModelsRequest),
    fork(modelModelsIncludeRequest),
    fork(modelModelsRemoveRequest),
    fork(userResetPassword),
    fork(createOrgGroupRequest),
    fork(updateOrgGroupRequest),
    fork(getFormulariesRequest),
    fork(getSelectedProjectRequest),
    fork(getUserAPICredentialsRequest)
  ]);
}
