import theme from './themes';

const apiConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
  env: process.env.REACT_APP_ENV_IMAGE,
  authPath: `auth`
};

/**
 * Google API key:
 * https://maps.googleapis.com/maps/api/js?key=AIzaSyACZHrAGn2moZlseagDsbEp8rZJ6EVXwhA
 */
const googleConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY
};

const siteConfig = {
  siteName: 'Captudata',
  siteIcon: 'ion-flash',
  siteFooter: 'Captudata ©2019, Inc',
  homePage: '/executive',
  prefix: 'captudata'
};

const language = 'spanish';

export { apiConfig, googleConfig, siteConfig, language, theme };
